import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { CircularProgress, Stack } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import {
  approveOrCancelInitialBillingAction,
  deleteAdminServiceBillingById,
  getAdminServiceBillingById,
  recalculateAdminServiceBillingById,
  sapSendAdminServiceBillingById,
} from "../../../../../domain/portal/admin/billings/initial/BillingsInitial.Repository"
import { InitialBillingInfoHeader } from "./fragments/InitialBillingInfoHeader"
import { ServiceBillingState } from "../../../../../data/generated-sources/openapi"
import { PrimaryButton } from "../../../../../uikit/button"
import { StatusType } from "../../../../../domain/Domain.Model"
import { AxiosErrorDataType } from "../../../../Shared.Utils"
import { InitialBillingDetailsTable } from "./fragments/InitialBillingDetailsTable"
import { ErrorAlert, OptionalSuccessAlert } from "../../../../../uikit/Shared.Alert"
import { ActivateIcon, DeactivateIcon, RefreshIcon, RemoveIcon, SendIcon } from "../../../../../uikit/Shared.Icon"

export const InitialBillingDetails = ({ history, match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("billings-initial")
  const { billingId } = params

  const {
    data: initialBilling,
    remove,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery(["getInitialBilling"], () => getAdminServiceBillingById(billingId), {
    enabled: !!billingId,
  })

  const {
    mutate: recalculateServiceBilling,
    isLoading: isRecalculateServiceBilling,
    isSuccess: isSuccessRecalculateServiceBilling,
    isError: isRecalculateError,
    error: recalculateError,
  } = useMutation(["recalculateServiceBilling"], () => recalculateAdminServiceBillingById(billingId))

  const {
    mutate: changeBillingState,
    isLoading: isChangingBillingState,
    isError: isChangeBillingStateError,
    error: changeBillingStateError,
  } = useMutation(
    ["changeBillingState"],
    (billingAction: "approve" | "cancel") => approveOrCancelInitialBillingAction(billingId, billingAction),
    {
      onSuccess: () => refetch(),
    },
  )

  const {
    mutate: sapSendBilling,
    isLoading: isSapSendingBilling,
    isError: isSapSendBillingError,
    error: sapSendBillingError,
    isSuccess: isSapSendBilling,
  } = useMutation(["sapSendBilling"], () => sapSendAdminServiceBillingById(billingId))

  const navigateToBillings = () => history.push("/billings/initial")

  const {
    mutate: deleteBilling,
    isLoading: isDeletingBilling,
    isError: isDeleteBillingError,
    error: deleteBillingError,
  } = useMutation(["deleteBilling"], () => deleteAdminServiceBillingById(billingId), {
    onSuccess: () => navigateToBillings(),
  })

  useEffect(() => {
    return () => remove()
  }, [remove])

  if (isFetching && !isError) {
    return <CircularProgress />
  }

  const isErrorVisible =
    isError || isRecalculateError || isChangeBillingStateError || isSapSendBillingError || isDeleteBillingError

  const errorMessage = (
    (error ||
      recalculateError ||
      changeBillingStateError ||
      sapSendBillingError ||
      deleteBillingError) as AxiosErrorDataType
  )?.code

  return (
    <>
      <ErrorAlert
        scrollOnDisplay
        visible={isErrorVisible}
        message={t(`error-codes:${errorMessage ?? "OTHER"}`)}
        retry={() => (isSapSendBillingError ? sapSendBilling() : null)}
        retryButtonLabel={isSapSendBillingError ? t("task.retransmit") : ""}
      />
      <OptionalSuccessAlert
        scrollOnDisplay
        show={isSapSendBilling || isSuccessRecalculateServiceBilling}
        message={isSapSendBilling ? t("details.action.billingSap.success") : t("details.action.recalculate.success")}
      />

      {initialBilling && (
        <>
          <InitialBillingInfoHeader {...initialBilling} />

          <InitialBillingDetailsTable {...initialBilling} />

          <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} flexWrap={"wrap"}>
            <Stack direction={"row"} spacing={1} flexWrap={"wrap"}>
              <PrimaryButton
                startIcon={<RefreshIcon fontSize="large" />}
                label={t("detail.button.recalculate")}
                isLoading={isRecalculateServiceBilling}
                disabled={initialBilling?.activeState !== ServiceBillingState.DRAFT || isRecalculateServiceBilling}
                onClick={() => recalculateServiceBilling()}
              />
              {initialBilling?.activeState !== ServiceBillingState.APPROVED && (
                <PrimaryButton
                  startIcon={<ActivateIcon fontSize="large" />}
                  label={t("detail.button.share")}
                  isLoading={isChangingBillingState}
                  disabled={initialBilling?.activeState !== ServiceBillingState.DRAFT || isChangingBillingState}
                  onClick={() => changeBillingState("approve")}
                />
              )}
              <PrimaryButton
                startIcon={<DeactivateIcon fontSize="large" />}
                label={t("detail.button.cancel")}
                isLoading={isChangingBillingState}
                disabled={initialBilling?.activeState !== ServiceBillingState.APPROVED || isChangingBillingState}
                onClick={() => changeBillingState("cancel")}
              />
              <PrimaryButton
                startIcon={<SendIcon fontSize="large" />}
                label={t("detail.button.submitToSAP")}
                isLoading={isSapSendingBilling}
                disabled={
                  isSapSendingBilling ||
                  initialBilling?.activeState !== ServiceBillingState.APPROVED ||
                  initialBilling?.accountingStatus === t(`accountingStatus.${StatusType.CREATED}`)
                }
                onClick={() => sapSendBilling()}
              />
            </Stack>
            <PrimaryButton
              startIcon={<RemoveIcon fontSize="large" />}
              label={t("detail.button.delete")}
              isLoading={isDeletingBilling}
              disabled={initialBilling?.activeState !== ServiceBillingState.DRAFT || isDeletingBilling}
              onClick={() => deleteBilling()}
            />
          </Stack>
        </>
      )}
    </>
  )
}
