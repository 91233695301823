import { t } from "i18next"
import {
  MultiServiceBillingAdminResponse,
  PagedAreaAdminResponse,
  ServiceBillingType,
} from "../../../../../data/generated-sources/openapi"
import { DomainDependencies } from "../../../../Domain.Dependencies"
import { appFormattedDate } from "../../../../Domain.Formatters"
import { StatusType } from "../../../../Domain.Model"
import { BillingsInitialList } from "./BillingsInitial.Model"

export const billingsInitialListMapper = (
  billingsInitial: MultiServiceBillingAdminResponse,
  area: PagedAreaAdminResponse,
  deps: DomainDependencies,
): BillingsInitialList => {
  return {
    billingsInitial: billingsInitial.elements
      .filter((billing) => billing.billingType === ServiceBillingType.INITIAL)
      .map((billing) => {
        const zev = area.elements.find((area) => billing.id === area.id)
        return {
          id: billing.id,
          statusType: StatusType[billing.activeState],
          sortableStatusType: t(`shared:status.${StatusType[billing.activeState]}`),
          billNumber: billing.invoiceReferenceNumber ?? "",
          date: appFormattedDate(billing.billingDate, deps),
          zevId: zev?.id ?? "",
          zevName: zev?.name ?? "",
        }
      }),
  }
}
