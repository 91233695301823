import { useGridInitialization } from "../hooks/core/useGridInitialization.js";
import { useGridInitializeState } from "../hooks/utils/useGridInitializeState.js";
import { useGridClipboard } from "../hooks/features/clipboard/useGridClipboard.js";
import { columnMenuStateInitializer, useGridColumnMenu } from "../hooks/features/columnMenu/useGridColumnMenu.js";
import { useGridColumns, columnsStateInitializer } from "../hooks/features/columns/useGridColumns.js";
import { densityStateInitializer, useGridDensity } from "../hooks/features/density/useGridDensity.js";
import { useGridCsvExport } from "../hooks/features/export/useGridCsvExport.js";
import { useGridPrintExport } from "../hooks/features/export/useGridPrintExport.js";
import { useGridFilter, filterStateInitializer } from "../hooks/features/filter/useGridFilter.js";
import { focusStateInitializer, useGridFocus } from "../hooks/features/focus/useGridFocus.js";
import { useGridKeyboardNavigation } from "../hooks/features/keyboardNavigation/useGridKeyboardNavigation.js";
import { useGridPagination, paginationStateInitializer } from "../hooks/features/pagination/useGridPagination.js";
import { useGridPreferencesPanel, preferencePanelStateInitializer } from "../hooks/features/preferencesPanel/useGridPreferencesPanel.js";
import { useGridEditing, editingStateInitializer } from "../hooks/features/editing/useGridEditing.js";
import { useGridRows, rowsStateInitializer } from "../hooks/features/rows/useGridRows.js";
import { useGridRowsPreProcessors } from "../hooks/features/rows/useGridRowsPreProcessors.js";
import { useGridParamsApi } from "../hooks/features/rows/useGridParamsApi.js";
import { rowSelectionStateInitializer, useGridRowSelection } from "../hooks/features/rowSelection/useGridRowSelection.js";
import { useGridRowSelectionPreProcessors } from "../hooks/features/rowSelection/useGridRowSelectionPreProcessors.js";
import { useGridSorting, sortingStateInitializer } from "../hooks/features/sorting/useGridSorting.js";
import { useGridScroll } from "../hooks/features/scroll/useGridScroll.js";
import { useGridEvents } from "../hooks/features/events/useGridEvents.js";
import { dimensionsStateInitializer, useGridDimensions } from "../hooks/features/dimensions/useGridDimensions.js";
import { rowsMetaStateInitializer, useGridRowsMeta } from "../hooks/features/rows/useGridRowsMeta.js";
import { useGridStatePersistence } from "../hooks/features/statePersistence/useGridStatePersistence.js";
import { useGridColumnSpanning } from "../hooks/features/columns/useGridColumnSpanning.js";
import { useGridColumnGrouping, columnGroupsStateInitializer } from "../hooks/features/columnGrouping/useGridColumnGrouping.js";
import { useGridVirtualization, virtualizationStateInitializer } from "../hooks/features/virtualization/index.js";
import { columnResizeStateInitializer, useGridColumnResize } from "../hooks/features/columnResize/useGridColumnResize.js";
export const useDataGridComponent = (inputApiRef, props) => {
  const apiRef = useGridInitialization(inputApiRef, props);

  /**
   * Register all pre-processors called during state initialization here.
   */
  useGridRowSelectionPreProcessors(apiRef, props);
  useGridRowsPreProcessors(apiRef);

  /**
   * Register all state initializers here.
   */
  useGridInitializeState(dimensionsStateInitializer, apiRef, props);
  useGridInitializeState(rowSelectionStateInitializer, apiRef, props);
  useGridInitializeState(columnsStateInitializer, apiRef, props);
  useGridInitializeState(rowsStateInitializer, apiRef, props);
  useGridInitializeState(editingStateInitializer, apiRef, props);
  useGridInitializeState(focusStateInitializer, apiRef, props);
  useGridInitializeState(sortingStateInitializer, apiRef, props);
  useGridInitializeState(preferencePanelStateInitializer, apiRef, props);
  useGridInitializeState(filterStateInitializer, apiRef, props);
  useGridInitializeState(densityStateInitializer, apiRef, props);
  useGridInitializeState(columnResizeStateInitializer, apiRef, props);
  useGridInitializeState(paginationStateInitializer, apiRef, props);
  useGridInitializeState(rowsMetaStateInitializer, apiRef, props);
  useGridInitializeState(columnMenuStateInitializer, apiRef, props);
  useGridInitializeState(columnGroupsStateInitializer, apiRef, props);
  useGridInitializeState(virtualizationStateInitializer, apiRef, props);
  useGridKeyboardNavigation(apiRef, props);
  useGridRowSelection(apiRef, props);
  useGridColumns(apiRef, props);
  useGridRows(apiRef, props);
  useGridParamsApi(apiRef);
  useGridColumnSpanning(apiRef);
  useGridColumnGrouping(apiRef, props);
  useGridEditing(apiRef, props);
  useGridFocus(apiRef, props);
  useGridPreferencesPanel(apiRef, props);
  useGridFilter(apiRef, props);
  useGridSorting(apiRef, props);
  useGridDensity(apiRef, props);
  useGridColumnResize(apiRef, props);
  useGridPagination(apiRef, props);
  useGridRowsMeta(apiRef, props);
  useGridScroll(apiRef, props);
  useGridColumnMenu(apiRef);
  useGridCsvExport(apiRef, props);
  useGridPrintExport(apiRef, props);
  useGridClipboard(apiRef, props);
  useGridDimensions(apiRef, props);
  useGridEvents(apiRef, props);
  useGridStatePersistence(apiRef);
  useGridVirtualization(apiRef, props);
  return apiRef;
};