import { combineReducers } from "redux"
import { AuthForgottenPasswordState, authForgottenPasswordReducer } from "../../auth/AuthForgottenPassword.Reducer"
import { AuthNewAccountPasswordState, authNewAccountPasswordReducer } from "../../auth/AuthNewAccountPassword.Reducer"
import { BillingsInitialListState, billingsInitialListReducer } from "./billings/initial/BillingsInitialList.Reducer"
import {
  BillingsRecurringCreateState,
  billingsRecurringCreateReducer,
} from "./billings/recurring/BillingsRecurringCreate.Reducer"
import {
  BillingsRecurringDetailState,
  billingsRecurringDetailReducer,
} from "./billings/recurring/BillingsRecurringDetail.Reducer"
import {
  BillingsRecurringListState,
  billingsRecurringListReducer,
} from "./billings/recurring/BillingsRecurringList.Reducer"
import { BuildingsCreateState, buildingsCreateReducer } from "./buildings/BuildingsCreate.Reducer"
import { BuildingsDetailState, buildingsDetailReducer } from "./buildings/BuildingsDetail.Reducer"
import { BuildingsListState, buildingsListReducer } from "./buildings/BuildingsList.Reducer"
import { BuildingsUpdateState, buildingsUpdateReducer } from "./buildings/BuildingsUpdate.Reducer"
import { MyProfileDetailState, myProfileDetailReducer } from "./my-profile/MyProfileDetail.Reducer"
import { MyProfileUpdateState, myProfileUpdateReducer } from "./my-profile/MyProfileUpdate.Reducer"
import { ProductsCreateState, productsCreateReducer } from "./products/ProductsCreate.Reducer"
import { ProductsDetailState, productsDetailReducer } from "./products/ProductsDetail.Reducer"
import { ProductsListState, productsListReducer } from "./products/ProductsList.Reducer"
import { ProductsUpdateState, productsUpdateReducer } from "./products/ProductsUpdate.Reducer"
import { ProfilesCreateState, profilesCreateReducer } from "./profiles/ProfilesCreate.Reducer"
import { ProfilesDetailState, profilesDetailReducer } from "./profiles/ProfilesDetail.Reducer"
import { ProfilesListState, profilesListReducer } from "./profiles/ProfilesList.Reducer"
import {
  ProfilesManagerSelectionState,
  profilesManagerSelectionReducer,
} from "./profiles/ProfilesManagerSelection.Reducer"
import { ProfilesUpdateState, profilesUpdateReducer } from "./profiles/ProfilesUpdate.Reducer"
import {
  ServiceComponentsSelectionState,
  serviceComponentsSelectionReducer,
} from "./service-components/ServiceComponentSelection.Reducer"
import { SettingsChangePasswordState, settingsChangePasswordReducer } from "./settings/SettingsChangePassword.Reducer"

import uiSlice, { UIState } from "./store/uiSlice"
import billingsSlice, { BillingsState } from "./store/billingsSlice"
import {
  authResetPasswordReducer,
  AuthResetPasswordState,
} from "../../auth/AuthResetPassword/AuthResetPassword.Reducer"

export interface AdminPortalState {
  authForgottenPassword: AuthForgottenPasswordState
  authNewAccountPassword: AuthNewAccountPasswordState
  profilesManagerSelection: ProfilesManagerSelectionState
  profilesList: ProfilesListState
  profilesDetail: ProfilesDetailState
  profilesUpdate: ProfilesUpdateState
  profilesCreate: ProfilesCreateState
  serviceComponentsSelection: ServiceComponentsSelectionState
  buildingsList: BuildingsListState
  buildingsDetail: BuildingsDetailState
  buildingsUpdate: BuildingsUpdateState
  buildingsCreate: BuildingsCreateState
  billingsInitialList: BillingsInitialListState
  billingsRecurringList: BillingsRecurringListState
  billingsRecurringDetail: BillingsRecurringDetailState
  billingsRecurringCreate: BillingsRecurringCreateState
  productsList: ProductsListState
  productsDetail: ProductsDetailState
  productsCreate: ProductsCreateState
  productsUpdate: ProductsUpdateState
  authResetPassword: AuthResetPasswordState
  myProfileDetailView: MyProfileDetailState
  myProfileUpdate: MyProfileUpdateState
  settingsChangePassword: SettingsChangePasswordState
  ui: UIState
  billings: BillingsState
}

export const adminPortalReducer = combineReducers<AdminPortalState>({
  authForgottenPassword: authForgottenPasswordReducer,
  authNewAccountPassword: authNewAccountPasswordReducer,
  authResetPassword: authResetPasswordReducer,
  profilesManagerSelection: profilesManagerSelectionReducer,
  profilesList: profilesListReducer,
  profilesUpdate: profilesUpdateReducer,
  profilesCreate: profilesCreateReducer,
  profilesDetail: profilesDetailReducer,
  productsCreate: productsCreateReducer,
  productsUpdate: productsUpdateReducer,
  serviceComponentsSelection: serviceComponentsSelectionReducer,
  buildingsList: buildingsListReducer,
  buildingsDetail: buildingsDetailReducer,
  buildingsUpdate: buildingsUpdateReducer,
  buildingsCreate: buildingsCreateReducer,
  billingsInitialList: billingsInitialListReducer,
  billingsRecurringList: billingsRecurringListReducer,
  billingsRecurringDetail: billingsRecurringDetailReducer,
  billingsRecurringCreate: billingsRecurringCreateReducer,
  productsList: productsListReducer,
  productsDetail: productsDetailReducer,
  myProfileDetailView: myProfileDetailReducer,
  myProfileUpdate: myProfileUpdateReducer,
  settingsChangePassword: settingsChangePasswordReducer,
  ui: uiSlice,
  billings: billingsSlice,
})
