import { DomainDependencies } from "../../../../Domain.Dependencies"
import { from, map, mergeMap, Observable } from "rxjs"
import { DomainResponse } from "../../../../Domain.Response"
import { apiCall, apiHeaders } from "../../../../Domain.Calls"
import { BillingsInitialList, InitialBillingAdminResponse } from "./BillingsInitial.Model"
import { DOMAIN_DEPENDENCIES } from "../../../../../app/App.Config"
import { billingsInitialListMapper } from "./BillingsInitial.Mapper"
import { MultiServiceBillingAdminResponse, PagedAreaAdminResponse } from "../../../../../data/generated-sources/openapi"
import { AxiosResponse } from "axios"

export const getInitialBillings = (deps: DomainDependencies): Observable<DomainResponse<BillingsInitialList>> => {
  const headers = apiHeaders(deps)
  return apiCall(
    from(deps.adminServiceBillingsApi.getAdminServiceBillings(headers)).pipe(
      mergeMap((billingResponse: AxiosResponse<MultiServiceBillingAdminResponse>) =>
        from(deps.adminAreaApi.adminGetAreas(1, 10000, "", headers)).pipe(
          map((areaResponse: AxiosResponse<PagedAreaAdminResponse>) =>
            billingsInitialListMapper(billingResponse.data, areaResponse.data, deps),
          ),
        ),
      ),
    ),
  )
}

export const getOnlyInitialBillingById = (billingId: string) => {
  const headers = apiHeaders(DOMAIN_DEPENDENCIES)
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.getAdminServiceBillingById(billingId, headers)
}

export const getAdminServiceBillingById = async (billingId: string): Promise<InitialBillingAdminResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminServiceBillingsApi.getAdminServiceBillingById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  const { data: contractData } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetContractById(
    data.contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  return { ...data, contractName: contractData.name }
}

export const recalculateAdminServiceBillingById = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.recalculateAdminServiceBillingById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const approveOrCancelInitialBillingAction = async (
  billingId: string,
  approveOrCancelAction: "approve" | "cancel",
) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.putAdminServiceBillingStateChangeById(
    billingId,
    approveOrCancelAction,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const sapSendAdminServiceBillingById = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.sapSendAdminServiceBillingById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const deleteAdminServiceBillingById = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.deleteAdminServiceBillingById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}
