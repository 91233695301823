/* tslint:disable */
/* eslint-disable */
/**
 * CKW API
 * Endpoints for both CKW customers and administrators to create assets, user accounts, link them and update their details
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@ckw.noumenadigital.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export enum AccountingStatus {
    CREATED = 'CREATED',
    UNAVAILABLE = 'UNAVAILABLE',
    ERROR = 'ERROR'
}

/**
 * 
 * @export
 * @interface AddTariffToContractRequest
 */
export interface AddTariffToContractRequest {
    /**
     * 
     * @type {string}
     * @memberof AddTariffToContractRequest
     */
    'tariffId': string;
}
/**
 * 
 * @export
 * @interface AddressData
 */
export interface AddressData {
    /**
     * 
     * @type {string}
     * @memberof AddressData
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof AddressData
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof AddressData
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof AddressData
     */
    'city': string;
}
/**
 * 
 * @export
 * @interface AllParticipantBillingDetailsAdminResponse
 */
export interface AllParticipantBillingDetailsAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'billingStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'billingEndDate': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'billingDate'?: string;
    /**
     * 
     * @type {AllParticipantsBillingState}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'billingStatus': AllParticipantsBillingState;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'totalEnergyProduction': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'totalEnergyHighTariff': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'totalEnergyLowTariff': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'totalEnergyCharge': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'totalConsumption': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'totalCosts': string;
    /**
     * 
     * @type {Array<PowerMeterParameters>}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'powerMeterParameters': Array<PowerMeterParameters>;
    /**
     * 
     * @type {Array<ZevPricePackage>}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'pricePackages'?: Array<ZevPricePackage>;
    /**
     * 
     * @type {Array<BillDetails>}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'bills': Array<BillDetails>;
    /**
     * 
     * @type {boolean}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'isConfirmedByManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AllParticipantBillingDetailsAdminResponse
     */
    'hasCollection': boolean;
}
/**
 * 
 * @export
 * @interface AllParticipantBillingDetailsCustomerResponse
 */
export interface AllParticipantBillingDetailsCustomerResponse {
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'billingStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'billingEndDate': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'billingDate'?: string;
    /**
     * 
     * @type {AllParticipantsBillingState}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'billingStatus': AllParticipantsBillingState;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'totalEnergyProduction': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'totalEnergyHighTariff': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'totalEnergyLowTariff': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'totalEnergyCharge': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'totalConsumption': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'totalCosts': string;
    /**
     * 
     * @type {Array<PowerMeterParameters>}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'powerMeterParameters': Array<PowerMeterParameters>;
    /**
     * 
     * @type {Array<ZevPricePackage>}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'pricePackages'?: Array<ZevPricePackage>;
    /**
     * 
     * @type {Array<BillDetails>}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'bills': Array<BillDetails>;
    /**
     * 
     * @type {boolean}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'isConfirmedByManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AllParticipantBillingDetailsCustomerResponse
     */
    'hasCollection': boolean;
}
/**
 * 
 * @export
 * @interface AllParticipantBillingPricesUpdateAdminRequest
 */
export interface AllParticipantBillingPricesUpdateAdminRequest {
    /**
     * 
     * @type {Array<ZevPricePackage>}
     * @memberof AllParticipantBillingPricesUpdateAdminRequest
     */
    'pricePackages': Array<ZevPricePackage>;
}
/**
 * 
 * @export
 * @interface AllParticipantBillingPricesUpdateCustomerRequest
 */
export interface AllParticipantBillingPricesUpdateCustomerRequest {
    /**
     * 
     * @type {ZevPricePackage}
     * @memberof AllParticipantBillingPricesUpdateCustomerRequest
     */
    'pricePackage': ZevPricePackage;
}
/**
 * 
 * @export
 * @interface AllParticipantsBilling
 */
export interface AllParticipantsBilling {
    /**
     * 
     * @type {string}
     * @memberof AllParticipantsBilling
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantsBilling
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantsBilling
     */
    'endDate': string;
    /**
     * 
     * @type {AllParticipantsBillingState}
     * @memberof AllParticipantsBilling
     */
    'activeState': AllParticipantsBillingState;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantsBilling
     */
    'totalSum'?: string;
    /**
     * 
     * @type {Array<PowerMeterParameters>}
     * @memberof AllParticipantsBilling
     */
    'powerMeterParameters': Array<PowerMeterParameters>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllParticipantsBilling
     */
    'bills': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantsBilling
     */
    'contractId': string;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantsBilling
     */
    'areaId'?: string;
    /**
     * 
     * @type {Array<ZevPricePackage>}
     * @memberof AllParticipantsBilling
     */
    'pricePackages': Array<ZevPricePackage>;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantsBilling
     */
    'billingDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AllParticipantsBilling
     */
    'isConfirmedByManager': boolean;
    /**
     * 
     * @type {string}
     * @memberof AllParticipantsBilling
     */
    'productName': string;
    /**
     * 
     * @type {boolean}
     * @memberof AllParticipantsBilling
     */
    'hasCollection': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AllParticipantsBillingState {
    WAITING_FOR_DATA = 'WAITING_FOR_DATA',
    DATA_AVAILABLE = 'DATA_AVAILABLE',
    SUSPENDED = 'SUSPENDED',
    IN_PROGRESS = 'IN_PROGRESS',
    IN_PROGRESS_REOPENED = 'IN_PROGRESS_REOPENED',
    DONE = 'DONE',
    PAID = 'PAID'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ApiErrorCode {
    FORGOT_PASSWORD_TOKEN_VALIDATION = 'FORGOT_PASSWORD_TOKEN_VALIDATION',
    FORGOT_PASSWORD_ACTION_DONE = 'FORGOT_PASSWORD_ACTION_DONE',
    FORGOT_PASSWORD_ACTION_EXPIRED = 'FORGOT_PASSWORD_ACTION_EXPIRED',
    USER_ACCOUNT_EMAIL_ACTION_DONE = 'USER_ACCOUNT_EMAIL_ACTION_DONE',
    USER_ACCOUNT_EMAIL_UNAVAILABLE = 'USER_ACCOUNT_EMAIL_UNAVAILABLE',
    USER_ACCOUNT_EMAIL_TOKEN_VALIDATION = 'USER_ACCOUNT_EMAIL_TOKEN_VALIDATION',
    USER_ACCOUNT_ALREADY_SETUP = 'USER_ACCOUNT_ALREADY_SETUP',
    USER_ACCOUNT_VERIFICATION_EXPIRED = 'USER_ACCOUNT_VERIFICATION_EXPIRED',
    USER_ACCOUNT_INVALID_PASSWORD = 'USER_ACCOUNT_INVALID_PASSWORD',
    USER_ACCOUNT_MISCONFIGURED_PERSON = 'USER_ACCOUNT_MISCONFIGURED_PERSON',
    USER_ACCOUNT_MISCONFIGURED_MANAGER = 'USER_ACCOUNT_MISCONFIGURED_MANAGER',
    USER_ACCOUNT_MISCONFIGURED_ROLE = 'USER_ACCOUNT_MISCONFIGURED_ROLE',
    USER_ACCOUNT_VERIFICATION_PENDING = 'USER_ACCOUNT_VERIFICATION_PENDING',
    USER_ACCOUNT_INVALID_CREDENTIALS = 'USER_ACCOUNT_INVALID_CREDENTIALS',
    USER_ACCOUNT_BLOCKED = 'USER_ACCOUNT_BLOCKED',
    USER_ACCOUNT_INVALID_BLOCK_ACTION_USER = 'USER_ACCOUNT_INVALID_BLOCK_ACTION_USER',
    USER_ACCOUNT_SET_PASSWORD_ACTION_DONE = 'USER_ACCOUNT_SET_PASSWORD_ACTION_DONE',
    USER_ACCOUNT_SET_PASSWORD_ACTION_EXPIRED = 'USER_ACCOUNT_SET_PASSWORD_ACTION_EXPIRED',
    USER_ACCOUNT_SET_PASSWORD_TOKEN_VALIDATION = 'USER_ACCOUNT_SET_PASSWORD_TOKEN_VALIDATION',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    INVALID_EMAIL = 'INVALID_EMAIL',
    INVALID_ADMIN_EMAIL_DOMAIN = 'INVALID_ADMIN_EMAIL_DOMAIN',
    ZEV_INVALID_ZEV_MANAGER = 'ZEV_INVALID_ZEV_MANAGER',
    ZEV_INVALID_BILLING_DATE = 'ZEV_INVALID_BILLING_DATE',
    INVALID_PRICE_COMPONENT_VALID_FROM_MONTH_START = 'INVALID_PRICE_COMPONENT_VALID_FROM_MONTH_START',
    INVALID_PRICE_COMPONENT_VALID_UNTIL_BLANK_MONTH_END = 'INVALID_PRICE_COMPONENT_VALID_UNTIL_BLANK_MONTH_END',
    INVALID_PRICE_COMPONENT_PRODUCT_LINK = 'INVALID_PRICE_COMPONENT_PRODUCT_LINK',
    CONTRACT_ZEV_HAS_RELEASED_OR_ACTIVE_CONTRACT = 'CONTRACT_ZEV_HAS_RELEASED_OR_ACTIVE_CONTRACT',
    CONTRACT_ZEV_ACTIVATION_WITHOUT_PREDECESSOR = 'CONTRACT_ZEV_ACTIVATION_WITHOUT_PREDECESSOR',
    PRODUCT_USED_IN_CONTRACT = 'PRODUCT_USED_IN_CONTRACT',
    PRODUCT_NOT_LISTED = 'PRODUCT_NOT_LISTED',
    SERVICE_COMPONENT_NAME_UNAVAILABLE = 'SERVICE_COMPONENT_NAME_UNAVAILABLE',
    OBJECT_NOT_FOUND = 'OBJECT_NOT_FOUND',
    NO_VALID_CONTRACT_FOUND = 'NO_VALID_CONTRACT_FOUND',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    PERMISSION_ERROR = 'PERMISSION_ERROR',
    ILLEGAL_PROTOCOL_STATE = 'ILLEGAL_PROTOCOL_STATE',
    INVALID_STATE_TRANSITION = 'INVALID_STATE_TRANSITION',
    INVALID_METERING_CODE_LENGTH = 'INVALID_METERING_CODE_LENGTH',
    INVALID_CONSUMPTION_POINT_STATE = 'INVALID_CONSUMPTION_POINT_STATE',
    OTHER = 'OTHER',
    PARTICIPATION_VACANCY_MODIFICATION = 'PARTICIPATION_VACANCY_MODIFICATION',
    PARTICIPATION_FIRST_ENTRY_DATE_MODIFICATION = 'PARTICIPATION_FIRST_ENTRY_DATE_MODIFICATION',
    PARTICIPATION_MOVE_OUT_DATE_UNDEFINED = 'PARTICIPATION_MOVE_OUT_DATE_UNDEFINED',
    PARTICIPATION_MOVE_BILL_EXISTS = 'PARTICIPATION_MOVE_BILL_EXISTS',
    PARTICIPATION_CANCEL_MOVE_IN_VACANCY_NO_MODIFICATION = 'PARTICIPATION_CANCEL_MOVE_IN_VACANCY_NO_MODIFICATION',
    PARTICIPATION_CANCEL_MOVE_IN_KNOWN_SUCCESSOR = 'PARTICIPATION_CANCEL_MOVE_IN_KNOWN_SUCCESSOR',
    PARTICIPATION_CANCEL_MOVE_IN_BILL_EXISTS = 'PARTICIPATION_CANCEL_MOVE_IN_BILL_EXISTS',
    PARTICIPATION_CANCEL_MOVE_IN_VACANCY_PERIOD_BILLED = 'PARTICIPATION_CANCEL_MOVE_IN_VACANCY_PERIOD_BILLED',
    PARTICIPATION_CANCEL_MOVE_OUT_VACANCY_NO_MODIFICATION = 'PARTICIPATION_CANCEL_MOVE_OUT_VACANCY_NO_MODIFICATION',
    PARTICIPATION_CANCEL_MOVE_OUT_DATE_UNDEFINED = 'PARTICIPATION_CANCEL_MOVE_OUT_DATE_UNDEFINED',
    PARTICIPATION_CANCEL_MOVE_OUT_KNOWN_SUCCESSOR = 'PARTICIPATION_CANCEL_MOVE_OUT_KNOWN_SUCCESSOR',
    PARTICIPATION_CANCEL_MOVE_OUT_NEXT_PARTICIPANT_KNOWN_SUCCESSOR = 'PARTICIPATION_CANCEL_MOVE_OUT_NEXT_PARTICIPANT_KNOWN_SUCCESSOR',
    PARTICIPATION_CANCEL_MOVE_OUT_BILL_EXISTS = 'PARTICIPATION_CANCEL_MOVE_OUT_BILL_EXISTS',
    PARTICIPATION_MOVE_IN_VACANCY_ONLY = 'PARTICIPATION_MOVE_IN_VACANCY_ONLY',
    PARTICIPATION_MOVE_IN_DATE_BEFORE_VACANCY_START = 'PARTICIPATION_MOVE_IN_DATE_BEFORE_VACANCY_START',
    PARTICIPATION_MOVE_IN_VACANCY_NO_SUCCESSOR = 'PARTICIPATION_MOVE_IN_VACANCY_NO_SUCCESSOR',
    PARTICIPATION_MOVE_IN_UNKNOWN_ZEV_PARTICIPANT = 'PARTICIPATION_MOVE_IN_UNKNOWN_ZEV_PARTICIPANT',
    PARTICIPATION_MOVE_IN_BILL_EXISTS = 'PARTICIPATION_MOVE_IN_BILL_EXISTS',
    NOT_PRESENT_MOVE_IN_DATE_CAN_NOT_BE_CHANGED = 'NOT_PRESENT_MOVE_IN_DATE_CAN_NOT_BE_CHANGED',
    PARTICIPANT_FROM_DIFFERENT_AREA = 'PARTICIPANT_FROM_DIFFERENT_AREA',
    PARTICIPATION_MOVE_OUT_WITHOUT_NP_VACANCY_NO_MODIFICATION = 'PARTICIPATION_MOVE_OUT_WITHOUT_NP_VACANCY_NO_MODIFICATION',
    PARTICIPATION_MOVE_OUT_WITHOUT_NP_KNOWN_SUCCESSOR = 'PARTICIPATION_MOVE_OUT_WITHOUT_NP_KNOWN_SUCCESSOR',
    PARTICIPATION_MOVE_OUT_WITHOUT_NP_DATE_AFTER_MOVE_IN_DATE = 'PARTICIPATION_MOVE_OUT_WITHOUT_NP_DATE_AFTER_MOVE_IN_DATE',
    PARTICIPATION_MOVE_OUT_WITHOUT_NP_BILL_EXISTS = 'PARTICIPATION_MOVE_OUT_WITHOUT_NP_BILL_EXISTS',
    SERVICE_BILLING_PROVIDE_START_END_DATES = 'SERVICE_BILLING_PROVIDE_START_END_DATES',
    SERVICE_BILLING_BILLED_ZEV_ACTIVE_TERMINATED = 'SERVICE_BILLING_BILLED_ZEV_ACTIVE_TERMINATED',
    SERVICE_BILLING_BILLED_AREA_ACTIVE_TERMINATED = 'SERVICE_BILLING_BILLED_AREA_ACTIVE_TERMINATED',
    INDIVIDUAL_BILLING_AFTER_SERVICE_START_DATE = 'INDIVIDUAL_BILLING_AFTER_SERVICE_START_DATE',
    THE_BILLING_PROCESS_CONTAINS_NO_BILL = 'THE_BILLING_PROCESS_CONTAINS_NO_BILL',
    INDV_BILLING_BEFORE_SYSTEM_START = 'INDV_BILLING_BEFORE_SYSTEM_START',
    BILL_PRICES_NOT_PROVIDED = 'BILL_PRICES_NOT_PROVIDED',
    SERVICE_BILLING_RUN_OVERLAPPING_OBJECT = 'SERVICE_BILLING_RUN_OVERLAPPING_OBJECT',
    ZEV_PRICE_PACKAGE_NOT_FOUND = 'ZEV_PRICE_PACKAGE_NOT_FOUND',
    ZEV_PRICE_PACKAGE_NOT_IN_BILLING = 'ZEV_PRICE_PACKAGE_NOT_IN_BILLING',
    METER_INACTIVE_BILLABLE_DATES = 'METER_INACTIVE_BILLABLE_DATES',
    METER_INACTIVE_NOT_LINKED_TO_CONTRACT = 'METER_INACTIVE_NOT_LINKED_TO_CONTRACT',
    CONTRACT_TERMINATION_DATE = 'CONTRACT_TERMINATION_DATE',
    CONTRACT_CANNOT_REPLACE_CONTRACT_WITH_INITIAL_BILLS = 'CONTRACT_CANNOT_REPLACE_CONTRACT_WITH_INITIAL_BILLS',
    CONTRACT_CANNOT_REPLACE_CONTRACT_WITH_RECURRING_BILLS = 'CONTRACT_CANNOT_REPLACE_CONTRACT_WITH_RECURRING_BILLS',
    CONTRACT_SHOULD_NOT_HAVE_SUCCESSOR = 'CONTRACT_SHOULD_NOT_HAVE_SUCCESSOR',
    PARTICIPATION_START_BEFORE_END = 'PARTICIPATION_START_BEFORE_END',
    PARTICIPATION_END_AFTER_START = 'PARTICIPATION_END_AFTER_START',
    METER_START_BEFORE_END = 'METER_START_BEFORE_END',
    METER_UPDATE_START_DATE_WITH_EXISTING_BILLS = 'METER_UPDATE_START_DATE_WITH_EXISTING_BILLS',
    ALL_BILLING_INVALID_STATE_CUSTOMER = 'ALL_BILLING_INVALID_STATE_CUSTOMER',
    INDV_BILLING_INVALID_STATE_CUSTOMER = 'INDV_BILLING_INVALID_STATE_CUSTOMER',
    USER_ACCOUNT_ZEV_MANAGER_UNASSIGNED = 'USER_ACCOUNT_ZEV_MANAGER_UNASSIGNED',
    ZEV_EXTERNAL_REFERENCE_NUMBER_MISSING = 'ZEV_EXTERNAL_REFERENCE_NUMBER_MISSING',
    PRICE_COMPONENT_EXTERNAL_REFERENCE_NUMBER_MISSING = 'PRICE_COMPONENT_EXTERNAL_REFERENCE_NUMBER_MISSING',
    SAP_SERVICE_BILLING_RECURRING_DATES_NULL = 'SAP_SERVICE_BILLING_RECURRING_DATES_NULL',
    SAP_SERVICE_BILLING_TYPES_MISMATCH = 'SAP_SERVICE_BILLING_TYPES_MISMATCH',
    SAP_GENERAL_FAILURE = 'SAP_GENERAL_FAILURE',
    PARTICIPANT_DELETE_IN_USE = 'PARTICIPANT_DELETE_IN_USE',
    SERVICE_BILLING_RUN_SAP_UPDATE_SOME_WITHOUT_RESULT = 'SERVICE_BILLING_RUN_SAP_UPDATE_SOME_WITHOUT_RESULT',
    SERVICE_BILLING_RUN_SAP_UPDATE_MISMATCH = 'SERVICE_BILLING_RUN_SAP_UPDATE_MISMATCH',
    SERVICE_BILLING_RUN_SAP_UPDATE_MISSING_DATA = 'SERVICE_BILLING_RUN_SAP_UPDATE_MISSING_DATA',
    SERVICE_BILLING_RUN_IS_MISSING_FOR_RECURRING_SERVICE_BILLING = 'SERVICE_BILLING_RUN_IS_MISSING_FOR_RECURRING_SERVICE_BILLING',
    START_DATE_MUST_BE_EQUALS_TO_END_DATE_FOR_INITIAL_SERVICE_BILLING = 'START_DATE_MUST_BE_EQUALS_TO_END_DATE_FOR_INITIAL_SERVICE_BILLING',
    START_DATE_MUST_BE_BEFORE_END_DATE_FOR_RECURRING_SERVICE_BILLING = 'START_DATE_MUST_BE_BEFORE_END_DATE_FOR_RECURRING_SERVICE_BILLING',
    PRODUCT_COLLECTION_RESTRICTED_ACCESS = 'PRODUCT_COLLECTION_RESTRICTED_ACCESS',
    PARTICIPANTS_ALREADY_CONFIRMED_SAP_SYNC = 'PARTICIPANTS_ALREADY_CONFIRMED_SAP_SYNC',
    BILLING_ZEV_MANAGER_ALREADY_APPROVED = 'BILLING_ZEV_MANAGER_ALREADY_APPROVED',
    ADMIN_EMAIL_CHANGE_NOT_PERMITTED = 'ADMIN_EMAIL_CHANGE_NOT_PERMITTED',
    ADMIN_PROFILE_DELETION_NOT_PERMITTED = 'ADMIN_PROFILE_DELETION_NOT_PERMITTED',
    ZEV_PRICE_CALCULATOR_INSUFFICIENT_YEAR_DATA = 'ZEV_PRICE_CALCULATOR_INSUFFICIENT_YEAR_DATA',
    ZEV_PRICE_CALCULATOR_MISSING_INPUT = 'ZEV_PRICE_CALCULATOR_MISSING_INPUT',
    ZEV_PRICE_CALCULATOR_MISSING_YEAR_TARIFFS = 'ZEV_PRICE_CALCULATOR_MISSING_YEAR_TARIFFS',
    ZEV_PRICE_CALCULATOR_PRICES_HIGHER_THAN_REFERENCE = 'ZEV_PRICE_CALCULATOR_PRICES_HIGHER_THAN_REFERENCE',
    ZEV_PRICE_CALCULATOR_MISSING_YEAR_VAT_RATE = 'ZEV_PRICE_CALCULATOR_MISSING_YEAR_VAT_RATE',
    SAP_MISCONFIGURED_PARTICIPANT = 'SAP_MISCONFIGURED_PARTICIPANT',
    ZEV_PRICE_CALCULATOR_MISSING_ENERGY_DATA = 'ZEV_PRICE_CALCULATOR_MISSING_ENERGY_DATA',
    USER_ACCOUNT_ZEV_MANAGER_INACTIVE_CONTRACT = 'USER_ACCOUNT_ZEV_MANAGER_INACTIVE_CONTRACT',
    ZEV_PRICE_CALCULATOR_INCONSISTENT_INPUT_VALUES = 'ZEV_PRICE_CALCULATOR_INCONSISTENT_INPUT_VALUES',
    USER_DELETION_FAILED = 'USER_DELETION_FAILED',
    PROFILE_DELETION_MANAGING_ZEV = 'PROFILE_DELETION_MANAGING_ZEV',
    ZEV_CONFIG_MUST_BE_LINKED_TO_A_CONTRACT_CONFIG = 'ZEV_CONFIG_MUST_BE_LINKED_TO_A_CONTRACT_CONFIG',
    INDIVIDUAL_PARTICIPANT_BILLINGS_ONLY_AVAILABLE_FOR_MEDIUM_ELECTRICITY = 'INDIVIDUAL_PARTICIPANT_BILLINGS_ONLY_AVAILABLE_FOR_MEDIUM_ELECTRICITY',
    BILLING_PROCESS_CAN_NOT_START_BEFORE_SERVICE_START_DATE = 'BILLING_PROCESS_CAN_NOT_START_BEFORE_SERVICE_START_DATE',
    UTILITY_UNIT_FOR_BILLING_MUST_HAVE_ONE_PHYSICAL_POWERMETER = 'UTILITY_UNIT_FOR_BILLING_MUST_HAVE_ONE_PHYSICAL_POWERMETER',
    PRICES_NOT_AVAILABLE_FOR_INDIVIDUAL_PARTICIPANT_BILLING = 'PRICES_NOT_AVAILABLE_FOR_INDIVIDUAL_PARTICIPANT_BILLING',
    USAGE_TYPE_IS_NOT_SUPPORTED_ON_THIS_TOPOLOGY_ELEMENT = 'USAGE_TYPE_IS_NOT_SUPPORTED_ON_THIS_TOPOLOGY_ELEMENT',
    USAGE_TYPE_ON_METER_NOT_IN_SYNC_WITH_UTILITY_UNIT_USAGE_TYPE = 'USAGE_TYPE_ON_METER_NOT_IN_SYNC_WITH_UTILITY_UNIT_USAGE_TYPE',
    THIS_MEDIUM_IS_NOT_SUPPORTED_ON_THIS_TOPOLOGY_ELEMENT = 'THIS_MEDIUM_IS_NOT_SUPPORTED_ON_THIS_TOPOLOGY_ELEMENT',
    NO_ADDITIONAL_METER_IS_ALLOWED_ON_THIS_TOPOLOGY_ELEMENT = 'NO_ADDITIONAL_METER_IS_ALLOWED_ON_THIS_TOPOLOGY_ELEMENT',
    PRICE_PACKAGE_ONLY_AVAILABLE_ON_POWER_METER = 'PRICE_PACKAGE_ONLY_AVAILABLE_ON_POWER_METER',
    PRICE_PACKAGE_NOT_SET_ON_POWER_METER = 'PRICE_PACKAGE_NOT_SET_ON_POWER_METER',
    POWER_METER_TYPE_NOT_SET_FOR_PHYSICAL_METER = 'POWER_METER_TYPE_NOT_SET_FOR_PHYSICAL_METER',
    POWER_METER_TYPE_MUST_ONLY_BE_SET_FOR_PHYSICAL_METER = 'POWER_METER_TYPE_MUST_ONLY_BE_SET_FOR_PHYSICAL_METER',
    THE_ZEV_CONFIG_NOT_LINKED_TO_CONTRACT_CONFIG = 'THE_ZEV_CONFIG_NOT_LINKED_TO_CONTRACT_CONFIG',
    USAGE_TYPE_UNDEFINED_IS_NOT_SUPPORTED_ON_UTILITY_UNITS = 'USAGE_TYPE_UNDEFINED_IS_NOT_SUPPORTED_ON_UTILITY_UNITS',
    UNIT_SPACE_IS_NOT_VALID_FOR_PROVIDED_UTILITY_UNIT_TYPE = 'UNIT_SPACE_IS_NOT_VALID_FOR_PROVIDED_UTILITY_UNIT_TYPE',
    EMAIL_UPDATE = 'EMAIL_UPDATE',
    MISSING_PHYSICAL_METER = 'MISSING_PHYSICAL_METER',
    START_DATE_MUST_BE_BEFORE_END_DATE = 'START_DATE_MUST_BE_BEFORE_END_DATE',
    BILLING_PERIODS_NOT_ALIGNED_WITH_SERVICE_DATES = 'BILLING_PERIODS_NOT_ALIGNED_WITH_SERVICE_DATES',
    APPROPRIATE_CONTRACT_FOR_CONTRACT_CONFIG_IS_MISSING = 'APPROPRIATE_CONTRACT_FOR_CONTRACT_CONFIG_IS_MISSING',
    PERSON_IS_NOT_ZEV_MANAGER = 'PERSON_IS_NOT_ZEV_MANAGER',
    SECOND_PHYSICAL_POWER_METER_NOT_SUPPORTED = 'SECOND_PHYSICAL_POWER_METER_NOT_SUPPORTED',
    BILLING_DATE_DOES_NOT_MATCH_BILLING_FREQUENCY = 'BILLING_DATE_DOES_NOT_MATCH_BILLING_FREQUENCY',
    BILLING_FREQUENCY_CAN_ONLY_BE_SET_IN_DRAFT_STATUS = 'BILLING_FREQUENCY_CAN_ONLY_BE_SET_IN_DRAFT_STATUS',
    PAYMENT_DEADLINE_MUST_BE_POSITIVE_INTEGER = 'PAYMENT_DEADLINE_MUST_BE_POSITIVE_INTEGER',
    DEFAULT_PRICE_PACKAGE_AT_INDEX_ZERO_MISSING = 'DEFAULT_PRICE_PACKAGE_AT_INDEX_ZERO_MISSING',
    CANNOT_PROVIDE_BOTH_LUMP_SUM_AND_VARIABLE_BILLING_METHODS = 'CANNOT_PROVIDE_BOTH_LUMP_SUM_AND_VARIABLE_BILLING_METHODS',
    UNIQUE_ORDER_OF_PRICE_PACKAGES_MISSING = 'UNIQUE_ORDER_OF_PRICE_PACKAGES_MISSING',
    PRICE_PACKAGE_ORDERS_MUST_BE_POSITIVE = 'PRICE_PACKAGE_ORDERS_MUST_BE_POSITIVE',
    WRONG_NUMBER_OF_PRICES_PROVIDED = 'WRONG_NUMBER_OF_PRICES_PROVIDED',
    DEFAULT_PRICE_PACKAGE_ID_MUST_NOT_BE_CHANGED = 'DEFAULT_PRICE_PACKAGE_ID_MUST_NOT_BE_CHANGED',
    INVALID_VEWA_MEDIUM = 'INVALID_VEWA_MEDIUM',
    INVALID_HEATING_COST_SHARES = 'INVALID_HEATING_COST_SHARES',
    INPUT_PARAMETERS_FROM_DIFFERENT_BILLING_SCOPE = 'INPUT_PARAMETERS_FROM_DIFFERENT_BILLING_SCOPE',
    CONTRACT_CONFIGURATION_MISSING_ON_SUCCESSOR_CONTRACT = 'CONTRACT_CONFIGURATION_MISSING_ON_SUCCESSOR_CONTRACT',
    CONTRACT_END_DATE_SHOULD_BE_AT_THE_END_OF_BILLING_PERIOD = 'CONTRACT_END_DATE_SHOULD_BE_AT_THE_END_OF_BILLING_PERIOD',
    CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_RECURRING_SERVICE_BILLING = 'CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_RECURRING_SERVICE_BILLING',
    CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_INITIAL_SERVICE_BILLING = 'CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_INITIAL_SERVICE_BILLING',
    CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_ALL_PARTICIPANT_BILLING = 'CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_ALL_PARTICIPANT_BILLING',
    CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_INDIVIDUAL_PARTICIPANT_BILLING = 'CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_INDIVIDUAL_PARTICIPANT_BILLING',
    CONTRACT_NOT_PART_OF_BILLING = 'CONTRACT_NOT_PART_OF_BILLING',
    INVALID_BILLING_FREQUENCY_ON_VEWA_CONTRACT = 'INVALID_BILLING_FREQUENCY_ON_VEWA_CONTRACT',
    INVALID_BILLING_FREQUENCY_ON_ZEV_CONTRACT = 'INVALID_BILLING_FREQUENCY_ON_ZEV_CONTRACT',
    CANNOT_MODIFY_BILLING_FREQUENCY_ON_VEWA_CONTRACT = 'CANNOT_MODIFY_BILLING_FREQUENCY_ON_VEWA_CONTRACT',
    INVALID_NEXT_BILLING_FREQUENCY_ON_ZEV_CONTRACT = 'INVALID_NEXT_BILLING_FREQUENCY_ON_ZEV_CONTRACT',
    BILLING_DATE_OUTSIDE_OF_VALID_DATE_RANGE = 'BILLING_DATE_OUTSIDE_OF_VALID_DATE_RANGE',
    BUILDING_METERS_BLOCK_REMOVAL_OF_MEDIUM = 'BUILDING_METERS_BLOCK_REMOVAL_OF_MEDIUM',
    UTILITY_UNIT_METERS_BLOCK_REMOVAL_OF_MEDIUM = 'UTILITY_UNIT_METERS_BLOCK_REMOVAL_OF_MEDIUM',
    CONTRACT_PRODUCT_SHOULD_MATCH_PREDECESSOR_PRODUCT = 'CONTRACT_PRODUCT_SHOULD_MATCH_PREDECESSOR_PRODUCT',
    INVALID_METER_MEDIUM = 'INVALID_METER_MEDIUM',
    PRICE_PACKAGE_ID_MUST_BE_SET = 'PRICE_PACKAGE_ID_MUST_BE_SET',
    PRICE_PACKAGE_ID_CAN_ONLY_BE_SET_ON_PHYSICAL_METER = 'PRICE_PACKAGE_ID_CAN_ONLY_BE_SET_ON_PHYSICAL_METER',
    POWERMETER_TYPE_MISSING_FOR_PHYSICAL_METER = 'POWERMETER_TYPE_MISSING_FOR_PHYSICAL_METER',
    INVALID_PRICE_PACKAGE_ID = 'INVALID_PRICE_PACKAGE_ID',
    INVALID_USAGE_TYPE = 'INVALID_USAGE_TYPE',
    AREA_WITH_REGISTERED_CONTRACT = 'AREA_WITH_REGISTERED_CONTRACT',
    PRICES_CONTAIN_INVALID_NUMBERS = 'PRICES_CONTAIN_INVALID_NUMBERS',
    PRICE_PACKAGE_ALREADY_EXISTS = 'PRICE_PACKAGE_ALREADY_EXISTS',
    INVALID_PRICE_PACKAGE_ORDER = 'INVALID_PRICE_PACKAGE_ORDER',
    CANNOT_DELETE_PRICES_IN_DEFAULT_PRICE_PACKAGE = 'CANNOT_DELETE_PRICES_IN_DEFAULT_PRICE_PACKAGE',
    CANNOT_DELETE_DEFAULT_PRICE_PACKAGE = 'CANNOT_DELETE_DEFAULT_PRICE_PACKAGE',
    BUSINESS_RULES_NOT_SATISFIED = 'BUSINESS_RULES_NOT_SATISFIED',
    CANNOT_CHANGE_PRODUCT = 'CANNOT_CHANGE_PRODUCT',
    CANNOT_DELETE_BUILDING_LINKED_TO_CONTRACT = 'CANNOT_DELETE_BUILDING_LINKED_TO_CONTRACT',
    CANNOT_DELETE_BUILDING_WITH_LINKED_METER = 'CANNOT_DELETE_BUILDING_WITH_LINKED_METER',
    METER_WITH_GIVEN_MEDIUM_AND_USAGE_TYPE_ALREADY_EXISTS = 'METER_WITH_GIVEN_MEDIUM_AND_USAGE_TYPE_ALREADY_EXISTS',
    CANNOT_REPLACE_IN_VACANCIES = 'CANNOT_REPLACE_IN_VACANCIES',
    CANNOT_REPLACE_AFTER_CREATION_OF_BILL = 'CANNOT_REPLACE_AFTER_CREATION_OF_BILL',
    ALL_UTILITY_UNITS_MUST_HAVE_ELECTRICITY_METER = 'ALL_UTILITY_UNITS_MUST_HAVE_ELECTRICITY_METER',
    AT_LEAST_ONE_INCOMING_METER_MUST_BE_LINKED_TO_ZEV_CONFIG = 'AT_LEAST_ONE_INCOMING_METER_MUST_BE_LINKED_TO_ZEV_CONFIG',
    AT_LEAST_ONE_OUTGOING_METER_MUST_BE_LINKED_TO_ZEV_CONFIG = 'AT_LEAST_ONE_OUTGOING_METER_MUST_BE_LINKED_TO_ZEV_CONFIG',
    BUILDING_CAN_ONLY_BE_LINKED_TO_ONE_CONTRACT_PER_PRODUCT = 'BUILDING_CAN_ONLY_BE_LINKED_TO_ONE_CONTRACT_PER_PRODUCT',
    BUILDINGS_CAN_ONLY_BE_REMOVED_IN_DRAFT_STATE = 'BUILDINGS_CAN_ONLY_BE_REMOVED_IN_DRAFT_STATE',
    BUILDINGS_MUST_BELONG_TO_SAME_AREA_AS_CONTRACT = 'BUILDINGS_MUST_BELONG_TO_SAME_AREA_AS_CONTRACT',
    INITIAL_CONTRACT_WITHOUT_PREDECESSOR_MUST_BE_PARENT = 'INITIAL_CONTRACT_WITHOUT_PREDECESSOR_MUST_BE_PARENT',
    THERE_MUST_BE_AT_LEAST_ONE_PARTICIPANT_FOR_EACH_UTILITY_UNIT = 'THERE_MUST_BE_AT_LEAST_ONE_PARTICIPANT_FOR_EACH_UTILITY_UNIT',
    THERE_MUST_BE_ONE_POWER_METER_FOR_EACH_UTILITY_UNIT = 'THERE_MUST_BE_ONE_POWER_METER_FOR_EACH_UTILITY_UNIT',
    BUILDING_IS_NOT_IN_SCOPE_OF_CONTRACT = 'BUILDING_IS_NOT_IN_SCOPE_OF_CONTRACT',
    METER_IS_NOT_ATTACHED_TO_BUILDING = 'METER_IS_NOT_ATTACHED_TO_BUILDING',
    METER_STATE_IS_INVALID = 'METER_STATE_IS_INVALID',
    BILLING_SCOPE_ALREADY_EXIST = 'BILLING_SCOPE_ALREADY_EXIST',
    BILLING_SCOPE_IS_NOT_PRESENT_IN_VEWA_CONFIG = 'BILLING_SCOPE_IS_NOT_PRESENT_IN_VEWA_CONFIG',
    BILLING_IS_NOT_PRESENT_IN_VEWA_PARTICIPANT_BILLINGS_HISTORY = 'BILLING_IS_NOT_PRESENT_IN_VEWA_PARTICIPANT_BILLINGS_HISTORY',
    PRODUCT_HAS_NO_PRICE_COMPONENT_DEFINED_FOR_ONE_ZEV_METER_POWERTYPE = 'PRODUCT_HAS_NO_PRICE_COMPONENT_DEFINED_FOR_ONE_ZEV_METER_POWERTYPE',
    INITIAL_BILLINGS_CAN_ONLY_BE_STARTED_FOR_ACTIVE_AREAS = 'INITIAL_BILLINGS_CAN_ONLY_BE_STARTED_FOR_ACTIVE_AREAS',
    ZEV_CONTRACT_CAN_HAVE_ONLY_ONE_INITIAL_BILLING = 'ZEV_CONTRACT_CAN_HAVE_ONLY_ONE_INITIAL_BILLING',
    BILLING_FREQUENCY_NOT_SUPPORTED = 'BILLING_FREQUENCY_NOT_SUPPORTED',
    SERVICE_BILLING_MUST_BE_OF_TYPE_INITIAL = 'SERVICE_BILLING_MUST_BE_OF_TYPE_INITIAL',
    ALL_PARTICIPANT_BILLING_ALREADY_EXISTS_FOR_REGISTERED_NEXT_BILLING_DATE = 'ALL_PARTICIPANT_BILLING_ALREADY_EXISTS_FOR_REGISTERED_NEXT_BILLING_DATE',
    ZEV_ALL_PARTICIPANT_BILLING_CANNOT_BE_CREATED_WHEN_VEWA_BILLINGS_ARE_PRESENT = 'ZEV_ALL_PARTICIPANT_BILLING_CANNOT_BE_CREATED_WHEN_VEWA_BILLINGS_ARE_PRESENT',
    VEWA_PARTICIPANT_BILLING_CANNOT_BE_CREATED_WHEN_ZEV_PARTICIPANT_BILLINGS_ARE_PRESENT = 'VEWA_PARTICIPANT_BILLING_CANNOT_BE_CREATED_WHEN_ZEV_PARTICIPANT_BILLINGS_ARE_PRESENT',
    NO_RELEASED_OR_ACTIVE_CONTRACT_FOUND_FOR_PROVIDED_DATE = 'NO_RELEASED_OR_ACTIVE_CONTRACT_FOUND_FOR_PROVIDED_DATE',
    MORE_THAN_ONE_CONTRACT_FOUND_FOR_PROVIDED_DATE = 'MORE_THAN_ONE_CONTRACT_FOUND_FOR_PROVIDED_DATE',
    NEXT_BILLING_DATE_CAN_ONLY_BE_UPDATED_FOR_VEWA_CONTRACTS = 'NEXT_BILLING_DATE_CAN_ONLY_BE_UPDATED_FOR_VEWA_CONTRACTS',
    NEXT_BILLING_DATE_FREQUENCY_CAN_ONLY_BE_UPDATED_FOR_ZEV_CONTRACTS = 'NEXT_BILLING_DATE_FREQUENCY_CAN_ONLY_BE_UPDATED_FOR_ZEV_CONTRACTS',
    SAME_DAY_PERIODS_OVERLAPPING = 'SAME_DAY_PERIODS_OVERLAPPING',
    MINUTES_MUST_BE_DIVISIBLE_BY_15_MINUTES = 'MINUTES_MUST_BE_DIVISIBLE_BY_15_MINUTES',
    INVALID_PERIOD_START_OR_END_TIME = 'INVALID_PERIOD_START_OR_END_TIME',
    NAME_ALREADY_EXISTS = 'NAME_ALREADY_EXISTS',
    DEFAULT_TARIFF_CANNOT_BE_REMOVED = 'DEFAULT_TARIFF_CANNOT_BE_REMOVED',
    TARIFF_USED_BY_CONTRACT_CANNOT_BE_REMOVED = 'TARIFF_USED_BY_CONTRACT_CANNOT_BE_REMOVED',
    COMMENT_TEXT_LENGTH_TOO_LONG = 'COMMENT_TEXT_LENGTH_TOO_LONG',
    METERING_CODE_ALREADY_ASSIGNED_TO_BILLABLE_METER = 'METERING_CODE_ALREADY_ASSIGNED_TO_BILLABLE_METER'
}

/**
 * 
 * @export
 * @interface ApiErrorResponse
 */
export interface ApiErrorResponse {
    /**
     * 
     * @type {ApiErrorCode}
     * @memberof ApiErrorResponse
     */
    'code': ApiErrorCode;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface AreaAdminResponse
 */
export interface AreaAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof AreaAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {AreaState}
     * @memberof AreaAdminResponse
     */
    'activeState': AreaState;
    /**
     * 
     * @type {string}
     * @memberof AreaAdminResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AreaAdminResponse
     */
    'contracts': Array<string>;
}
/**
 * 
 * @export
 * @interface AreaBreadcrumbsAdminResponse
 */
export interface AreaBreadcrumbsAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof AreaBreadcrumbsAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AreaBreadcrumbsAdminResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AreaCreateAdminRequest
 */
export interface AreaCreateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof AreaCreateAdminRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AreaCustomerResponse
 */
export interface AreaCustomerResponse {
    /**
     * 
     * @type {string}
     * @memberof AreaCustomerResponse
     */
    'id': string;
    /**
     * 
     * @type {AreaState}
     * @memberof AreaCustomerResponse
     */
    'activeState': AreaState;
    /**
     * 
     * @type {string}
     * @memberof AreaCustomerResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AreaState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DELETED = 'DELETED'
}

/**
 * 
 * @export
 * @interface AreaUpdateAdminRequest
 */
export interface AreaUpdateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof AreaUpdateAdminRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AuthenticationRefreshRequest
 */
export interface AuthenticationRefreshRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRefreshRequest
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface AuthenticationRequest
 */
export interface AuthenticationRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AuthenticationResponse
 */
export interface AuthenticationResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    'refreshToken'?: string;
}
/**
 * 
 * @export
 * @interface BillDetails
 */
export interface BillDetails {
    /**
     * 
     * @type {string}
     * @memberof BillDetails
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BillDetails
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof BillDetails
     */
    'to': string;
    /**
     * 
     * @type {string}
     * @memberof BillDetails
     */
    'billNumber': string;
    /**
     * 
     * @type {ParticipantPersonalData}
     * @memberof BillDetails
     */
    'billPersonalData'?: ParticipantPersonalData;
    /**
     * 
     * @type {string}
     * @memberof BillDetails
     */
    'utilityUnitId': string;
    /**
     * 
     * @type {BillingCalculations}
     * @memberof BillDetails
     */
    'billingCalculations': BillingCalculations;
    /**
     * 
     * @type {Array<EnergyConsumption>}
     * @memberof BillDetails
     */
    'energyConsumption': Array<EnergyConsumption>;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof BillDetails
     */
    'paymentStatus': PaymentStatus;
    /**
     * 
     * @type {AccountingStatus}
     * @memberof BillDetails
     */
    'accountingStatus': AccountingStatus;
    /**
     * 
     * @type {string}
     * @memberof BillDetails
     */
    'orderReferenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDetails
     */
    'invoiceReferenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDetails
     */
    'accountingErrorMessage'?: string;
    /**
     * 
     * @type {UtilityUnitResponse}
     * @memberof BillDetails
     */
    'utilityUnit'?: UtilityUnitResponse;
    /**
     * 
     * @type {string}
     * @memberof BillDetails
     */
    'buildingName'?: string;
}
/**
 * 
 * @export
 * @interface BillPaymentStatusUpdateResponse
 */
export interface BillPaymentStatusUpdateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BillPaymentStatusUpdateResponse
     */
    'paid': boolean;
}
/**
 * 
 * @export
 * @interface BillingCalculations
 */
export interface BillingCalculations {
    /**
     * 
     * @type {string}
     * @memberof BillingCalculations
     */
    'calculatedOn': string;
    /**
     * 
     * @type {string}
     * @memberof BillingCalculations
     */
    'shareInSolar': string;
    /**
     * 
     * @type {Array<EnergyPosition>}
     * @memberof BillingCalculations
     */
    'energyPositions': Array<EnergyPosition>;
    /**
     * 
     * @type {Array<OtherPosition>}
     * @memberof BillingCalculations
     */
    'otherPositions': Array<OtherPosition>;
    /**
     * 
     * @type {Array<SummaryPosition>}
     * @memberof BillingCalculations
     */
    'summaryPositions': Array<SummaryPosition>;
    /**
     * 
     * @type {VatPosition}
     * @memberof BillingCalculations
     */
    'vatPosition'?: VatPosition;
}
/**
 * 
 * @export
 * @interface BillingDateRequest
 */
export interface BillingDateRequest {
    /**
     * 
     * @type {string}
     * @memberof BillingDateRequest
     */
    'newNextBillingDate': string;
}
/**
 * 
 * @export
 * @interface BillingDetails
 */
export interface BillingDetails {
    /**
     * 
     * @type {BillingDetailsOfAllParticipantBilling}
     * @memberof BillingDetails
     */
    'allParticipantBillingDetails'?: BillingDetailsOfAllParticipantBilling;
    /**
     * 
     * @type {BillingDetailsOfIndividualParticipantBilling}
     * @memberof BillingDetails
     */
    'individualParticipantBillingDetails'?: BillingDetailsOfIndividualParticipantBilling;
}
/**
 * 
 * @export
 * @interface BillingDetailsOfAllParticipantBilling
 */
export interface BillingDetailsOfAllParticipantBilling {
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsOfAllParticipantBilling
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsOfAllParticipantBilling
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsOfAllParticipantBilling
     */
    'endDate': string;
    /**
     * 
     * @type {AllParticipantsBillingState}
     * @memberof BillingDetailsOfAllParticipantBilling
     */
    'billingStatus': AllParticipantsBillingState;
    /**
     * 
     * @type {BillDetails}
     * @memberof BillingDetailsOfAllParticipantBilling
     */
    'billDetails'?: BillDetails;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsOfAllParticipantBilling
     */
    'participantId'?: string;
    /**
     * 
     * @type {ParticipantPersonalData}
     * @memberof BillingDetailsOfAllParticipantBilling
     */
    'participantPersonalData'?: ParticipantPersonalData;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsOfAllParticipantBilling
     */
    'participationId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BillingDetailsOfAllParticipantBilling
     */
    'isConfirmedByManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BillingDetailsOfAllParticipantBilling
     */
    'hasCollection': boolean;
}
/**
 * 
 * @export
 * @interface BillingDetailsOfIndividualParticipantBilling
 */
export interface BillingDetailsOfIndividualParticipantBilling {
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsOfIndividualParticipantBilling
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsOfIndividualParticipantBilling
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsOfIndividualParticipantBilling
     */
    'endDate': string;
    /**
     * 
     * @type {IndividualParticipantBillingState}
     * @memberof BillingDetailsOfIndividualParticipantBilling
     */
    'billingStatus': IndividualParticipantBillingState;
    /**
     * 
     * @type {BillDetails}
     * @memberof BillingDetailsOfIndividualParticipantBilling
     */
    'billDetails'?: BillDetails;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsOfIndividualParticipantBilling
     */
    'participantId'?: string;
    /**
     * 
     * @type {ParticipantPersonalData}
     * @memberof BillingDetailsOfIndividualParticipantBilling
     */
    'participantPersonalData'?: ParticipantPersonalData;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsOfIndividualParticipantBilling
     */
    'participationId': string;
    /**
     * 
     * @type {boolean}
     * @memberof BillingDetailsOfIndividualParticipantBilling
     */
    'isConfirmedByManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BillingDetailsOfIndividualParticipantBilling
     */
    'hasCollection': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BillingFrequency {
    BIANNUAL = 'BIANNUAL',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    YEARLY = 'YEARLY',
    YEARLY_ROLLING = 'YEARLY_ROLLING'
}

/**
 * 
 * @export
 * @interface BillingPeriod
 */
export interface BillingPeriod {
    /**
     * 
     * @type {string}
     * @memberof BillingPeriod
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BillingPeriod
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BillingScope {
    HEATING_BILL = 'HEATING_BILL',
    COOLING_BILL = 'COOLING_BILL',
    WATER_BILL = 'WATER_BILL'
}

/**
 * 
 * @export
 * @interface BillingSettingsResponse
 */
export interface BillingSettingsResponse {
    /**
     * 
     * @type {string}
     * @memberof BillingSettingsResponse
     */
    'nextBillingDate': string;
    /**
     * 
     * @type {BillingFrequency}
     * @memberof BillingSettingsResponse
     */
    'billingFrequency': BillingFrequency;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof BillingSettingsResponse
     */
    'billingPeriod': BillingPeriod;
    /**
     * 
     * @type {BillingFrequency}
     * @memberof BillingSettingsResponse
     */
    'nextBillingFrequency'?: BillingFrequency;
    /**
     * 
     * @type {MainContactPersonData}
     * @memberof BillingSettingsResponse
     */
    'mainContactPerson': MainContactPersonData;
    /**
     * 
     * @type {PaymentInformation}
     * @memberof BillingSettingsResponse
     */
    'paymentInformation': PaymentInformation;
}
/**
 * 
 * @export
 * @interface BillingSettingsUpdateAdminRequest
 */
export interface BillingSettingsUpdateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof BillingSettingsUpdateAdminRequest
     */
    'nextBillingDate': string;
    /**
     * 
     * @type {BillingFrequency}
     * @memberof BillingSettingsUpdateAdminRequest
     */
    'billingFrequency': BillingFrequency;
    /**
     * 
     * @type {BillingFrequency}
     * @memberof BillingSettingsUpdateAdminRequest
     */
    'nextBillingFrequency'?: BillingFrequency;
    /**
     * 
     * @type {MainContactPersonData}
     * @memberof BillingSettingsUpdateAdminRequest
     */
    'mainContactPerson': MainContactPersonData;
    /**
     * 
     * @type {PaymentInformation}
     * @memberof BillingSettingsUpdateAdminRequest
     */
    'paymentInformation': PaymentInformation;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BillingType {
    MONTHLY_FEE = 'MONTHLY_FEE',
    INITIAL_FEE = 'INITIAL_FEE',
    INITIAL_FEE_PER_CONSUMPTION_POINT = 'INITIAL_FEE_PER_CONSUMPTION_POINT',
    MONTHLY_FEE_PER_CONSUMPTION_POINT = 'MONTHLY_FEE_PER_CONSUMPTION_POINT',
    MONTHLY_SPECIFIC_FEE_PER_CONSUMPTION_POINT = 'MONTHLY_SPECIFIC_FEE_PER_CONSUMPTION_POINT'
}

/**
 * 
 * @export
 * @interface BreadcrumbsAdminRequest
 */
export interface BreadcrumbsAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbsAdminRequest
     */
    'buildingId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbsAdminRequest
     */
    'utilityUnitId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbsAdminRequest
     */
    'meterId'?: string;
}
/**
 * 
 * @export
 * @interface BreadcrumbsAdminResponse
 */
export interface BreadcrumbsAdminResponse {
    /**
     * 
     * @type {BuildingBreadcrumbsAdminResponse}
     * @memberof BreadcrumbsAdminResponse
     */
    'building'?: BuildingBreadcrumbsAdminResponse;
    /**
     * 
     * @type {UtilityUnitBreadcrumbsAdminResponse}
     * @memberof BreadcrumbsAdminResponse
     */
    'utilityUnit'?: UtilityUnitBreadcrumbsAdminResponse;
    /**
     * 
     * @type {MeterBreadcrumbsAdminResponse}
     * @memberof BreadcrumbsAdminResponse
     */
    'meter'?: MeterBreadcrumbsAdminResponse;
}
/**
 * 
 * @export
 * @interface BuildingAdminResponse
 */
export interface BuildingAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof BuildingAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BuildingAdminResponse
     */
    'name': string;
    /**
     * 
     * @type {AddressData}
     * @memberof BuildingAdminResponse
     */
    'address': AddressData;
    /**
     * CKW use this ID for Buildings in SAP and potentially other systems
     * @type {string}
     * @memberof BuildingAdminResponse
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingAdminResponse
     */
    'areaId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuildingAdminResponse
     */
    'utilityUnits': Array<string>;
    /**
     * 
     * @type {BuildingState}
     * @memberof BuildingAdminResponse
     */
    'activeState': BuildingState;
    /**
     * 
     * @type {string}
     * @memberof BuildingAdminResponse
     */
    'contractId'?: string;
}
/**
 * 
 * @export
 * @interface BuildingBreadcrumbsAdminResponse
 */
export interface BuildingBreadcrumbsAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof BuildingBreadcrumbsAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BuildingBreadcrumbsAdminResponse
     */
    'name': string;
    /**
     * 
     * @type {AreaBreadcrumbsAdminResponse}
     * @memberof BuildingBreadcrumbsAdminResponse
     */
    'area': AreaBreadcrumbsAdminResponse;
}
/**
 * 
 * @export
 * @interface BuildingCreateAdminRequest
 */
export interface BuildingCreateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof BuildingCreateAdminRequest
     */
    'name': string;
    /**
     * 
     * @type {AddressData}
     * @memberof BuildingCreateAdminRequest
     */
    'address': AddressData;
}
/**
 * 
 * @export
 * @interface BuildingCreateMeterAdminRequest
 */
export interface BuildingCreateMeterAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof BuildingCreateMeterAdminRequest
     */
    'meteringCode': string;
    /**
     * 
     * @type {Medium}
     * @memberof BuildingCreateMeterAdminRequest
     */
    'medium': Medium;
    /**
     * 
     * @type {UsageType}
     * @memberof BuildingCreateMeterAdminRequest
     */
    'usageType': UsageType;
}
/**
 * 
 * @export
 * @interface BuildingCustomerResponse
 */
export interface BuildingCustomerResponse {
    /**
     * 
     * @type {string}
     * @memberof BuildingCustomerResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BuildingCustomerResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BuildingCustomerResponse
     */
    'areaId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuildingCustomerResponse
     */
    'utilityUnitIds': Array<string>;
    /**
     * 
     * @type {BuildingState}
     * @memberof BuildingCustomerResponse
     */
    'activeState': BuildingState;
    /**
     * 
     * @type {string}
     * @memberof BuildingCustomerResponse
     */
    'contractId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BuildingState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

/**
 * 
 * @export
 * @interface BuildingUpdateAdminRequest
 */
export interface BuildingUpdateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof BuildingUpdateAdminRequest
     */
    'name': string;
    /**
     * 
     * @type {AddressData}
     * @memberof BuildingUpdateAdminRequest
     */
    'address': AddressData;
}
/**
 * 
 * @export
 * @interface CalcCountPerCalcResolution
 */
export interface CalcCountPerCalcResolution {
    /**
     * 
     * @type {string}
     * @memberof CalcCountPerCalcResolution
     */
    'dateTime': string;
    /**
     * consumption points count
     * @type {string}
     * @memberof CalcCountPerCalcResolution
     */
    'consumptionPointCount': string;
}
/**
 * 
 * @export
 * @interface CalcPerCalcResolution
 */
export interface CalcPerCalcResolution {
    /**
     * 
     * @type {string}
     * @memberof CalcPerCalcResolution
     */
    'dateTime': string;
    /**
     * energy consumption in measuring unit
     * @type {string}
     * @memberof CalcPerCalcResolution
     */
    'consumption': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ConsumptionScope {
    ROOM_HEATING = 'ROOM_HEATING',
    WATER_HEATING = 'WATER_HEATING',
    HEATING = 'HEATING',
    ROOM_COOLING = 'ROOM_COOLING',
    WATER = 'WATER'
}

/**
 * 
 * @export
 * @interface ContactData
 */
export interface ContactData {
    /**
     * 
     * @type {string}
     * @memberof ContactData
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactData
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactData
     */
    'mobile'?: string;
}
/**
 * 
 * @export
 * @interface ContractAddressData
 */
export interface ContractAddressData {
    /**
     * 
     * @type {string}
     * @memberof ContractAddressData
     */
    'addressLineOne'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractAddressData
     */
    'addressLineTwo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractAddressData
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof ContractAddressData
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ContractAddressData
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ContractAddressData
     */
    'city': string;
}
/**
 * 
 * @export
 * @interface ContractAdminResponse
 */
export interface ContractAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof ContractAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ContractAdminResponse
     */
    'name': string;
    /**
     * 
     * @type {ContractState}
     * @memberof ContractAdminResponse
     */
    'activeState': ContractState;
    /**
     * 
     * @type {string}
     * @memberof ContractAdminResponse
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ContractAdminResponse
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractAdminResponse
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ContractAdminResponse
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof ContractAdminResponse
     */
    'areaName'?: string;
    /**
     * If a new contract will substitute an existing contract, the old contract will become the predecessor
     * @type {string}
     * @memberof ContractAdminResponse
     */
    'predecessorContractId'?: string;
    /**
     * If a contract has been terminated with a replacement (for example when upgrading package), it will have a  successor contract
     * @type {string}
     * @memberof ContractAdminResponse
     */
    'successorContractId'?: string;
    /**
     * 
     * @type {ProductAdminResponse}
     * @memberof ContractAdminResponse
     */
    'product'?: ProductAdminResponse;
    /**
     * 
     * @type {string}
     * @memberof ContractAdminResponse
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface ContractBreadcrumbsAdminResponse
 */
export interface ContractBreadcrumbsAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof ContractBreadcrumbsAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ContractBreadcrumbsAdminResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ContractBuildingsAdminRequest
 */
export interface ContractBuildingsAdminRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractBuildingsAdminRequest
     */
    'buildingIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ContractContactCustomerResponse
 */
export interface ContractContactCustomerResponse {
    /**
     * 
     * @type {ContactData}
     * @memberof ContractContactCustomerResponse
     */
    'contact': ContactData;
    /**
     * 
     * @type {ContractAddressData}
     * @memberof ContractContactCustomerResponse
     */
    'addressData': ContractAddressData;
}
/**
 * 
 * @export
 * @interface ContractCreateAdminRequest
 */
export interface ContractCreateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof ContractCreateAdminRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContractCreateAdminRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ContractCreateAdminRequest
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractCreateAdminRequest
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface ContractCustomerResponse
 */
export interface ContractCustomerResponse {
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerResponse
     */
    'name': string;
    /**
     * 
     * @type {ContractAddressData}
     * @memberof ContractCustomerResponse
     */
    'address'?: ContractAddressData;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractCustomerResponse
     */
    'buildings': Array<string>;
    /**
     * 
     * @type {ContractState}
     * @memberof ContractCustomerResponse
     */
    'activeState': ContractState;
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerResponse
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerResponse
     */
    'endDate'?: string;
    /**
     * 
     * @type {PaymentInformation}
     * @memberof ContractCustomerResponse
     */
    'paymentInformation': PaymentInformation;
    /**
     * 
     * @type {MainContactPersonData}
     * @memberof ContractCustomerResponse
     */
    'mainContactPerson': MainContactPersonData;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractCustomerResponse
     */
    'allParticipantsBillingsHistory': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ContractCustomerResponse
     */
    'paymentDeadlineInDays': number;
    /**
     * 
     * @type {BillingFrequency}
     * @memberof ContractCustomerResponse
     */
    'billingFrequency': BillingFrequency;
    /**
     * 
     * @type {BillingFrequency}
     * @memberof ContractCustomerResponse
     */
    'nextBillingFrequency'?: BillingFrequency;
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerResponse
     */
    'vatNumber'?: string;
    /**
     * 
     * @type {ProductCustomerResponse}
     * @memberof ContractCustomerResponse
     */
    'product'?: ProductCustomerResponse;
    /**
     * 
     * @type {ContractMasterDataResponse}
     * @memberof ContractCustomerResponse
     */
    'successorContract'?: ContractMasterDataResponse;
}
/**
 * 
 * @export
 * @interface ContractDataAvailabilityResponse
 */
export interface ContractDataAvailabilityResponse {
    /**
     * 
     * @type {string}
     * @memberof ContractDataAvailabilityResponse
     */
    'latestCalcAvailability'?: string;
}
/**
 * 
 * @export
 * @interface ContractIndividualParticipantBillingCustomerResponse
 */
export interface ContractIndividualParticipantBillingCustomerResponse {
    /**
     * 
     * @type {Array<IndividualParticipantBilling>}
     * @memberof ContractIndividualParticipantBillingCustomerResponse
     */
    'elements': Array<IndividualParticipantBilling>;
}
/**
 * 
 * @export
 * @interface ContractManagersAdminRequest
 */
export interface ContractManagersAdminRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractManagersAdminRequest
     */
    'managerIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ContractMasterDataResponse
 */
export interface ContractMasterDataResponse {
    /**
     * 
     * @type {string}
     * @memberof ContractMasterDataResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ContractMasterDataResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContractMasterDataResponse
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ContractMasterDataResponse
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface ContractReplaceAdminRequest
 */
export interface ContractReplaceAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof ContractReplaceAdminRequest
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ContractReplaceAdminRequest
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface ContractServiceComponentFeaturesResponse
 */
export interface ContractServiceComponentFeaturesResponse {
    /**
     * 
     * @type {Array<ServiceComponentFeature>}
     * @memberof ContractServiceComponentFeaturesResponse
     */
    'features': Array<ServiceComponentFeature>;
}
/**
 * 
 * @export
 * @interface ContractServiceUpdateAdminRequest
 */
export interface ContractServiceUpdateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof ContractServiceUpdateAdminRequest
     */
    'date': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ContractState {
    DRAFT = 'DRAFT',
    RELEASED = 'RELEASED',
    ACTIVE = 'ACTIVE',
    TERMINATED = 'TERMINATED'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ContractType {
    ZEV = 'ZEV',
    VEWA = 'VEWA',
    ALL = 'ALL'
}

/**
 * 
 * @export
 * @interface ContractUpdateAdminRequest
 */
export interface ContractUpdateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof ContractUpdateAdminRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContractUpdateAdminRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ContractUpdateAdminRequest
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractUpdateAdminRequest
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface ContractUpdateContactAdminRequest
 */
export interface ContractUpdateContactAdminRequest {
    /**
     * 
     * @type {ContactData}
     * @memberof ContractUpdateContactAdminRequest
     */
    'contact': ContactData;
    /**
     * 
     * @type {ContractAddressData}
     * @memberof ContractUpdateContactAdminRequest
     */
    'addressData': ContractAddressData;
    /**
     * 
     * @type {string}
     * @memberof ContractUpdateContactAdminRequest
     */
    'externalReferenceNumber': string;
}
/**
 * 
 * @export
 * @interface ContractUpdateMainContactPersonCustomerRequest
 */
export interface ContractUpdateMainContactPersonCustomerRequest {
    /**
     * 
     * @type {MainContactPersonData}
     * @memberof ContractUpdateMainContactPersonCustomerRequest
     */
    'mainContactPerson': MainContactPersonData;
}
/**
 * 
 * @export
 * @interface ContractUpdatePaymentInformationCustomerRequest
 */
export interface ContractUpdatePaymentInformationCustomerRequest {
    /**
     * 
     * @type {PaymentInformation}
     * @memberof ContractUpdatePaymentInformationCustomerRequest
     */
    'paymentInformation': PaymentInformation;
}
/**
 * 
 * @export
 * @interface ContractUpdateStartDateCustomerRequest
 */
export interface ContractUpdateStartDateCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof ContractUpdateStartDateCustomerRequest
     */
    'startDate': string;
}
/**
 * 
 * @export
 * @interface ContractUpdateVewaBillingSettingsCustomerRequest
 */
export interface ContractUpdateVewaBillingSettingsCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof ContractUpdateVewaBillingSettingsCustomerRequest
     */
    'newNextBillingDate': string;
}
/**
 * 
 * @export
 * @interface ContractUpdateZevBillingSettingsCustomerRequest
 */
export interface ContractUpdateZevBillingSettingsCustomerRequest {
    /**
     * 
     * @type {BillingFrequency}
     * @memberof ContractUpdateZevBillingSettingsCustomerRequest
     */
    'newNextBillingFrequency'?: BillingFrequency;
}
/**
 * 
 * @export
 * @interface CostPosition
 */
export interface CostPosition {
    /**
     * 
     * @type {string}
     * @memberof CostPosition
     */
    'name': string;
    /**
     * 
     * @type {BillingType}
     * @memberof CostPosition
     */
    'billingType': BillingType;
    /**
     * 
     * @type {PowerMeterType}
     * @memberof CostPosition
     */
    'powermeterType'?: PowerMeterType;
    /**
     * 
     * @type {string}
     * @memberof CostPosition
     */
    'units': string;
    /**
     * 
     * @type {string}
     * @memberof CostPosition
     */
    'priceWithoutVat': string;
    /**
     * 
     * @type {string}
     * @memberof CostPosition
     */
    'sum': string;
}
/**
 * 
 * @export
 * @interface CostShareType
 */
export interface CostShareType {
    /**
     * 
     * @type {BillingScope}
     * @memberof CostShareType
     */
    'billingScope': BillingScope;
    /**
     * 
     * @type {ConsumptionScope}
     * @memberof CostShareType
     */
    'consumptionScope': ConsumptionScope;
    /**
     * 
     * @type {UsageType}
     * @memberof CostShareType
     */
    'usageType': UsageType;
}
/**
 * 
 * @export
 * @interface CostStructure
 */
export interface CostStructure {
    /**
     * 
     * @type {number}
     * @memberof CostStructure
     */
    'basicCosts': number;
    /**
     * 
     * @type {number}
     * @memberof CostStructure
     */
    'consumptionBasedCosts': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DayInAWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY'
}

/**
 * 
 * @export
 * @interface DomicileAddress
 */
export interface DomicileAddress {
    /**
     * 
     * @type {string}
     * @memberof DomicileAddress
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof DomicileAddress
     */
    'postCode': string;
    /**
     * 
     * @type {string}
     * @memberof DomicileAddress
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DomicileAddress
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof DomicileAddress
     */
    'land': string;
    /**
     * 
     * @type {string}
     * @memberof DomicileAddress
     */
    'co'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomicileAddress
     */
    'poBox'?: string;
}
/**
 * 
 * @export
 * @interface EnergyConsumption
 */
export interface EnergyConsumption {
    /**
     * 
     * @type {EnergyType}
     * @memberof EnergyConsumption
     */
    'energyType': EnergyType;
    /**
     * 
     * @type {string}
     * @memberof EnergyConsumption
     */
    'consumption': string;
}
/**
 * 
 * @export
 * @interface EnergyPosition
 */
export interface EnergyPosition {
    /**
     * 
     * @type {EnergyType}
     * @memberof EnergyPosition
     */
    'energyType': EnergyType;
    /**
     * 
     * @type {string}
     * @memberof EnergyPosition
     */
    'consumption': string;
    /**
     * 
     * @type {string}
     * @memberof EnergyPosition
     */
    'price': string;
    /**
     * 
     * @type {string}
     * @memberof EnergyPosition
     */
    'amountDue': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum EnergyType {
    SOLAR = 'SOLAR',
    HIGH_TARIFF = 'HIGH_TARIFF',
    LOW_TARIFF = 'LOW_TARIFF',
    CHARGE = 'CHARGE'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum EventType {
    IN = 'MOVE_IN',
    OUT = 'MOVE_OUT'
}

/**
 * 
 * @export
 * @interface GroupMeter
 */
export interface GroupMeter {
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupMeter
     */
    'meters': Array<string>;
    /**
     * 
     * @type {CostShareType}
     * @memberof GroupMeter
     */
    'costShare': CostShareType;
}
/**
 * 
 * @export
 * @interface GroupMeterConfig
 */
export interface GroupMeterConfig {
    /**
     * 
     * @type {Array<GroupMeter>}
     * @memberof GroupMeterConfig
     */
    'groupMeters': Array<GroupMeter>;
}
/**
 * 
 * @export
 * @interface IndividualParticipantBilling
 */
export interface IndividualParticipantBilling {
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBilling
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBilling
     */
    'contractId': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBilling
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBilling
     */
    'endDate': string;
    /**
     * 
     * @type {IndividualParticipantBillingState}
     * @memberof IndividualParticipantBilling
     */
    'activeState': IndividualParticipantBillingState;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBilling
     */
    'utilityUnitId': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBilling
     */
    'participationId': string;
    /**
     * 
     * @type {ParticipantResponse}
     * @memberof IndividualParticipantBilling
     */
    'participant'?: ParticipantResponse;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBilling
     */
    'finalAmountDue'?: string;
    /**
     * 
     * @type {PowerMeterParameters}
     * @memberof IndividualParticipantBilling
     */
    'powerMeterParameters': PowerMeterParameters;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBilling
     */
    'bill'?: string;
    /**
     * 
     * @type {ZevPrices}
     * @memberof IndividualParticipantBilling
     */
    'prices': ZevPrices;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBilling
     */
    'billingDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualParticipantBilling
     */
    'isConfirmedByManager': boolean;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBilling
     */
    'utilityUnitName': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBilling
     */
    'buildingName': string;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualParticipantBilling
     */
    'hasCollection': boolean;
}
/**
 * 
 * @export
 * @interface IndividualParticipantBillingDetailsAdminResponse
 */
export interface IndividualParticipantBillingDetailsAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'billingStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'billingEndDate': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'billingDate'?: string;
    /**
     * 
     * @type {IndividualParticipantBillingState}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'billingStatus': IndividualParticipantBillingState;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'totalEnergyProduction': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'totalEnergyHighTariff': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'totalEnergyLowTariff': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'totalEnergyCharge': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'totalConsumption': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'totalCosts': string;
    /**
     * 
     * @type {PowerMeterParameters}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'powerMeterParameters': PowerMeterParameters;
    /**
     * 
     * @type {ZevPrices}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'prices': ZevPrices;
    /**
     * 
     * @type {BillDetails}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'bill'?: BillDetails;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'isConfirmedByManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualParticipantBillingDetailsAdminResponse
     */
    'hasCollection': boolean;
}
/**
 * 
 * @export
 * @interface IndividualParticipantBillingDetailsCustomerResponse
 */
export interface IndividualParticipantBillingDetailsCustomerResponse {
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'billingStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'billingEndDate': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'billingDate'?: string;
    /**
     * 
     * @type {IndividualParticipantBillingState}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'billingStatus': IndividualParticipantBillingState;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'totalEnergyProduction': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'totalEnergyHighTariff': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'totalEnergyLowTariff': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'totalEnergyCharge': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'totalConsumption': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'totalCosts': string;
    /**
     * 
     * @type {PowerMeterParameters}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'powerMeterParameters': PowerMeterParameters;
    /**
     * 
     * @type {ZevPrices}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'prices': ZevPrices;
    /**
     * 
     * @type {BillDetails}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'bill': BillDetails;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'isConfirmedByManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualParticipantBillingDetailsCustomerResponse
     */
    'hasCollection': boolean;
}
/**
 * 
 * @export
 * @interface IndividualParticipantBillingPricesUpdateRequest
 */
export interface IndividualParticipantBillingPricesUpdateRequest {
    /**
     * 
     * @type {ZevPrices}
     * @memberof IndividualParticipantBillingPricesUpdateRequest
     */
    'prices': ZevPrices;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum IndividualParticipantBillingState {
    WAITING_FOR_DATA = 'WAITING_FOR_DATA',
    DATA_AVAILABLE = 'DATA_AVAILABLE',
    SUSPENDED = 'SUSPENDED',
    IN_PROGRESS = 'IN_PROGRESS',
    IN_PROGRESS_REOPENED = 'IN_PROGRESS_REOPENED',
    DONE = 'DONE',
    PAID = 'PAID'
}

/**
 * 
 * @export
 * @interface MainContactPersonData
 */
export interface MainContactPersonData {
    /**
     * 
     * @type {string}
     * @memberof MainContactPersonData
     */
    'name': string;
    /**
     * 
     * @type {ContactData}
     * @memberof MainContactPersonData
     */
    'contact': ContactData;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Medium {
    ELECTRICITY = 'ELECTRICITY',
    HEAT = 'HEAT',
    COLD = 'COLD',
    WARM_WATER = 'WARM_WATER',
    COLD_WATER = 'COLD_WATER'
}

/**
 * 
 * @export
 * @interface MediumAdminResponse
 */
export interface MediumAdminResponse {
    /**
     * 
     * @type {Array<Medium>}
     * @memberof MediumAdminResponse
     */
    'mediums': Array<Medium>;
}
/**
 * 
 * @export
 * @interface MediumZevAdminResponse
 */
export interface MediumZevAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof MediumZevAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MediumZevAdminResponse
     */
    'municipality': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MediumZevAdminResponse
     */
    'incomingMeters': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MediumZevAdminResponse
     */
    'outgoingMeters': Array<string>;
}
/**
 * 
 * @export
 * @interface MediumZevAdminUpdateRequest
 */
export interface MediumZevAdminUpdateRequest {
    /**
     * 
     * @type {Municipality}
     * @memberof MediumZevAdminUpdateRequest
     */
    'municipality': Municipality;
    /**
     * 
     * @type {Array<ZevMeter>}
     * @memberof MediumZevAdminUpdateRequest
     */
    'meters': Array<ZevMeter>;
}
/**
 * 
 * @export
 * @interface Meter
 */
export interface Meter {
    /**
     * 
     * @type {string}
     * @memberof Meter
     */
    'meterId': string;
    /**
     * 
     * @type {string}
     * @memberof Meter
     */
    'meteringCode': string;
}
/**
 * 
 * @export
 * @interface MeterActivationRequest
 */
export interface MeterActivationRequest {
    /**
     * 
     * @type {string}
     * @memberof MeterActivationRequest
     */
    'meterId': string;
    /**
     * 
     * @type {string}
     * @memberof MeterActivationRequest
     */
    'billableFrom': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MeterAttachmentType {
    UTILITY_UNIT = 'UTILITY_UNIT',
    BUILDING = 'BUILDING',
    ZEV_CONFIG = 'ZEV_CONFIG'
}

/**
 * 
 * @export
 * @interface MeterBreadcrumbsAdminResponse
 */
export interface MeterBreadcrumbsAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof MeterBreadcrumbsAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MeterBreadcrumbsAdminResponse
     */
    'name': string;
    /**
     * 
     * @type {MeterAttachmentType}
     * @memberof MeterBreadcrumbsAdminResponse
     */
    'meterAttachmentType': MeterAttachmentType;
    /**
     * 
     * @type {BuildingBreadcrumbsAdminResponse}
     * @memberof MeterBreadcrumbsAdminResponse
     */
    'building'?: BuildingBreadcrumbsAdminResponse;
    /**
     * 
     * @type {UtilityUnitBreadcrumbsAdminResponse}
     * @memberof MeterBreadcrumbsAdminResponse
     */
    'utilityUnit'?: UtilityUnitBreadcrumbsAdminResponse;
    /**
     * 
     * @type {ContractBreadcrumbsAdminResponse}
     * @memberof MeterBreadcrumbsAdminResponse
     */
    'contract'?: ContractBreadcrumbsAdminResponse;
}
/**
 * 
 * @export
 * @interface MeterCreateAdminRequest
 */
export interface MeterCreateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof MeterCreateAdminRequest
     */
    'meteringCode': string;
    /**
     * 
     * @type {PowerMeterType}
     * @memberof MeterCreateAdminRequest
     */
    'powerMeterType'?: PowerMeterType;
    /**
     * 
     * @type {Medium}
     * @memberof MeterCreateAdminRequest
     */
    'medium': Medium;
    /**
     * 
     * @type {string}
     * @memberof MeterCreateAdminRequest
     */
    'billableFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeterCreateAdminRequest
     */
    'billableTo'?: string;
    /**
     * 
     * @type {number}
     * @memberof MeterCreateAdminRequest
     */
    'pricePackageId'?: number;
}
/**
 * 
 * @export
 * @interface MeterDataCalcCountResponse
 */
export interface MeterDataCalcCountResponse {
    /**
     * 
     * @type {string}
     * @memberof MeterDataCalcCountResponse
     */
    'meteringCode': string;
    /**
     * 
     * @type {Array<CalcCountPerCalcResolution>}
     * @memberof MeterDataCalcCountResponse
     */
    'data': Array<CalcCountPerCalcResolution>;
}
/**
 * 
 * @export
 * @interface MeterReading
 */
export interface MeterReading {
    /**
     * 
     * @type {string}
     * @memberof MeterReading
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof MeterReading
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof MeterReading
     */
    'volume': number;
}
/**
 * 
 * @export
 * @interface MeterReadingsAreaElectricityCalc
 */
export interface MeterReadingsAreaElectricityCalc {
    /**
     * 
     * @type {string}
     * @memberof MeterReadingsAreaElectricityCalc
     */
    'dateTime': string;
    /**
     * energy consumption in kWh of all meters of a area
     * @type {string}
     * @memberof MeterReadingsAreaElectricityCalc
     */
    'cpSum': string;
    /**
     * sold energy in kWh
     * @type {string}
     * @memberof MeterReadingsAreaElectricityCalc
     */
    'sold': string;
    /**
     * bought energy in kWh
     * @type {string}
     * @memberof MeterReadingsAreaElectricityCalc
     */
    'bought': string;
    /**
     * energy production of the area in kWh
     * @type {string}
     * @memberof MeterReadingsAreaElectricityCalc
     */
    'production': string;
    /**
     * percentage of total consumed electricity provided by in-house production
     * @type {string}
     * @memberof MeterReadingsAreaElectricityCalc
     */
    'percentageTotal': string;
    /**
     * sum of produced energy in kWh over all meters of this area
     * @type {string}
     * @memberof MeterReadingsAreaElectricityCalc
     */
    'sumProduction': string;
    /**
     * sum of purchased energy in kWh in the period 7am-10pm over all meters of this area
     * @type {string}
     * @memberof MeterReadingsAreaElectricityCalc
     */
    'sumEnergyHighTariff': string;
    /**
     * sum of purchased energy in kWh outside of the period 7am-10pm over all meters of this area
     * @type {string}
     * @memberof MeterReadingsAreaElectricityCalc
     */
    'sumEnergyLowTariff': string;
    /**
     * sum of produced energy in kWh in the period 7am-10pm over all meters of this area
     * @type {string}
     * @memberof MeterReadingsAreaElectricityCalc
     */
    'sumProductionHighTariff': string;
    /**
     * sum of produced energy in kWh outside of the period 7am-10pm over all meters of this area
     * @type {string}
     * @memberof MeterReadingsAreaElectricityCalc
     */
    'sumProductionLowTariff': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MeterReadingsCalcResolution {
    MINUTE = 'MINUTE',
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface MeterReadingsContractElectricityCalcResponse
 */
export interface MeterReadingsContractElectricityCalcResponse {
    /**
     * 
     * @type {string}
     * @memberof MeterReadingsContractElectricityCalcResponse
     */
    'contractId': string;
    /**
     * 
     * @type {MeterReadingsCalcResolution}
     * @memberof MeterReadingsContractElectricityCalcResponse
     */
    'resolution': MeterReadingsCalcResolution;
    /**
     * 
     * @type {Array<MeterReadingsAreaElectricityCalc>}
     * @memberof MeterReadingsContractElectricityCalcResponse
     */
    'data': Array<MeterReadingsAreaElectricityCalc>;
    /**
     * total energy consumption in kWh of all meters of a contract, over time window
     * @type {string}
     * @memberof MeterReadingsContractElectricityCalcResponse
     */
    'totalCpSum': string;
    /**
     * total sold energy in kWh, over time window
     * @type {string}
     * @memberof MeterReadingsContractElectricityCalcResponse
     */
    'totalSold': string;
    /**
     * total bought energy in kWh, over time window
     * @type {string}
     * @memberof MeterReadingsContractElectricityCalcResponse
     */
    'totalBought': string;
    /**
     * total energy production of the contract in kWh, over time window
     * @type {string}
     * @memberof MeterReadingsContractElectricityCalcResponse
     */
    'totalProduction': string;
    /**
     * total sum of produced energy in kWh over all meters of this contract, over time window
     * @type {string}
     * @memberof MeterReadingsContractElectricityCalcResponse
     */
    'totalSumProduction': string;
    /**
     * total sum of purchased energy in kWh in the period 7am-10pm over all meters of this contract, over time window
     * @type {string}
     * @memberof MeterReadingsContractElectricityCalcResponse
     */
    'totalSumEnergyHighTariff': string;
    /**
     * total sum of purchased energy in kWh outside of the period 7am-10pm over all meters of this contract, over time window
     * @type {string}
     * @memberof MeterReadingsContractElectricityCalcResponse
     */
    'totalSumEnergyLowTariff': string;
    /**
     * total sum of produced energy in kWh in the period 7am-10pm over all meters of this contract, over time window
     * @type {string}
     * @memberof MeterReadingsContractElectricityCalcResponse
     */
    'totalSumProductionHighTariff': string;
    /**
     * total sum of produced energy in kWh outside of the period 7am-10pm over all meters of this contract, over time window
     * @type {string}
     * @memberof MeterReadingsContractElectricityCalcResponse
     */
    'totalSumProductionLowTariff': string;
}
/**
 * 
 * @export
 * @interface MeterReadingsContractResponse
 */
export interface MeterReadingsContractResponse {
    /**
     * 
     * @type {MeterReadingsContractElectricityCalcResponse}
     * @memberof MeterReadingsContractResponse
     */
    'zev'?: MeterReadingsContractElectricityCalcResponse;
    /**
     * 
     * @type {VewaMeterReadingsResponse}
     * @memberof MeterReadingsContractResponse
     */
    'vewa'?: VewaMeterReadingsResponse;
}
/**
 * Provides cumulative consumption that increases over a given time interval with 15 MIN resolution
 * @export
 * @interface MeterReadingsCumulative
 */
export interface MeterReadingsCumulative {
    /**
     * 
     * @type {string}
     * @memberof MeterReadingsCumulative
     */
    'meterNationalId': string;
    /**
     * 
     * @type {Array<MeterReading>}
     * @memberof MeterReadingsCumulative
     */
    'readings': Array<MeterReading>;
}
/**
 * 
 * @export
 * @interface MeterReadingsCumulativeAdminResponse
 */
export interface MeterReadingsCumulativeAdminResponse {
    /**
     * 
     * @type {Array<MeterReadingsCumulative>}
     * @memberof MeterReadingsCumulativeAdminResponse
     */
    'data': Array<MeterReadingsCumulative>;
}
/**
 * Provides the volume consumption over a given time interval with 15 MIN time resolution
 * @export
 * @interface MeterReadingsSimple
 */
export interface MeterReadingsSimple {
    /**
     * 
     * @type {string}
     * @memberof MeterReadingsSimple
     */
    'meterNationalId': string;
    /**
     * 
     * @type {Array<MeterReading>}
     * @memberof MeterReadingsSimple
     */
    'readings': Array<MeterReading>;
}
/**
 * 
 * @export
 * @interface MeterReadingsSimpleAdminResponse
 */
export interface MeterReadingsSimpleAdminResponse {
    /**
     * 
     * @type {Array<MeterReadingsSimple>}
     * @memberof MeterReadingsSimpleAdminResponse
     */
    'data': Array<MeterReadingsSimple>;
}
/**
 * 
 * @export
 * @interface MeterReadingsSimpleCountAdminResponse
 */
export interface MeterReadingsSimpleCountAdminResponse {
    /**
     * 
     * @type {number}
     * @memberof MeterReadingsSimpleCountAdminResponse
     */
    'count': number;
}
/**
 * Provides the total volume consumption (sum) over a given time interval
 * @export
 * @interface MeterReadingsTotal
 */
export interface MeterReadingsTotal {
    /**
     * 
     * @type {string}
     * @memberof MeterReadingsTotal
     */
    'meteringCode': string;
    /**
     * 
     * @type {number}
     * @memberof MeterReadingsTotal
     */
    'totalConsumption': number;
}
/**
 * 
 * @export
 * @interface MeterReadingsTotalAdminResponse
 */
export interface MeterReadingsTotalAdminResponse {
    /**
     * 
     * @type {Array<MeterReadingsTotal>}
     * @memberof MeterReadingsTotalAdminResponse
     */
    'data': Array<MeterReadingsTotal>;
}
/**
 * 
 * @export
 * @interface MeterReadingsUtilityUnitCalc
 */
export interface MeterReadingsUtilityUnitCalc {
    /**
     * 
     * @type {string}
     * @memberof MeterReadingsUtilityUnitCalc
     */
    'dateTime': string;
    /**
     * energy consumption in kWh
     * @type {string}
     * @memberof MeterReadingsUtilityUnitCalc
     */
    'consumption': string;
    /**
     * percentage of total consumed electricity provided by in-house production of the zev
     * @type {string}
     * @memberof MeterReadingsUtilityUnitCalc
     */
    'areaPercentageTotal': string;
    /**
     * share of produced energy in kWh
     * @type {string}
     * @memberof MeterReadingsUtilityUnitCalc
     */
    'produced': string;
    /**
     * share of purchased energy in kWh in the period 7am-10pm
     * @type {string}
     * @memberof MeterReadingsUtilityUnitCalc
     */
    'energyHighTariff': string;
    /**
     * share of purchased energy in kWh outside of the period 7am-10pm
     * @type {string}
     * @memberof MeterReadingsUtilityUnitCalc
     */
    'energyLowTariff': string;
}
/**
 * 
 * @export
 * @interface MeterReadingsUtilityUnitElectricityCalcResponse
 */
export interface MeterReadingsUtilityUnitElectricityCalcResponse {
    /**
     * 
     * @type {string}
     * @memberof MeterReadingsUtilityUnitElectricityCalcResponse
     */
    'meterId'?: string;
    /**
     * 
     * @type {MeterReadingsCalcResolution}
     * @memberof MeterReadingsUtilityUnitElectricityCalcResponse
     */
    'resolution': MeterReadingsCalcResolution;
    /**
     * 
     * @type {Array<MeterReadingsUtilityUnitCalc>}
     * @memberof MeterReadingsUtilityUnitElectricityCalcResponse
     */
    'data': Array<MeterReadingsUtilityUnitCalc>;
    /**
     * total energy consumption in kWh, over time window
     * @type {string}
     * @memberof MeterReadingsUtilityUnitElectricityCalcResponse
     */
    'totalConsumption': string;
    /**
     * total share of produced energy in kWh, over time window
     * @type {string}
     * @memberof MeterReadingsUtilityUnitElectricityCalcResponse
     */
    'totalProduced': string;
    /**
     * total share of purchased energy in kWh in the period 7am-10pm, over time window
     * @type {string}
     * @memberof MeterReadingsUtilityUnitElectricityCalcResponse
     */
    'totalEnergyHighTariff': string;
    /**
     * total share of purchased energy in kWh outside of the period 7am-10pm, over time window
     * @type {string}
     * @memberof MeterReadingsUtilityUnitElectricityCalcResponse
     */
    'totalEnergyLowTariff': string;
}
/**
 * 
 * @export
 * @interface MeterReadingsUtilityUnitResponse
 */
export interface MeterReadingsUtilityUnitResponse {
    /**
     * 
     * @type {MeterReadingsUtilityUnitElectricityCalcResponse}
     * @memberof MeterReadingsUtilityUnitResponse
     */
    'zev': MeterReadingsUtilityUnitElectricityCalcResponse;
    /**
     * 
     * @type {VewaMeterReadingsResponse}
     * @memberof MeterReadingsUtilityUnitResponse
     */
    'vewa': VewaMeterReadingsResponse;
}
/**
 * 
 * @export
 * @interface MeterResponse
 */
export interface MeterResponse {
    /**
     * 
     * @type {string}
     * @memberof MeterResponse
     */
    'id': string;
    /**
     * 
     * @type {MeterState}
     * @memberof MeterResponse
     */
    'activeState': MeterState;
    /**
     * 
     * @type {PowerMeterType}
     * @memberof MeterResponse
     */
    'powerMeterType'?: PowerMeterType;
    /**
     * 
     * @type {Medium}
     * @memberof MeterResponse
     */
    'medium': Medium;
    /**
     * 
     * @type {string}
     * @memberof MeterResponse
     */
    'meteringCode': string;
    /**
     * 
     * @type {MeterAttachmentType}
     * @memberof MeterResponse
     */
    'meterAttachmentType': MeterAttachmentType;
    /**
     * 
     * @type {UsageType}
     * @memberof MeterResponse
     */
    'usageType': UsageType;
    /**
     * 
     * @type {string}
     * @memberof MeterResponse
     */
    'meterAttachmentId': string;
    /**
     * 
     * @type {string}
     * @memberof MeterResponse
     */
    'meterAttachmentName': string;
    /**
     * 
     * @type {string}
     * @memberof MeterResponse
     */
    'billableFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeterResponse
     */
    'billableTo'?: string;
    /**
     * 
     * @type {number}
     * @memberof MeterResponse
     */
    'pricePackageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MeterResponse
     */
    'pricePackageName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeterResponse
     */
    'contractId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeterResponse
     */
    'isLinkedToVewaConfig'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MeterState {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DELETED = 'DELETED'
}

/**
 * 
 * @export
 * @interface MeterUpdateAdminRequest
 */
export interface MeterUpdateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof MeterUpdateAdminRequest
     */
    'meteringCode': string;
    /**
     * 
     * @type {PowerMeterType}
     * @memberof MeterUpdateAdminRequest
     */
    'powerMeterType'?: PowerMeterType;
    /**
     * 
     * @type {Medium}
     * @memberof MeterUpdateAdminRequest
     */
    'medium': Medium;
    /**
     * 
     * @type {UsageType}
     * @memberof MeterUpdateAdminRequest
     */
    'usageType'?: UsageType;
    /**
     * 
     * @type {string}
     * @memberof MeterUpdateAdminRequest
     */
    'billableFrom': string;
    /**
     * 
     * @type {string}
     * @memberof MeterUpdateAdminRequest
     */
    'billableTo'?: string;
    /**
     * 
     * @type {number}
     * @memberof MeterUpdateAdminRequest
     */
    'pricePackageId'?: number;
}
/**
 * 
 * @export
 * @interface MeterUpdatePricePackageCustomerRequest
 */
export interface MeterUpdatePricePackageCustomerRequest {
    /**
     * 
     * @type {number}
     * @memberof MeterUpdatePricePackageCustomerRequest
     */
    'pricePackageId': number;
}
/**
 * 
 * @export
 * @interface MoveinParticipationExistingRequest
 */
export interface MoveinParticipationExistingRequest {
    /**
     * 
     * @type {string}
     * @memberof MoveinParticipationExistingRequest
     */
    'participantId': string;
    /**
     * 
     * @type {string}
     * @memberof MoveinParticipationExistingRequest
     */
    'moveinDate': string;
}
/**
 * 
 * @export
 * @interface MoveinWithNewParticipantAdminRequest
 */
export interface MoveinWithNewParticipantAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof MoveinWithNewParticipantAdminRequest
     */
    'moveinDate': string;
    /**
     * 
     * @type {ParticipantAdminCreateRequest}
     * @memberof MoveinWithNewParticipantAdminRequest
     */
    'participant': ParticipantAdminCreateRequest;
}
/**
 * 
 * @export
 * @interface MoveinWithNewParticipantCustomerRequest
 */
export interface MoveinWithNewParticipantCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof MoveinWithNewParticipantCustomerRequest
     */
    'moveinDate': string;
    /**
     * 
     * @type {ParticipantCustomerCreateRequest}
     * @memberof MoveinWithNewParticipantCustomerRequest
     */
    'participant': ParticipantCustomerCreateRequest;
}
/**
 * 
 * @export
 * @interface MoveoutParticipationVacancyRequest
 */
export interface MoveoutParticipationVacancyRequest {
    /**
     * 
     * @type {string}
     * @memberof MoveoutParticipationVacancyRequest
     */
    'moveoutDate': string;
}
/**
 * 
 * @export
 * @interface MultiBillingDetailsResponse
 */
export interface MultiBillingDetailsResponse {
    /**
     * 
     * @type {Array<BillingDetails>}
     * @memberof MultiBillingDetailsResponse
     */
    'elements': Array<BillingDetails>;
}
/**
 * 
 * @export
 * @interface MultiContractCustomerResponse
 */
export interface MultiContractCustomerResponse {
    /**
     * 
     * @type {Array<ContractCustomerResponse>}
     * @memberof MultiContractCustomerResponse
     */
    'elements': Array<ContractCustomerResponse>;
}
/**
 * All participants living in a utility Unit within an Area.
 * @export
 * @interface MultiParticipantResponse
 */
export interface MultiParticipantResponse {
    /**
     * 
     * @type {Array<ParticipantResponse>}
     * @memberof MultiParticipantResponse
     */
    'elements': Array<ParticipantResponse>;
}
/**
 * 
 * @export
 * @interface MultiProductAdminResponse
 */
export interface MultiProductAdminResponse {
    /**
     * 
     * @type {Array<ProductAdminResponse>}
     * @memberof MultiProductAdminResponse
     */
    'elements': Array<ProductAdminResponse>;
}
/**
 * 
 * @export
 * @interface MultiServiceBillingAdminResponse
 */
export interface MultiServiceBillingAdminResponse {
    /**
     * 
     * @type {Array<ServiceBillingAdminResponse>}
     * @memberof MultiServiceBillingAdminResponse
     */
    'elements': Array<ServiceBillingAdminResponse>;
}
/**
 * 
 * @export
 * @interface MultiServiceComponentAdminResponse
 */
export interface MultiServiceComponentAdminResponse {
    /**
     * 
     * @type {Array<ServiceComponentAdminResponse>}
     * @memberof MultiServiceComponentAdminResponse
     */
    'elements': Array<ServiceComponentAdminResponse>;
}
/**
 * 
 * @export
 * @interface MultiUnifiedServiceBillingAdminResponse
 */
export interface MultiUnifiedServiceBillingAdminResponse {
    /**
     * 
     * @type {Array<UnifiedServiceBillingAdminResponse>}
     * @memberof MultiUnifiedServiceBillingAdminResponse
     */
    'elements': Array<UnifiedServiceBillingAdminResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Municipality {
    EMMEN = 'EMMEN',
    HORW = 'HORW',
    LUZERN = 'LUZERN',
    MEGGEN = 'MEGGEN',
    NEUENKIRCH = 'NEUENKIRCH',
    SCHONGAU = 'SCHONGAU',
    ADLIGENSWIL = 'ADLIGENSWIL',
    AESCH = 'AESCH',
    ALBERSWIL = 'ALBERSWIL',
    ALTBUERON = 'ALTBUERON',
    ALTISHOFEN = 'ALTISHOFEN',
    ALTWIS = 'ALTWIS',
    BALLWIL = 'BALLWIL',
    BEROMUENSTER = 'BEROMUENSTER',
    BUCHRAIN = 'BUCHRAIN',
    BUERON = 'BUERON',
    BUTTISHOLZ = 'BUTTISHOLZ',
    DAGMERSELLEN = 'DAGMERSELLEN',
    DIERIKON = 'DIERIKON',
    DOPPLESCHWAND = 'DOPPLESCHWAND',
    EBERSEKEN = 'EBERSEKEN',
    EBIKON = 'EBIKON',
    EGOLZWIL = 'EGOLZWIL',
    EICH = 'EICH',
    ENTLEBUCH = 'ENTLEBUCH',
    ERMENSEE = 'ERMENSEE',
    ESCHENBACH = 'ESCHENBACH',
    ESCHOLZMATT_MARBACH = 'ESCHOLZMATT_MARBACH',
    ETTISWIL = 'ETTISWIL',
    FISCHBACH = 'FISCHBACH',
    FLUEHLI = 'FLUEHLI',
    GETTNAU = 'GETTNAU',
    GEUENSEE = 'GEUENSEE',
    GISIKON = 'GISIKON',
    GROSSDIETWIL = 'GROSSDIETWIL',
    GROSSWANGEN = 'GROSSWANGEN',
    HASLE = 'HASLE',
    HERGISWIL_BEI_WILLISAU = 'HERGISWIL_BEI_WILLISAU',
    HILDISRIEDEN = 'HILDISRIEDEN',
    HITZKIRCH = 'HITZKIRCH',
    HOHENRAIN = 'HOHENRAIN',
    HONAU = 'HONAU',
    INWIL = 'INWIL',
    KNUTWIL = 'KNUTWIL',
    KRIENS = 'KRIENS',
    MAUENSEE = 'MAUENSEE',
    MEIERSKAPPEL = 'MEIERSKAPPEL',
    MENZNAU = 'MENZNAU',
    NEBIKON = 'NEBIKON',
    NOTTWIL = 'NOTTWIL',
    OBERKIRCH = 'OBERKIRCH',
    PFAFFNAU = 'PFAFFNAU',
    RICKENBACH = 'RICKENBACH',
    RAIN = 'RAIN',
    REIDEN = 'REIDEN',
    ROGGLISWIL = 'ROGGLISWIL',
    ROEMERSWIL = 'ROEMERSWIL',
    ROMOOS = 'ROMOOS',
    ROOT = 'ROOT',
    ROTHENBURG = 'ROTHENBURG',
    RUSWIL = 'RUSWIL',
    SCHENKON = 'SCHENKON',
    SCHLIERBACH = 'SCHLIERBACH',
    SCHOETZ = 'SCHOETZ',
    SCHUEPFHEIM = 'SCHUEPFHEIM',
    SEMPACH = 'SEMPACH',
    SURSEE = 'SURSEE',
    TRIENGEN = 'TRIENGEN',
    UDLIGENSWIL = 'UDLIGENSWIL',
    WAUWIL = 'WAUWIL',
    WERTHENSTEIN = 'WERTHENSTEIN',
    WIKON = 'WIKON',
    WILLISAU = 'WILLISAU',
    WOLHUSEN = 'WOLHUSEN',
    ZELL = 'ZELL'
}

/**
 * 
 * @export
 * @interface NewAccountPasswordData
 */
export interface NewAccountPasswordData {
    /**
     * 
     * @type {string}
     * @memberof NewAccountPasswordData
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface OtherPosition
 */
export interface OtherPosition {
    /**
     * 
     * @type {OtherType}
     * @memberof OtherPosition
     */
    'otherType': OtherType;
    /**
     * 
     * @type {string}
     * @memberof OtherPosition
     */
    'consumption': string;
    /**
     * 
     * @type {string}
     * @memberof OtherPosition
     */
    'price': string;
    /**
     * 
     * @type {string}
     * @memberof OtherPosition
     */
    'amountDue': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum OtherType {
    SERVICES = 'ADDITIONAL_SERVICES',
    SERVICES_LUMP_SUM = 'ADDITIONAL_SERVICES_LUMP_SUM'
}

/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'currentPage': number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'totalElements': number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'totalPages': number;
}
/**
 * 
 * @export
 * @interface PagedAllParticipantsBillingResponse
 */
export interface PagedAllParticipantsBillingResponse {
    /**
     * 
     * @type {Array<AllParticipantsBilling>}
     * @memberof PagedAllParticipantsBillingResponse
     */
    'elements': Array<AllParticipantsBilling>;
    /**
     * 
     * @type {Page}
     * @memberof PagedAllParticipantsBillingResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedAreaAdminResponse
 */
export interface PagedAreaAdminResponse {
    /**
     * 
     * @type {Array<AreaAdminResponse>}
     * @memberof PagedAreaAdminResponse
     */
    'elements': Array<AreaAdminResponse>;
    /**
     * 
     * @type {Page}
     * @memberof PagedAreaAdminResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedBuildingAdminResponse
 */
export interface PagedBuildingAdminResponse {
    /**
     * 
     * @type {Array<BuildingAdminResponse>}
     * @memberof PagedBuildingAdminResponse
     */
    'elements': Array<BuildingAdminResponse>;
    /**
     * 
     * @type {Page}
     * @memberof PagedBuildingAdminResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedContractAdminResponse
 */
export interface PagedContractAdminResponse {
    /**
     * 
     * @type {Array<ContractAdminResponse>}
     * @memberof PagedContractAdminResponse
     */
    'elements': Array<ContractAdminResponse>;
    /**
     * 
     * @type {Page}
     * @memberof PagedContractAdminResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedIndividualParticipantsBillingResponse
 */
export interface PagedIndividualParticipantsBillingResponse {
    /**
     * 
     * @type {Array<IndividualParticipantBilling>}
     * @memberof PagedIndividualParticipantsBillingResponse
     */
    'elements': Array<IndividualParticipantBilling>;
    /**
     * 
     * @type {Page}
     * @memberof PagedIndividualParticipantsBillingResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedMeterResponse
 */
export interface PagedMeterResponse {
    /**
     * 
     * @type {Array<MeterResponse>}
     * @memberof PagedMeterResponse
     */
    'elements': Array<MeterResponse>;
    /**
     * 
     * @type {Page}
     * @memberof PagedMeterResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedParticipantResponse
 */
export interface PagedParticipantResponse {
    /**
     * 
     * @type {Array<ParticipantResponse>}
     * @memberof PagedParticipantResponse
     */
    'elements': Array<ParticipantResponse>;
    /**
     * 
     * @type {Page}
     * @memberof PagedParticipantResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedParticipationResponse
 */
export interface PagedParticipationResponse {
    /**
     * 
     * @type {Array<ParticipationResponse>}
     * @memberof PagedParticipationResponse
     */
    'elements': Array<ParticipationResponse>;
    /**
     * 
     * @type {Page}
     * @memberof PagedParticipationResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedProfileAdminResponse
 */
export interface PagedProfileAdminResponse {
    /**
     * 
     * @type {Array<ProfileAdminResponse>}
     * @memberof PagedProfileAdminResponse
     */
    'elements': Array<ProfileAdminResponse>;
    /**
     * 
     * @type {Page}
     * @memberof PagedProfileAdminResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedServiceBillingAdminResponse
 */
export interface PagedServiceBillingAdminResponse {
    /**
     * 
     * @type {Array<ServiceBillingAdminResponse>}
     * @memberof PagedServiceBillingAdminResponse
     */
    'elements': Array<ServiceBillingAdminResponse>;
    /**
     * 
     * @type {Page}
     * @memberof PagedServiceBillingAdminResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedTaskAdminResponse
 */
export interface PagedTaskAdminResponse {
    /**
     * 
     * @type {Array<Task>}
     * @memberof PagedTaskAdminResponse
     */
    'elements': Array<Task>;
    /**
     * 
     * @type {Page}
     * @memberof PagedTaskAdminResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedUtilityUnitResponse
 */
export interface PagedUtilityUnitResponse {
    /**
     * 
     * @type {Array<UtilityUnitResponse>}
     * @memberof PagedUtilityUnitResponse
     */
    'elements': Array<UtilityUnitResponse>;
    /**
     * 
     * @type {Page}
     * @memberof PagedUtilityUnitResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedVewaAllParticipantsBillingResponse
 */
export interface PagedVewaAllParticipantsBillingResponse {
    /**
     * 
     * @type {Array<VewaAllParticipantsBilling>}
     * @memberof PagedVewaAllParticipantsBillingResponse
     */
    'elements': Array<VewaAllParticipantsBilling>;
    /**
     * 
     * @type {Page}
     * @memberof PagedVewaAllParticipantsBillingResponse
     */
    'page': Page;
}
/**
 * 
 * @export
 * @interface PagedVewaBillsResponse
 */
export interface PagedVewaBillsResponse {
    /**
     * 
     * @type {string}
     * @memberof PagedVewaBillsResponse
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof PagedVewaBillsResponse
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof PagedVewaBillsResponse
     */
    'billingDate': string;
    /**
     * 
     * @type {string}
     * @memberof PagedVewaBillsResponse
     */
    'totalEnergyConsumption': string;
    /**
     * 
     * @type {string}
     * @memberof PagedVewaBillsResponse
     */
    'totalWaterConsumption': string;
    /**
     * 
     * @type {VewaAllParticipantsBillingState}
     * @memberof PagedVewaBillsResponse
     */
    'status': VewaAllParticipantsBillingState;
    /**
     * 
     * @type {Array<VewaIndividualParticipationConsumptionResponse>}
     * @memberof PagedVewaBillsResponse
     */
    'elements': Array<VewaIndividualParticipationConsumptionResponse>;
}
/**
 * 
 * @export
 * @interface PagedVewaIndividualParticipationConsumptionResponse
 */
export interface PagedVewaIndividualParticipationConsumptionResponse {
    /**
     * 
     * @type {Array<VewaIndividualParticipationConsumptionResponse>}
     * @memberof PagedVewaIndividualParticipationConsumptionResponse
     */
    'elements': Array<VewaIndividualParticipationConsumptionResponse>;
}
/**
 * 
 * @export
 * @interface ParticipantAdminCreateRequest
 */
export interface ParticipantAdminCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ParticipantAdminCreateRequest
     */
    'sapGp'?: string;
    /**
     * 
     * @type {ParticipantGpType}
     * @memberof ParticipantAdminCreateRequest
     */
    'gpType': ParticipantGpType;
    /**
     * 
     * @type {ParticipantPersonalData}
     * @memberof ParticipantAdminCreateRequest
     */
    'personalData': ParticipantPersonalData;
    /**
     * 
     * @type {ParticipantContactDetails}
     * @memberof ParticipantAdminCreateRequest
     */
    'contactDetails': ParticipantContactDetails;
    /**
     * 
     * @type {DomicileAddress}
     * @memberof ParticipantAdminCreateRequest
     */
    'domicileAddress': DomicileAddress;
}
/**
 * 
 * @export
 * @interface ParticipantAdminUpdateRequest
 */
export interface ParticipantAdminUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ParticipantAdminUpdateRequest
     */
    'sapGp'?: string;
    /**
     * 
     * @type {ParticipantGpType}
     * @memberof ParticipantAdminUpdateRequest
     */
    'gpType': ParticipantGpType;
    /**
     * 
     * @type {ParticipantPersonalData}
     * @memberof ParticipantAdminUpdateRequest
     */
    'personalData': ParticipantPersonalData;
    /**
     * 
     * @type {ParticipantContactDetails}
     * @memberof ParticipantAdminUpdateRequest
     */
    'contactDetails': ParticipantContactDetails;
    /**
     * 
     * @type {DomicileAddress}
     * @memberof ParticipantAdminUpdateRequest
     */
    'domicileAddress': DomicileAddress;
}
/**
 * 
 * @export
 * @interface ParticipantAllBillingsResponse
 */
export interface ParticipantAllBillingsResponse {
    /**
     * 
     * @type {Array<ParticipantBillingsResponse>}
     * @memberof ParticipantAllBillingsResponse
     */
    'participantBillings': Array<ParticipantBillingsResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ParticipantBillingState {
    WAITING_FOR_DATA = 'WAITING_FOR_DATA',
    DATA_AVAILABLE = 'DATA_AVAILABLE',
    SUSPENDED = 'SUSPENDED',
    IN_PROGRESS = 'IN_PROGRESS',
    IN_PROGRESS_REOPENED = 'IN_PROGRESS_REOPENED',
    DONE = 'DONE',
    PAID = 'PAID'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ParticipantBillingType {
    INDIVIDUAL = 'INDIVIDUAL',
    ALL = 'ALL'
}

/**
 * 
 * @export
 * @interface ParticipantBillingsResponse
 */
export interface ParticipantBillingsResponse {
    /**
     * 
     * @type {string}
     * @memberof ParticipantBillingsResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantBillingsResponse
     */
    'billingStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantBillingsResponse
     */
    'billingEndDate': string;
    /**
     * 
     * @type {ParticipantBillingState}
     * @memberof ParticipantBillingsResponse
     */
    'status': ParticipantBillingState;
    /**
     * 
     * @type {ParticipantBillingType}
     * @memberof ParticipantBillingsResponse
     */
    'type': ParticipantBillingType;
    /**
     * 
     * @type {string}
     * @memberof ParticipantBillingsResponse
     */
    'contractId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantBillingsResponse
     */
    'contractName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantBillingsResponse
     */
    'dataMissingForAllMeters': boolean;
    /**
     * 
     * @type {Array<Meter>}
     * @memberof ParticipantBillingsResponse
     */
    'missingDataMeters': Array<Meter>;
}
/**
 * 
 * @export
 * @interface ParticipantContactDetails
 */
export interface ParticipantContactDetails {
    /**
     * 
     * @type {string}
     * @memberof ParticipantContactDetails
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantContactDetails
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantContactDetails
     */
    'mobile'?: string;
}
/**
 * 
 * @export
 * @interface ParticipantCustomerCreateRequest
 */
export interface ParticipantCustomerCreateRequest {
    /**
     * 
     * @type {ParticipantGpType}
     * @memberof ParticipantCustomerCreateRequest
     */
    'gpType': ParticipantGpType;
    /**
     * 
     * @type {ParticipantPersonalData}
     * @memberof ParticipantCustomerCreateRequest
     */
    'personalData': ParticipantPersonalData;
    /**
     * 
     * @type {ParticipantContactDetails}
     * @memberof ParticipantCustomerCreateRequest
     */
    'contactDetails': ParticipantContactDetails;
    /**
     * 
     * @type {DomicileAddress}
     * @memberof ParticipantCustomerCreateRequest
     */
    'domicileAddress': DomicileAddress;
}
/**
 * 
 * @export
 * @interface ParticipantCustomerUpdateRequest
 */
export interface ParticipantCustomerUpdateRequest {
    /**
     * 
     * @type {ParticipantPersonalData}
     * @memberof ParticipantCustomerUpdateRequest
     */
    'personalData': ParticipantPersonalData;
    /**
     * 
     * @type {ParticipantContactDetails}
     * @memberof ParticipantCustomerUpdateRequest
     */
    'contactDetails': ParticipantContactDetails;
    /**
     * 
     * @type {DomicileAddress}
     * @memberof ParticipantCustomerUpdateRequest
     */
    'domicileAddress': DomicileAddress;
    /**
     * 
     * @type {ParticipantGpType}
     * @memberof ParticipantCustomerUpdateRequest
     */
    'gpType': ParticipantGpType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ParticipantGpType {
    PERSON = 'PERSON'
}

/**
 * 
 * @export
 * @interface ParticipantPersonalData
 */
export interface ParticipantPersonalData {
    /**
     * 
     * @type {string}
     * @memberof ParticipantPersonalData
     */
    'salutation': ParticipantPersonalDataSalutationEnum;
    /**
     * 
     * @type {string}
     * @memberof ParticipantPersonalData
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantPersonalData
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantPersonalData
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantPersonalData
     */
    'firstNameSecondPerson'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantPersonalData
     */
    'lastNameSecondPerson'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ParticipantPersonalDataSalutationEnum {
    MR = 'MR',
    MS = 'MS',
    MR_AND_MS = 'MR_AND_MS',
    MRS = 'MRS',
    MSS = 'MSS',
    SHARED_FLAT = 'SHARED_FLAT',
    COMPANY = 'COMPANY',
    UNDEFINED = 'UNDEFINED'
}

/**
 * Person living in a utility Unit within an Area.
 * @export
 * @interface ParticipantResponse
 */
export interface ParticipantResponse {
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    'sapGp'?: string;
    /**
     * 
     * @type {ParticipantGpType}
     * @memberof ParticipantResponse
     */
    'gpType': ParticipantGpType;
    /**
     * 
     * @type {ParticipantPersonalData}
     * @memberof ParticipantResponse
     */
    'personalData': ParticipantPersonalData;
    /**
     * 
     * @type {ParticipantContactDetails}
     * @memberof ParticipantResponse
     */
    'contactDetails': ParticipantContactDetails;
    /**
     * 
     * @type {DomicileAddress}
     * @memberof ParticipantResponse
     */
    'domicileAddress': DomicileAddress;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantResponse
     */
    'syncStatus': boolean;
    /**
     * 
     * @type {Array<ParticipationResponse>}
     * @memberof ParticipantResponse
     */
    'participations'?: Array<ParticipationResponse>;
}
/**
 * 
 * @export
 * @interface ParticipantsBulkRequest
 */
export interface ParticipantsBulkRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ParticipantsBulkRequest
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface ParticipationEvent
 */
export interface ParticipationEvent {
    /**
     * 
     * @type {string}
     * @memberof ParticipationEvent
     */
    'id': string;
    /**
     * 
     * @type {EventType}
     * @memberof ParticipationEvent
     */
    'event': EventType;
    /**
     * 
     * @type {string}
     * @memberof ParticipationEvent
     */
    'date'?: string;
}
/**
 * Person living in a utilityUnit.  If participantId is empty, the utilityUnit is empty for that time period.
 * @export
 * @interface ParticipationResponse
 */
export interface ParticipationResponse {
    /**
     * 
     * @type {string}
     * @memberof ParticipationResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationResponse
     */
    'moveInDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationResponse
     */
    'moveOutDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationResponse
     */
    'participantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParticipationResponse
     */
    'bills': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ParticipationResponse
     */
    'utilityUnitId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipationResponse
     */
    'isCurrent': boolean;
    /**
     * 
     * @type {UtilityUnitResponse}
     * @memberof ParticipationResponse
     */
    'utilityUnit'?: UtilityUnitResponse;
    /**
     * 
     * @type {ParticipantResponse}
     * @memberof ParticipationResponse
     */
    'participant'?: ParticipantResponse;
}
/**
 * 
 * @export
 * @interface ParticipationUpdateMoveinRequest
 */
export interface ParticipationUpdateMoveinRequest {
    /**
     * 
     * @type {string}
     * @memberof ParticipationUpdateMoveinRequest
     */
    'moveinDate': string;
}
/**
 * 
 * @export
 * @interface ParticipationUpdateMoveoutRequest
 */
export interface ParticipationUpdateMoveoutRequest {
    /**
     * 
     * @type {string}
     * @memberof ParticipationUpdateMoveoutRequest
     */
    'moveoutDate': string;
}
/**
 * 
 * @export
 * @interface PaymentInformation
 */
export interface PaymentInformation {
    /**
     * 
     * @type {string}
     * @memberof PaymentInformation
     */
    'payee': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInformation
     */
    'iban': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInformation
     */
    'vatNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentInformation
     */
    'paymentDeadlineInDays': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PaymentStatus {
    PAID = 'PAID',
    UNPAID = 'UNPAID'
}

/**
 * 
 * @export
 * @interface PeriodResponse
 */
export interface PeriodResponse {
    /**
     * 
     * @type {DayInAWeek}
     * @memberof PeriodResponse
     */
    'day': DayInAWeek;
    /**
     * A string representing a local time in 15-minute intervals. The time should be in the format HH:MM, where HH is a two-digit hour (00-23) and MM is one of 00, 15, 30, or 45. 
     * @type {string}
     * @memberof PeriodResponse
     */
    'startTime': string;
    /**
     * A string representing a local time in 15-minute intervals. The time should be in the format HH:MM, where HH is a two-digit hour (00-23) and MM is one of 00, 15, 30, or 45. 
     * @type {string}
     * @memberof PeriodResponse
     */
    'endTime'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PersonState {
    CREATED = 'CREATED'
}

/**
 * 
 * @export
 * @interface PersonalData
 */
export interface PersonalData {
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'salutation': PersonalDataSalutationEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'lastName': string;
}

/**
    * @export
    * @enum {string}
    */
export enum PersonalDataSalutationEnum {
    MR = 'MR',
    MS = 'MS'
}

/**
 * 
 * @export
 * @interface PowerMeterParameters
 */
export interface PowerMeterParameters {
    /**
     * 
     * @type {string}
     * @memberof PowerMeterParameters
     */
    'meteringCode': string;
    /**
     * 
     * @type {string}
     * @memberof PowerMeterParameters
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof PowerMeterParameters
     */
    'to': string;
    /**
     * 
     * @type {boolean}
     * @memberof PowerMeterParameters
     */
    'isDataAvailable': boolean;
    /**
     * 
     * @type {string}
     * @memberof PowerMeterParameters
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerMeterParameters
     */
    'participationId'?: string;
    /**
     * 
     * @type {Array<EnergyConsumption>}
     * @memberof PowerMeterParameters
     */
    'consumptionData': Array<EnergyConsumption>;
    /**
     * 
     * @type {string}
     * @memberof PowerMeterParameters
     */
    'meterId': string;
    /**
     * 
     * @type {MeterResponse}
     * @memberof PowerMeterParameters
     */
    'meter'?: MeterResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PowerMeterType {
    HOUSEHOLD_POWERMETER = 'HOUSEHOLD_POWERMETER',
    HOUSEHOLD_POWERMETER_ON_RAILS = 'HOUSEHOLD_POWERMETER_ON_RAILS',
    COMMERCIAL_POWERMETER_SMALL = 'COMMERCIAL_POWERMETER_SMALL',
    COMMERCIAL_POWERMETER_LARGE = 'COMMERCIAL_POWERMETER_LARGE'
}

/**
 * 
 * @export
 * @interface PriceCalculatorTariff
 */
export interface PriceCalculatorTariff {
    /**
     * 
     * @type {number}
     * @memberof PriceCalculatorTariff
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PriceCalculatorTariff
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof PriceCalculatorTariff
     */
    'isDefaultReferenceTariff': boolean;
    /**
     * 
     * @type {number}
     * @memberof PriceCalculatorTariff
     */
    'basePrice': number;
    /**
     * 
     * @type {number}
     * @memberof PriceCalculatorTariff
     */
    'peakPower': number;
    /**
     * 
     * @type {number}
     * @memberof PriceCalculatorTariff
     */
    'high': number;
    /**
     * 
     * @type {number}
     * @memberof PriceCalculatorTariff
     */
    'low': number;
}
/**
 * 
 * @export
 * @interface PriceCalculatorTariffs
 */
export interface PriceCalculatorTariffs {
    /**
     * 
     * @type {number}
     * @memberof PriceCalculatorTariffs
     */
    'year': number;
    /**
     * 
     * @type {Array<PriceCalculatorTariff>}
     * @memberof PriceCalculatorTariffs
     */
    'tariffs': Array<PriceCalculatorTariff>;
}
/**
 * 
 * @export
 * @interface PriceComponent
 */
export interface PriceComponent {
    /**
     * 
     * @type {string}
     * @memberof PriceComponent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PriceComponent
     */
    'name': string;
    /**
     * 
     * @type {BillingType}
     * @memberof PriceComponent
     */
    'billingType': BillingType;
    /**
     * 
     * @type {PowerMeterType}
     * @memberof PriceComponent
     */
    'powermeterType'?: PowerMeterType;
    /**
     * 
     * @type {string}
     * @memberof PriceComponent
     */
    'priceWithoutVat': string;
    /**
     * 
     * @type {string}
     * @memberof PriceComponent
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PriceComponent
     */
    'validUntil'?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceComponent
     */
    'externalReferenceNumber': string;
}
/**
 * 
 * @export
 * @interface PriceComponentCreateAdminRequest
 */
export interface PriceComponentCreateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof PriceComponentCreateAdminRequest
     */
    'name': string;
    /**
     * 
     * @type {BillingType}
     * @memberof PriceComponentCreateAdminRequest
     */
    'billingType': BillingType;
    /**
     * 
     * @type {PowerMeterType}
     * @memberof PriceComponentCreateAdminRequest
     */
    'powermeterType'?: PowerMeterType;
    /**
     * 
     * @type {string}
     * @memberof PriceComponentCreateAdminRequest
     */
    'priceWithoutVat': string;
    /**
     * 
     * @type {string}
     * @memberof PriceComponentCreateAdminRequest
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PriceComponentCreateAdminRequest
     */
    'validUntil'?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceComponentCreateAdminRequest
     */
    'externalReferenceNumber': string;
}
/**
 * 
 * @export
 * @interface PriceComponentUpdateAdminRequest
 */
export interface PriceComponentUpdateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof PriceComponentUpdateAdminRequest
     */
    'name': string;
    /**
     * 
     * @type {BillingType}
     * @memberof PriceComponentUpdateAdminRequest
     */
    'billingType': BillingType;
    /**
     * 
     * @type {PowerMeterType}
     * @memberof PriceComponentUpdateAdminRequest
     */
    'powermeterType'?: PowerMeterType;
    /**
     * 
     * @type {string}
     * @memberof PriceComponentUpdateAdminRequest
     */
    'priceWithoutVat': string;
    /**
     * 
     * @type {string}
     * @memberof PriceComponentUpdateAdminRequest
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PriceComponentUpdateAdminRequest
     */
    'validUntil'?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceComponentUpdateAdminRequest
     */
    'externalReferenceNumber': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PricingModel {
    RENTAL = 'RENTAL',
    PURCHASE = 'PURCHASE'
}

/**
 * 
 * @export
 * @interface PricingModelRequest
 */
export interface PricingModelRequest {
    /**
     * 
     * @type {PricingModel}
     * @memberof PricingModelRequest
     */
    'pricingModel': PricingModel;
}
/**
 * 
 * @export
 * @interface PricingModelResponse
 */
export interface PricingModelResponse {
    /**
     * 
     * @type {PricingModel}
     * @memberof PricingModelResponse
     */
    'pricingModel': PricingModel;
}
/**
 * 
 * @export
 * @interface ProductAdminResponse
 */
export interface ProductAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminResponse
     */
    'name': string;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductAdminResponse
     */
    'productType': ProductType;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductAdminResponse
     */
    'serviceComponents': Array<string>;
    /**
     * List of price components within this product. Cannot be empty.
     * @type {Array<PriceComponent>}
     * @memberof ProductAdminResponse
     */
    'priceComponents': Array<PriceComponent>;
}
/**
 * 
 * @export
 * @interface ProductCreateAdminRequest
 */
export interface ProductCreateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductCreateAdminRequest
     */
    'name': string;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductCreateAdminRequest
     */
    'productType': ProductType;
    /**
     * List of Services components available within this product. Cannot be empty.
     * @type {Array<string>}
     * @memberof ProductCreateAdminRequest
     */
    'serviceComponents': Array<string>;
}
/**
 * 
 * @export
 * @interface ProductCustomerResponse
 */
export interface ProductCustomerResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductCustomerResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCustomerResponse
     */
    'name': string;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductCustomerResponse
     */
    'productType': ProductType;
}
/**
 * 
 * @export
 * @interface ProductHistory
 */
export interface ProductHistory {
    /**
     * 
     * @type {string}
     * @memberof ProductHistory
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof ProductHistory
     */
    'contractStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductHistory
     */
    'contractEndDate'?: string;
}
/**
 * 
 * @export
 * @interface ProductHistoryResponse
 */
export interface ProductHistoryResponse {
    /**
     * 
     * @type {Array<ProductHistory>}
     * @memberof ProductHistoryResponse
     */
    'productHistory': Array<ProductHistory>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductType {
    ZEV = 'ZEV',
    VEWA = 'VEWA'
}

/**
 * 
 * @export
 * @interface ProductUpdateAdminRequest
 */
export interface ProductUpdateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateAdminRequest
     */
    'name': string;
    /**
     * List of Service Components within this Product. Cannot be empty.
     * @type {Array<string>}
     * @memberof ProductUpdateAdminRequest
     */
    'serviceComponents': Array<string>;
}
/**
 * 
 * @export
 * @interface ProfileAdminResponse
 */
export interface ProfileAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof ProfileAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileAdminResponse
     */
    'party': string;
    /**
     * 
     * @type {UserType}
     * @memberof ProfileAdminResponse
     */
    'userType': UserType;
    /**
     * 
     * @type {ContactData}
     * @memberof ProfileAdminResponse
     */
    'contact': ContactData;
    /**
     * 
     * @type {AddressData}
     * @memberof ProfileAdminResponse
     */
    'address': AddressData;
    /**
     * 
     * @type {PersonalData}
     * @memberof ProfileAdminResponse
     */
    'personal': PersonalData;
    /**
     * 
     * @type {string}
     * @memberof ProfileAdminResponse
     */
    'customerNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileAdminResponse
     */
    'externalUserId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileAdminResponse
     */
    'isBlocked': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileAdminResponse
     */
    'username': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileAdminResponse
     */
    'externalUserParties'?: Array<string>;
    /**
     * 
     * @type {PersonState}
     * @memberof ProfileAdminResponse
     */
    'activeState': PersonState;
}
/**
 * 
 * @export
 * @interface ProfileContractsAdminRequest
 */
export interface ProfileContractsAdminRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileContractsAdminRequest
     */
    'contractIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ProfileCreateAdminRequest
 */
export interface ProfileCreateAdminRequest {
    /**
     * 
     * @type {UserType}
     * @memberof ProfileCreateAdminRequest
     */
    'userType': UserType;
    /**
     * 
     * @type {ContactData}
     * @memberof ProfileCreateAdminRequest
     */
    'contact': ContactData;
    /**
     * 
     * @type {AddressData}
     * @memberof ProfileCreateAdminRequest
     */
    'address': AddressData;
    /**
     * 
     * @type {PersonalData}
     * @memberof ProfileCreateAdminRequest
     */
    'personal': PersonalData;
}
/**
 * 
 * @export
 * @interface ProfileCustomerResponse
 */
export interface ProfileCustomerResponse {
    /**
     * 
     * @type {string}
     * @memberof ProfileCustomerResponse
     */
    'id': string;
    /**
     * 
     * @type {PersonalData}
     * @memberof ProfileCustomerResponse
     */
    'personal': PersonalData;
    /**
     * 
     * @type {ContactData}
     * @memberof ProfileCustomerResponse
     */
    'contact': ContactData;
    /**
     * 
     * @type {AddressData}
     * @memberof ProfileCustomerResponse
     */
    'address': AddressData;
    /**
     * 
     * @type {string}
     * @memberof ProfileCustomerResponse
     */
    'customerNumber'?: string;
}
/**
 * 
 * @export
 * @interface ProfilePasswordRequest
 */
export interface ProfilePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfilePasswordRequest
     */
    'currentPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ProfilePasswordRequest
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface ProfileUpdateAddressCustomerRequest
 */
export interface ProfileUpdateAddressCustomerRequest {
    /**
     * 
     * @type {AddressData}
     * @memberof ProfileUpdateAddressCustomerRequest
     */
    'address': AddressData;
}
/**
 * 
 * @export
 * @interface ProfileUpdateAdminRequest
 */
export interface ProfileUpdateAdminRequest {
    /**
     * 
     * @type {ContactData}
     * @memberof ProfileUpdateAdminRequest
     */
    'contact': ContactData;
    /**
     * 
     * @type {AddressData}
     * @memberof ProfileUpdateAdminRequest
     */
    'address': AddressData;
    /**
     * 
     * @type {PersonalData}
     * @memberof ProfileUpdateAdminRequest
     */
    'personal': PersonalData;
}
/**
 * 
 * @export
 * @interface ProfileUpdateContactCustomerRequest
 */
export interface ProfileUpdateContactCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateContactCustomerRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateContactCustomerRequest
     */
    'mobile'?: string;
}
/**
 * 
 * @export
 * @interface ProfileUpdateEmailCustomerRequest
 */
export interface ProfileUpdateEmailCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateEmailCustomerRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ProfileUpdatePersonalCustomerRequest
 */
export interface ProfileUpdatePersonalCustomerRequest {
    /**
     * 
     * @type {PersonalData}
     * @memberof ProfileUpdatePersonalCustomerRequest
     */
    'personal': PersonalData;
}
/**
 * 
 * @export
 * @interface ReplaceParticipantRequest
 */
export interface ReplaceParticipantRequest {
    /**
     * 
     * @type {string}
     * @memberof ReplaceParticipantRequest
     */
    'participantId': string;
}
/**
 * 
 * @export
 * @interface ResetPasswordData
 */
export interface ResetPasswordData {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordData
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ServiceBillingAdminResponse
 */
export interface ServiceBillingAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingAdminResponse
     */
    'billingDate': string;
    /**
     * 
     * @type {ServiceBillingState}
     * @memberof ServiceBillingAdminResponse
     */
    'activeState': ServiceBillingState;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingAdminResponse
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingAdminResponse
     */
    'endDate': string;
    /**
     * 
     * @type {Array<ServiceBillingPosition>}
     * @memberof ServiceBillingAdminResponse
     */
    'positions': Array<ServiceBillingPosition>;
    /**
     * 
     * @type {ServiceBillingType}
     * @memberof ServiceBillingAdminResponse
     */
    'billingType': ServiceBillingType;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingAdminResponse
     */
    'contractId': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingAdminResponse
     */
    'billingRunId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingAdminResponse
     */
    'totalAmountDue': string;
    /**
     * 
     * @type {AccountingStatus}
     * @memberof ServiceBillingAdminResponse
     */
    'accountingStatus': AccountingStatus;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingAdminResponse
     */
    'orderReferenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingAdminResponse
     */
    'invoiceReferenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingAdminResponse
     */
    'accountingErrorMessage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceBillingAdminResponse
     */
    'isManuallyCreated'?: boolean;
}
/**
 * 
 * @export
 * @interface ServiceBillingPosition
 */
export interface ServiceBillingPosition {
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingPosition
     */
    'name': string;
    /**
     * 
     * @type {BillingType}
     * @memberof ServiceBillingPosition
     */
    'billingType': BillingType;
    /**
     * 
     * @type {PowerMeterType}
     * @memberof ServiceBillingPosition
     */
    'powermeterType'?: PowerMeterType;
    /**
     * 
     * @type {number}
     * @memberof ServiceBillingPosition
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingPosition
     */
    'price': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingPosition
     */
    'finalAmountDue': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingPosition
     */
    'externalReferenceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof ServiceBillingPosition
     */
    'totalNumberOfMeters'?: number;
}
/**
 * 
 * @export
 * @interface ServiceBillingRunAdminResponse
 */
export interface ServiceBillingRunAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingRunAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingRunAdminResponse
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingRunAdminResponse
     */
    'endDate': string;
    /**
     * 
     * @type {ServiceBillingRunState}
     * @memberof ServiceBillingRunAdminResponse
     */
    'activeState': ServiceBillingRunState;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceBillingRunAdminResponse
     */
    'serviceBillingIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ServiceBillingRunCreateAdminRequest
 */
export interface ServiceBillingRunCreateAdminRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceBillingRunCreateAdminRequest
     */
    'contractIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingRunCreateAdminRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingRunCreateAdminRequest
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface ServiceBillingRunMultiAdminResponse
 */
export interface ServiceBillingRunMultiAdminResponse {
    /**
     * 
     * @type {Array<ServiceBillingRunAdminResponse>}
     * @memberof ServiceBillingRunMultiAdminResponse
     */
    'elements': Array<ServiceBillingRunAdminResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ServiceBillingRunState {
    DRAFT = 'DRAFT',
    APPROVED = 'APPROVED',
    CANCELLED = 'CANCELLED'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ServiceBillingState {
    DRAFT = 'DRAFT',
    APPROVED = 'APPROVED',
    CANCELLED = 'CANCELLED'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ServiceBillingType {
    INITIAL = 'INITIAL',
    RECURRING = 'RECURRING'
}

/**
 * 
 * @export
 * @interface ServiceBillingUpdateAdminRequest
 */
export interface ServiceBillingUpdateAdminRequest {
    /**
     * 
     * @type {AccountingStatus}
     * @memberof ServiceBillingUpdateAdminRequest
     */
    'accountingStatus': AccountingStatus;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingUpdateAdminRequest
     */
    'orderReferenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingUpdateAdminRequest
     */
    'invoiceReferenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBillingUpdateAdminRequest
     */
    'accountingErrorMessage'?: string;
}
/**
 * 
 * @export
 * @interface ServiceComponentAdminResponse
 */
export interface ServiceComponentAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof ServiceComponentAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComponentAdminResponse
     */
    'name': string;
    /**
     * 
     * @type {ServiceComponentFeature}
     * @memberof ServiceComponentAdminResponse
     */
    'feature': ServiceComponentFeature;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ServiceComponentFeature {
    COLLECTION = 'COLLECTION',
    POWERMETER_READING = 'POWERMETER_READING',
    ZEV_PARTICIPANT_BILLING = 'ZEV_PARTICIPANT_BILLING',
    PARTICIPANT_MANAGEMENT = 'PARTICIPANT_MANAGEMENT',
    VEWA_PARTICIPANT_PERMILLE_BILLING = 'VEWA_PARTICIPANT_PERMILLE_BILLING'
}

/**
 * 
 * @export
 * @interface ServiceComponentUpdateAdminRequest
 */
export interface ServiceComponentUpdateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceComponentUpdateAdminRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SummaryPosition
 */
export interface SummaryPosition {
    /**
     * 
     * @type {SummaryType}
     * @memberof SummaryPosition
     */
    'summaryType': SummaryType;
    /**
     * 
     * @type {string}
     * @memberof SummaryPosition
     */
    'consumption'?: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryPosition
     */
    'amountDue': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SummaryType {
    TOTAL_ENERGY_CONSUMPTION = 'TOTAL_ENERGY_CONSUMPTION',
    TOTAL_ENERGY_CONSUMPTION_AND_SERVICES = 'TOTAL_ENERGY_CONSUMPTION_AND_SERVICES',
    ROUNDING_DIFFERENCE = 'ROUNDING_DIFFERENCE',
    FINAL_AMOUNT_DUE = 'FINAL_AMOUNT_DUE',
    VAT = 'VAT'
}

/**
 * 
 * @export
 * @interface TariffScheduleRequest
 */
export interface TariffScheduleRequest {
    /**
     * 
     * @type {string}
     * @memberof TariffScheduleRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<PeriodResponse>}
     * @memberof TariffScheduleRequest
     */
    'lowTariffPeriods': Array<PeriodResponse>;
}
/**
 * 
 * @export
 * @interface TariffScheduleResponse
 */
export interface TariffScheduleResponse {
    /**
     * 
     * @type {string}
     * @memberof TariffScheduleResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TariffScheduleResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<PeriodResponse>}
     * @memberof TariffScheduleResponse
     */
    'lowTariffPeriods': Array<PeriodResponse>;
    /**
     * 
     * @type {Array<PeriodResponse>}
     * @memberof TariffScheduleResponse
     */
    'highTariffPeriods': Array<PeriodResponse>;
}
/**
 * 
 * @export
 * @interface TariffSchedulesResponse
 */
export interface TariffSchedulesResponse {
    /**
     * 
     * @type {Array<TariffScheduleResponse>}
     * @memberof TariffSchedulesResponse
     */
    'elements': Array<TariffScheduleResponse>;
}
/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'taskId': number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'completedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'reference': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'status': TaskStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'type': TaskTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'taskScope': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'taskScopeType': TaskTaskScopeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'referenceType': TaskReferenceTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    'participantSapSyncStatus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'taskScopeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'comment'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TaskStatusEnum {
    CREATED = 'CREATED',
    DONE = 'DONE'
}
/**
    * @export
    * @enum {string}
    */
export enum TaskTypeEnum {
    NEW_PARTICIPANT = 'NEW_PARTICIPANT',
    MODIFIED_PARTICIPANT = 'MODIFIED_PARTICIPANT',
    BILLING_CONFIRMATION = 'BILLING_CONFIRMATION',
    MODIFIED_CONTACT_DATA = 'MODIFIED_CONTACT_DATA',
    MODIFIED_PAYMENT_INFORMATION = 'MODIFIED_PAYMENT_INFORMATION'
}
/**
    * @export
    * @enum {string}
    */
export enum TaskTaskScopeTypeEnum {
    AREA = 'AREA',
    CONTRACT = 'CONTRACT'
}
/**
    * @export
    * @enum {string}
    */
export enum TaskReferenceTypeEnum {
    CONTRACT = 'CONTRACT',
    PARTICIPANT = 'PARTICIPANT',
    ALL_PARTICIPANT_BILLING = 'ALL_PARTICIPANT_BILLING',
    INDIVIDUAL_PARTICIPANT_BILLING = 'INDIVIDUAL_PARTICIPANT_BILLING'
}

/**
 * 
 * @export
 * @interface UnifiedServiceBillingAdminResponse
 */
export interface UnifiedServiceBillingAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof UnifiedServiceBillingAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {ServiceBillingState}
     * @memberof UnifiedServiceBillingAdminResponse
     */
    'status': ServiceBillingState;
    /**
     * 
     * @type {string}
     * @memberof UnifiedServiceBillingAdminResponse
     */
    'billingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnifiedServiceBillingAdminResponse
     */
    'billingDate': string;
    /**
     * 
     * @type {number}
     * @memberof UnifiedServiceBillingAdminResponse
     */
    'numberOfBillings': number;
    /**
     * 
     * @type {ServiceBillingType}
     * @memberof UnifiedServiceBillingAdminResponse
     */
    'billingType': ServiceBillingType;
    /**
     * 
     * @type {string}
     * @memberof UnifiedServiceBillingAdminResponse
     */
    'contractId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UnifiedServiceBillingAdminResponse
     */
    'isManuallyCreated': boolean;
}
/**
 * 
 * @export
 * @interface UpdateCommentRequest
 */
export interface UpdateCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentRequest
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UsageType {
    RESIDENCE = 'RESIDENCE',
    BUSINESS = 'BUSINESS',
    GENERAL_SUPPLY = 'GENERAL_SUPPLY',
    HEATING_PUMP = 'HEATING_PUMP',
    UNDEFINED = 'UNDEFINED'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum UserType {
    ADMIN = 'ADMIN',
    ZEV_MANAGER = 'ZEV_MANAGER'
}

/**
 * 
 * @export
 * @interface UtilityUnitBreadcrumbsAdminResponse
 */
export interface UtilityUnitBreadcrumbsAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof UtilityUnitBreadcrumbsAdminResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UtilityUnitBreadcrumbsAdminResponse
     */
    'name': string;
    /**
     * 
     * @type {BuildingBreadcrumbsAdminResponse}
     * @memberof UtilityUnitBreadcrumbsAdminResponse
     */
    'building': BuildingBreadcrumbsAdminResponse;
}
/**
 * 
 * @export
 * @interface UtilityUnitCreateAdminRequest
 */
export interface UtilityUnitCreateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof UtilityUnitCreateAdminRequest
     */
    'name': string;
    /**
     * 
     * @type {UsageType}
     * @memberof UtilityUnitCreateAdminRequest
     */
    'usageType': UsageType;
    /**
     * 
     * @type {number}
     * @memberof UtilityUnitCreateAdminRequest
     */
    'unitSpace'?: number;
}
/**
 * 
 * @export
 * @interface UtilityUnitResponse
 */
export interface UtilityUnitResponse {
    /**
     * 
     * @type {string}
     * @memberof UtilityUnitResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UtilityUnitResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UtilityUnitResponse
     */
    'unitSpace'?: number;
    /**
     * 
     * @type {UsageType}
     * @memberof UtilityUnitResponse
     */
    'usageType': UsageType;
    /**
     * 
     * @type {string}
     * @memberof UtilityUnitResponse
     */
    'buildingId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UtilityUnitResponse
     */
    'participations': Array<string>;
    /**
     * 
     * @type {UtilityUnitState}
     * @memberof UtilityUnitResponse
     */
    'activeState': UtilityUnitState;
    /**
     * 
     * @type {string}
     * @memberof UtilityUnitResponse
     */
    'contractId'?: string;
    /**
     * 
     * @type {BuildingAdminResponse}
     * @memberof UtilityUnitResponse
     */
    'building': BuildingAdminResponse;
    /**
     * 
     * @type {ParticipationEvent}
     * @memberof UtilityUnitResponse
     */
    'upcomingEvent'?: ParticipationEvent;
    /**
     * 
     * @type {Array<ParticipationResponse>}
     * @memberof UtilityUnitResponse
     */
    'participationObjects': Array<ParticipationResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UtilityUnitState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DELETED = 'DELETED'
}

/**
 * 
 * @export
 * @interface UtilityUnitUpdateAdminRequest
 */
export interface UtilityUnitUpdateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof UtilityUnitUpdateAdminRequest
     */
    'name': string;
    /**
     * 
     * @type {UsageType}
     * @memberof UtilityUnitUpdateAdminRequest
     */
    'usageType': UsageType;
    /**
     * 
     * @type {number}
     * @memberof UtilityUnitUpdateAdminRequest
     */
    'unitSpace'?: number;
}
/**
 * 
 * @export
 * @interface VatPosition
 */
export interface VatPosition {
    /**
     * 
     * @type {SummaryType}
     * @memberof VatPosition
     */
    'summaryType': SummaryType;
    /**
     * 
     * @type {string}
     * @memberof VatPosition
     */
    'consumption'?: string;
    /**
     * 
     * @type {string}
     * @memberof VatPosition
     */
    'amountDue': string;
}
/**
 * 
 * @export
 * @interface VewaAllParticipantsBilling
 */
export interface VewaAllParticipantsBilling {
    /**
     * 
     * @type {string}
     * @memberof VewaAllParticipantsBilling
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VewaAllParticipantsBilling
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof VewaAllParticipantsBilling
     */
    'endDate': string;
    /**
     * 
     * @type {VewaAllParticipantsBillingState}
     * @memberof VewaAllParticipantsBilling
     */
    'activeState': VewaAllParticipantsBillingState;
    /**
     * 
     * @type {string}
     * @memberof VewaAllParticipantsBilling
     */
    'contractId': string;
    /**
     * 
     * @type {number}
     * @memberof VewaAllParticipantsBilling
     */
    'totalColdConsumption'?: number;
    /**
     * 
     * @type {number}
     * @memberof VewaAllParticipantsBilling
     */
    'totalHeatConsumption'?: number;
    /**
     * 
     * @type {number}
     * @memberof VewaAllParticipantsBilling
     */
    'totalColdWaterConsumption'?: number;
    /**
     * 
     * @type {number}
     * @memberof VewaAllParticipantsBilling
     */
    'totalWarmWaterConsumption'?: number;
    /**
     * 
     * @type {VewaCostShares}
     * @memberof VewaAllParticipantsBilling
     */
    'costAllocations': VewaCostShares;
    /**
     * 
     * @type {boolean}
     * @memberof VewaAllParticipantsBilling
     */
    'hasCollection': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum VewaAllParticipantsBillingState {
    WAITING_FOR_DATA = 'WAITING_FOR_DATA',
    IN_PROGRESS = 'IN_PROGRESS',
    DELETED = 'DELETED',
    DONE = 'DONE'
}

/**
 * 
 * @export
 * @interface VewaCalcResponse
 */
export interface VewaCalcResponse {
    /**
     * 
     * @type {VewaCalcUnit}
     * @memberof VewaCalcResponse
     */
    'unit': VewaCalcUnit;
    /**
     * total energy consumption in measuring unit, over time window
     * @type {string}
     * @memberof VewaCalcResponse
     */
    'totalConsumption': string;
    /**
     * 
     * @type {Array<CalcPerCalcResolution>}
     * @memberof VewaCalcResponse
     */
    'data': Array<CalcPerCalcResolution>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum VewaCalcUnit {
    kWh = 'kWh',
    m3 = 'm3'
}

/**
 * 
 * @export
 * @interface VewaConfig
 */
export interface VewaConfig {
    /**
     * 
     * @type {Array<VewaCostShare>}
     * @memberof VewaConfig
     */
    'costShares': Array<VewaCostShare>;
    /**
     * 
     * @type {Array<GroupMeter>}
     * @memberof VewaConfig
     */
    'groupMeters': Array<GroupMeter>;
    /**
     * 
     * @type {Array<Medium>}
     * @memberof VewaConfig
     */
    'waterAllocationMediums'?: Array<Medium>;
}
/**
 * 
 * @export
 * @interface VewaConfigCreateRequest
 */
export interface VewaConfigCreateRequest {
    /**
     * 
     * @type {BillingScope}
     * @memberof VewaConfigCreateRequest
     */
    'billingScope': BillingScope;
}
/**
 * 
 * @export
 * @interface VewaConfigUpdateRequest
 */
export interface VewaConfigUpdateRequest {
    /**
     * 
     * @type {BillingScope}
     * @memberof VewaConfigUpdateRequest
     */
    'billingScope': BillingScope;
    /**
     * 
     * @type {VewaCostShares}
     * @memberof VewaConfigUpdateRequest
     */
    'vewaCostShares': VewaCostShares;
    /**
     * 
     * @type {GroupMeterConfig}
     * @memberof VewaConfigUpdateRequest
     */
    'groupMeterConfig': GroupMeterConfig;
    /**
     * 
     * @type {Array<Medium>}
     * @memberof VewaConfigUpdateRequest
     */
    'waterAllocationMediums'?: Array<Medium>;
}
/**
 * 
 * @export
 * @interface VewaCostShare
 */
export interface VewaCostShare {
    /**
     * 
     * @type {CostShareType}
     * @memberof VewaCostShare
     */
    'type': CostShareType;
    /**
     * 
     * @type {CostStructure}
     * @memberof VewaCostShare
     */
    'costStructure': CostStructure;
}
/**
 * 
 * @export
 * @interface VewaCostShares
 */
export interface VewaCostShares {
    /**
     * 
     * @type {Array<VewaCostShare>}
     * @memberof VewaCostShares
     */
    'costShares': Array<VewaCostShare>;
}
/**
 * Person living in a utility Unit within an Area.
 * @export
 * @interface VewaIndividualParticipationConsumptionResponse
 */
export interface VewaIndividualParticipationConsumptionResponse {
    /**
     * 
     * @type {string}
     * @memberof VewaIndividualParticipationConsumptionResponse
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof VewaIndividualParticipationConsumptionResponse
     */
    'to': string;
    /**
     * 
     * @type {string}
     * @memberof VewaIndividualParticipationConsumptionResponse
     */
    'energyConsumption': string;
    /**
     * 
     * @type {string}
     * @memberof VewaIndividualParticipationConsumptionResponse
     */
    'waterConsumption': string;
    /**
     * 
     * @type {UtilityUnitResponse}
     * @memberof VewaIndividualParticipationConsumptionResponse
     */
    'utilityUnit': UtilityUnitResponse;
    /**
     * 
     * @type {BuildingAdminResponse}
     * @memberof VewaIndividualParticipationConsumptionResponse
     */
    'building': BuildingAdminResponse;
    /**
     * 
     * @type {string}
     * @memberof VewaIndividualParticipationConsumptionResponse
     */
    'participationId': string;
    /**
     * 
     * @type {ParticipantResponse}
     * @memberof VewaIndividualParticipationConsumptionResponse
     */
    'participant'?: ParticipantResponse;
}
/**
 * 
 * @export
 * @interface VewaMeterReadingsResponse
 */
export interface VewaMeterReadingsResponse {
    /**
     * 
     * @type {MeterReadingsCalcResolution}
     * @memberof VewaMeterReadingsResponse
     */
    'resolution': MeterReadingsCalcResolution;
    /**
     * 
     * @type {{ [key: string]: VewaCalcResponse; }}
     * @memberof VewaMeterReadingsResponse
     */
    'data': { [key: string]: VewaCalcResponse; };
    /**
     * total kWh consumption in measuring unit, over time window
     * @type {string}
     * @memberof VewaMeterReadingsResponse
     */
    'totalEnergyConsumption': string;
    /**
     * total m3 consumption in measuring unit, over time window
     * @type {string}
     * @memberof VewaMeterReadingsResponse
     */
    'totalWaterConsumption': string;
}
/**
 * 
 * @export
 * @interface ZevContractCustomerResponse
 */
export interface ZevContractCustomerResponse {
    /**
     * 
     * @type {string}
     * @memberof ZevContractCustomerResponse
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof ZevContractCustomerResponse
     */
    'totalMonthlyFee': string;
    /**
     * 
     * @type {Array<CostPosition>}
     * @memberof ZevContractCustomerResponse
     */
    'costPositions': Array<CostPosition>;
    /**
     * 
     * @type {Array<PriceComponent>}
     * @memberof ZevContractCustomerResponse
     */
    'upcomingChanges': Array<PriceComponent>;
    /**
     * 
     * @type {number}
     * @memberof ZevContractCustomerResponse
     */
    'numberOfMeters': number;
}
/**
 * 
 * @export
 * @interface ZevMeter
 */
export interface ZevMeter {
    /**
     * 
     * @type {string}
     * @memberof ZevMeter
     */
    'meteringCode': string;
    /**
     * 
     * @type {ZevMeterType}
     * @memberof ZevMeter
     */
    'meterType': ZevMeterType;
    /**
     * 
     * @type {string}
     * @memberof ZevMeter
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof ZevMeter
     */
    'dateTo'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ZevMeterType {
    INCOMING = 'INCOMING',
    OUTGOING = 'OUTGOING'
}

/**
 * 
 * @export
 * @interface ZevPriceCalculatorEnergy
 */
export interface ZevPriceCalculatorEnergy {
    /**
     * 
     * @type {string}
     * @memberof ZevPriceCalculatorEnergy
     */
    'boughtHighTariff': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPriceCalculatorEnergy
     */
    'boughtLowTariff': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPriceCalculatorEnergy
     */
    'boughtTotal': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPriceCalculatorEnergy
     */
    'soldTotal': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPriceCalculatorEnergy
     */
    'production': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPriceCalculatorEnergy
     */
    'consumptionOfProductionHighTariff': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPriceCalculatorEnergy
     */
    'consumptionOfProductionLowTariff': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPriceCalculatorEnergy
     */
    'consumptionOfProductionTotal': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPriceCalculatorEnergy
     */
    'consumptionTotal': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPriceCalculatorEnergy
     */
    'averageBoughtMonthlyPeak': string;
}
/**
 * 
 * @export
 * @interface ZevPriceCalculatorInput
 */
export interface ZevPriceCalculatorInput {
    /**
     * 
     * @type {number}
     * @memberof ZevPriceCalculatorInput
     */
    'totalInvestmentAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ZevPriceCalculatorInput
     */
    'interestRateOnInvestment': number;
    /**
     * 
     * @type {number}
     * @memberof ZevPriceCalculatorInput
     */
    'deprecationPeriodInYears': number;
    /**
     * 
     * @type {number}
     * @memberof ZevPriceCalculatorInput
     */
    'sellingElectricityPricePerKwh': number;
    /**
     * 
     * @type {number}
     * @memberof ZevPriceCalculatorInput
     */
    'operatingCostsPerKwh': number;
    /**
     * 
     * @type {number}
     * @memberof ZevPriceCalculatorInput
     */
    'purchasingPowerProductId': number;
    /**
     * 
     * @type {number}
     * @memberof ZevPriceCalculatorInput
     */
    'referencePowerProductId': number;
}
/**
 * 
 * @export
 * @interface ZevPriceCalculatorInputResponse
 */
export interface ZevPriceCalculatorInputResponse {
    /**
     * 
     * @type {ZevPriceCalculatorInput}
     * @memberof ZevPriceCalculatorInputResponse
     */
    'inputPrices'?: ZevPriceCalculatorInput;
}
/**
 * 
 * @export
 * @interface ZevPriceCalculatorInputUpdateRequest
 */
export interface ZevPriceCalculatorInputUpdateRequest {
    /**
     * 
     * @type {ZevPriceCalculatorInput}
     * @memberof ZevPriceCalculatorInputUpdateRequest
     */
    'inputPrices': ZevPriceCalculatorInput;
}
/**
 * 
 * @export
 * @interface ZevPriceCalculatorPricesResponse
 */
export interface ZevPriceCalculatorPricesResponse {
    /**
     * 
     * @type {ZevPrices}
     * @memberof ZevPriceCalculatorPricesResponse
     */
    'prices': ZevPrices;
    /**
     * 
     * @type {string}
     * @memberof ZevPriceCalculatorPricesResponse
     */
    'maximumSolarPrice': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPriceCalculatorPricesResponse
     */
    'bottomUpSolarPrice': string;
    /**
     * 
     * @type {boolean}
     * @memberof ZevPriceCalculatorPricesResponse
     */
    'isReferenceProductCheaper': boolean;
}
/**
 * 
 * @export
 * @interface ZevPriceCalculatorTariffsResponse
 */
export interface ZevPriceCalculatorTariffsResponse {
    /**
     * 
     * @type {Array<PriceCalculatorTariffs>}
     * @memberof ZevPriceCalculatorTariffsResponse
     */
    'entries': Array<PriceCalculatorTariffs>;
}
/**
 * 
 * @export
 * @interface ZevPricePackage
 */
export interface ZevPricePackage {
    /**
     * 
     * @type {number}
     * @memberof ZevPricePackage
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ZevPricePackage
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ZevPricePackage
     */
    'order': number;
    /**
     * 
     * @type {ZevPrices}
     * @memberof ZevPricePackage
     */
    'prices'?: ZevPrices;
}
/**
 * 
 * @export
 * @interface ZevPrices
 */
export interface ZevPrices {
    /**
     * 
     * @type {string}
     * @memberof ZevPrices
     */
    'solarPrice': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPrices
     */
    'highTariffPrice': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPrices
     */
    'lowTariffPrice': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPrices
     */
    'additionalServicesPrice': string;
    /**
     * 
     * @type {string}
     * @memberof ZevPrices
     */
    'spikePrice'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ZevPrices
     */
    'isFixedRate': boolean;
}

/**
 * AdminAllParticipantBillingApi - axios parameter creator
 * @export
 */
export const AdminAllParticipantBillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete the billing details of an AllParticipantBilling
         * @summary Delete the billing details of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteAllParticipantBillingDetailsById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDeleteAllParticipantBillingDetailsById', 'id', id)
            const localVarPath = `/api/admin/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes Vewa participant billing data and resets next billing date
         * @summary Admin delete Vewa participant billing data and reset next billing date
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {BillingDateRequest} billingDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteVewaBillingDataAndResetNextBillingDate: async (vewaAllParticipantBillingId: string, billingDateRequest: BillingDateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('adminDeleteVewaBillingDataAndResetNextBillingDate', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            // verify required parameter 'billingDateRequest' is not null or undefined
            assertParamExists('adminDeleteVewaBillingDataAndResetNextBillingDate', 'billingDateRequest', billingDateRequest)
            const localVarPath = `/api/admin/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/delete`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all and individual participant billings
         * @summary Get all and individual participant billings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParticipantsBillings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/participantBillings/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the billing details of an VewaAllParticipantBilling
         * @summary Get the billing details of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVewaAllParticipantBillingById: async (vewaAllParticipantBillingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('adminGetVewaAllParticipantBillingById', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/admin/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the participants details of an VewaAllParticipantBilling
         * @summary Get the participants details of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVewaIndividualParticipationConsumption: async (vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('adminGetVewaIndividualParticipationConsumption', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/admin/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/participationConsumption`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reloads Vewa participant billing data
         * @summary Admin reload Vewa participant billing data
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReloadVewaParticipantBillingData: async (vewaAllParticipantBillingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('adminReloadVewaParticipantBillingData', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/admin/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/reloadData`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reloads Zev participant billing data
         * @summary Admin reload Zev participant billing data
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReloadZevParticipantBillingData: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminReloadZevParticipantBillingData', 'id', id)
            const localVarPath = `/api/admin/v1/allParticipantBilling/{id}/reload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reopens Vewa participant billing in DONE state
         * @summary Admin reopen Vewa participant billing
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReopenVewaParticipantBilling: async (vewaAllParticipantBillingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('adminReopenVewaParticipantBilling', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/admin/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/reopen`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update cost share settings per participant billing
         * @summary Admin update billing cost shares
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {VewaCostShares} vewaCostShares 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBillingCostShares: async (vewaAllParticipantBillingId: string, vewaCostShares: VewaCostShares, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('adminUpdateBillingCostShares', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            // verify required parameter 'vewaCostShares' is not null or undefined
            assertParamExists('adminUpdateBillingCostShares', 'vewaCostShares', vewaCostShares)
            const localVarPath = `/api/admin/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/costShares`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vewaCostShares, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the billing details of an AllParticipantBilling
         * @summary Get the billing details of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAllParticipantBillingDetailsById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminAllParticipantBillingDetailsById', 'id', id)
            const localVarPath = `/api/admin/v1/allParticipantBilling/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to approved for AllParticipantBilling
         * @summary State change to approved for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAllParticipantBillingApproveStateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminAllParticipantBillingApproveStateById', 'id', id)
            const localVarPath = `/api/admin/v1/allParticipantBilling/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to reopened for AllParticipantBilling
         * @summary State change to reopened for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAllParticipantBillingReopenStateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminAllParticipantBillingReopenStateById', 'id', id)
            const localVarPath = `/api/admin/v1/allParticipantBilling/{id}/reopen`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to suspended for AllParticipantBilling
         * @summary State change to suspended for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAllParticipantBillingSuspendStateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminAllParticipantBillingSuspendStateById', 'id', id)
            const localVarPath = `/api/admin/v1/allParticipantBilling/{id}/suspend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to unsuspended for AllParticipantBilling
         * @summary State change to unsuspended for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAllParticipantBillingUnSuspendStateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminAllParticipantBillingUnSuspendStateById', 'id', id)
            const localVarPath = `/api/admin/v1/allParticipantBilling/{id}/unsuspend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send an AllParticipantBilling details to SAP
         * @summary Send an AllParticipantBilling details to SAP
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sapSendAdminAllParticipantBillingById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sapSendAdminAllParticipantBillingById', 'id', id)
            const localVarPath = `/api/admin/v1/allParticipantBilling/{id}/sap-send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the billing prices of an AllParticipantBilling
         * @summary Update the billing prices of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {AllParticipantBillingPricesUpdateAdminRequest} allParticipantBillingPricesUpdateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminAllParticipantBillingPricesById: async (id: string, allParticipantBillingPricesUpdateAdminRequest: AllParticipantBillingPricesUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdminAllParticipantBillingPricesById', 'id', id)
            // verify required parameter 'allParticipantBillingPricesUpdateAdminRequest' is not null or undefined
            assertParamExists('updateAdminAllParticipantBillingPricesById', 'allParticipantBillingPricesUpdateAdminRequest', allParticipantBillingPricesUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/allParticipantBilling/{id}/prices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allParticipantBillingPricesUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAllParticipantBillingApi - functional programming interface
 * @export
 */
export const AdminAllParticipantBillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAllParticipantBillingApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete the billing details of an AllParticipantBilling
         * @summary Delete the billing details of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteAllParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteAllParticipantBillingDetailsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes Vewa participant billing data and resets next billing date
         * @summary Admin delete Vewa participant billing data and reset next billing date
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {BillingDateRequest} billingDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId: string, billingDateRequest: BillingDateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId, billingDateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all and individual participant billings
         * @summary Get all and individual participant billings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParticipantsBillings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantAllBillingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParticipantsBillings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the billing details of an VewaAllParticipantBilling
         * @summary Get the billing details of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetVewaAllParticipantBillingById(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VewaAllParticipantsBilling>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetVewaAllParticipantBillingById(vewaAllParticipantBillingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the participants details of an VewaAllParticipantBilling
         * @summary Get the participants details of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedVewaIndividualParticipationConsumptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reloads Vewa participant billing data
         * @summary Admin reload Vewa participant billing data
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReloadVewaParticipantBillingData(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReloadVewaParticipantBillingData(vewaAllParticipantBillingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reloads Zev participant billing data
         * @summary Admin reload Zev participant billing data
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReloadZevParticipantBillingData(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReloadZevParticipantBillingData(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reopens Vewa participant billing in DONE state
         * @summary Admin reopen Vewa participant billing
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReopenVewaParticipantBilling(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReopenVewaParticipantBilling(vewaAllParticipantBillingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update cost share settings per participant billing
         * @summary Admin update billing cost shares
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {VewaCostShares} vewaCostShares 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateBillingCostShares(vewaAllParticipantBillingId: string, vewaCostShares: VewaCostShares, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateBillingCostShares(vewaAllParticipantBillingId, vewaCostShares, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the billing details of an AllParticipantBilling
         * @summary Get the billing details of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAllParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllParticipantBillingDetailsAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAllParticipantBillingDetailsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to approved for AllParticipantBilling
         * @summary State change to approved for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminAllParticipantBillingApproveStateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminAllParticipantBillingApproveStateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to reopened for AllParticipantBilling
         * @summary State change to reopened for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminAllParticipantBillingReopenStateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminAllParticipantBillingReopenStateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to suspended for AllParticipantBilling
         * @summary State change to suspended for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminAllParticipantBillingSuspendStateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminAllParticipantBillingSuspendStateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to unsuspended for AllParticipantBilling
         * @summary State change to unsuspended for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminAllParticipantBillingUnSuspendStateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminAllParticipantBillingUnSuspendStateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send an AllParticipantBilling details to SAP
         * @summary Send an AllParticipantBilling details to SAP
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sapSendAdminAllParticipantBillingById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sapSendAdminAllParticipantBillingById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the billing prices of an AllParticipantBilling
         * @summary Update the billing prices of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {AllParticipantBillingPricesUpdateAdminRequest} allParticipantBillingPricesUpdateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminAllParticipantBillingPricesById(id: string, allParticipantBillingPricesUpdateAdminRequest: AllParticipantBillingPricesUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminAllParticipantBillingPricesById(id, allParticipantBillingPricesUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAllParticipantBillingApi - factory interface
 * @export
 */
export const AdminAllParticipantBillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAllParticipantBillingApiFp(configuration)
    return {
        /**
         * Delete the billing details of an AllParticipantBilling
         * @summary Delete the billing details of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteAllParticipantBillingDetailsById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteAllParticipantBillingDetailsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes Vewa participant billing data and resets next billing date
         * @summary Admin delete Vewa participant billing data and reset next billing date
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {BillingDateRequest} billingDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId: string, billingDateRequest: BillingDateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId, billingDateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all and individual participant billings
         * @summary Get all and individual participant billings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParticipantsBillings(options?: any): AxiosPromise<ParticipantAllBillingsResponse> {
            return localVarFp.adminGetParticipantsBillings(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the billing details of an VewaAllParticipantBilling
         * @summary Get the billing details of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVewaAllParticipantBillingById(vewaAllParticipantBillingId: string, options?: any): AxiosPromise<VewaAllParticipantsBilling> {
            return localVarFp.adminGetVewaAllParticipantBillingById(vewaAllParticipantBillingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the participants details of an VewaAllParticipantBilling
         * @summary Get the participants details of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedVewaIndividualParticipationConsumptionResponse> {
            return localVarFp.adminGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Reloads Vewa participant billing data
         * @summary Admin reload Vewa participant billing data
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReloadVewaParticipantBillingData(vewaAllParticipantBillingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminReloadVewaParticipantBillingData(vewaAllParticipantBillingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Reloads Zev participant billing data
         * @summary Admin reload Zev participant billing data
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReloadZevParticipantBillingData(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminReloadZevParticipantBillingData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Reopens Vewa participant billing in DONE state
         * @summary Admin reopen Vewa participant billing
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReopenVewaParticipantBilling(vewaAllParticipantBillingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminReopenVewaParticipantBilling(vewaAllParticipantBillingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update cost share settings per participant billing
         * @summary Admin update billing cost shares
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {VewaCostShares} vewaCostShares 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBillingCostShares(vewaAllParticipantBillingId: string, vewaCostShares: VewaCostShares, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateBillingCostShares(vewaAllParticipantBillingId, vewaCostShares, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the billing details of an AllParticipantBilling
         * @summary Get the billing details of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAllParticipantBillingDetailsById(id: string, options?: any): AxiosPromise<AllParticipantBillingDetailsAdminResponse> {
            return localVarFp.getAdminAllParticipantBillingDetailsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to approved for AllParticipantBilling
         * @summary State change to approved for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAllParticipantBillingApproveStateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putAdminAllParticipantBillingApproveStateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to reopened for AllParticipantBilling
         * @summary State change to reopened for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAllParticipantBillingReopenStateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putAdminAllParticipantBillingReopenStateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to suspended for AllParticipantBilling
         * @summary State change to suspended for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAllParticipantBillingSuspendStateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putAdminAllParticipantBillingSuspendStateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to unsuspended for AllParticipantBilling
         * @summary State change to unsuspended for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAllParticipantBillingUnSuspendStateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putAdminAllParticipantBillingUnSuspendStateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Send an AllParticipantBilling details to SAP
         * @summary Send an AllParticipantBilling details to SAP
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sapSendAdminAllParticipantBillingById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.sapSendAdminAllParticipantBillingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the billing prices of an AllParticipantBilling
         * @summary Update the billing prices of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {AllParticipantBillingPricesUpdateAdminRequest} allParticipantBillingPricesUpdateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminAllParticipantBillingPricesById(id: string, allParticipantBillingPricesUpdateAdminRequest: AllParticipantBillingPricesUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAdminAllParticipantBillingPricesById(id, allParticipantBillingPricesUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAllParticipantBillingApi - interface
 * @export
 * @interface AdminAllParticipantBillingApi
 */
export interface AdminAllParticipantBillingApiInterface {
    /**
     * Delete the billing details of an AllParticipantBilling
     * @summary Delete the billing details of an AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    adminDeleteAllParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Deletes Vewa participant billing data and resets next billing date
     * @summary Admin delete Vewa participant billing data and reset next billing date
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {BillingDateRequest} billingDateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    adminDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId: string, billingDateRequest: BillingDateRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get all and individual participant billings
     * @summary Get all and individual participant billings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    adminGetParticipantsBillings(options?: AxiosRequestConfig): AxiosPromise<ParticipantAllBillingsResponse>;

    /**
     * Get the billing details of an VewaAllParticipantBilling
     * @summary Get the billing details of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    adminGetVewaAllParticipantBillingById(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): AxiosPromise<VewaAllParticipantsBilling>;

    /**
     * Get the participants details of an VewaAllParticipantBilling
     * @summary Get the participants details of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    adminGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedVewaIndividualParticipationConsumptionResponse>;

    /**
     * Reloads Vewa participant billing data
     * @summary Admin reload Vewa participant billing data
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    adminReloadVewaParticipantBillingData(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Reloads Zev participant billing data
     * @summary Admin reload Zev participant billing data
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    adminReloadZevParticipantBillingData(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Reopens Vewa participant billing in DONE state
     * @summary Admin reopen Vewa participant billing
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    adminReopenVewaParticipantBilling(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update cost share settings per participant billing
     * @summary Admin update billing cost shares
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {VewaCostShares} vewaCostShares 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    adminUpdateBillingCostShares(vewaAllParticipantBillingId: string, vewaCostShares: VewaCostShares, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get the billing details of an AllParticipantBilling
     * @summary Get the billing details of an AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    getAdminAllParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig): AxiosPromise<AllParticipantBillingDetailsAdminResponse>;

    /**
     * State change to approved for AllParticipantBilling
     * @summary State change to approved for AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    putAdminAllParticipantBillingApproveStateById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * State change to reopened for AllParticipantBilling
     * @summary State change to reopened for AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    putAdminAllParticipantBillingReopenStateById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * State change to suspended for AllParticipantBilling
     * @summary State change to suspended for AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    putAdminAllParticipantBillingSuspendStateById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * State change to unsuspended for AllParticipantBilling
     * @summary State change to unsuspended for AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    putAdminAllParticipantBillingUnSuspendStateById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Send an AllParticipantBilling details to SAP
     * @summary Send an AllParticipantBilling details to SAP
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    sapSendAdminAllParticipantBillingById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update the billing prices of an AllParticipantBilling
     * @summary Update the billing prices of an AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {AllParticipantBillingPricesUpdateAdminRequest} allParticipantBillingPricesUpdateAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApiInterface
     */
    updateAdminAllParticipantBillingPricesById(id: string, allParticipantBillingPricesUpdateAdminRequest: AllParticipantBillingPricesUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminAllParticipantBillingApi - object-oriented interface
 * @export
 * @class AdminAllParticipantBillingApi
 * @extends {BaseAPI}
 */
export class AdminAllParticipantBillingApi extends BaseAPI implements AdminAllParticipantBillingApiInterface {
    /**
     * Delete the billing details of an AllParticipantBilling
     * @summary Delete the billing details of an AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public adminDeleteAllParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).adminDeleteAllParticipantBillingDetailsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes Vewa participant billing data and resets next billing date
     * @summary Admin delete Vewa participant billing data and reset next billing date
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {BillingDateRequest} billingDateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public adminDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId: string, billingDateRequest: BillingDateRequest, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).adminDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId, billingDateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all and individual participant billings
     * @summary Get all and individual participant billings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public adminGetParticipantsBillings(options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).adminGetParticipantsBillings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the billing details of an VewaAllParticipantBilling
     * @summary Get the billing details of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public adminGetVewaAllParticipantBillingById(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).adminGetVewaAllParticipantBillingById(vewaAllParticipantBillingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the participants details of an VewaAllParticipantBilling
     * @summary Get the participants details of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public adminGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).adminGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reloads Vewa participant billing data
     * @summary Admin reload Vewa participant billing data
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public adminReloadVewaParticipantBillingData(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).adminReloadVewaParticipantBillingData(vewaAllParticipantBillingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reloads Zev participant billing data
     * @summary Admin reload Zev participant billing data
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public adminReloadZevParticipantBillingData(id: string, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).adminReloadZevParticipantBillingData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reopens Vewa participant billing in DONE state
     * @summary Admin reopen Vewa participant billing
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public adminReopenVewaParticipantBilling(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).adminReopenVewaParticipantBilling(vewaAllParticipantBillingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update cost share settings per participant billing
     * @summary Admin update billing cost shares
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {VewaCostShares} vewaCostShares 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public adminUpdateBillingCostShares(vewaAllParticipantBillingId: string, vewaCostShares: VewaCostShares, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).adminUpdateBillingCostShares(vewaAllParticipantBillingId, vewaCostShares, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the billing details of an AllParticipantBilling
     * @summary Get the billing details of an AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public getAdminAllParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).getAdminAllParticipantBillingDetailsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to approved for AllParticipantBilling
     * @summary State change to approved for AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public putAdminAllParticipantBillingApproveStateById(id: string, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).putAdminAllParticipantBillingApproveStateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to reopened for AllParticipantBilling
     * @summary State change to reopened for AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public putAdminAllParticipantBillingReopenStateById(id: string, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).putAdminAllParticipantBillingReopenStateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to suspended for AllParticipantBilling
     * @summary State change to suspended for AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public putAdminAllParticipantBillingSuspendStateById(id: string, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).putAdminAllParticipantBillingSuspendStateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to unsuspended for AllParticipantBilling
     * @summary State change to unsuspended for AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public putAdminAllParticipantBillingUnSuspendStateById(id: string, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).putAdminAllParticipantBillingUnSuspendStateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send an AllParticipantBilling details to SAP
     * @summary Send an AllParticipantBilling details to SAP
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public sapSendAdminAllParticipantBillingById(id: string, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).sapSendAdminAllParticipantBillingById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the billing prices of an AllParticipantBilling
     * @summary Update the billing prices of an AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {AllParticipantBillingPricesUpdateAdminRequest} allParticipantBillingPricesUpdateAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllParticipantBillingApi
     */
    public updateAdminAllParticipantBillingPricesById(id: string, allParticipantBillingPricesUpdateAdminRequest: AllParticipantBillingPricesUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminAllParticipantBillingApiFp(this.configuration).updateAdminAllParticipantBillingPricesById(id, allParticipantBillingPricesUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminAreaApi - axios parameter creator
 * @export
 */
export const AdminAreaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an Area, upon which other parts of the topology can be attached
         * @summary Create an Area with the provided details
         * @param {AreaCreateAdminRequest} areaCreateAdminRequest An Area admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateArea: async (areaCreateAdminRequest: AreaCreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaCreateAdminRequest' is not null or undefined
            assertParamExists('adminCreateArea', 'areaCreateAdminRequest', areaCreateAdminRequest)
            const localVarPath = `/api/admin/v1/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(areaCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a participants in an Area
         * @summary Create a Area participant
         * @param {string} areaId uuid of the Area
         * @param {ParticipantAdminCreateRequest} participantAdminCreateRequest A Participant create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAreaParticipant: async (areaId: string, participantAdminCreateRequest: ParticipantAdminCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminCreateAreaParticipant', 'areaId', areaId)
            // verify required parameter 'participantAdminCreateRequest' is not null or undefined
            assertParamExists('adminCreateAreaParticipant', 'participantAdminCreateRequest', participantAdminCreateRequest)
            const localVarPath = `/api/admin/v1/areas/{areaId}/participants`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participantAdminCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Building and link it to the provided Area
         * @summary Create a Building with the provided details
         * @param {string} areaId uuid of the Area
         * @param {BuildingCreateAdminRequest} buildingCreateAdminRequest A building admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateBuildingForArea: async (areaId: string, buildingCreateAdminRequest: BuildingCreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminCreateBuildingForArea', 'areaId', areaId)
            // verify required parameter 'buildingCreateAdminRequest' is not null or undefined
            assertParamExists('adminCreateBuildingForArea', 'buildingCreateAdminRequest', buildingCreateAdminRequest)
            const localVarPath = `/api/admin/v1/areas/{areaId}/buildings`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Contract and link it to the provided Area
         * @summary Create a Contract with the provided details
         * @param {string} areaId uuid of the Area
         * @param {ContractCreateAdminRequest} contractCreateAdminRequest A building admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateContractForArea: async (areaId: string, contractCreateAdminRequest: ContractCreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminCreateContractForArea', 'areaId', areaId)
            // verify required parameter 'contractCreateAdminRequest' is not null or undefined
            assertParamExists('adminCreateContractForArea', 'contractCreateAdminRequest', contractCreateAdminRequest)
            const localVarPath = `/api/admin/v1/areas/{areaId}/contracts`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An active AREA can be deactivated, triggering the deactivation/deletion of its child objects as well and removal of the area from the areas list of an AREA Manager.
         * @summary Deactivate an AREA by its areaId
         * @param {string} areaId uuid of the Area
         * @param {string} from the date the deactivation begins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeactivateAreaById: async (areaId: string, from: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminDeactivateAreaById', 'areaId', areaId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('adminDeactivateAreaById', 'from', from)
            const localVarPath = `/api/admin/v1/areas/{areaId}/deactivate`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An Area can be deleted if mistakes are made in creation
         * @summary Delete an Area by its areaId
         * @param {string} areaId uuid of the Area
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteAreaById: async (areaId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminDeleteAreaById', 'areaId', areaId)
            const localVarPath = `/api/admin/v1/areas/{areaId}`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Buildings with some possible slicing and dicing for an areaId
         * @summary Get all Buildings in an Area
         * @param {string} areaId uuid of the Area
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAreaBuildings: async (areaId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminGetAreaBuildings', 'areaId', areaId)
            const localVarPath = `/api/admin/v1/areas/{areaId}/buildings`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Areas can be fetched by their respective UUID
         * @summary Get a Area by its ID
         * @param {string} areaId uuid of the Area
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAreaById: async (areaId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminGetAreaById', 'areaId', areaId)
            const localVarPath = `/api/admin/v1/areas/{areaId}`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Contracts with some possible slicing and dicing for an areaId
         * @summary Get all Contracts in an Area
         * @param {string} areaId uuid of the Area
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAreaContracts: async (areaId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminGetAreaContracts', 'areaId', areaId)
            const localVarPath = `/api/admin/v1/areas/{areaId}/contracts`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get all participants of an Area
         * @summary Get a Area\'s participants
         * @param {string} areaId uuid of the Area
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAreaParticipants: async (areaId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminGetAreaParticipants', 'areaId', areaId)
            const localVarPath = `/api/admin/v1/areas/{areaId}/participants`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Areas with some possible slicing and dicing
         * @summary Get all Areas
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAreas: async (page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An Area can have some of its properties updated post-creation
         * @summary Update an Area by its areaId
         * @param {string} areaId uuid of the Area
         * @param {AreaUpdateAdminRequest} areaUpdateAdminRequest An Area update request for CKW administrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAreaById: async (areaId: string, areaUpdateAdminRequest: AreaUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminUpdateAreaById', 'areaId', areaId)
            // verify required parameter 'areaUpdateAdminRequest' is not null or undefined
            assertParamExists('adminUpdateAreaById', 'areaUpdateAdminRequest', areaUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/areas/{areaId}`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(areaUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAreaApi - functional programming interface
 * @export
 */
export const AdminAreaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAreaApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an Area, upon which other parts of the topology can be attached
         * @summary Create an Area with the provided details
         * @param {AreaCreateAdminRequest} areaCreateAdminRequest An Area admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateArea(areaCreateAdminRequest: AreaCreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateArea(areaCreateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a participants in an Area
         * @summary Create a Area participant
         * @param {string} areaId uuid of the Area
         * @param {ParticipantAdminCreateRequest} participantAdminCreateRequest A Participant create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateAreaParticipant(areaId: string, participantAdminCreateRequest: ParticipantAdminCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateAreaParticipant(areaId, participantAdminCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Building and link it to the provided Area
         * @summary Create a Building with the provided details
         * @param {string} areaId uuid of the Area
         * @param {BuildingCreateAdminRequest} buildingCreateAdminRequest A building admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateBuildingForArea(areaId: string, buildingCreateAdminRequest: BuildingCreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateBuildingForArea(areaId, buildingCreateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Contract and link it to the provided Area
         * @summary Create a Contract with the provided details
         * @param {string} areaId uuid of the Area
         * @param {ContractCreateAdminRequest} contractCreateAdminRequest A building admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateContractForArea(areaId: string, contractCreateAdminRequest: ContractCreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateContractForArea(areaId, contractCreateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An active AREA can be deactivated, triggering the deactivation/deletion of its child objects as well and removal of the area from the areas list of an AREA Manager.
         * @summary Deactivate an AREA by its areaId
         * @param {string} areaId uuid of the Area
         * @param {string} from the date the deactivation begins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeactivateAreaById(areaId: string, from: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeactivateAreaById(areaId, from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An Area can be deleted if mistakes are made in creation
         * @summary Delete an Area by its areaId
         * @param {string} areaId uuid of the Area
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteAreaById(areaId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteAreaById(areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Buildings with some possible slicing and dicing for an areaId
         * @summary Get all Buildings in an Area
         * @param {string} areaId uuid of the Area
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAreaBuildings(areaId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedBuildingAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAreaBuildings(areaId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Areas can be fetched by their respective UUID
         * @summary Get a Area by its ID
         * @param {string} areaId uuid of the Area
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAreaById(areaId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAreaById(areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Contracts with some possible slicing and dicing for an areaId
         * @summary Get all Contracts in an Area
         * @param {string} areaId uuid of the Area
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAreaContracts(areaId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedContractAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAreaContracts(areaId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get all participants of an Area
         * @summary Get a Area\'s participants
         * @param {string} areaId uuid of the Area
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAreaParticipants(areaId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiParticipantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAreaParticipants(areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Areas with some possible slicing and dicing
         * @summary Get all Areas
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAreas(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedAreaAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAreas(page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An Area can have some of its properties updated post-creation
         * @summary Update an Area by its areaId
         * @param {string} areaId uuid of the Area
         * @param {AreaUpdateAdminRequest} areaUpdateAdminRequest An Area update request for CKW administrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateAreaById(areaId: string, areaUpdateAdminRequest: AreaUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateAreaById(areaId, areaUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAreaApi - factory interface
 * @export
 */
export const AdminAreaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAreaApiFp(configuration)
    return {
        /**
         * Create an Area, upon which other parts of the topology can be attached
         * @summary Create an Area with the provided details
         * @param {AreaCreateAdminRequest} areaCreateAdminRequest An Area admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateArea(areaCreateAdminRequest: AreaCreateAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateArea(areaCreateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * create a participants in an Area
         * @summary Create a Area participant
         * @param {string} areaId uuid of the Area
         * @param {ParticipantAdminCreateRequest} participantAdminCreateRequest A Participant create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAreaParticipant(areaId: string, participantAdminCreateRequest: ParticipantAdminCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateAreaParticipant(areaId, participantAdminCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Building and link it to the provided Area
         * @summary Create a Building with the provided details
         * @param {string} areaId uuid of the Area
         * @param {BuildingCreateAdminRequest} buildingCreateAdminRequest A building admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateBuildingForArea(areaId: string, buildingCreateAdminRequest: BuildingCreateAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateBuildingForArea(areaId, buildingCreateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Contract and link it to the provided Area
         * @summary Create a Contract with the provided details
         * @param {string} areaId uuid of the Area
         * @param {ContractCreateAdminRequest} contractCreateAdminRequest A building admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateContractForArea(areaId: string, contractCreateAdminRequest: ContractCreateAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateContractForArea(areaId, contractCreateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * An active AREA can be deactivated, triggering the deactivation/deletion of its child objects as well and removal of the area from the areas list of an AREA Manager.
         * @summary Deactivate an AREA by its areaId
         * @param {string} areaId uuid of the Area
         * @param {string} from the date the deactivation begins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeactivateAreaById(areaId: string, from: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeactivateAreaById(areaId, from, options).then((request) => request(axios, basePath));
        },
        /**
         * An Area can be deleted if mistakes are made in creation
         * @summary Delete an Area by its areaId
         * @param {string} areaId uuid of the Area
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteAreaById(areaId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteAreaById(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Buildings with some possible slicing and dicing for an areaId
         * @summary Get all Buildings in an Area
         * @param {string} areaId uuid of the Area
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAreaBuildings(areaId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedBuildingAdminResponse> {
            return localVarFp.adminGetAreaBuildings(areaId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Areas can be fetched by their respective UUID
         * @summary Get a Area by its ID
         * @param {string} areaId uuid of the Area
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAreaById(areaId: string, options?: any): AxiosPromise<AreaAdminResponse> {
            return localVarFp.adminGetAreaById(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Contracts with some possible slicing and dicing for an areaId
         * @summary Get all Contracts in an Area
         * @param {string} areaId uuid of the Area
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAreaContracts(areaId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedContractAdminResponse> {
            return localVarFp.adminGetAreaContracts(areaId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * get all participants of an Area
         * @summary Get a Area\'s participants
         * @param {string} areaId uuid of the Area
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAreaParticipants(areaId: string, options?: any): AxiosPromise<MultiParticipantResponse> {
            return localVarFp.adminGetAreaParticipants(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Areas with some possible slicing and dicing
         * @summary Get all Areas
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAreas(page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedAreaAdminResponse> {
            return localVarFp.adminGetAreas(page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * An Area can have some of its properties updated post-creation
         * @summary Update an Area by its areaId
         * @param {string} areaId uuid of the Area
         * @param {AreaUpdateAdminRequest} areaUpdateAdminRequest An Area update request for CKW administrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAreaById(areaId: string, areaUpdateAdminRequest: AreaUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateAreaById(areaId, areaUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAreaApi - interface
 * @export
 * @interface AdminAreaApi
 */
export interface AdminAreaApiInterface {
    /**
     * Create an Area, upon which other parts of the topology can be attached
     * @summary Create an Area with the provided details
     * @param {AreaCreateAdminRequest} areaCreateAdminRequest An Area admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApiInterface
     */
    adminCreateArea(areaCreateAdminRequest: AreaCreateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * create a participants in an Area
     * @summary Create a Area participant
     * @param {string} areaId uuid of the Area
     * @param {ParticipantAdminCreateRequest} participantAdminCreateRequest A Participant create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApiInterface
     */
    adminCreateAreaParticipant(areaId: string, participantAdminCreateRequest: ParticipantAdminCreateRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Create a Building and link it to the provided Area
     * @summary Create a Building with the provided details
     * @param {string} areaId uuid of the Area
     * @param {BuildingCreateAdminRequest} buildingCreateAdminRequest A building admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApiInterface
     */
    adminCreateBuildingForArea(areaId: string, buildingCreateAdminRequest: BuildingCreateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Create a Contract and link it to the provided Area
     * @summary Create a Contract with the provided details
     * @param {string} areaId uuid of the Area
     * @param {ContractCreateAdminRequest} contractCreateAdminRequest A building admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApiInterface
     */
    adminCreateContractForArea(areaId: string, contractCreateAdminRequest: ContractCreateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * An active AREA can be deactivated, triggering the deactivation/deletion of its child objects as well and removal of the area from the areas list of an AREA Manager.
     * @summary Deactivate an AREA by its areaId
     * @param {string} areaId uuid of the Area
     * @param {string} from the date the deactivation begins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApiInterface
     */
    adminDeactivateAreaById(areaId: string, from: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * An Area can be deleted if mistakes are made in creation
     * @summary Delete an Area by its areaId
     * @param {string} areaId uuid of the Area
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApiInterface
     */
    adminDeleteAreaById(areaId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get all Buildings with some possible slicing and dicing for an areaId
     * @summary Get all Buildings in an Area
     * @param {string} areaId uuid of the Area
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApiInterface
     */
    adminGetAreaBuildings(areaId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedBuildingAdminResponse>;

    /**
     * Areas can be fetched by their respective UUID
     * @summary Get a Area by its ID
     * @param {string} areaId uuid of the Area
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApiInterface
     */
    adminGetAreaById(areaId: string, options?: AxiosRequestConfig): AxiosPromise<AreaAdminResponse>;

    /**
     * Get all Contracts with some possible slicing and dicing for an areaId
     * @summary Get all Contracts in an Area
     * @param {string} areaId uuid of the Area
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApiInterface
     */
    adminGetAreaContracts(areaId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedContractAdminResponse>;

    /**
     * get all participants of an Area
     * @summary Get a Area\'s participants
     * @param {string} areaId uuid of the Area
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApiInterface
     */
    adminGetAreaParticipants(areaId: string, options?: AxiosRequestConfig): AxiosPromise<MultiParticipantResponse>;

    /**
     * Get all Areas with some possible slicing and dicing
     * @summary Get all Areas
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApiInterface
     */
    adminGetAreas(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedAreaAdminResponse>;

    /**
     * An Area can have some of its properties updated post-creation
     * @summary Update an Area by its areaId
     * @param {string} areaId uuid of the Area
     * @param {AreaUpdateAdminRequest} areaUpdateAdminRequest An Area update request for CKW administrators
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApiInterface
     */
    adminUpdateAreaById(areaId: string, areaUpdateAdminRequest: AreaUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminAreaApi - object-oriented interface
 * @export
 * @class AdminAreaApi
 * @extends {BaseAPI}
 */
export class AdminAreaApi extends BaseAPI implements AdminAreaApiInterface {
    /**
     * Create an Area, upon which other parts of the topology can be attached
     * @summary Create an Area with the provided details
     * @param {AreaCreateAdminRequest} areaCreateAdminRequest An Area admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApi
     */
    public adminCreateArea(areaCreateAdminRequest: AreaCreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminAreaApiFp(this.configuration).adminCreateArea(areaCreateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a participants in an Area
     * @summary Create a Area participant
     * @param {string} areaId uuid of the Area
     * @param {ParticipantAdminCreateRequest} participantAdminCreateRequest A Participant create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApi
     */
    public adminCreateAreaParticipant(areaId: string, participantAdminCreateRequest: ParticipantAdminCreateRequest, options?: AxiosRequestConfig) {
        return AdminAreaApiFp(this.configuration).adminCreateAreaParticipant(areaId, participantAdminCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Building and link it to the provided Area
     * @summary Create a Building with the provided details
     * @param {string} areaId uuid of the Area
     * @param {BuildingCreateAdminRequest} buildingCreateAdminRequest A building admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApi
     */
    public adminCreateBuildingForArea(areaId: string, buildingCreateAdminRequest: BuildingCreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminAreaApiFp(this.configuration).adminCreateBuildingForArea(areaId, buildingCreateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Contract and link it to the provided Area
     * @summary Create a Contract with the provided details
     * @param {string} areaId uuid of the Area
     * @param {ContractCreateAdminRequest} contractCreateAdminRequest A building admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApi
     */
    public adminCreateContractForArea(areaId: string, contractCreateAdminRequest: ContractCreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminAreaApiFp(this.configuration).adminCreateContractForArea(areaId, contractCreateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An active AREA can be deactivated, triggering the deactivation/deletion of its child objects as well and removal of the area from the areas list of an AREA Manager.
     * @summary Deactivate an AREA by its areaId
     * @param {string} areaId uuid of the Area
     * @param {string} from the date the deactivation begins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApi
     */
    public adminDeactivateAreaById(areaId: string, from: string, options?: AxiosRequestConfig) {
        return AdminAreaApiFp(this.configuration).adminDeactivateAreaById(areaId, from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An Area can be deleted if mistakes are made in creation
     * @summary Delete an Area by its areaId
     * @param {string} areaId uuid of the Area
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApi
     */
    public adminDeleteAreaById(areaId: string, options?: AxiosRequestConfig) {
        return AdminAreaApiFp(this.configuration).adminDeleteAreaById(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Buildings with some possible slicing and dicing for an areaId
     * @summary Get all Buildings in an Area
     * @param {string} areaId uuid of the Area
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApi
     */
    public adminGetAreaBuildings(areaId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminAreaApiFp(this.configuration).adminGetAreaBuildings(areaId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Areas can be fetched by their respective UUID
     * @summary Get a Area by its ID
     * @param {string} areaId uuid of the Area
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApi
     */
    public adminGetAreaById(areaId: string, options?: AxiosRequestConfig) {
        return AdminAreaApiFp(this.configuration).adminGetAreaById(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Contracts with some possible slicing and dicing for an areaId
     * @summary Get all Contracts in an Area
     * @param {string} areaId uuid of the Area
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApi
     */
    public adminGetAreaContracts(areaId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminAreaApiFp(this.configuration).adminGetAreaContracts(areaId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get all participants of an Area
     * @summary Get a Area\'s participants
     * @param {string} areaId uuid of the Area
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApi
     */
    public adminGetAreaParticipants(areaId: string, options?: AxiosRequestConfig) {
        return AdminAreaApiFp(this.configuration).adminGetAreaParticipants(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Areas with some possible slicing and dicing
     * @summary Get all Areas
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApi
     */
    public adminGetAreas(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminAreaApiFp(this.configuration).adminGetAreas(page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An Area can have some of its properties updated post-creation
     * @summary Update an Area by its areaId
     * @param {string} areaId uuid of the Area
     * @param {AreaUpdateAdminRequest} areaUpdateAdminRequest An Area update request for CKW administrators
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAreaApi
     */
    public adminUpdateAreaById(areaId: string, areaUpdateAdminRequest: AreaUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminAreaApiFp(this.configuration).adminUpdateAreaById(areaId, areaUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminBillsApi - axios parameter creator
 * @export
 */
export const AdminBillsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create bills of an VewaAllParticipantBilling
         * @summary Create bills of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateVewaParticipantBillingBills: async (vewaAllParticipantBillingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('adminCreateVewaParticipantBillingBills', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/admin/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/bills`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get bills of an VewaAllParticipantBilling
         * @summary Get bills of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVewaParticipantBillingBills: async (vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('adminGetVewaParticipantBillingBills', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/admin/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/bills`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exports Bills as a file in CSV format
         * @summary Exports Bills as a file in CSV format
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAllParticipantBillingCsv: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminAllParticipantBillingCsv', 'id', id)
            const localVarPath = `/api/admin/v1/allParticipantBilling/{id}/csv`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all PDF bills for a billing cycle zipped up
         * @summary Fetch all PDF bills for a billing cycle for admins
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAllParticipantBillingPdfs: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminAllParticipantBillingPdfs', 'id', id)
            const localVarPath = `/api/admin/v1/allParticipantBilling/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all PDF bills for vewa participant billing in zip file
         * @summary Fetch all PDF bills for vewa participant billing in zip file
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAllVewaBillPdfs: async (vewaAllParticipantBillingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('getAdminAllVewaBillPdfs', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/admin/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/zip`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a PDF billing document for the provided billing ID
         * @summary Fetch the PDF bill as admin
         * @param {string} billId uuid of the Bill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBillPdf: async (billId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billId' is not null or undefined
            assertParamExists('getAdminBillPdf', 'billId', billId)
            const localVarPath = `/api/admin/v1/bills/{billId}/pdf`
                .replace(`{${"billId"}}`, encodeURIComponent(String(billId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a PDF billing document for the provided billing ID and participant ID
         * @summary Fetch the PDF bill
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminVewaParticipantBillPdf: async (vewaAllParticipantBillingId: string, participantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('getAdminVewaParticipantBillPdf', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('getAdminVewaParticipantBillPdf', 'participantId', participantId)
            const localVarPath = `/api/admin/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/participant/{participantId}/pdf`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exports Bills as a file in CSV format
         * @summary Exports Bills as a file in CSV format
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminVewaParticipantBillingCsv: async (vewaAllParticipantBillingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('getAdminVewaParticipantBillingCsv', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/admin/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/csv`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the bill as paid or unpaid
         * @summary Update the bill as paid or unpaid
         * @param {string} id uuid of the Bill
         * @param {'paid' | 'unpaid'} paidOrUnpaid flag the bill as paid or unpaid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminBillPaidOrUnpaid: async (id: string, paidOrUnpaid: 'paid' | 'unpaid', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdminBillPaidOrUnpaid', 'id', id)
            // verify required parameter 'paidOrUnpaid' is not null or undefined
            assertParamExists('updateAdminBillPaidOrUnpaid', 'paidOrUnpaid', paidOrUnpaid)
            const localVarPath = `/api/admin/v1/bills/{id}/{paidOrUnpaid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"paidOrUnpaid"}}`, encodeURIComponent(String(paidOrUnpaid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminBillsApi - functional programming interface
 * @export
 */
export const AdminBillsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminBillsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create bills of an VewaAllParticipantBilling
         * @summary Create bills of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateVewaParticipantBillingBills(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateVewaParticipantBillingBills(vewaAllParticipantBillingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get bills of an VewaAllParticipantBilling
         * @summary Get bills of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetVewaParticipantBillingBills(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedVewaBillsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetVewaParticipantBillingBills(vewaAllParticipantBillingId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exports Bills as a file in CSV format
         * @summary Exports Bills as a file in CSV format
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAllParticipantBillingCsv(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAllParticipantBillingCsv(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all PDF bills for a billing cycle zipped up
         * @summary Fetch all PDF bills for a billing cycle for admins
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAllParticipantBillingPdfs(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAllParticipantBillingPdfs(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all PDF bills for vewa participant billing in zip file
         * @summary Fetch all PDF bills for vewa participant billing in zip file
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAllVewaBillPdfs(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAllVewaBillPdfs(vewaAllParticipantBillingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a PDF billing document for the provided billing ID
         * @summary Fetch the PDF bill as admin
         * @param {string} billId uuid of the Bill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminBillPdf(billId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminBillPdf(billId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a PDF billing document for the provided billing ID and participant ID
         * @summary Fetch the PDF bill
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminVewaParticipantBillPdf(vewaAllParticipantBillingId: string, participantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminVewaParticipantBillPdf(vewaAllParticipantBillingId, participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exports Bills as a file in CSV format
         * @summary Exports Bills as a file in CSV format
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminVewaParticipantBillingCsv(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminVewaParticipantBillingCsv(vewaAllParticipantBillingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the bill as paid or unpaid
         * @summary Update the bill as paid or unpaid
         * @param {string} id uuid of the Bill
         * @param {'paid' | 'unpaid'} paidOrUnpaid flag the bill as paid or unpaid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminBillPaidOrUnpaid(id: string, paidOrUnpaid: 'paid' | 'unpaid', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillPaymentStatusUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminBillPaidOrUnpaid(id, paidOrUnpaid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminBillsApi - factory interface
 * @export
 */
export const AdminBillsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminBillsApiFp(configuration)
    return {
        /**
         * Create bills of an VewaAllParticipantBilling
         * @summary Create bills of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateVewaParticipantBillingBills(vewaAllParticipantBillingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminCreateVewaParticipantBillingBills(vewaAllParticipantBillingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get bills of an VewaAllParticipantBilling
         * @summary Get bills of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVewaParticipantBillingBills(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedVewaBillsResponse> {
            return localVarFp.adminGetVewaParticipantBillingBills(vewaAllParticipantBillingId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Exports Bills as a file in CSV format
         * @summary Exports Bills as a file in CSV format
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAllParticipantBillingCsv(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getAdminAllParticipantBillingCsv(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all PDF bills for a billing cycle zipped up
         * @summary Fetch all PDF bills for a billing cycle for admins
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAllParticipantBillingPdfs(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getAdminAllParticipantBillingPdfs(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all PDF bills for vewa participant billing in zip file
         * @summary Fetch all PDF bills for vewa participant billing in zip file
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAllVewaBillPdfs(vewaAllParticipantBillingId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getAdminAllVewaBillPdfs(vewaAllParticipantBillingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a PDF billing document for the provided billing ID
         * @summary Fetch the PDF bill as admin
         * @param {string} billId uuid of the Bill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBillPdf(billId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getAdminBillPdf(billId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a PDF billing document for the provided billing ID and participant ID
         * @summary Fetch the PDF bill
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminVewaParticipantBillPdf(vewaAllParticipantBillingId: string, participantId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getAdminVewaParticipantBillPdf(vewaAllParticipantBillingId, participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Exports Bills as a file in CSV format
         * @summary Exports Bills as a file in CSV format
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminVewaParticipantBillingCsv(vewaAllParticipantBillingId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getAdminVewaParticipantBillingCsv(vewaAllParticipantBillingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the bill as paid or unpaid
         * @summary Update the bill as paid or unpaid
         * @param {string} id uuid of the Bill
         * @param {'paid' | 'unpaid'} paidOrUnpaid flag the bill as paid or unpaid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminBillPaidOrUnpaid(id: string, paidOrUnpaid: 'paid' | 'unpaid', options?: any): AxiosPromise<BillPaymentStatusUpdateResponse> {
            return localVarFp.updateAdminBillPaidOrUnpaid(id, paidOrUnpaid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminBillsApi - interface
 * @export
 * @interface AdminBillsApi
 */
export interface AdminBillsApiInterface {
    /**
     * Create bills of an VewaAllParticipantBilling
     * @summary Create bills of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApiInterface
     */
    adminCreateVewaParticipantBillingBills(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get bills of an VewaAllParticipantBilling
     * @summary Get bills of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApiInterface
     */
    adminGetVewaParticipantBillingBills(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedVewaBillsResponse>;

    /**
     * Exports Bills as a file in CSV format
     * @summary Exports Bills as a file in CSV format
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApiInterface
     */
    getAdminAllParticipantBillingCsv(id: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Fetch all PDF bills for a billing cycle zipped up
     * @summary Fetch all PDF bills for a billing cycle for admins
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApiInterface
     */
    getAdminAllParticipantBillingPdfs(id: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Fetch all PDF bills for vewa participant billing in zip file
     * @summary Fetch all PDF bills for vewa participant billing in zip file
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApiInterface
     */
    getAdminAllVewaBillPdfs(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Fetch a PDF billing document for the provided billing ID
     * @summary Fetch the PDF bill as admin
     * @param {string} billId uuid of the Bill
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApiInterface
     */
    getAdminBillPdf(billId: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Fetch a PDF billing document for the provided billing ID and participant ID
     * @summary Fetch the PDF bill
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApiInterface
     */
    getAdminVewaParticipantBillPdf(vewaAllParticipantBillingId: string, participantId: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Exports Bills as a file in CSV format
     * @summary Exports Bills as a file in CSV format
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApiInterface
     */
    getAdminVewaParticipantBillingCsv(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Update the bill as paid or unpaid
     * @summary Update the bill as paid or unpaid
     * @param {string} id uuid of the Bill
     * @param {'paid' | 'unpaid'} paidOrUnpaid flag the bill as paid or unpaid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApiInterface
     */
    updateAdminBillPaidOrUnpaid(id: string, paidOrUnpaid: 'paid' | 'unpaid', options?: AxiosRequestConfig): AxiosPromise<BillPaymentStatusUpdateResponse>;

}

/**
 * AdminBillsApi - object-oriented interface
 * @export
 * @class AdminBillsApi
 * @extends {BaseAPI}
 */
export class AdminBillsApi extends BaseAPI implements AdminBillsApiInterface {
    /**
     * Create bills of an VewaAllParticipantBilling
     * @summary Create bills of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApi
     */
    public adminCreateVewaParticipantBillingBills(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig) {
        return AdminBillsApiFp(this.configuration).adminCreateVewaParticipantBillingBills(vewaAllParticipantBillingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get bills of an VewaAllParticipantBilling
     * @summary Get bills of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApi
     */
    public adminGetVewaParticipantBillingBills(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminBillsApiFp(this.configuration).adminGetVewaParticipantBillingBills(vewaAllParticipantBillingId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exports Bills as a file in CSV format
     * @summary Exports Bills as a file in CSV format
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApi
     */
    public getAdminAllParticipantBillingCsv(id: string, options?: AxiosRequestConfig) {
        return AdminBillsApiFp(this.configuration).getAdminAllParticipantBillingCsv(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all PDF bills for a billing cycle zipped up
     * @summary Fetch all PDF bills for a billing cycle for admins
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApi
     */
    public getAdminAllParticipantBillingPdfs(id: string, options?: AxiosRequestConfig) {
        return AdminBillsApiFp(this.configuration).getAdminAllParticipantBillingPdfs(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all PDF bills for vewa participant billing in zip file
     * @summary Fetch all PDF bills for vewa participant billing in zip file
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApi
     */
    public getAdminAllVewaBillPdfs(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig) {
        return AdminBillsApiFp(this.configuration).getAdminAllVewaBillPdfs(vewaAllParticipantBillingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a PDF billing document for the provided billing ID
     * @summary Fetch the PDF bill as admin
     * @param {string} billId uuid of the Bill
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApi
     */
    public getAdminBillPdf(billId: string, options?: AxiosRequestConfig) {
        return AdminBillsApiFp(this.configuration).getAdminBillPdf(billId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a PDF billing document for the provided billing ID and participant ID
     * @summary Fetch the PDF bill
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApi
     */
    public getAdminVewaParticipantBillPdf(vewaAllParticipantBillingId: string, participantId: string, options?: AxiosRequestConfig) {
        return AdminBillsApiFp(this.configuration).getAdminVewaParticipantBillPdf(vewaAllParticipantBillingId, participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exports Bills as a file in CSV format
     * @summary Exports Bills as a file in CSV format
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApi
     */
    public getAdminVewaParticipantBillingCsv(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig) {
        return AdminBillsApiFp(this.configuration).getAdminVewaParticipantBillingCsv(vewaAllParticipantBillingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the bill as paid or unpaid
     * @summary Update the bill as paid or unpaid
     * @param {string} id uuid of the Bill
     * @param {'paid' | 'unpaid'} paidOrUnpaid flag the bill as paid or unpaid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBillsApi
     */
    public updateAdminBillPaidOrUnpaid(id: string, paidOrUnpaid: 'paid' | 'unpaid', options?: AxiosRequestConfig) {
        return AdminBillsApiFp(this.configuration).updateAdminBillPaidOrUnpaid(id, paidOrUnpaid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminBuildingApi - axios parameter creator
 * @export
 */
export const AdminBuildingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a Building and link it to the provided Area
         * @summary Create a Building with the provided details
         * @param {string} areaId uuid of the Area
         * @param {BuildingCreateAdminRequest} buildingCreateAdminRequest A building admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateBuildingForArea: async (areaId: string, buildingCreateAdminRequest: BuildingCreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminCreateBuildingForArea', 'areaId', areaId)
            // verify required parameter 'buildingCreateAdminRequest' is not null or undefined
            assertParamExists('adminCreateBuildingForArea', 'buildingCreateAdminRequest', buildingCreateAdminRequest)
            const localVarPath = `/api/admin/v1/areas/{areaId}/buildings`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Meter and link it to the Building
         * @summary Create a Meter for building with the provided details
         * @param {string} buildingId UUID of the Building
         * @param {BuildingCreateMeterAdminRequest} buildingCreateMeterAdminRequest A Meter admin create request for building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateMeterForBuilding: async (buildingId: string, buildingCreateMeterAdminRequest: BuildingCreateMeterAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('adminCreateMeterForBuilding', 'buildingId', buildingId)
            // verify required parameter 'buildingCreateMeterAdminRequest' is not null or undefined
            assertParamExists('adminCreateMeterForBuilding', 'buildingCreateMeterAdminRequest', buildingCreateMeterAdminRequest)
            const localVarPath = `/api/admin/v1/buildings/{buildingId}/meters`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingCreateMeterAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Utility Unit and link it to the provided Contract
         * @summary Create a Utility Unit with the provided details
         * @param {string} buildingId UUID of the Building
         * @param {UtilityUnitCreateAdminRequest} utilityUnitCreateAdminRequest A Utility Unit admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUtilityUnitForBuilding: async (buildingId: string, utilityUnitCreateAdminRequest: UtilityUnitCreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('adminCreateUtilityUnitForBuilding', 'buildingId', buildingId)
            // verify required parameter 'utilityUnitCreateAdminRequest' is not null or undefined
            assertParamExists('adminCreateUtilityUnitForBuilding', 'utilityUnitCreateAdminRequest', utilityUnitCreateAdminRequest)
            const localVarPath = `/api/admin/v1/buildings/{buildingId}/utilityUnits`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(utilityUnitCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An active Building can be deactivated, triggering the deactivation/deletion of its child objects.
         * @summary Deactivate a Building by its buildingId
         * @param {string} buildingId UUID of the Building
         * @param {string} from the date the deactivation begins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeactivateBuildingById: async (buildingId: string, from: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('adminDeactivateBuildingById', 'buildingId', buildingId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('adminDeactivateBuildingById', 'from', from)
            const localVarPath = `/api/admin/v1/buildings/{buildingId}/deactivate`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a building
         * @summary Delete a building
         * @param {string} buildingId UUID of the Building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteBuilding: async (buildingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('adminDeleteBuilding', 'buildingId', buildingId)
            const localVarPath = `/api/admin/v1/buildings/{buildingId}`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Building can be specified by its respective UUID
         * @summary Get all meters for building
         * @param {string} buildingId UUID of the Building
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllMetersForBuilding: async (buildingId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('adminGetAllMetersForBuilding', 'buildingId', buildingId)
            const localVarPath = `/api/admin/v1/buildings/{buildingId}/meters`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Buildings can be fetched by their respective UUID
         * @summary Get a Building by its ID
         * @param {string} buildingId UUID of the Building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBuildingById: async (buildingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('adminGetBuildingById', 'buildingId', buildingId)
            const localVarPath = `/api/admin/v1/buildings/{buildingId}`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Buildings, with some possible slicing and dicing
         * @summary Get all Buildings
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBuildings: async (page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/buildings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all contracts linked to the building page
         * @summary Get all Building Contracts
         * @param {string} buildingId UUID of the Building
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractForBuilding: async (buildingId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('adminGetContractForBuilding', 'buildingId', buildingId)
            const localVarPath = `/api/admin/v1/buildings/{buildingId}/contracts`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all utilityUnits linked to the building page
         * @summary Get all Building Utility Units
         * @param {string} buildingId UUID of the Building
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetUtilityUnitForBuilding: async (buildingId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('adminGetUtilityUnitForBuilding', 'buildingId', buildingId)
            const localVarPath = `/api/admin/v1/buildings/{buildingId}/utilityUnits`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A Building can have some of its properties updated post-creation
         * @summary Update a Building by its buildingId
         * @param {string} buildingId UUID of the Building
         * @param {BuildingUpdateAdminRequest} buildingUpdateAdminRequest A Building update request for CKW administrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBuildingById: async (buildingId: string, buildingUpdateAdminRequest: BuildingUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('adminUpdateBuildingById', 'buildingId', buildingId)
            // verify required parameter 'buildingUpdateAdminRequest' is not null or undefined
            assertParamExists('adminUpdateBuildingById', 'buildingUpdateAdminRequest', buildingUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/buildings/{buildingId}`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminBuildingApi - functional programming interface
 * @export
 */
export const AdminBuildingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminBuildingApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a Building and link it to the provided Area
         * @summary Create a Building with the provided details
         * @param {string} areaId uuid of the Area
         * @param {BuildingCreateAdminRequest} buildingCreateAdminRequest A building admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateBuildingForArea(areaId: string, buildingCreateAdminRequest: BuildingCreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateBuildingForArea(areaId, buildingCreateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Meter and link it to the Building
         * @summary Create a Meter for building with the provided details
         * @param {string} buildingId UUID of the Building
         * @param {BuildingCreateMeterAdminRequest} buildingCreateMeterAdminRequest A Meter admin create request for building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateMeterForBuilding(buildingId: string, buildingCreateMeterAdminRequest: BuildingCreateMeterAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateMeterForBuilding(buildingId, buildingCreateMeterAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Utility Unit and link it to the provided Contract
         * @summary Create a Utility Unit with the provided details
         * @param {string} buildingId UUID of the Building
         * @param {UtilityUnitCreateAdminRequest} utilityUnitCreateAdminRequest A Utility Unit admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateUtilityUnitForBuilding(buildingId: string, utilityUnitCreateAdminRequest: UtilityUnitCreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateUtilityUnitForBuilding(buildingId, utilityUnitCreateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An active Building can be deactivated, triggering the deactivation/deletion of its child objects.
         * @summary Deactivate a Building by its buildingId
         * @param {string} buildingId UUID of the Building
         * @param {string} from the date the deactivation begins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeactivateBuildingById(buildingId: string, from: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeactivateBuildingById(buildingId, from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a building
         * @summary Delete a building
         * @param {string} buildingId UUID of the Building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteBuilding(buildingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteBuilding(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Building can be specified by its respective UUID
         * @summary Get all meters for building
         * @param {string} buildingId UUID of the Building
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAllMetersForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMeterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAllMetersForBuilding(buildingId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Buildings can be fetched by their respective UUID
         * @summary Get a Building by its ID
         * @param {string} buildingId UUID of the Building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetBuildingById(buildingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetBuildingById(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Buildings, with some possible slicing and dicing
         * @summary Get all Buildings
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetBuildings(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedBuildingAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetBuildings(page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all contracts linked to the building page
         * @summary Get all Building Contracts
         * @param {string} buildingId UUID of the Building
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetContractForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedContractAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetContractForBuilding(buildingId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all utilityUnits linked to the building page
         * @summary Get all Building Utility Units
         * @param {string} buildingId UUID of the Building
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetUtilityUnitForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedUtilityUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetUtilityUnitForBuilding(buildingId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A Building can have some of its properties updated post-creation
         * @summary Update a Building by its buildingId
         * @param {string} buildingId UUID of the Building
         * @param {BuildingUpdateAdminRequest} buildingUpdateAdminRequest A Building update request for CKW administrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateBuildingById(buildingId: string, buildingUpdateAdminRequest: BuildingUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateBuildingById(buildingId, buildingUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminBuildingApi - factory interface
 * @export
 */
export const AdminBuildingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminBuildingApiFp(configuration)
    return {
        /**
         * Create a Building and link it to the provided Area
         * @summary Create a Building with the provided details
         * @param {string} areaId uuid of the Area
         * @param {BuildingCreateAdminRequest} buildingCreateAdminRequest A building admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateBuildingForArea(areaId: string, buildingCreateAdminRequest: BuildingCreateAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateBuildingForArea(areaId, buildingCreateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Meter and link it to the Building
         * @summary Create a Meter for building with the provided details
         * @param {string} buildingId UUID of the Building
         * @param {BuildingCreateMeterAdminRequest} buildingCreateMeterAdminRequest A Meter admin create request for building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateMeterForBuilding(buildingId: string, buildingCreateMeterAdminRequest: BuildingCreateMeterAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateMeterForBuilding(buildingId, buildingCreateMeterAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Utility Unit and link it to the provided Contract
         * @summary Create a Utility Unit with the provided details
         * @param {string} buildingId UUID of the Building
         * @param {UtilityUnitCreateAdminRequest} utilityUnitCreateAdminRequest A Utility Unit admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUtilityUnitForBuilding(buildingId: string, utilityUnitCreateAdminRequest: UtilityUnitCreateAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateUtilityUnitForBuilding(buildingId, utilityUnitCreateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * An active Building can be deactivated, triggering the deactivation/deletion of its child objects.
         * @summary Deactivate a Building by its buildingId
         * @param {string} buildingId UUID of the Building
         * @param {string} from the date the deactivation begins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeactivateBuildingById(buildingId: string, from: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeactivateBuildingById(buildingId, from, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a building
         * @summary Delete a building
         * @param {string} buildingId UUID of the Building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteBuilding(buildingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteBuilding(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Building can be specified by its respective UUID
         * @summary Get all meters for building
         * @param {string} buildingId UUID of the Building
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllMetersForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedMeterResponse> {
            return localVarFp.adminGetAllMetersForBuilding(buildingId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Buildings can be fetched by their respective UUID
         * @summary Get a Building by its ID
         * @param {string} buildingId UUID of the Building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBuildingById(buildingId: string, options?: any): AxiosPromise<BuildingAdminResponse> {
            return localVarFp.adminGetBuildingById(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Buildings, with some possible slicing and dicing
         * @summary Get all Buildings
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBuildings(page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedBuildingAdminResponse> {
            return localVarFp.adminGetBuildings(page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all contracts linked to the building page
         * @summary Get all Building Contracts
         * @param {string} buildingId UUID of the Building
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedContractAdminResponse> {
            return localVarFp.adminGetContractForBuilding(buildingId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all utilityUnits linked to the building page
         * @summary Get all Building Utility Units
         * @param {string} buildingId UUID of the Building
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetUtilityUnitForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedUtilityUnitResponse> {
            return localVarFp.adminGetUtilityUnitForBuilding(buildingId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * A Building can have some of its properties updated post-creation
         * @summary Update a Building by its buildingId
         * @param {string} buildingId UUID of the Building
         * @param {BuildingUpdateAdminRequest} buildingUpdateAdminRequest A Building update request for CKW administrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBuildingById(buildingId: string, buildingUpdateAdminRequest: BuildingUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateBuildingById(buildingId, buildingUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminBuildingApi - interface
 * @export
 * @interface AdminBuildingApi
 */
export interface AdminBuildingApiInterface {
    /**
     * Create a Building and link it to the provided Area
     * @summary Create a Building with the provided details
     * @param {string} areaId uuid of the Area
     * @param {BuildingCreateAdminRequest} buildingCreateAdminRequest A building admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApiInterface
     */
    adminCreateBuildingForArea(areaId: string, buildingCreateAdminRequest: BuildingCreateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Create a Meter and link it to the Building
     * @summary Create a Meter for building with the provided details
     * @param {string} buildingId UUID of the Building
     * @param {BuildingCreateMeterAdminRequest} buildingCreateMeterAdminRequest A Meter admin create request for building
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApiInterface
     */
    adminCreateMeterForBuilding(buildingId: string, buildingCreateMeterAdminRequest: BuildingCreateMeterAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Create a Utility Unit and link it to the provided Contract
     * @summary Create a Utility Unit with the provided details
     * @param {string} buildingId UUID of the Building
     * @param {UtilityUnitCreateAdminRequest} utilityUnitCreateAdminRequest A Utility Unit admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApiInterface
     */
    adminCreateUtilityUnitForBuilding(buildingId: string, utilityUnitCreateAdminRequest: UtilityUnitCreateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * An active Building can be deactivated, triggering the deactivation/deletion of its child objects.
     * @summary Deactivate a Building by its buildingId
     * @param {string} buildingId UUID of the Building
     * @param {string} from the date the deactivation begins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApiInterface
     */
    adminDeactivateBuildingById(buildingId: string, from: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete a building
     * @summary Delete a building
     * @param {string} buildingId UUID of the Building
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApiInterface
     */
    adminDeleteBuilding(buildingId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Building can be specified by its respective UUID
     * @summary Get all meters for building
     * @param {string} buildingId UUID of the Building
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApiInterface
     */
    adminGetAllMetersForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedMeterResponse>;

    /**
     * Buildings can be fetched by their respective UUID
     * @summary Get a Building by its ID
     * @param {string} buildingId UUID of the Building
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApiInterface
     */
    adminGetBuildingById(buildingId: string, options?: AxiosRequestConfig): AxiosPromise<BuildingAdminResponse>;

    /**
     * Get all Buildings, with some possible slicing and dicing
     * @summary Get all Buildings
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApiInterface
     */
    adminGetBuildings(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedBuildingAdminResponse>;

    /**
     * Get all contracts linked to the building page
     * @summary Get all Building Contracts
     * @param {string} buildingId UUID of the Building
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApiInterface
     */
    adminGetContractForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedContractAdminResponse>;

    /**
     * Get all utilityUnits linked to the building page
     * @summary Get all Building Utility Units
     * @param {string} buildingId UUID of the Building
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApiInterface
     */
    adminGetUtilityUnitForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedUtilityUnitResponse>;

    /**
     * A Building can have some of its properties updated post-creation
     * @summary Update a Building by its buildingId
     * @param {string} buildingId UUID of the Building
     * @param {BuildingUpdateAdminRequest} buildingUpdateAdminRequest A Building update request for CKW administrators
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApiInterface
     */
    adminUpdateBuildingById(buildingId: string, buildingUpdateAdminRequest: BuildingUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminBuildingApi - object-oriented interface
 * @export
 * @class AdminBuildingApi
 * @extends {BaseAPI}
 */
export class AdminBuildingApi extends BaseAPI implements AdminBuildingApiInterface {
    /**
     * Create a Building and link it to the provided Area
     * @summary Create a Building with the provided details
     * @param {string} areaId uuid of the Area
     * @param {BuildingCreateAdminRequest} buildingCreateAdminRequest A building admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApi
     */
    public adminCreateBuildingForArea(areaId: string, buildingCreateAdminRequest: BuildingCreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminBuildingApiFp(this.configuration).adminCreateBuildingForArea(areaId, buildingCreateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Meter and link it to the Building
     * @summary Create a Meter for building with the provided details
     * @param {string} buildingId UUID of the Building
     * @param {BuildingCreateMeterAdminRequest} buildingCreateMeterAdminRequest A Meter admin create request for building
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApi
     */
    public adminCreateMeterForBuilding(buildingId: string, buildingCreateMeterAdminRequest: BuildingCreateMeterAdminRequest, options?: AxiosRequestConfig) {
        return AdminBuildingApiFp(this.configuration).adminCreateMeterForBuilding(buildingId, buildingCreateMeterAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Utility Unit and link it to the provided Contract
     * @summary Create a Utility Unit with the provided details
     * @param {string} buildingId UUID of the Building
     * @param {UtilityUnitCreateAdminRequest} utilityUnitCreateAdminRequest A Utility Unit admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApi
     */
    public adminCreateUtilityUnitForBuilding(buildingId: string, utilityUnitCreateAdminRequest: UtilityUnitCreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminBuildingApiFp(this.configuration).adminCreateUtilityUnitForBuilding(buildingId, utilityUnitCreateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An active Building can be deactivated, triggering the deactivation/deletion of its child objects.
     * @summary Deactivate a Building by its buildingId
     * @param {string} buildingId UUID of the Building
     * @param {string} from the date the deactivation begins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApi
     */
    public adminDeactivateBuildingById(buildingId: string, from: string, options?: AxiosRequestConfig) {
        return AdminBuildingApiFp(this.configuration).adminDeactivateBuildingById(buildingId, from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a building
     * @summary Delete a building
     * @param {string} buildingId UUID of the Building
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApi
     */
    public adminDeleteBuilding(buildingId: string, options?: AxiosRequestConfig) {
        return AdminBuildingApiFp(this.configuration).adminDeleteBuilding(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Building can be specified by its respective UUID
     * @summary Get all meters for building
     * @param {string} buildingId UUID of the Building
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApi
     */
    public adminGetAllMetersForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminBuildingApiFp(this.configuration).adminGetAllMetersForBuilding(buildingId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Buildings can be fetched by their respective UUID
     * @summary Get a Building by its ID
     * @param {string} buildingId UUID of the Building
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApi
     */
    public adminGetBuildingById(buildingId: string, options?: AxiosRequestConfig) {
        return AdminBuildingApiFp(this.configuration).adminGetBuildingById(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Buildings, with some possible slicing and dicing
     * @summary Get all Buildings
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApi
     */
    public adminGetBuildings(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminBuildingApiFp(this.configuration).adminGetBuildings(page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all contracts linked to the building page
     * @summary Get all Building Contracts
     * @param {string} buildingId UUID of the Building
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApi
     */
    public adminGetContractForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminBuildingApiFp(this.configuration).adminGetContractForBuilding(buildingId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all utilityUnits linked to the building page
     * @summary Get all Building Utility Units
     * @param {string} buildingId UUID of the Building
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApi
     */
    public adminGetUtilityUnitForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminBuildingApiFp(this.configuration).adminGetUtilityUnitForBuilding(buildingId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A Building can have some of its properties updated post-creation
     * @summary Update a Building by its buildingId
     * @param {string} buildingId UUID of the Building
     * @param {BuildingUpdateAdminRequest} buildingUpdateAdminRequest A Building update request for CKW administrators
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBuildingApi
     */
    public adminUpdateBuildingById(buildingId: string, buildingUpdateAdminRequest: BuildingUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminBuildingApiFp(this.configuration).adminUpdateBuildingById(buildingId, buildingUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminContractApi - axios parameter creator
 * @export
 */
export const AdminContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Activating meters after initial approval of a Contract
         * @summary Activate meters of a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {Array<MeterActivationRequest>} meterActivationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminActivateMetersByContractId: async (contractId: string, meterActivationRequest: Array<MeterActivationRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminActivateMetersByContractId', 'contractId', contractId)
            // verify required parameter 'meterActivationRequest' is not null or undefined
            assertParamExists('adminActivateMetersByContractId', 'meterActivationRequest', meterActivationRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/activateMeters`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meterActivationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Contract and link it to the provided Area
         * @summary Create a Contract with the provided details
         * @param {string} areaId uuid of the Area
         * @param {ContractCreateAdminRequest} contractCreateAdminRequest A building admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateContractForArea: async (areaId: string, contractCreateAdminRequest: ContractCreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminCreateContractForArea', 'areaId', areaId)
            // verify required parameter 'contractCreateAdminRequest' is not null or undefined
            assertParamExists('adminCreateContractForArea', 'contractCreateAdminRequest', contractCreateAdminRequest)
            const localVarPath = `/api/admin/v1/areas/{areaId}/contracts`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Contract that\'s in `draft` state
         * @summary Delete a Contract that\'s in `draft` state
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteContractById: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminDeleteContractById', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get billing information for a contract
         * @summary Get billing information for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBillingSettingsByContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetBillingSettingsByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/billingSettings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get contact information of a contract
         * @summary Get contact to contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContactByContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetContactByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/contact`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Contract by its ID
         * @summary Get single Contract by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractById: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetContractById', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the time of the last EDM data point for a Contract
         * @summary Get the time of the last EDM data point for a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractLastDataPointById: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetContractLastDataPointById', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/lastDataPoint`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get contract settings for contractConfig for a single medium
         * @summary Get Contract medium settings for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractMediumByContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetContractMediumByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/mediumZev`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the energy values for the price calculator
         * @summary Get the energy values for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} year the year for which to return the Contract energy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractPriceCalculatorEnergyById: async (contractId: string, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetContractPriceCalculatorEnergyById', 'contractId', contractId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('adminGetContractPriceCalculatorEnergyById', 'year', year)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/priceCalculator/energy`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the Contract municipality tariffs for all the known years for the price calculator
         * @summary Get the Contract municipality tariffs for all the known years for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractPriceCalculatorTariffsById: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetContractPriceCalculatorTariffsById', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/priceCalculator/tariffs`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a contract\'s service-component features
         * @summary Get a contract\'s service-component features
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractServiceComponentFeatures: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetContractServiceComponentFeatures', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/serviceComponentFeatures`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Contracts, with some paging and sorting
         * @summary Get all Contracts
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContracts: async (page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all initial service billings for a contract
         * @summary Get all initial service billings for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetInitialServiceBillingsByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetInitialServiceBillingsByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/initialServiceBillings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all managers for a contract
         * @summary Get managers for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetManagersByContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetManagersByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/managers`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all possible mediums for a specific contract
         * @summary Get possible mediums depending on the contract/product type
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMediumsByContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetMediumsByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/mediums`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides calculated meter-readings for a all related contracts (including all predecessors and successors) divided according to the provided time resolution
         * @summary Get meter-readings for a specific contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingByContractId: async (contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetMeterReadingByContractId', 'contractId', contractId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('adminGetMeterReadingByContractId', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('adminGetMeterReadingByContractId', 'endDate', endDate)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('adminGetMeterReadingByContractId', 'resolution', resolution)
            const localVarPath = `/api/admin/v1/meter-readings/contract/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get page AllParticipantsBilling of a contract
         * @summary Get page of participants billing for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPageAllParticipantsBillingsByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetPageAllParticipantsBillingsByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/allParticipantBillings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get page of buildings for a contract
         * @summary Get page of buildings for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPageBuildingsByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetPageBuildingsByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/buildings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get All IndividualParticipantBillings of a contract
         * @summary Get All Individual Participant Billings for a given contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPageIndividualParticipantsBillingsByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetPageIndividualParticipantsBillingsByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/individualParticipantBillings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get page of meters in state DRAFT not attached to utility unit for a contract
         * @summary Get page of meters in state DRAFT not attached to utility unit for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPagedDraftNonUtilityUnitMetersByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetPagedDraftNonUtilityUnitMetersByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/draftNonUtilityUnitMeters`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get page of meters in state DRAFT attached to utility unit for a contract
         * @summary Get page of meters in state DRAFT attached to utility unit for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPagedDraftUtilityUnitMetersByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetPagedDraftUtilityUnitMetersByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/draftUtilityUnitMeters`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get page of VEWA All Participant Billing for a contract
         * @summary Get page of VEWA participants billing for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPagedVewaAllParticipantsBillingsByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetPagedVewaAllParticipantsBillingsByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/vewaAllParticipantBillings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get page of VEWA meters linked to buildings for a contract
         * @summary Get page of VEWA meters linked to buildings for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPagedVewaBuildingMetersByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetPagedVewaBuildingMetersByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/buildingMeters`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all participants for a contract
         * @summary Get participants for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParticipantsByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetParticipantsByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/participants`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all price packages for a contract
         * @summary Get price packages for contracts
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPricePackagesByContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetPricePackagesByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/pricePackages`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get contract pricing model
         * @summary Get contract pricing model
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPricingModelByContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetPricingModelByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/pricingModel`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get contract product history
         * @summary Get contract product history
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProductHistoryContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetProductHistoryContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/productHistory`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all recurring service billings for a contract
         * @summary Get all recurring service billings for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRecurringServiceBillingsByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetRecurringServiceBillingsByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/recurringServiceBillings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get VEWA config details for contract
         * @summary Get VEWA config details for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVewaConfigByContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetVewaConfigByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/vewaConfig`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create an initial service billing for this contract by id
         * @summary create an initial service billing for this contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPostContractInitialServiceBillingById: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminPostContractInitialServiceBillingById', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/initialServiceBillings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add default vewaConfig for a given billing scope
         * @summary Add default vewaConfig for a given billing scope
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {VewaConfigCreateRequest} vewaConfigCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPostVewaConfigForBillingScopeByContractId: async (contractId: string, vewaConfigCreateRequest: VewaConfigCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminPostVewaConfigForBillingScopeByContractId', 'contractId', contractId)
            // verify required parameter 'vewaConfigCreateRequest' is not null or undefined
            assertParamExists('adminPostVewaConfigForBillingScopeByContractId', 'vewaConfigCreateRequest', vewaConfigCreateRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/vewaConfig`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vewaConfigCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Put contract settings for contractConfig for a single medium
         * @summary Put Contract medium settings for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {MediumZevAdminUpdateRequest} mediumZevAdminUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPutContractMediumByContractId: async (contractId: string, mediumZevAdminUpdateRequest: MediumZevAdminUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminPutContractMediumByContractId', 'contractId', contractId)
            // verify required parameter 'mediumZevAdminUpdateRequest' is not null or undefined
            assertParamExists('adminPutContractMediumByContractId', 'mediumZevAdminUpdateRequest', mediumZevAdminUpdateRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/mediumZev`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mediumZevAdminUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Release a Contract that\'s in `draft` state
         * @summary Release a Contract that\'s in `draft` state
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReleaseContractById: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminReleaseContractById', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/release`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove billing scope from VEWA config by contractId
         * @summary Remove billing scope from VEWA config by contractId
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {BillingScope} billingScope resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveBillingScopeFromVewaConfig: async (contractId: string, billingScope: BillingScope, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminRemoveBillingScopeFromVewaConfig', 'contractId', contractId)
            // verify required parameter 'billingScope' is not null or undefined
            assertParamExists('adminRemoveBillingScopeFromVewaConfig', 'billingScope', billingScope)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/vewaConfig/{billingScope}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"billingScope"}}`, encodeURIComponent(String(billingScope)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * When changing a product this will create a replacement contract containing the new product
         * @summary Replace a Contract that\'s in `APPROVED` state
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractReplaceAdminRequest} contractReplaceAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReplaceContractById: async (contractId: string, contractReplaceAdminRequest: ContractReplaceAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminReplaceContractById', 'contractId', contractId)
            // verify required parameter 'contractReplaceAdminRequest' is not null or undefined
            assertParamExists('adminReplaceContractById', 'contractReplaceAdminRequest', contractReplaceAdminRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/replace`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractReplaceAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update billing information for a contract
         * @summary Update billing information for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {BillingSettingsUpdateAdminRequest} billingSettingsUpdateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBillingSettingsByContractId: async (contractId: string, billingSettingsUpdateAdminRequest: BillingSettingsUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminUpdateBillingSettingsByContractId', 'contractId', contractId)
            // verify required parameter 'billingSettingsUpdateAdminRequest' is not null or undefined
            assertParamExists('adminUpdateBillingSettingsByContractId', 'billingSettingsUpdateAdminRequest', billingSettingsUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/billingSettings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingSettingsUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update list of buildings for a contract
         * @summary Update buildings for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractBuildingsAdminRequest} contractBuildingsAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBuildingsByContractId: async (contractId: string, contractBuildingsAdminRequest: ContractBuildingsAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminUpdateBuildingsByContractId', 'contractId', contractId)
            // verify required parameter 'contractBuildingsAdminRequest' is not null or undefined
            assertParamExists('adminUpdateBuildingsByContractId', 'contractBuildingsAdminRequest', contractBuildingsAdminRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/buildings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractBuildingsAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * By clicking the edit button in the Contact section this will update an Contact
         * @summary Update contact to contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateContactAdminRequest} contractUpdateContactAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateContactByContractId: async (contractId: string, contractUpdateContactAdminRequest: ContractUpdateContactAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminUpdateContactByContractId', 'contractId', contractId)
            // verify required parameter 'contractUpdateContactAdminRequest' is not null or undefined
            assertParamExists('adminUpdateContactByContractId', 'contractUpdateContactAdminRequest', contractUpdateContactAdminRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/contact`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractUpdateContactAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update details of a DRAFT Contract, such as Product, Start Date and End Date.
         * @summary Update a Contract by its Id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateAdminRequest} contractUpdateAdminRequest Contract information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateContractById: async (contractId: string, contractUpdateAdminRequest: ContractUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminUpdateContractById', 'contractId', contractId)
            // verify required parameter 'contractUpdateAdminRequest' is not null or undefined
            assertParamExists('adminUpdateContractById', 'contractUpdateAdminRequest', contractUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a contract\'s service end date by Id
         * @summary Update a contract\'s service end date by Id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractServiceUpdateAdminRequest} contractServiceUpdateAdminRequest A Contract service date change update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateContractServiceEndById: async (contractId: string, contractServiceUpdateAdminRequest: ContractServiceUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminUpdateContractServiceEndById', 'contractId', contractId)
            // verify required parameter 'contractServiceUpdateAdminRequest' is not null or undefined
            assertParamExists('adminUpdateContractServiceEndById', 'contractServiceUpdateAdminRequest', contractServiceUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/serviceEnd`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractServiceUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update list of managers for a contract
         * @summary Update managers for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractManagersAdminRequest} contractManagersAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateManagersByContractId: async (contractId: string, contractManagersAdminRequest: ContractManagersAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminUpdateManagersByContractId', 'contractId', contractId)
            // verify required parameter 'contractManagersAdminRequest' is not null or undefined
            assertParamExists('adminUpdateManagersByContractId', 'contractManagersAdminRequest', contractManagersAdminRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/managers`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractManagersAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update price packages for a contract
         * @summary Update price packages for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {Array<ZevPricePackage>} zevPricePackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdatePricePackagesByContractId: async (contractId: string, zevPricePackage: Array<ZevPricePackage>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminUpdatePricePackagesByContractId', 'contractId', contractId)
            // verify required parameter 'zevPricePackage' is not null or undefined
            assertParamExists('adminUpdatePricePackagesByContractId', 'zevPricePackage', zevPricePackage)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/pricePackages`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zevPricePackage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update pricing model for a contract
         * @summary Update pricing model for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {PricingModelRequest} pricingModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdatePricingModelByContractId: async (contractId: string, pricingModelRequest: PricingModelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminUpdatePricingModelByContractId', 'contractId', contractId)
            // verify required parameter 'pricingModelRequest' is not null or undefined
            assertParamExists('adminUpdatePricingModelByContractId', 'pricingModelRequest', pricingModelRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/pricingModel`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricingModelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update VEWA config details such as cost shares & group meter config, by contractId.
         * @summary Admin update VEWA config by contractId
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {VewaConfigUpdateRequest} vewaConfigUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateVewaConfig: async (contractId: string, vewaConfigUpdateRequest: VewaConfigUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminUpdateVewaConfig', 'contractId', contractId)
            // verify required parameter 'vewaConfigUpdateRequest' is not null or undefined
            assertParamExists('adminUpdateVewaConfig', 'vewaConfigUpdateRequest', vewaConfigUpdateRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/vewaConfig`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vewaConfigUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exports the participants to a file in CSV format
         * @summary Exports the participants to a file in CSV format
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParticipantByContractIdCsv: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getAllParticipantByContractIdCsv', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/participants/csv`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update a contract\'s comment
         * @summary update a contract\'s comment
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {UpdateCommentRequest} updateCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContractCommentById: async (contractId: string, updateCommentRequest: UpdateCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('updateContractCommentById', 'contractId', contractId)
            // verify required parameter 'updateCommentRequest' is not null or undefined
            assertParamExists('updateContractCommentById', 'updateCommentRequest', updateCommentRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/comments`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminContractApi - functional programming interface
 * @export
 */
export const AdminContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminContractApiAxiosParamCreator(configuration)
    return {
        /**
         * Activating meters after initial approval of a Contract
         * @summary Activate meters of a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {Array<MeterActivationRequest>} meterActivationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminActivateMetersByContractId(contractId: string, meterActivationRequest: Array<MeterActivationRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminActivateMetersByContractId(contractId, meterActivationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Contract and link it to the provided Area
         * @summary Create a Contract with the provided details
         * @param {string} areaId uuid of the Area
         * @param {ContractCreateAdminRequest} contractCreateAdminRequest A building admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateContractForArea(areaId: string, contractCreateAdminRequest: ContractCreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateContractForArea(areaId, contractCreateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a Contract that\'s in `draft` state
         * @summary Delete a Contract that\'s in `draft` state
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteContractById(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteContractById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get billing information for a contract
         * @summary Get billing information for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetBillingSettingsByContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetBillingSettingsByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get contact information of a contract
         * @summary Get contact to contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetContactByContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractUpdateContactAdminRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetContactByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a single Contract by its ID
         * @summary Get single Contract by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetContractById(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetContractById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the time of the last EDM data point for a Contract
         * @summary Get the time of the last EDM data point for a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetContractLastDataPointById(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDataAvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetContractLastDataPointById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get contract settings for contractConfig for a single medium
         * @summary Get Contract medium settings for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetContractMediumByContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediumZevAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetContractMediumByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the energy values for the price calculator
         * @summary Get the energy values for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} year the year for which to return the Contract energy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetContractPriceCalculatorEnergyById(contractId: string, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZevPriceCalculatorEnergy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetContractPriceCalculatorEnergyById(contractId, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the Contract municipality tariffs for all the known years for the price calculator
         * @summary Get the Contract municipality tariffs for all the known years for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetContractPriceCalculatorTariffsById(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZevPriceCalculatorTariffsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetContractPriceCalculatorTariffsById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a contract\'s service-component features
         * @summary Get a contract\'s service-component features
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetContractServiceComponentFeatures(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractServiceComponentFeaturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetContractServiceComponentFeatures(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Contracts, with some paging and sorting
         * @summary Get all Contracts
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetContracts(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedContractAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetContracts(page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all initial service billings for a contract
         * @summary Get all initial service billings for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetInitialServiceBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedServiceBillingAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetInitialServiceBillingsByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all managers for a contract
         * @summary Get managers for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetManagersByContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfileAdminResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetManagersByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all possible mediums for a specific contract
         * @summary Get possible mediums depending on the contract/product type
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMediumsByContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediumAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMediumsByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides calculated meter-readings for a all related contracts (including all predecessors and successors) divided according to the provided time resolution
         * @summary Get meter-readings for a specific contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMeterReadingByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadingsContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMeterReadingByContractId(contractId, startDate, endDate, resolution, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get page AllParticipantsBilling of a contract
         * @summary Get page of participants billing for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPageAllParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedAllParticipantsBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPageAllParticipantsBillingsByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get page of buildings for a contract
         * @summary Get page of buildings for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPageBuildingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedBuildingAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPageBuildingsByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get All IndividualParticipantBillings of a contract
         * @summary Get All Individual Participant Billings for a given contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPageIndividualParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedIndividualParticipantsBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPageIndividualParticipantsBillingsByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get page of meters in state DRAFT not attached to utility unit for a contract
         * @summary Get page of meters in state DRAFT not attached to utility unit for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPagedDraftNonUtilityUnitMetersByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMeterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPagedDraftNonUtilityUnitMetersByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get page of meters in state DRAFT attached to utility unit for a contract
         * @summary Get page of meters in state DRAFT attached to utility unit for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPagedDraftUtilityUnitMetersByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMeterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPagedDraftUtilityUnitMetersByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get page of VEWA All Participant Billing for a contract
         * @summary Get page of VEWA participants billing for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPagedVewaAllParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedVewaAllParticipantsBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPagedVewaAllParticipantsBillingsByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get page of VEWA meters linked to buildings for a contract
         * @summary Get page of VEWA meters linked to buildings for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPagedVewaBuildingMetersByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMeterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPagedVewaBuildingMetersByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all participants for a contract
         * @summary Get participants for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParticipantsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedParticipantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParticipantsByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all price packages for a contract
         * @summary Get price packages for contracts
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPricePackagesByContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZevPricePackage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPricePackagesByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get contract pricing model
         * @summary Get contract pricing model
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPricingModelByContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPricingModelByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get contract product history
         * @summary Get contract product history
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetProductHistoryContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetProductHistoryContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all recurring service billings for a contract
         * @summary Get all recurring service billings for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRecurringServiceBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedServiceBillingAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRecurringServiceBillingsByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get VEWA config details for contract
         * @summary Get VEWA config details for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetVewaConfigByContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VewaConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetVewaConfigByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create an initial service billing for this contract by id
         * @summary create an initial service billing for this contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPostContractInitialServiceBillingById(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPostContractInitialServiceBillingById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add default vewaConfig for a given billing scope
         * @summary Add default vewaConfig for a given billing scope
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {VewaConfigCreateRequest} vewaConfigCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPostVewaConfigForBillingScopeByContractId(contractId: string, vewaConfigCreateRequest: VewaConfigCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPostVewaConfigForBillingScopeByContractId(contractId, vewaConfigCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Put contract settings for contractConfig for a single medium
         * @summary Put Contract medium settings for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {MediumZevAdminUpdateRequest} mediumZevAdminUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPutContractMediumByContractId(contractId: string, mediumZevAdminUpdateRequest: MediumZevAdminUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPutContractMediumByContractId(contractId, mediumZevAdminUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Release a Contract that\'s in `draft` state
         * @summary Release a Contract that\'s in `draft` state
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReleaseContractById(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReleaseContractById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove billing scope from VEWA config by contractId
         * @summary Remove billing scope from VEWA config by contractId
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {BillingScope} billingScope resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRemoveBillingScopeFromVewaConfig(contractId: string, billingScope: BillingScope, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRemoveBillingScopeFromVewaConfig(contractId, billingScope, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * When changing a product this will create a replacement contract containing the new product
         * @summary Replace a Contract that\'s in `APPROVED` state
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractReplaceAdminRequest} contractReplaceAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReplaceContractById(contractId: string, contractReplaceAdminRequest: ContractReplaceAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReplaceContractById(contractId, contractReplaceAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update billing information for a contract
         * @summary Update billing information for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {BillingSettingsUpdateAdminRequest} billingSettingsUpdateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateBillingSettingsByContractId(contractId: string, billingSettingsUpdateAdminRequest: BillingSettingsUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateBillingSettingsByContractId(contractId, billingSettingsUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update list of buildings for a contract
         * @summary Update buildings for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractBuildingsAdminRequest} contractBuildingsAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateBuildingsByContractId(contractId: string, contractBuildingsAdminRequest: ContractBuildingsAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateBuildingsByContractId(contractId, contractBuildingsAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * By clicking the edit button in the Contact section this will update an Contact
         * @summary Update contact to contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateContactAdminRequest} contractUpdateContactAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateContactByContractId(contractId: string, contractUpdateContactAdminRequest: ContractUpdateContactAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateContactByContractId(contractId, contractUpdateContactAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update details of a DRAFT Contract, such as Product, Start Date and End Date.
         * @summary Update a Contract by its Id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateAdminRequest} contractUpdateAdminRequest Contract information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateContractById(contractId: string, contractUpdateAdminRequest: ContractUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateContractById(contractId, contractUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a contract\'s service end date by Id
         * @summary Update a contract\'s service end date by Id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractServiceUpdateAdminRequest} contractServiceUpdateAdminRequest A Contract service date change update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateContractServiceEndById(contractId: string, contractServiceUpdateAdminRequest: ContractServiceUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateContractServiceEndById(contractId, contractServiceUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update list of managers for a contract
         * @summary Update managers for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractManagersAdminRequest} contractManagersAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateManagersByContractId(contractId: string, contractManagersAdminRequest: ContractManagersAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateManagersByContractId(contractId, contractManagersAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update price packages for a contract
         * @summary Update price packages for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {Array<ZevPricePackage>} zevPricePackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdatePricePackagesByContractId(contractId: string, zevPricePackage: Array<ZevPricePackage>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdatePricePackagesByContractId(contractId, zevPricePackage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update pricing model for a contract
         * @summary Update pricing model for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {PricingModelRequest} pricingModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdatePricingModelByContractId(contractId: string, pricingModelRequest: PricingModelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdatePricingModelByContractId(contractId, pricingModelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update VEWA config details such as cost shares & group meter config, by contractId.
         * @summary Admin update VEWA config by contractId
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {VewaConfigUpdateRequest} vewaConfigUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateVewaConfig(contractId: string, vewaConfigUpdateRequest: VewaConfigUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateVewaConfig(contractId, vewaConfigUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exports the participants to a file in CSV format
         * @summary Exports the participants to a file in CSV format
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllParticipantByContractIdCsv(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllParticipantByContractIdCsv(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update a contract\'s comment
         * @summary update a contract\'s comment
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {UpdateCommentRequest} updateCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContractCommentById(contractId: string, updateCommentRequest: UpdateCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContractCommentById(contractId, updateCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminContractApi - factory interface
 * @export
 */
export const AdminContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminContractApiFp(configuration)
    return {
        /**
         * Activating meters after initial approval of a Contract
         * @summary Activate meters of a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {Array<MeterActivationRequest>} meterActivationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminActivateMetersByContractId(contractId: string, meterActivationRequest: Array<MeterActivationRequest>, options?: any): AxiosPromise<void> {
            return localVarFp.adminActivateMetersByContractId(contractId, meterActivationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Contract and link it to the provided Area
         * @summary Create a Contract with the provided details
         * @param {string} areaId uuid of the Area
         * @param {ContractCreateAdminRequest} contractCreateAdminRequest A building admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateContractForArea(areaId: string, contractCreateAdminRequest: ContractCreateAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateContractForArea(areaId, contractCreateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Contract that\'s in `draft` state
         * @summary Delete a Contract that\'s in `draft` state
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteContractById(contractId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteContractById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get billing information for a contract
         * @summary Get billing information for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBillingSettingsByContractId(contractId: string, options?: any): AxiosPromise<BillingSettingsResponse> {
            return localVarFp.adminGetBillingSettingsByContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get contact information of a contract
         * @summary Get contact to contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContactByContractId(contractId: string, options?: any): AxiosPromise<ContractUpdateContactAdminRequest> {
            return localVarFp.adminGetContactByContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Contract by its ID
         * @summary Get single Contract by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractById(contractId: string, options?: any): AxiosPromise<ContractAdminResponse> {
            return localVarFp.adminGetContractById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the time of the last EDM data point for a Contract
         * @summary Get the time of the last EDM data point for a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractLastDataPointById(contractId: string, options?: any): AxiosPromise<ContractDataAvailabilityResponse> {
            return localVarFp.adminGetContractLastDataPointById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get contract settings for contractConfig for a single medium
         * @summary Get Contract medium settings for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractMediumByContractId(contractId: string, options?: any): AxiosPromise<MediumZevAdminResponse> {
            return localVarFp.adminGetContractMediumByContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the energy values for the price calculator
         * @summary Get the energy values for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} year the year for which to return the Contract energy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractPriceCalculatorEnergyById(contractId: string, year: number, options?: any): AxiosPromise<ZevPriceCalculatorEnergy> {
            return localVarFp.adminGetContractPriceCalculatorEnergyById(contractId, year, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the Contract municipality tariffs for all the known years for the price calculator
         * @summary Get the Contract municipality tariffs for all the known years for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractPriceCalculatorTariffsById(contractId: string, options?: any): AxiosPromise<ZevPriceCalculatorTariffsResponse> {
            return localVarFp.adminGetContractPriceCalculatorTariffsById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a contract\'s service-component features
         * @summary Get a contract\'s service-component features
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContractServiceComponentFeatures(contractId: string, options?: any): AxiosPromise<ContractServiceComponentFeaturesResponse> {
            return localVarFp.adminGetContractServiceComponentFeatures(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Contracts, with some paging and sorting
         * @summary Get all Contracts
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContracts(page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedContractAdminResponse> {
            return localVarFp.adminGetContracts(page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all initial service billings for a contract
         * @summary Get all initial service billings for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetInitialServiceBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedServiceBillingAdminResponse> {
            return localVarFp.adminGetInitialServiceBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all managers for a contract
         * @summary Get managers for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetManagersByContractId(contractId: string, options?: any): AxiosPromise<Array<ProfileAdminResponse>> {
            return localVarFp.adminGetManagersByContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all possible mediums for a specific contract
         * @summary Get possible mediums depending on the contract/product type
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMediumsByContractId(contractId: string, options?: any): AxiosPromise<MediumAdminResponse> {
            return localVarFp.adminGetMediumsByContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides calculated meter-readings for a all related contracts (including all predecessors and successors) divided according to the provided time resolution
         * @summary Get meter-readings for a specific contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: any): AxiosPromise<MeterReadingsContractResponse> {
            return localVarFp.adminGetMeterReadingByContractId(contractId, startDate, endDate, resolution, options).then((request) => request(axios, basePath));
        },
        /**
         * Get page AllParticipantsBilling of a contract
         * @summary Get page of participants billing for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPageAllParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedAllParticipantsBillingResponse> {
            return localVarFp.adminGetPageAllParticipantsBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get page of buildings for a contract
         * @summary Get page of buildings for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPageBuildingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedBuildingAdminResponse> {
            return localVarFp.adminGetPageBuildingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get All IndividualParticipantBillings of a contract
         * @summary Get All Individual Participant Billings for a given contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPageIndividualParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedIndividualParticipantsBillingResponse> {
            return localVarFp.adminGetPageIndividualParticipantsBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get page of meters in state DRAFT not attached to utility unit for a contract
         * @summary Get page of meters in state DRAFT not attached to utility unit for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPagedDraftNonUtilityUnitMetersByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedMeterResponse> {
            return localVarFp.adminGetPagedDraftNonUtilityUnitMetersByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get page of meters in state DRAFT attached to utility unit for a contract
         * @summary Get page of meters in state DRAFT attached to utility unit for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPagedDraftUtilityUnitMetersByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedMeterResponse> {
            return localVarFp.adminGetPagedDraftUtilityUnitMetersByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get page of VEWA All Participant Billing for a contract
         * @summary Get page of VEWA participants billing for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPagedVewaAllParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedVewaAllParticipantsBillingResponse> {
            return localVarFp.adminGetPagedVewaAllParticipantsBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get page of VEWA meters linked to buildings for a contract
         * @summary Get page of VEWA meters linked to buildings for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPagedVewaBuildingMetersByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedMeterResponse> {
            return localVarFp.adminGetPagedVewaBuildingMetersByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all participants for a contract
         * @summary Get participants for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParticipantsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedParticipantResponse> {
            return localVarFp.adminGetParticipantsByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all price packages for a contract
         * @summary Get price packages for contracts
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPricePackagesByContractId(contractId: string, options?: any): AxiosPromise<Array<ZevPricePackage>> {
            return localVarFp.adminGetPricePackagesByContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get contract pricing model
         * @summary Get contract pricing model
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPricingModelByContractId(contractId: string, options?: any): AxiosPromise<PricingModelResponse> {
            return localVarFp.adminGetPricingModelByContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get contract product history
         * @summary Get contract product history
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProductHistoryContractId(contractId: string, options?: any): AxiosPromise<ProductHistoryResponse> {
            return localVarFp.adminGetProductHistoryContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all recurring service billings for a contract
         * @summary Get all recurring service billings for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRecurringServiceBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedServiceBillingAdminResponse> {
            return localVarFp.adminGetRecurringServiceBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get VEWA config details for contract
         * @summary Get VEWA config details for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVewaConfigByContractId(contractId: string, options?: any): AxiosPromise<VewaConfig> {
            return localVarFp.adminGetVewaConfigByContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * create an initial service billing for this contract by id
         * @summary create an initial service billing for this contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPostContractInitialServiceBillingById(contractId: string, options?: any): AxiosPromise<string> {
            return localVarFp.adminPostContractInitialServiceBillingById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add default vewaConfig for a given billing scope
         * @summary Add default vewaConfig for a given billing scope
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {VewaConfigCreateRequest} vewaConfigCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPostVewaConfigForBillingScopeByContractId(contractId: string, vewaConfigCreateRequest: VewaConfigCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminPostVewaConfigForBillingScopeByContractId(contractId, vewaConfigCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Put contract settings for contractConfig for a single medium
         * @summary Put Contract medium settings for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {MediumZevAdminUpdateRequest} mediumZevAdminUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPutContractMediumByContractId(contractId: string, mediumZevAdminUpdateRequest: MediumZevAdminUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminPutContractMediumByContractId(contractId, mediumZevAdminUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Release a Contract that\'s in `draft` state
         * @summary Release a Contract that\'s in `draft` state
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReleaseContractById(contractId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminReleaseContractById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove billing scope from VEWA config by contractId
         * @summary Remove billing scope from VEWA config by contractId
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {BillingScope} billingScope resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveBillingScopeFromVewaConfig(contractId: string, billingScope: BillingScope, options?: any): AxiosPromise<void> {
            return localVarFp.adminRemoveBillingScopeFromVewaConfig(contractId, billingScope, options).then((request) => request(axios, basePath));
        },
        /**
         * When changing a product this will create a replacement contract containing the new product
         * @summary Replace a Contract that\'s in `APPROVED` state
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractReplaceAdminRequest} contractReplaceAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReplaceContractById(contractId: string, contractReplaceAdminRequest: ContractReplaceAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminReplaceContractById(contractId, contractReplaceAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update billing information for a contract
         * @summary Update billing information for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {BillingSettingsUpdateAdminRequest} billingSettingsUpdateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBillingSettingsByContractId(contractId: string, billingSettingsUpdateAdminRequest: BillingSettingsUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateBillingSettingsByContractId(contractId, billingSettingsUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update list of buildings for a contract
         * @summary Update buildings for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractBuildingsAdminRequest} contractBuildingsAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBuildingsByContractId(contractId: string, contractBuildingsAdminRequest: ContractBuildingsAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateBuildingsByContractId(contractId, contractBuildingsAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * By clicking the edit button in the Contact section this will update an Contact
         * @summary Update contact to contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateContactAdminRequest} contractUpdateContactAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateContactByContractId(contractId: string, contractUpdateContactAdminRequest: ContractUpdateContactAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateContactByContractId(contractId, contractUpdateContactAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update details of a DRAFT Contract, such as Product, Start Date and End Date.
         * @summary Update a Contract by its Id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateAdminRequest} contractUpdateAdminRequest Contract information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateContractById(contractId: string, contractUpdateAdminRequest: ContractUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateContractById(contractId, contractUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a contract\'s service end date by Id
         * @summary Update a contract\'s service end date by Id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractServiceUpdateAdminRequest} contractServiceUpdateAdminRequest A Contract service date change update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateContractServiceEndById(contractId: string, contractServiceUpdateAdminRequest: ContractServiceUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateContractServiceEndById(contractId, contractServiceUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update list of managers for a contract
         * @summary Update managers for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractManagersAdminRequest} contractManagersAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateManagersByContractId(contractId: string, contractManagersAdminRequest: ContractManagersAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateManagersByContractId(contractId, contractManagersAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update price packages for a contract
         * @summary Update price packages for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {Array<ZevPricePackage>} zevPricePackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdatePricePackagesByContractId(contractId: string, zevPricePackage: Array<ZevPricePackage>, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdatePricePackagesByContractId(contractId, zevPricePackage, options).then((request) => request(axios, basePath));
        },
        /**
         * Update pricing model for a contract
         * @summary Update pricing model for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {PricingModelRequest} pricingModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdatePricingModelByContractId(contractId: string, pricingModelRequest: PricingModelRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdatePricingModelByContractId(contractId, pricingModelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update VEWA config details such as cost shares & group meter config, by contractId.
         * @summary Admin update VEWA config by contractId
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {VewaConfigUpdateRequest} vewaConfigUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateVewaConfig(contractId: string, vewaConfigUpdateRequest: VewaConfigUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateVewaConfig(contractId, vewaConfigUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Exports the participants to a file in CSV format
         * @summary Exports the participants to a file in CSV format
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParticipantByContractIdCsv(contractId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getAllParticipantByContractIdCsv(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * update a contract\'s comment
         * @summary update a contract\'s comment
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {UpdateCommentRequest} updateCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContractCommentById(contractId: string, updateCommentRequest: UpdateCommentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateContractCommentById(contractId, updateCommentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminContractApi - interface
 * @export
 * @interface AdminContractApi
 */
export interface AdminContractApiInterface {
    /**
     * Activating meters after initial approval of a Contract
     * @summary Activate meters of a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {Array<MeterActivationRequest>} meterActivationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminActivateMetersByContractId(contractId: string, meterActivationRequest: Array<MeterActivationRequest>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Create a Contract and link it to the provided Area
     * @summary Create a Contract with the provided details
     * @param {string} areaId uuid of the Area
     * @param {ContractCreateAdminRequest} contractCreateAdminRequest A building admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminCreateContractForArea(areaId: string, contractCreateAdminRequest: ContractCreateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Delete a Contract that\'s in `draft` state
     * @summary Delete a Contract that\'s in `draft` state
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminDeleteContractById(contractId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get billing information for a contract
     * @summary Get billing information for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetBillingSettingsByContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<BillingSettingsResponse>;

    /**
     * Get contact information of a contract
     * @summary Get contact to contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetContactByContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ContractUpdateContactAdminRequest>;

    /**
     * Get a single Contract by its ID
     * @summary Get single Contract by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetContractById(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ContractAdminResponse>;

    /**
     * Get the time of the last EDM data point for a Contract
     * @summary Get the time of the last EDM data point for a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetContractLastDataPointById(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ContractDataAvailabilityResponse>;

    /**
     * Get contract settings for contractConfig for a single medium
     * @summary Get Contract medium settings for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetContractMediumByContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<MediumZevAdminResponse>;

    /**
     * Get the energy values for the price calculator
     * @summary Get the energy values for the price calculator
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} year the year for which to return the Contract energy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetContractPriceCalculatorEnergyById(contractId: string, year: number, options?: AxiosRequestConfig): AxiosPromise<ZevPriceCalculatorEnergy>;

    /**
     * Get the Contract municipality tariffs for all the known years for the price calculator
     * @summary Get the Contract municipality tariffs for all the known years for the price calculator
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetContractPriceCalculatorTariffsById(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ZevPriceCalculatorTariffsResponse>;

    /**
     * Get a contract\'s service-component features
     * @summary Get a contract\'s service-component features
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetContractServiceComponentFeatures(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ContractServiceComponentFeaturesResponse>;

    /**
     * Get all Contracts, with some paging and sorting
     * @summary Get all Contracts
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetContracts(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedContractAdminResponse>;

    /**
     * Get all initial service billings for a contract
     * @summary Get all initial service billings for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetInitialServiceBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedServiceBillingAdminResponse>;

    /**
     * Get all managers for a contract
     * @summary Get managers for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetManagersByContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ProfileAdminResponse>>;

    /**
     * Get all possible mediums for a specific contract
     * @summary Get possible mediums depending on the contract/product type
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetMediumsByContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<MediumAdminResponse>;

    /**
     * Provides calculated meter-readings for a all related contracts (including all predecessors and successors) divided according to the provided time resolution
     * @summary Get meter-readings for a specific contract by id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetMeterReadingByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: AxiosRequestConfig): AxiosPromise<MeterReadingsContractResponse>;

    /**
     * Get page AllParticipantsBilling of a contract
     * @summary Get page of participants billing for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetPageAllParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedAllParticipantsBillingResponse>;

    /**
     * Get page of buildings for a contract
     * @summary Get page of buildings for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetPageBuildingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedBuildingAdminResponse>;

    /**
     * Get All IndividualParticipantBillings of a contract
     * @summary Get All Individual Participant Billings for a given contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetPageIndividualParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedIndividualParticipantsBillingResponse>;

    /**
     * Get page of meters in state DRAFT not attached to utility unit for a contract
     * @summary Get page of meters in state DRAFT not attached to utility unit for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetPagedDraftNonUtilityUnitMetersByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedMeterResponse>;

    /**
     * Get page of meters in state DRAFT attached to utility unit for a contract
     * @summary Get page of meters in state DRAFT attached to utility unit for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetPagedDraftUtilityUnitMetersByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedMeterResponse>;

    /**
     * Get page of VEWA All Participant Billing for a contract
     * @summary Get page of VEWA participants billing for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetPagedVewaAllParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedVewaAllParticipantsBillingResponse>;

    /**
     * Get page of VEWA meters linked to buildings for a contract
     * @summary Get page of VEWA meters linked to buildings for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetPagedVewaBuildingMetersByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedMeterResponse>;

    /**
     * Get all participants for a contract
     * @summary Get participants for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetParticipantsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedParticipantResponse>;

    /**
     * Get all price packages for a contract
     * @summary Get price packages for contracts
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetPricePackagesByContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ZevPricePackage>>;

    /**
     * Get contract pricing model
     * @summary Get contract pricing model
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetPricingModelByContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<PricingModelResponse>;

    /**
     * Get contract product history
     * @summary Get contract product history
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetProductHistoryContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ProductHistoryResponse>;

    /**
     * Get all recurring service billings for a contract
     * @summary Get all recurring service billings for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetRecurringServiceBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedServiceBillingAdminResponse>;

    /**
     * Get VEWA config details for contract
     * @summary Get VEWA config details for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminGetVewaConfigByContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<VewaConfig>;

    /**
     * create an initial service billing for this contract by id
     * @summary create an initial service billing for this contract by id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminPostContractInitialServiceBillingById(contractId: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Add default vewaConfig for a given billing scope
     * @summary Add default vewaConfig for a given billing scope
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {VewaConfigCreateRequest} vewaConfigCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminPostVewaConfigForBillingScopeByContractId(contractId: string, vewaConfigCreateRequest: VewaConfigCreateRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Put contract settings for contractConfig for a single medium
     * @summary Put Contract medium settings for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {MediumZevAdminUpdateRequest} mediumZevAdminUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminPutContractMediumByContractId(contractId: string, mediumZevAdminUpdateRequest: MediumZevAdminUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Release a Contract that\'s in `draft` state
     * @summary Release a Contract that\'s in `draft` state
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminReleaseContractById(contractId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Remove billing scope from VEWA config by contractId
     * @summary Remove billing scope from VEWA config by contractId
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {BillingScope} billingScope resolution window of the returned data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminRemoveBillingScopeFromVewaConfig(contractId: string, billingScope: BillingScope, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * When changing a product this will create a replacement contract containing the new product
     * @summary Replace a Contract that\'s in `APPROVED` state
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractReplaceAdminRequest} contractReplaceAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminReplaceContractById(contractId: string, contractReplaceAdminRequest: ContractReplaceAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Update billing information for a contract
     * @summary Update billing information for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {BillingSettingsUpdateAdminRequest} billingSettingsUpdateAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminUpdateBillingSettingsByContractId(contractId: string, billingSettingsUpdateAdminRequest: BillingSettingsUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update list of buildings for a contract
     * @summary Update buildings for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractBuildingsAdminRequest} contractBuildingsAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminUpdateBuildingsByContractId(contractId: string, contractBuildingsAdminRequest: ContractBuildingsAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * By clicking the edit button in the Contact section this will update an Contact
     * @summary Update contact to contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdateContactAdminRequest} contractUpdateContactAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminUpdateContactByContractId(contractId: string, contractUpdateContactAdminRequest: ContractUpdateContactAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update details of a DRAFT Contract, such as Product, Start Date and End Date.
     * @summary Update a Contract by its Id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdateAdminRequest} contractUpdateAdminRequest Contract information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminUpdateContractById(contractId: string, contractUpdateAdminRequest: ContractUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update a contract\'s service end date by Id
     * @summary Update a contract\'s service end date by Id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractServiceUpdateAdminRequest} contractServiceUpdateAdminRequest A Contract service date change update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminUpdateContractServiceEndById(contractId: string, contractServiceUpdateAdminRequest: ContractServiceUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update list of managers for a contract
     * @summary Update managers for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractManagersAdminRequest} contractManagersAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminUpdateManagersByContractId(contractId: string, contractManagersAdminRequest: ContractManagersAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update price packages for a contract
     * @summary Update price packages for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {Array<ZevPricePackage>} zevPricePackage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminUpdatePricePackagesByContractId(contractId: string, zevPricePackage: Array<ZevPricePackage>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update pricing model for a contract
     * @summary Update pricing model for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {PricingModelRequest} pricingModelRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminUpdatePricingModelByContractId(contractId: string, pricingModelRequest: PricingModelRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update VEWA config details such as cost shares & group meter config, by contractId.
     * @summary Admin update VEWA config by contractId
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {VewaConfigUpdateRequest} vewaConfigUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    adminUpdateVewaConfig(contractId: string, vewaConfigUpdateRequest: VewaConfigUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Exports the participants to a file in CSV format
     * @summary Exports the participants to a file in CSV format
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    getAllParticipantByContractIdCsv(contractId: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * update a contract\'s comment
     * @summary update a contract\'s comment
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {UpdateCommentRequest} updateCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApiInterface
     */
    updateContractCommentById(contractId: string, updateCommentRequest: UpdateCommentRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminContractApi - object-oriented interface
 * @export
 * @class AdminContractApi
 * @extends {BaseAPI}
 */
export class AdminContractApi extends BaseAPI implements AdminContractApiInterface {
    /**
     * Activating meters after initial approval of a Contract
     * @summary Activate meters of a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {Array<MeterActivationRequest>} meterActivationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminActivateMetersByContractId(contractId: string, meterActivationRequest: Array<MeterActivationRequest>, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminActivateMetersByContractId(contractId, meterActivationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Contract and link it to the provided Area
     * @summary Create a Contract with the provided details
     * @param {string} areaId uuid of the Area
     * @param {ContractCreateAdminRequest} contractCreateAdminRequest A building admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminCreateContractForArea(areaId: string, contractCreateAdminRequest: ContractCreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminCreateContractForArea(areaId, contractCreateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Contract that\'s in `draft` state
     * @summary Delete a Contract that\'s in `draft` state
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminDeleteContractById(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminDeleteContractById(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get billing information for a contract
     * @summary Get billing information for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetBillingSettingsByContractId(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetBillingSettingsByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get contact information of a contract
     * @summary Get contact to contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetContactByContractId(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetContactByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Contract by its ID
     * @summary Get single Contract by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetContractById(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetContractById(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the time of the last EDM data point for a Contract
     * @summary Get the time of the last EDM data point for a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetContractLastDataPointById(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetContractLastDataPointById(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get contract settings for contractConfig for a single medium
     * @summary Get Contract medium settings for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetContractMediumByContractId(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetContractMediumByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the energy values for the price calculator
     * @summary Get the energy values for the price calculator
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} year the year for which to return the Contract energy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetContractPriceCalculatorEnergyById(contractId: string, year: number, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetContractPriceCalculatorEnergyById(contractId, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the Contract municipality tariffs for all the known years for the price calculator
     * @summary Get the Contract municipality tariffs for all the known years for the price calculator
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetContractPriceCalculatorTariffsById(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetContractPriceCalculatorTariffsById(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a contract\'s service-component features
     * @summary Get a contract\'s service-component features
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetContractServiceComponentFeatures(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetContractServiceComponentFeatures(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Contracts, with some paging and sorting
     * @summary Get all Contracts
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetContracts(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetContracts(page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all initial service billings for a contract
     * @summary Get all initial service billings for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetInitialServiceBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetInitialServiceBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all managers for a contract
     * @summary Get managers for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetManagersByContractId(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetManagersByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all possible mediums for a specific contract
     * @summary Get possible mediums depending on the contract/product type
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetMediumsByContractId(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetMediumsByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides calculated meter-readings for a all related contracts (including all predecessors and successors) divided according to the provided time resolution
     * @summary Get meter-readings for a specific contract by id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetMeterReadingByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetMeterReadingByContractId(contractId, startDate, endDate, resolution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get page AllParticipantsBilling of a contract
     * @summary Get page of participants billing for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetPageAllParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetPageAllParticipantsBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get page of buildings for a contract
     * @summary Get page of buildings for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetPageBuildingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetPageBuildingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get All IndividualParticipantBillings of a contract
     * @summary Get All Individual Participant Billings for a given contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetPageIndividualParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetPageIndividualParticipantsBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get page of meters in state DRAFT not attached to utility unit for a contract
     * @summary Get page of meters in state DRAFT not attached to utility unit for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetPagedDraftNonUtilityUnitMetersByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetPagedDraftNonUtilityUnitMetersByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get page of meters in state DRAFT attached to utility unit for a contract
     * @summary Get page of meters in state DRAFT attached to utility unit for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetPagedDraftUtilityUnitMetersByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetPagedDraftUtilityUnitMetersByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get page of VEWA All Participant Billing for a contract
     * @summary Get page of VEWA participants billing for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetPagedVewaAllParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetPagedVewaAllParticipantsBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get page of VEWA meters linked to buildings for a contract
     * @summary Get page of VEWA meters linked to buildings for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetPagedVewaBuildingMetersByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetPagedVewaBuildingMetersByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all participants for a contract
     * @summary Get participants for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetParticipantsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetParticipantsByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all price packages for a contract
     * @summary Get price packages for contracts
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetPricePackagesByContractId(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetPricePackagesByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get contract pricing model
     * @summary Get contract pricing model
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetPricingModelByContractId(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetPricingModelByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get contract product history
     * @summary Get contract product history
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetProductHistoryContractId(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetProductHistoryContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all recurring service billings for a contract
     * @summary Get all recurring service billings for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetRecurringServiceBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetRecurringServiceBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get VEWA config details for contract
     * @summary Get VEWA config details for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminGetVewaConfigByContractId(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminGetVewaConfigByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create an initial service billing for this contract by id
     * @summary create an initial service billing for this contract by id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminPostContractInitialServiceBillingById(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminPostContractInitialServiceBillingById(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add default vewaConfig for a given billing scope
     * @summary Add default vewaConfig for a given billing scope
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {VewaConfigCreateRequest} vewaConfigCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminPostVewaConfigForBillingScopeByContractId(contractId: string, vewaConfigCreateRequest: VewaConfigCreateRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminPostVewaConfigForBillingScopeByContractId(contractId, vewaConfigCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Put contract settings for contractConfig for a single medium
     * @summary Put Contract medium settings for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {MediumZevAdminUpdateRequest} mediumZevAdminUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminPutContractMediumByContractId(contractId: string, mediumZevAdminUpdateRequest: MediumZevAdminUpdateRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminPutContractMediumByContractId(contractId, mediumZevAdminUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Release a Contract that\'s in `draft` state
     * @summary Release a Contract that\'s in `draft` state
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminReleaseContractById(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminReleaseContractById(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove billing scope from VEWA config by contractId
     * @summary Remove billing scope from VEWA config by contractId
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {BillingScope} billingScope resolution window of the returned data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminRemoveBillingScopeFromVewaConfig(contractId: string, billingScope: BillingScope, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminRemoveBillingScopeFromVewaConfig(contractId, billingScope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * When changing a product this will create a replacement contract containing the new product
     * @summary Replace a Contract that\'s in `APPROVED` state
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractReplaceAdminRequest} contractReplaceAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminReplaceContractById(contractId: string, contractReplaceAdminRequest: ContractReplaceAdminRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminReplaceContractById(contractId, contractReplaceAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update billing information for a contract
     * @summary Update billing information for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {BillingSettingsUpdateAdminRequest} billingSettingsUpdateAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminUpdateBillingSettingsByContractId(contractId: string, billingSettingsUpdateAdminRequest: BillingSettingsUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminUpdateBillingSettingsByContractId(contractId, billingSettingsUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update list of buildings for a contract
     * @summary Update buildings for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractBuildingsAdminRequest} contractBuildingsAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminUpdateBuildingsByContractId(contractId: string, contractBuildingsAdminRequest: ContractBuildingsAdminRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminUpdateBuildingsByContractId(contractId, contractBuildingsAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * By clicking the edit button in the Contact section this will update an Contact
     * @summary Update contact to contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdateContactAdminRequest} contractUpdateContactAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminUpdateContactByContractId(contractId: string, contractUpdateContactAdminRequest: ContractUpdateContactAdminRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminUpdateContactByContractId(contractId, contractUpdateContactAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update details of a DRAFT Contract, such as Product, Start Date and End Date.
     * @summary Update a Contract by its Id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdateAdminRequest} contractUpdateAdminRequest Contract information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminUpdateContractById(contractId: string, contractUpdateAdminRequest: ContractUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminUpdateContractById(contractId, contractUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a contract\'s service end date by Id
     * @summary Update a contract\'s service end date by Id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractServiceUpdateAdminRequest} contractServiceUpdateAdminRequest A Contract service date change update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminUpdateContractServiceEndById(contractId: string, contractServiceUpdateAdminRequest: ContractServiceUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminUpdateContractServiceEndById(contractId, contractServiceUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update list of managers for a contract
     * @summary Update managers for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractManagersAdminRequest} contractManagersAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminUpdateManagersByContractId(contractId: string, contractManagersAdminRequest: ContractManagersAdminRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminUpdateManagersByContractId(contractId, contractManagersAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update price packages for a contract
     * @summary Update price packages for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {Array<ZevPricePackage>} zevPricePackage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminUpdatePricePackagesByContractId(contractId: string, zevPricePackage: Array<ZevPricePackage>, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminUpdatePricePackagesByContractId(contractId, zevPricePackage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update pricing model for a contract
     * @summary Update pricing model for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {PricingModelRequest} pricingModelRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminUpdatePricingModelByContractId(contractId: string, pricingModelRequest: PricingModelRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminUpdatePricingModelByContractId(contractId, pricingModelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update VEWA config details such as cost shares & group meter config, by contractId.
     * @summary Admin update VEWA config by contractId
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {VewaConfigUpdateRequest} vewaConfigUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public adminUpdateVewaConfig(contractId: string, vewaConfigUpdateRequest: VewaConfigUpdateRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).adminUpdateVewaConfig(contractId, vewaConfigUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exports the participants to a file in CSV format
     * @summary Exports the participants to a file in CSV format
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public getAllParticipantByContractIdCsv(contractId: string, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).getAllParticipantByContractIdCsv(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update a contract\'s comment
     * @summary update a contract\'s comment
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {UpdateCommentRequest} updateCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContractApi
     */
    public updateContractCommentById(contractId: string, updateCommentRequest: UpdateCommentRequest, options?: AxiosRequestConfig) {
        return AdminContractApiFp(this.configuration).updateContractCommentById(contractId, updateCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminIndividualParticipantBillingApi - axios parameter creator
 * @export
 */
export const AdminIndividualParticipantBillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the billing details of an IndividualParticipantBilling
         * @summary Get the billing details of an IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminIndividualParticipantBillingDetailsById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminIndividualParticipantBillingDetailsById', 'id', id)
            const localVarPath = `/api/admin/v1/individualParticipantBilling/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to approved for IndividualParticipantBilling
         * @summary State change to approved for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminIndividualParticipantBillingApproveStateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminIndividualParticipantBillingApproveStateById', 'id', id)
            const localVarPath = `/api/admin/v1/individualParticipantBilling/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to deleted for IndividualParticipantBilling
         * @summary State change to deleted for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminIndividualParticipantBillingDeleteById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminIndividualParticipantBillingDeleteById', 'id', id)
            const localVarPath = `/api/admin/v1/individualParticipantBilling/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to reopened for IndividualParticipantBilling
         * @summary State change to reopened for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminIndividualParticipantBillingReopenStateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminIndividualParticipantBillingReopenStateById', 'id', id)
            const localVarPath = `/api/admin/v1/individualParticipantBilling/{id}/reopen`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to suspended for IndividualParticipantBilling
         * @summary State change to suspended for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminIndividualParticipantBillingSuspendStateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminIndividualParticipantBillingSuspendStateById', 'id', id)
            const localVarPath = `/api/admin/v1/individualParticipantBilling/{id}/suspend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to unsuspended for IndividualParticipantBilling
         * @summary State change to unsuspended for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminIndividualParticipantBillingUnSuspendStateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminIndividualParticipantBillingUnSuspendStateById', 'id', id)
            const localVarPath = `/api/admin/v1/individualParticipantBilling/{id}/unsuspend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send an IndividualParticipantBilling details by SAP
         * @summary Send an IndividualParticipantBilling details by SAP
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sapSendAdminIndividualParticipantBillingById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sapSendAdminIndividualParticipantBillingById', 'id', id)
            const localVarPath = `/api/admin/v1/individualParticipantBilling/{id}/sap-send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the billing prices of an IndividualParticipantBilling
         * @summary Update the billing prices of an IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {IndividualParticipantBillingPricesUpdateRequest} individualParticipantBillingPricesUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminIndividualParticipantBillingPricesById: async (id: string, individualParticipantBillingPricesUpdateRequest: IndividualParticipantBillingPricesUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdminIndividualParticipantBillingPricesById', 'id', id)
            // verify required parameter 'individualParticipantBillingPricesUpdateRequest' is not null or undefined
            assertParamExists('updateAdminIndividualParticipantBillingPricesById', 'individualParticipantBillingPricesUpdateRequest', individualParticipantBillingPricesUpdateRequest)
            const localVarPath = `/api/admin/v1/individualParticipantBilling/{id}/prices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(individualParticipantBillingPricesUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminIndividualParticipantBillingApi - functional programming interface
 * @export
 */
export const AdminIndividualParticipantBillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminIndividualParticipantBillingApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the billing details of an IndividualParticipantBilling
         * @summary Get the billing details of an IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminIndividualParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndividualParticipantBillingDetailsAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminIndividualParticipantBillingDetailsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to approved for IndividualParticipantBilling
         * @summary State change to approved for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminIndividualParticipantBillingApproveStateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminIndividualParticipantBillingApproveStateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to deleted for IndividualParticipantBilling
         * @summary State change to deleted for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminIndividualParticipantBillingDeleteById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminIndividualParticipantBillingDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to reopened for IndividualParticipantBilling
         * @summary State change to reopened for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminIndividualParticipantBillingReopenStateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminIndividualParticipantBillingReopenStateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to suspended for IndividualParticipantBilling
         * @summary State change to suspended for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminIndividualParticipantBillingSuspendStateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminIndividualParticipantBillingSuspendStateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to unsuspended for IndividualParticipantBilling
         * @summary State change to unsuspended for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminIndividualParticipantBillingUnSuspendStateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminIndividualParticipantBillingUnSuspendStateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send an IndividualParticipantBilling details by SAP
         * @summary Send an IndividualParticipantBilling details by SAP
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sapSendAdminIndividualParticipantBillingById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sapSendAdminIndividualParticipantBillingById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the billing prices of an IndividualParticipantBilling
         * @summary Update the billing prices of an IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {IndividualParticipantBillingPricesUpdateRequest} individualParticipantBillingPricesUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminIndividualParticipantBillingPricesById(id: string, individualParticipantBillingPricesUpdateRequest: IndividualParticipantBillingPricesUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminIndividualParticipantBillingPricesById(id, individualParticipantBillingPricesUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminIndividualParticipantBillingApi - factory interface
 * @export
 */
export const AdminIndividualParticipantBillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminIndividualParticipantBillingApiFp(configuration)
    return {
        /**
         * Get the billing details of an IndividualParticipantBilling
         * @summary Get the billing details of an IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminIndividualParticipantBillingDetailsById(id: string, options?: any): AxiosPromise<IndividualParticipantBillingDetailsAdminResponse> {
            return localVarFp.getAdminIndividualParticipantBillingDetailsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to approved for IndividualParticipantBilling
         * @summary State change to approved for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminIndividualParticipantBillingApproveStateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putAdminIndividualParticipantBillingApproveStateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to deleted for IndividualParticipantBilling
         * @summary State change to deleted for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminIndividualParticipantBillingDeleteById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putAdminIndividualParticipantBillingDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to reopened for IndividualParticipantBilling
         * @summary State change to reopened for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminIndividualParticipantBillingReopenStateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putAdminIndividualParticipantBillingReopenStateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to suspended for IndividualParticipantBilling
         * @summary State change to suspended for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminIndividualParticipantBillingSuspendStateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putAdminIndividualParticipantBillingSuspendStateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to unsuspended for IndividualParticipantBilling
         * @summary State change to unsuspended for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminIndividualParticipantBillingUnSuspendStateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putAdminIndividualParticipantBillingUnSuspendStateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Send an IndividualParticipantBilling details by SAP
         * @summary Send an IndividualParticipantBilling details by SAP
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sapSendAdminIndividualParticipantBillingById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.sapSendAdminIndividualParticipantBillingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the billing prices of an IndividualParticipantBilling
         * @summary Update the billing prices of an IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {IndividualParticipantBillingPricesUpdateRequest} individualParticipantBillingPricesUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminIndividualParticipantBillingPricesById(id: string, individualParticipantBillingPricesUpdateRequest: IndividualParticipantBillingPricesUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAdminIndividualParticipantBillingPricesById(id, individualParticipantBillingPricesUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminIndividualParticipantBillingApi - interface
 * @export
 * @interface AdminIndividualParticipantBillingApi
 */
export interface AdminIndividualParticipantBillingApiInterface {
    /**
     * Get the billing details of an IndividualParticipantBilling
     * @summary Get the billing details of an IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApiInterface
     */
    getAdminIndividualParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig): AxiosPromise<IndividualParticipantBillingDetailsAdminResponse>;

    /**
     * State change to approved for IndividualParticipantBilling
     * @summary State change to approved for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApiInterface
     */
    putAdminIndividualParticipantBillingApproveStateById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * State change to deleted for IndividualParticipantBilling
     * @summary State change to deleted for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApiInterface
     */
    putAdminIndividualParticipantBillingDeleteById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * State change to reopened for IndividualParticipantBilling
     * @summary State change to reopened for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApiInterface
     */
    putAdminIndividualParticipantBillingReopenStateById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * State change to suspended for IndividualParticipantBilling
     * @summary State change to suspended for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApiInterface
     */
    putAdminIndividualParticipantBillingSuspendStateById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * State change to unsuspended for IndividualParticipantBilling
     * @summary State change to unsuspended for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApiInterface
     */
    putAdminIndividualParticipantBillingUnSuspendStateById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Send an IndividualParticipantBilling details by SAP
     * @summary Send an IndividualParticipantBilling details by SAP
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApiInterface
     */
    sapSendAdminIndividualParticipantBillingById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update the billing prices of an IndividualParticipantBilling
     * @summary Update the billing prices of an IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {IndividualParticipantBillingPricesUpdateRequest} individualParticipantBillingPricesUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApiInterface
     */
    updateAdminIndividualParticipantBillingPricesById(id: string, individualParticipantBillingPricesUpdateRequest: IndividualParticipantBillingPricesUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminIndividualParticipantBillingApi - object-oriented interface
 * @export
 * @class AdminIndividualParticipantBillingApi
 * @extends {BaseAPI}
 */
export class AdminIndividualParticipantBillingApi extends BaseAPI implements AdminIndividualParticipantBillingApiInterface {
    /**
     * Get the billing details of an IndividualParticipantBilling
     * @summary Get the billing details of an IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApi
     */
    public getAdminIndividualParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig) {
        return AdminIndividualParticipantBillingApiFp(this.configuration).getAdminIndividualParticipantBillingDetailsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to approved for IndividualParticipantBilling
     * @summary State change to approved for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApi
     */
    public putAdminIndividualParticipantBillingApproveStateById(id: string, options?: AxiosRequestConfig) {
        return AdminIndividualParticipantBillingApiFp(this.configuration).putAdminIndividualParticipantBillingApproveStateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to deleted for IndividualParticipantBilling
     * @summary State change to deleted for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApi
     */
    public putAdminIndividualParticipantBillingDeleteById(id: string, options?: AxiosRequestConfig) {
        return AdminIndividualParticipantBillingApiFp(this.configuration).putAdminIndividualParticipantBillingDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to reopened for IndividualParticipantBilling
     * @summary State change to reopened for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApi
     */
    public putAdminIndividualParticipantBillingReopenStateById(id: string, options?: AxiosRequestConfig) {
        return AdminIndividualParticipantBillingApiFp(this.configuration).putAdminIndividualParticipantBillingReopenStateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to suspended for IndividualParticipantBilling
     * @summary State change to suspended for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApi
     */
    public putAdminIndividualParticipantBillingSuspendStateById(id: string, options?: AxiosRequestConfig) {
        return AdminIndividualParticipantBillingApiFp(this.configuration).putAdminIndividualParticipantBillingSuspendStateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to unsuspended for IndividualParticipantBilling
     * @summary State change to unsuspended for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApi
     */
    public putAdminIndividualParticipantBillingUnSuspendStateById(id: string, options?: AxiosRequestConfig) {
        return AdminIndividualParticipantBillingApiFp(this.configuration).putAdminIndividualParticipantBillingUnSuspendStateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send an IndividualParticipantBilling details by SAP
     * @summary Send an IndividualParticipantBilling details by SAP
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApi
     */
    public sapSendAdminIndividualParticipantBillingById(id: string, options?: AxiosRequestConfig) {
        return AdminIndividualParticipantBillingApiFp(this.configuration).sapSendAdminIndividualParticipantBillingById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the billing prices of an IndividualParticipantBilling
     * @summary Update the billing prices of an IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {IndividualParticipantBillingPricesUpdateRequest} individualParticipantBillingPricesUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIndividualParticipantBillingApi
     */
    public updateAdminIndividualParticipantBillingPricesById(id: string, individualParticipantBillingPricesUpdateRequest: IndividualParticipantBillingPricesUpdateRequest, options?: AxiosRequestConfig) {
        return AdminIndividualParticipantBillingApiFp(this.configuration).updateAdminIndividualParticipantBillingPricesById(id, individualParticipantBillingPricesUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminMeterApi - axios parameter creator
 * @export
 */
export const AdminMeterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a Meter and link it to the Building
         * @summary Create a Meter for building with the provided details
         * @param {string} buildingId UUID of the Building
         * @param {BuildingCreateMeterAdminRequest} buildingCreateMeterAdminRequest A Meter admin create request for building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateMeterForBuilding: async (buildingId: string, buildingCreateMeterAdminRequest: BuildingCreateMeterAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('adminCreateMeterForBuilding', 'buildingId', buildingId)
            // verify required parameter 'buildingCreateMeterAdminRequest' is not null or undefined
            assertParamExists('adminCreateMeterForBuilding', 'buildingCreateMeterAdminRequest', buildingCreateMeterAdminRequest)
            const localVarPath = `/api/admin/v1/buildings/{buildingId}/meters`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingCreateMeterAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Meter and link it to the Utility Unit
         * @summary Create a Meter with the provided details
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {MeterCreateAdminRequest} meterCreateAdminRequest A Meter admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateMeterForUtilityUnit: async (utilityUnitId: string, meterCreateAdminRequest: MeterCreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminCreateMeterForUtilityUnit', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'meterCreateAdminRequest' is not null or undefined
            assertParamExists('adminCreateMeterForUtilityUnit', 'meterCreateAdminRequest', meterCreateAdminRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/meters`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meterCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Meter can be deleted by its respective UUID
         * @summary Delete a Meter by its ID
         * @param {string} meterId uuid of the Meter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteMeterById: async (meterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meterId' is not null or undefined
            assertParamExists('adminDeleteMeterById', 'meterId', meterId)
            const localVarPath = `/api/admin/v1/meters/{meterId}`
                .replace(`{${"meterId"}}`, encodeURIComponent(String(meterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Meter can be fetched by their respective UUID
         * @summary Get a Meter by its ID
         * @param {string} meterId uuid of the Meter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterById: async (meterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meterId' is not null or undefined
            assertParamExists('adminGetMeterById', 'meterId', meterId)
            const localVarPath = `/api/admin/v1/meters/{meterId}`
                .replace(`{${"meterId"}}`, encodeURIComponent(String(meterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Meters, with some possible slicing and dicing
         * @summary Get all Meters
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeters: async (page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/meters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Meter can be updated by its respective UUID
         * @summary Put a Meter by its ID
         * @param {string} meterId uuid of the Meter
         * @param {MeterUpdateAdminRequest} meterUpdateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPutMeterById: async (meterId: string, meterUpdateAdminRequest: MeterUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meterId' is not null or undefined
            assertParamExists('adminPutMeterById', 'meterId', meterId)
            // verify required parameter 'meterUpdateAdminRequest' is not null or undefined
            assertParamExists('adminPutMeterById', 'meterUpdateAdminRequest', meterUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/meters/{meterId}`
                .replace(`{${"meterId"}}`, encodeURIComponent(String(meterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meterUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminMeterApi - functional programming interface
 * @export
 */
export const AdminMeterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminMeterApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a Meter and link it to the Building
         * @summary Create a Meter for building with the provided details
         * @param {string} buildingId UUID of the Building
         * @param {BuildingCreateMeterAdminRequest} buildingCreateMeterAdminRequest A Meter admin create request for building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateMeterForBuilding(buildingId: string, buildingCreateMeterAdminRequest: BuildingCreateMeterAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateMeterForBuilding(buildingId, buildingCreateMeterAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Meter and link it to the Utility Unit
         * @summary Create a Meter with the provided details
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {MeterCreateAdminRequest} meterCreateAdminRequest A Meter admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateMeterForUtilityUnit(utilityUnitId: string, meterCreateAdminRequest: MeterCreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateMeterForUtilityUnit(utilityUnitId, meterCreateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Meter can be deleted by its respective UUID
         * @summary Delete a Meter by its ID
         * @param {string} meterId uuid of the Meter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteMeterById(meterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteMeterById(meterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Meter can be fetched by their respective UUID
         * @summary Get a Meter by its ID
         * @param {string} meterId uuid of the Meter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMeterById(meterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMeterById(meterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Meters, with some possible slicing and dicing
         * @summary Get all Meters
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMeters(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMeterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMeters(page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Meter can be updated by its respective UUID
         * @summary Put a Meter by its ID
         * @param {string} meterId uuid of the Meter
         * @param {MeterUpdateAdminRequest} meterUpdateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPutMeterById(meterId: string, meterUpdateAdminRequest: MeterUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPutMeterById(meterId, meterUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminMeterApi - factory interface
 * @export
 */
export const AdminMeterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminMeterApiFp(configuration)
    return {
        /**
         * Create a Meter and link it to the Building
         * @summary Create a Meter for building with the provided details
         * @param {string} buildingId UUID of the Building
         * @param {BuildingCreateMeterAdminRequest} buildingCreateMeterAdminRequest A Meter admin create request for building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateMeterForBuilding(buildingId: string, buildingCreateMeterAdminRequest: BuildingCreateMeterAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateMeterForBuilding(buildingId, buildingCreateMeterAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Meter and link it to the Utility Unit
         * @summary Create a Meter with the provided details
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {MeterCreateAdminRequest} meterCreateAdminRequest A Meter admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateMeterForUtilityUnit(utilityUnitId: string, meterCreateAdminRequest: MeterCreateAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateMeterForUtilityUnit(utilityUnitId, meterCreateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Meter can be deleted by its respective UUID
         * @summary Delete a Meter by its ID
         * @param {string} meterId uuid of the Meter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteMeterById(meterId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteMeterById(meterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Meter can be fetched by their respective UUID
         * @summary Get a Meter by its ID
         * @param {string} meterId uuid of the Meter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterById(meterId: string, options?: any): AxiosPromise<MeterResponse> {
            return localVarFp.adminGetMeterById(meterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Meters, with some possible slicing and dicing
         * @summary Get all Meters
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeters(page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedMeterResponse> {
            return localVarFp.adminGetMeters(page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Meter can be updated by its respective UUID
         * @summary Put a Meter by its ID
         * @param {string} meterId uuid of the Meter
         * @param {MeterUpdateAdminRequest} meterUpdateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPutMeterById(meterId: string, meterUpdateAdminRequest: MeterUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminPutMeterById(meterId, meterUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminMeterApi - interface
 * @export
 * @interface AdminMeterApi
 */
export interface AdminMeterApiInterface {
    /**
     * Create a Meter and link it to the Building
     * @summary Create a Meter for building with the provided details
     * @param {string} buildingId UUID of the Building
     * @param {BuildingCreateMeterAdminRequest} buildingCreateMeterAdminRequest A Meter admin create request for building
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterApiInterface
     */
    adminCreateMeterForBuilding(buildingId: string, buildingCreateMeterAdminRequest: BuildingCreateMeterAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Create a Meter and link it to the Utility Unit
     * @summary Create a Meter with the provided details
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {MeterCreateAdminRequest} meterCreateAdminRequest A Meter admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterApiInterface
     */
    adminCreateMeterForUtilityUnit(utilityUnitId: string, meterCreateAdminRequest: MeterCreateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Meter can be deleted by its respective UUID
     * @summary Delete a Meter by its ID
     * @param {string} meterId uuid of the Meter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterApiInterface
     */
    adminDeleteMeterById(meterId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Meter can be fetched by their respective UUID
     * @summary Get a Meter by its ID
     * @param {string} meterId uuid of the Meter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterApiInterface
     */
    adminGetMeterById(meterId: string, options?: AxiosRequestConfig): AxiosPromise<MeterResponse>;

    /**
     * Get all Meters, with some possible slicing and dicing
     * @summary Get all Meters
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterApiInterface
     */
    adminGetMeters(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedMeterResponse>;

    /**
     * Meter can be updated by its respective UUID
     * @summary Put a Meter by its ID
     * @param {string} meterId uuid of the Meter
     * @param {MeterUpdateAdminRequest} meterUpdateAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterApiInterface
     */
    adminPutMeterById(meterId: string, meterUpdateAdminRequest: MeterUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminMeterApi - object-oriented interface
 * @export
 * @class AdminMeterApi
 * @extends {BaseAPI}
 */
export class AdminMeterApi extends BaseAPI implements AdminMeterApiInterface {
    /**
     * Create a Meter and link it to the Building
     * @summary Create a Meter for building with the provided details
     * @param {string} buildingId UUID of the Building
     * @param {BuildingCreateMeterAdminRequest} buildingCreateMeterAdminRequest A Meter admin create request for building
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterApi
     */
    public adminCreateMeterForBuilding(buildingId: string, buildingCreateMeterAdminRequest: BuildingCreateMeterAdminRequest, options?: AxiosRequestConfig) {
        return AdminMeterApiFp(this.configuration).adminCreateMeterForBuilding(buildingId, buildingCreateMeterAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Meter and link it to the Utility Unit
     * @summary Create a Meter with the provided details
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {MeterCreateAdminRequest} meterCreateAdminRequest A Meter admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterApi
     */
    public adminCreateMeterForUtilityUnit(utilityUnitId: string, meterCreateAdminRequest: MeterCreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminMeterApiFp(this.configuration).adminCreateMeterForUtilityUnit(utilityUnitId, meterCreateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Meter can be deleted by its respective UUID
     * @summary Delete a Meter by its ID
     * @param {string} meterId uuid of the Meter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterApi
     */
    public adminDeleteMeterById(meterId: string, options?: AxiosRequestConfig) {
        return AdminMeterApiFp(this.configuration).adminDeleteMeterById(meterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Meter can be fetched by their respective UUID
     * @summary Get a Meter by its ID
     * @param {string} meterId uuid of the Meter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterApi
     */
    public adminGetMeterById(meterId: string, options?: AxiosRequestConfig) {
        return AdminMeterApiFp(this.configuration).adminGetMeterById(meterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Meters, with some possible slicing and dicing
     * @summary Get all Meters
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterApi
     */
    public adminGetMeters(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminMeterApiFp(this.configuration).adminGetMeters(page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Meter can be updated by its respective UUID
     * @summary Put a Meter by its ID
     * @param {string} meterId uuid of the Meter
     * @param {MeterUpdateAdminRequest} meterUpdateAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterApi
     */
    public adminPutMeterById(meterId: string, meterUpdateAdminRequest: MeterUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminMeterApiFp(this.configuration).adminPutMeterById(meterId, meterUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminMeterReadingsApi - axios parameter creator
 * @export
 */
export const AdminMeterReadingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Provides calculated meter-readings for a all related contracts (including all predecessors and successors) divided according to the provided time resolution
         * @summary Get meter-readings for a specific contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingByContractId: async (contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetMeterReadingByContractId', 'contractId', contractId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('adminGetMeterReadingByContractId', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('adminGetMeterReadingByContractId', 'endDate', endDate)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('adminGetMeterReadingByContractId', 'resolution', resolution)
            const localVarPath = `/api/admin/v1/meter-readings/contract/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides calculated meter-readings for a utilityUnitId over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific utilityUnitId by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsByUtilityUnitId: async (utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminGetMeterReadingsByUtilityUnitId', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsByUtilityUnitId', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsByUtilityUnitId', 'endDate', endDate)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('adminGetMeterReadingsByUtilityUnitId', 'resolution', resolution)
            const localVarPath = `/api/admin/v1/meter-readings/utilityUnits/{utilityUnitId}`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }

            if (isFinalOnly !== undefined) {
                localVarQueryParameter['isFinalOnly'] = isFinalOnly;
            }

            if (contractType !== undefined) {
                localVarQueryParameter['contractType'] = contractType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides cumulative consumption that increases over a given time interval with 15 MIN resolution
         * @summary Get meter-readings for a list of consumption point ids
         * @param {Array<string>} meteringCodes list of metering codes
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsCumulative: async (meteringCodes: Array<string>, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meteringCodes' is not null or undefined
            assertParamExists('adminGetMeterReadingsCumulative', 'meteringCodes', meteringCodes)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsCumulative', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsCumulative', 'endDate', endDate)
            const localVarPath = `/api/admin/v1/meter-readings/cumulative`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (meteringCodes) {
                localVarQueryParameter['meteringCodes'] = meteringCodes.join(COLLECTION_FORMATS.csv);
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides calculated meter-readings inspection for a meter over a time period divided according to the provided time resolution
         * @summary Get meter-readings inspection for a specific meter by metering code
         * @param {string} meteringCode metering code of Meter
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsInspectionByMeteringCode: async (meteringCode: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meteringCode' is not null or undefined
            assertParamExists('adminGetMeterReadingsInspectionByMeteringCode', 'meteringCode', meteringCode)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsInspectionByMeteringCode', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsInspectionByMeteringCode', 'endDate', endDate)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('adminGetMeterReadingsInspectionByMeteringCode', 'resolution', resolution)
            const localVarPath = `/api/admin/v1/meter-readings/meters/{meteringCode}/inspection`
                .replace(`{${"meteringCode"}}`, encodeURIComponent(String(meteringCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }

            if (isFinalOnly !== undefined) {
                localVarQueryParameter['isFinalOnly'] = isFinalOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides the volume consumption over a given time interval with 15 MIN time resolution
         * @summary Get meter-readings for a list of consumption point ids
         * @param {Array<string>} meteringCodes list of metering codes
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsSimple: async (meteringCodes: Array<string>, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meteringCodes' is not null or undefined
            assertParamExists('adminGetMeterReadingsSimple', 'meteringCodes', meteringCodes)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsSimple', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsSimple', 'endDate', endDate)
            const localVarPath = `/api/admin/v1/meter-readings/simple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (meteringCodes) {
                localVarQueryParameter['meteringCodes'] = meteringCodes.join(COLLECTION_FORMATS.csv);
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides the total number of data points in given interval for a consumption point id
         * @summary Get meter-readings data point count for a consumption point id
         * @param {Array<string>} meteringCodes list of metering codes
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {string} inputZone the input time zone
         * @param {boolean} [isMonthlyCorrected] whether to count corrected or non-corrected data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsSimpleCount: async (meteringCodes: Array<string>, startDate: string, endDate: string, inputZone: string, isMonthlyCorrected?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meteringCodes' is not null or undefined
            assertParamExists('adminGetMeterReadingsSimpleCount', 'meteringCodes', meteringCodes)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsSimpleCount', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsSimpleCount', 'endDate', endDate)
            // verify required parameter 'inputZone' is not null or undefined
            assertParamExists('adminGetMeterReadingsSimpleCount', 'inputZone', inputZone)
            const localVarPath = `/api/admin/v1/meter-readings/simpleCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (meteringCodes) {
                localVarQueryParameter['meteringCodes'] = meteringCodes.join(COLLECTION_FORMATS.csv);
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (isMonthlyCorrected !== undefined) {
                localVarQueryParameter['isMonthlyCorrected'] = isMonthlyCorrected;
            }

            if (inputZone !== undefined) {
                localVarQueryParameter['inputZone'] = inputZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides the total volume consumption (sum) over a given time interval
         * @summary Get meter-readings for a list of consumption point ids
         * @param {Array<string>} meteringCodes list of metering codes
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {boolean} [onlyFinal] flag that indicates should all data be queried or only final
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsTotal: async (meteringCodes: Array<string>, startDate: string, endDate: string, onlyFinal?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meteringCodes' is not null or undefined
            assertParamExists('adminGetMeterReadingsTotal', 'meteringCodes', meteringCodes)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsTotal', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsTotal', 'endDate', endDate)
            const localVarPath = `/api/admin/v1/meter-readings/total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (meteringCodes) {
                localVarQueryParameter['meteringCodes'] = meteringCodes.join(COLLECTION_FORMATS.csv);
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (onlyFinal !== undefined) {
                localVarQueryParameter['onlyFinal'] = onlyFinal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminMeterReadingsApi - functional programming interface
 * @export
 */
export const AdminMeterReadingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminMeterReadingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Provides calculated meter-readings for a all related contracts (including all predecessors and successors) divided according to the provided time resolution
         * @summary Get meter-readings for a specific contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMeterReadingByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadingsContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMeterReadingByContractId(contractId, startDate, endDate, resolution, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides calculated meter-readings for a utilityUnitId over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific utilityUnitId by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadingsUtilityUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMeterReadingsByUtilityUnitId(utilityUnitId, startDate, endDate, resolution, isFinalOnly, contractType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides cumulative consumption that increases over a given time interval with 15 MIN resolution
         * @summary Get meter-readings for a list of consumption point ids
         * @param {Array<string>} meteringCodes list of metering codes
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMeterReadingsCumulative(meteringCodes: Array<string>, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadingsCumulativeAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMeterReadingsCumulative(meteringCodes, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides calculated meter-readings inspection for a meter over a time period divided according to the provided time resolution
         * @summary Get meter-readings inspection for a specific meter by metering code
         * @param {string} meteringCode metering code of Meter
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMeterReadingsInspectionByMeteringCode(meteringCode: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterDataCalcCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMeterReadingsInspectionByMeteringCode(meteringCode, startDate, endDate, resolution, isFinalOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides the volume consumption over a given time interval with 15 MIN time resolution
         * @summary Get meter-readings for a list of consumption point ids
         * @param {Array<string>} meteringCodes list of metering codes
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMeterReadingsSimple(meteringCodes: Array<string>, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadingsSimpleAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMeterReadingsSimple(meteringCodes, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides the total number of data points in given interval for a consumption point id
         * @summary Get meter-readings data point count for a consumption point id
         * @param {Array<string>} meteringCodes list of metering codes
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {string} inputZone the input time zone
         * @param {boolean} [isMonthlyCorrected] whether to count corrected or non-corrected data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMeterReadingsSimpleCount(meteringCodes: Array<string>, startDate: string, endDate: string, inputZone: string, isMonthlyCorrected?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadingsSimpleCountAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMeterReadingsSimpleCount(meteringCodes, startDate, endDate, inputZone, isMonthlyCorrected, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides the total volume consumption (sum) over a given time interval
         * @summary Get meter-readings for a list of consumption point ids
         * @param {Array<string>} meteringCodes list of metering codes
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {boolean} [onlyFinal] flag that indicates should all data be queried or only final
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMeterReadingsTotal(meteringCodes: Array<string>, startDate: string, endDate: string, onlyFinal?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadingsTotalAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMeterReadingsTotal(meteringCodes, startDate, endDate, onlyFinal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminMeterReadingsApi - factory interface
 * @export
 */
export const AdminMeterReadingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminMeterReadingsApiFp(configuration)
    return {
        /**
         * Provides calculated meter-readings for a all related contracts (including all predecessors and successors) divided according to the provided time resolution
         * @summary Get meter-readings for a specific contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: any): AxiosPromise<MeterReadingsContractResponse> {
            return localVarFp.adminGetMeterReadingByContractId(contractId, startDate, endDate, resolution, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides calculated meter-readings for a utilityUnitId over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific utilityUnitId by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: any): AxiosPromise<MeterReadingsUtilityUnitResponse> {
            return localVarFp.adminGetMeterReadingsByUtilityUnitId(utilityUnitId, startDate, endDate, resolution, isFinalOnly, contractType, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides cumulative consumption that increases over a given time interval with 15 MIN resolution
         * @summary Get meter-readings for a list of consumption point ids
         * @param {Array<string>} meteringCodes list of metering codes
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsCumulative(meteringCodes: Array<string>, startDate: string, endDate: string, options?: any): AxiosPromise<MeterReadingsCumulativeAdminResponse> {
            return localVarFp.adminGetMeterReadingsCumulative(meteringCodes, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides calculated meter-readings inspection for a meter over a time period divided according to the provided time resolution
         * @summary Get meter-readings inspection for a specific meter by metering code
         * @param {string} meteringCode metering code of Meter
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsInspectionByMeteringCode(meteringCode: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, options?: any): AxiosPromise<MeterDataCalcCountResponse> {
            return localVarFp.adminGetMeterReadingsInspectionByMeteringCode(meteringCode, startDate, endDate, resolution, isFinalOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides the volume consumption over a given time interval with 15 MIN time resolution
         * @summary Get meter-readings for a list of consumption point ids
         * @param {Array<string>} meteringCodes list of metering codes
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsSimple(meteringCodes: Array<string>, startDate: string, endDate: string, options?: any): AxiosPromise<MeterReadingsSimpleAdminResponse> {
            return localVarFp.adminGetMeterReadingsSimple(meteringCodes, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides the total number of data points in given interval for a consumption point id
         * @summary Get meter-readings data point count for a consumption point id
         * @param {Array<string>} meteringCodes list of metering codes
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {string} inputZone the input time zone
         * @param {boolean} [isMonthlyCorrected] whether to count corrected or non-corrected data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsSimpleCount(meteringCodes: Array<string>, startDate: string, endDate: string, inputZone: string, isMonthlyCorrected?: boolean, options?: any): AxiosPromise<MeterReadingsSimpleCountAdminResponse> {
            return localVarFp.adminGetMeterReadingsSimpleCount(meteringCodes, startDate, endDate, inputZone, isMonthlyCorrected, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides the total volume consumption (sum) over a given time interval
         * @summary Get meter-readings for a list of consumption point ids
         * @param {Array<string>} meteringCodes list of metering codes
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {boolean} [onlyFinal] flag that indicates should all data be queried or only final
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsTotal(meteringCodes: Array<string>, startDate: string, endDate: string, onlyFinal?: boolean, options?: any): AxiosPromise<MeterReadingsTotalAdminResponse> {
            return localVarFp.adminGetMeterReadingsTotal(meteringCodes, startDate, endDate, onlyFinal, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminMeterReadingsApi - interface
 * @export
 * @interface AdminMeterReadingsApi
 */
export interface AdminMeterReadingsApiInterface {
    /**
     * Provides calculated meter-readings for a all related contracts (including all predecessors and successors) divided according to the provided time resolution
     * @summary Get meter-readings for a specific contract by id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApiInterface
     */
    adminGetMeterReadingByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: AxiosRequestConfig): AxiosPromise<MeterReadingsContractResponse>;

    /**
     * Provides calculated meter-readings for a utilityUnitId over a time period divided according to the provided time resolution
     * @summary Get meter-readings for a specific utilityUnitId by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
     * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApiInterface
     */
    adminGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: AxiosRequestConfig): AxiosPromise<MeterReadingsUtilityUnitResponse>;

    /**
     * Provides cumulative consumption that increases over a given time interval with 15 MIN resolution
     * @summary Get meter-readings for a list of consumption point ids
     * @param {Array<string>} meteringCodes list of metering codes
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApiInterface
     */
    adminGetMeterReadingsCumulative(meteringCodes: Array<string>, startDate: string, endDate: string, options?: AxiosRequestConfig): AxiosPromise<MeterReadingsCumulativeAdminResponse>;

    /**
     * Provides calculated meter-readings inspection for a meter over a time period divided according to the provided time resolution
     * @summary Get meter-readings inspection for a specific meter by metering code
     * @param {string} meteringCode metering code of Meter
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApiInterface
     */
    adminGetMeterReadingsInspectionByMeteringCode(meteringCode: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, options?: AxiosRequestConfig): AxiosPromise<MeterDataCalcCountResponse>;

    /**
     * Provides the volume consumption over a given time interval with 15 MIN time resolution
     * @summary Get meter-readings for a list of consumption point ids
     * @param {Array<string>} meteringCodes list of metering codes
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApiInterface
     */
    adminGetMeterReadingsSimple(meteringCodes: Array<string>, startDate: string, endDate: string, options?: AxiosRequestConfig): AxiosPromise<MeterReadingsSimpleAdminResponse>;

    /**
     * Provides the total number of data points in given interval for a consumption point id
     * @summary Get meter-readings data point count for a consumption point id
     * @param {Array<string>} meteringCodes list of metering codes
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {string} inputZone the input time zone
     * @param {boolean} [isMonthlyCorrected] whether to count corrected or non-corrected data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApiInterface
     */
    adminGetMeterReadingsSimpleCount(meteringCodes: Array<string>, startDate: string, endDate: string, inputZone: string, isMonthlyCorrected?: boolean, options?: AxiosRequestConfig): AxiosPromise<MeterReadingsSimpleCountAdminResponse>;

    /**
     * Provides the total volume consumption (sum) over a given time interval
     * @summary Get meter-readings for a list of consumption point ids
     * @param {Array<string>} meteringCodes list of metering codes
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {boolean} [onlyFinal] flag that indicates should all data be queried or only final
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApiInterface
     */
    adminGetMeterReadingsTotal(meteringCodes: Array<string>, startDate: string, endDate: string, onlyFinal?: boolean, options?: AxiosRequestConfig): AxiosPromise<MeterReadingsTotalAdminResponse>;

}

/**
 * AdminMeterReadingsApi - object-oriented interface
 * @export
 * @class AdminMeterReadingsApi
 * @extends {BaseAPI}
 */
export class AdminMeterReadingsApi extends BaseAPI implements AdminMeterReadingsApiInterface {
    /**
     * Provides calculated meter-readings for a all related contracts (including all predecessors and successors) divided according to the provided time resolution
     * @summary Get meter-readings for a specific contract by id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApi
     */
    public adminGetMeterReadingByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: AxiosRequestConfig) {
        return AdminMeterReadingsApiFp(this.configuration).adminGetMeterReadingByContractId(contractId, startDate, endDate, resolution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides calculated meter-readings for a utilityUnitId over a time period divided according to the provided time resolution
     * @summary Get meter-readings for a specific utilityUnitId by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
     * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApi
     */
    public adminGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: AxiosRequestConfig) {
        return AdminMeterReadingsApiFp(this.configuration).adminGetMeterReadingsByUtilityUnitId(utilityUnitId, startDate, endDate, resolution, isFinalOnly, contractType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides cumulative consumption that increases over a given time interval with 15 MIN resolution
     * @summary Get meter-readings for a list of consumption point ids
     * @param {Array<string>} meteringCodes list of metering codes
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApi
     */
    public adminGetMeterReadingsCumulative(meteringCodes: Array<string>, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return AdminMeterReadingsApiFp(this.configuration).adminGetMeterReadingsCumulative(meteringCodes, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides calculated meter-readings inspection for a meter over a time period divided according to the provided time resolution
     * @summary Get meter-readings inspection for a specific meter by metering code
     * @param {string} meteringCode metering code of Meter
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApi
     */
    public adminGetMeterReadingsInspectionByMeteringCode(meteringCode: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, options?: AxiosRequestConfig) {
        return AdminMeterReadingsApiFp(this.configuration).adminGetMeterReadingsInspectionByMeteringCode(meteringCode, startDate, endDate, resolution, isFinalOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides the volume consumption over a given time interval with 15 MIN time resolution
     * @summary Get meter-readings for a list of consumption point ids
     * @param {Array<string>} meteringCodes list of metering codes
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApi
     */
    public adminGetMeterReadingsSimple(meteringCodes: Array<string>, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return AdminMeterReadingsApiFp(this.configuration).adminGetMeterReadingsSimple(meteringCodes, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides the total number of data points in given interval for a consumption point id
     * @summary Get meter-readings data point count for a consumption point id
     * @param {Array<string>} meteringCodes list of metering codes
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {string} inputZone the input time zone
     * @param {boolean} [isMonthlyCorrected] whether to count corrected or non-corrected data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApi
     */
    public adminGetMeterReadingsSimpleCount(meteringCodes: Array<string>, startDate: string, endDate: string, inputZone: string, isMonthlyCorrected?: boolean, options?: AxiosRequestConfig) {
        return AdminMeterReadingsApiFp(this.configuration).adminGetMeterReadingsSimpleCount(meteringCodes, startDate, endDate, inputZone, isMonthlyCorrected, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides the total volume consumption (sum) over a given time interval
     * @summary Get meter-readings for a list of consumption point ids
     * @param {Array<string>} meteringCodes list of metering codes
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {boolean} [onlyFinal] flag that indicates should all data be queried or only final
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMeterReadingsApi
     */
    public adminGetMeterReadingsTotal(meteringCodes: Array<string>, startDate: string, endDate: string, onlyFinal?: boolean, options?: AxiosRequestConfig) {
        return AdminMeterReadingsApiFp(this.configuration).adminGetMeterReadingsTotal(meteringCodes, startDate, endDate, onlyFinal, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminParticipantsApi - axios parameter creator
 * @export
 */
export const AdminParticipantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Confirm the SAP synchronisation
         * @summary Confirm the SAP synchronisation
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminConfirmSapSyncParticipantById: async (participantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('adminConfirmSapSyncParticipantById', 'participantId', participantId)
            const localVarPath = `/api/admin/v1/participants/{participantId}/confirm-sap-sync`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a participants in an Area
         * @summary Create a Area participant
         * @param {string} areaId uuid of the Area
         * @param {ParticipantAdminCreateRequest} participantAdminCreateRequest A Participant create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAreaParticipant: async (areaId: string, participantAdminCreateRequest: ParticipantAdminCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('adminCreateAreaParticipant', 'areaId', areaId)
            // verify required parameter 'participantAdminCreateRequest' is not null or undefined
            assertParamExists('adminCreateAreaParticipant', 'participantAdminCreateRequest', participantAdminCreateRequest)
            const localVarPath = `/api/admin/v1/areas/{areaId}/participants`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participantAdminCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete participant by ID
         * @summary Delete a participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteParticipantById: async (participantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('adminDeleteParticipantById', 'participantId', participantId)
            const localVarPath = `/api/admin/v1/participants/{participantId}`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all participants in bulk by ids
         * @summary Fetch all participants in bulk by ids
         * @param {ParticipantsBulkRequest} participantsBulkRequest A participant bulk fetch request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParticipantBulkByIds: async (participantsBulkRequest: ParticipantsBulkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantsBulkRequest' is not null or undefined
            assertParamExists('adminGetParticipantBulkByIds', 'participantsBulkRequest', participantsBulkRequest)
            const localVarPath = `/api/admin/v1/participants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participantsBulkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a participant by ID
         * @summary Get a participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParticipantById: async (participantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('adminGetParticipantById', 'participantId', participantId)
            const localVarPath = `/api/admin/v1/participants/{participantId}`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Participant by ID
         * @summary Update a Participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {ParticipantAdminUpdateRequest} participantAdminUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateParticipantById: async (participantId: string, participantAdminUpdateRequest: ParticipantAdminUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('adminUpdateParticipantById', 'participantId', participantId)
            // verify required parameter 'participantAdminUpdateRequest' is not null or undefined
            assertParamExists('adminUpdateParticipantById', 'participantAdminUpdateRequest', participantAdminUpdateRequest)
            const localVarPath = `/api/admin/v1/participants/{participantId}`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participantAdminUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminParticipantsApi - functional programming interface
 * @export
 */
export const AdminParticipantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminParticipantsApiAxiosParamCreator(configuration)
    return {
        /**
         * Confirm the SAP synchronisation
         * @summary Confirm the SAP synchronisation
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminConfirmSapSyncParticipantById(participantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminConfirmSapSyncParticipantById(participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a participants in an Area
         * @summary Create a Area participant
         * @param {string} areaId uuid of the Area
         * @param {ParticipantAdminCreateRequest} participantAdminCreateRequest A Participant create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateAreaParticipant(areaId: string, participantAdminCreateRequest: ParticipantAdminCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateAreaParticipant(areaId, participantAdminCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete participant by ID
         * @summary Delete a participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteParticipantById(participantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteParticipantById(participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all participants in bulk by ids
         * @summary Fetch all participants in bulk by ids
         * @param {ParticipantsBulkRequest} participantsBulkRequest A participant bulk fetch request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParticipantBulkByIds(participantsBulkRequest: ParticipantsBulkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiParticipantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParticipantBulkByIds(participantsBulkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a participant by ID
         * @summary Get a participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParticipantById(participantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParticipantById(participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a Participant by ID
         * @summary Update a Participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {ParticipantAdminUpdateRequest} participantAdminUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateParticipantById(participantId: string, participantAdminUpdateRequest: ParticipantAdminUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateParticipantById(participantId, participantAdminUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminParticipantsApi - factory interface
 * @export
 */
export const AdminParticipantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminParticipantsApiFp(configuration)
    return {
        /**
         * Confirm the SAP synchronisation
         * @summary Confirm the SAP synchronisation
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminConfirmSapSyncParticipantById(participantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminConfirmSapSyncParticipantById(participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * create a participants in an Area
         * @summary Create a Area participant
         * @param {string} areaId uuid of the Area
         * @param {ParticipantAdminCreateRequest} participantAdminCreateRequest A Participant create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAreaParticipant(areaId: string, participantAdminCreateRequest: ParticipantAdminCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateAreaParticipant(areaId, participantAdminCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * delete participant by ID
         * @summary Delete a participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteParticipantById(participantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteParticipantById(participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all participants in bulk by ids
         * @summary Fetch all participants in bulk by ids
         * @param {ParticipantsBulkRequest} participantsBulkRequest A participant bulk fetch request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParticipantBulkByIds(participantsBulkRequest: ParticipantsBulkRequest, options?: any): AxiosPromise<MultiParticipantResponse> {
            return localVarFp.adminGetParticipantBulkByIds(participantsBulkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * get a participant by ID
         * @summary Get a participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParticipantById(participantId: string, options?: any): AxiosPromise<ParticipantResponse> {
            return localVarFp.adminGetParticipantById(participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Participant by ID
         * @summary Update a Participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {ParticipantAdminUpdateRequest} participantAdminUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateParticipantById(participantId: string, participantAdminUpdateRequest: ParticipantAdminUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateParticipantById(participantId, participantAdminUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminParticipantsApi - interface
 * @export
 * @interface AdminParticipantsApi
 */
export interface AdminParticipantsApiInterface {
    /**
     * Confirm the SAP synchronisation
     * @summary Confirm the SAP synchronisation
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipantsApiInterface
     */
    adminConfirmSapSyncParticipantById(participantId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * create a participants in an Area
     * @summary Create a Area participant
     * @param {string} areaId uuid of the Area
     * @param {ParticipantAdminCreateRequest} participantAdminCreateRequest A Participant create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipantsApiInterface
     */
    adminCreateAreaParticipant(areaId: string, participantAdminCreateRequest: ParticipantAdminCreateRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * delete participant by ID
     * @summary Delete a participant by ID
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipantsApiInterface
     */
    adminDeleteParticipantById(participantId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Fetch all participants in bulk by ids
     * @summary Fetch all participants in bulk by ids
     * @param {ParticipantsBulkRequest} participantsBulkRequest A participant bulk fetch request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipantsApiInterface
     */
    adminGetParticipantBulkByIds(participantsBulkRequest: ParticipantsBulkRequest, options?: AxiosRequestConfig): AxiosPromise<MultiParticipantResponse>;

    /**
     * get a participant by ID
     * @summary Get a participant by ID
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipantsApiInterface
     */
    adminGetParticipantById(participantId: string, options?: AxiosRequestConfig): AxiosPromise<ParticipantResponse>;

    /**
     * Update a Participant by ID
     * @summary Update a Participant by ID
     * @param {string} participantId uuid of the Participant
     * @param {ParticipantAdminUpdateRequest} participantAdminUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipantsApiInterface
     */
    adminUpdateParticipantById(participantId: string, participantAdminUpdateRequest: ParticipantAdminUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminParticipantsApi - object-oriented interface
 * @export
 * @class AdminParticipantsApi
 * @extends {BaseAPI}
 */
export class AdminParticipantsApi extends BaseAPI implements AdminParticipantsApiInterface {
    /**
     * Confirm the SAP synchronisation
     * @summary Confirm the SAP synchronisation
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipantsApi
     */
    public adminConfirmSapSyncParticipantById(participantId: string, options?: AxiosRequestConfig) {
        return AdminParticipantsApiFp(this.configuration).adminConfirmSapSyncParticipantById(participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a participants in an Area
     * @summary Create a Area participant
     * @param {string} areaId uuid of the Area
     * @param {ParticipantAdminCreateRequest} participantAdminCreateRequest A Participant create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipantsApi
     */
    public adminCreateAreaParticipant(areaId: string, participantAdminCreateRequest: ParticipantAdminCreateRequest, options?: AxiosRequestConfig) {
        return AdminParticipantsApiFp(this.configuration).adminCreateAreaParticipant(areaId, participantAdminCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete participant by ID
     * @summary Delete a participant by ID
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipantsApi
     */
    public adminDeleteParticipantById(participantId: string, options?: AxiosRequestConfig) {
        return AdminParticipantsApiFp(this.configuration).adminDeleteParticipantById(participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all participants in bulk by ids
     * @summary Fetch all participants in bulk by ids
     * @param {ParticipantsBulkRequest} participantsBulkRequest A participant bulk fetch request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipantsApi
     */
    public adminGetParticipantBulkByIds(participantsBulkRequest: ParticipantsBulkRequest, options?: AxiosRequestConfig) {
        return AdminParticipantsApiFp(this.configuration).adminGetParticipantBulkByIds(participantsBulkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a participant by ID
     * @summary Get a participant by ID
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipantsApi
     */
    public adminGetParticipantById(participantId: string, options?: AxiosRequestConfig) {
        return AdminParticipantsApiFp(this.configuration).adminGetParticipantById(participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Participant by ID
     * @summary Update a Participant by ID
     * @param {string} participantId uuid of the Participant
     * @param {ParticipantAdminUpdateRequest} participantAdminUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipantsApi
     */
    public adminUpdateParticipantById(participantId: string, participantAdminUpdateRequest: ParticipantAdminUpdateRequest, options?: AxiosRequestConfig) {
        return AdminParticipantsApiFp(this.configuration).adminUpdateParticipantById(participantId, participantAdminUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminParticipationApi - axios parameter creator
 * @export
 */
export const AdminParticipationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all participation of a utility  unit
         * @summary Get all participation of a utility unit
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParticipationByUtilityUnitId: async (utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminGetParticipationByUtilityUnitId', 'utilityUnitId', utilityUnitId)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new participation with a new participant for a vacancy
         * @summary Creates a new participation with a new participant for a vacancy
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveinParticipationExisting: async (utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminMoveinParticipationExisting', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminMoveinParticipationExisting', 'participationId', participationId)
            // verify required parameter 'moveinParticipationExistingRequest' is not null or undefined
            assertParamExists('adminMoveinParticipationExisting', 'moveinParticipationExistingRequest', moveinParticipationExistingRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein/existing`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveinParticipationExistingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create the participation for a utilityUnit with new participant
         * @summary Create the participation for a utilityUnit with new participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinWithNewParticipantAdminRequest} moveinWithNewParticipantAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveinWithNewParticipant: async (utilityUnitId: string, participationId: string, moveinWithNewParticipantAdminRequest: MoveinWithNewParticipantAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminMoveinWithNewParticipant', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminMoveinWithNewParticipant', 'participationId', participationId)
            // verify required parameter 'moveinWithNewParticipantAdminRequest' is not null or undefined
            assertParamExists('adminMoveinWithNewParticipant', 'moveinWithNewParticipantAdminRequest', moveinWithNewParticipantAdminRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein/new`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveinWithNewParticipantAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a vacancy after the moveout
         * @summary Creates a vacancy after the moveout
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveoutParticipationVacancy: async (utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminMoveoutParticipationVacancy', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminMoveoutParticipationVacancy', 'participationId', participationId)
            // verify required parameter 'moveoutParticipationVacancyRequest' is not null or undefined
            assertParamExists('adminMoveoutParticipationVacancy', 'moveoutParticipationVacancyRequest', moveoutParticipationVacancyRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/moveout/successor/vacancy`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveoutParticipationVacancyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change the movein date of a participation
         * @summary Change the movein date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationChangeMoveinDate: async (utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminParticipationChangeMoveinDate', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminParticipationChangeMoveinDate', 'participationId', participationId)
            // verify required parameter 'participationUpdateMoveinRequest' is not null or undefined
            assertParamExists('adminParticipationChangeMoveinDate', 'participationUpdateMoveinRequest', participationUpdateMoveinRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participationUpdateMoveinRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change the moveout date of a participation
         * @summary Change the moveout date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationChangeMoveoutDate: async (utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminParticipationChangeMoveoutDate', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminParticipationChangeMoveoutDate', 'participationId', participationId)
            // verify required parameter 'participationUpdateMoveoutRequest' is not null or undefined
            assertParamExists('adminParticipationChangeMoveoutDate', 'participationUpdateMoveoutRequest', participationUpdateMoveoutRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/moveout`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participationUpdateMoveoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stops the movein of a participant
         * @summary Stops the movein of a participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationStopMovein: async (utilityUnitId: string, participationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminParticipationStopMovein', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminParticipationStopMovein', 'participationId', participationId)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stops the moveout of the participant
         * @summary Stops the moveout of the participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationStopMoveout: async (utilityUnitId: string, participationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminParticipationStopMoveout', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminParticipationStopMoveout', 'participationId', participationId)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/moveout`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replace Participant for a Participation
         * @summary Replace Participant for a Participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ReplaceParticipantRequest} replaceParticipantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReplaceParticipant: async (utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminReplaceParticipant', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminReplaceParticipant', 'participationId', participationId)
            // verify required parameter 'replaceParticipantRequest' is not null or undefined
            assertParamExists('adminReplaceParticipant', 'replaceParticipantRequest', replaceParticipantRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/participant/replace`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceParticipantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a participation by id
         * @summary Get a participation by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminParticipationById: async (utilityUnitId: string, participationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('getAdminParticipationById', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('getAdminParticipationById', 'participationId', participationId)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminParticipationApi - functional programming interface
 * @export
 */
export const AdminParticipationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminParticipationApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all participation of a utility  unit
         * @summary Get all participation of a utility unit
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParticipationByUtilityUnitId(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedParticipationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParticipationByUtilityUnitId(utilityUnitId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new participation with a new participant for a vacancy
         * @summary Creates a new participation with a new participant for a vacancy
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMoveinParticipationExisting(utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMoveinParticipationExisting(utilityUnitId, participationId, moveinParticipationExistingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create the participation for a utilityUnit with new participant
         * @summary Create the participation for a utilityUnit with new participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinWithNewParticipantAdminRequest} moveinWithNewParticipantAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantAdminRequest: MoveinWithNewParticipantAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMoveinWithNewParticipant(utilityUnitId, participationId, moveinWithNewParticipantAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a vacancy after the moveout
         * @summary Creates a vacancy after the moveout
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMoveoutParticipationVacancy(utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMoveoutParticipationVacancy(utilityUnitId, participationId, moveoutParticipationVacancyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Change the movein date of a participation
         * @summary Change the movein date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminParticipationChangeMoveinDate(utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminParticipationChangeMoveinDate(utilityUnitId, participationId, participationUpdateMoveinRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Change the moveout date of a participation
         * @summary Change the moveout date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminParticipationChangeMoveoutDate(utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminParticipationChangeMoveoutDate(utilityUnitId, participationId, participationUpdateMoveoutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stops the movein of a participant
         * @summary Stops the movein of a participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminParticipationStopMovein(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminParticipationStopMovein(utilityUnitId, participationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stops the moveout of the participant
         * @summary Stops the moveout of the participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminParticipationStopMoveout(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminParticipationStopMoveout(utilityUnitId, participationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replace Participant for a Participation
         * @summary Replace Participant for a Participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ReplaceParticipantRequest} replaceParticipantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReplaceParticipant(utilityUnitId, participationId, replaceParticipantRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a participation by id
         * @summary Get a participation by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminParticipationById(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminParticipationById(utilityUnitId, participationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminParticipationApi - factory interface
 * @export
 */
export const AdminParticipationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminParticipationApiFp(configuration)
    return {
        /**
         * Get all participation of a utility  unit
         * @summary Get all participation of a utility unit
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParticipationByUtilityUnitId(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedParticipationResponse> {
            return localVarFp.adminGetParticipationByUtilityUnitId(utilityUnitId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new participation with a new participant for a vacancy
         * @summary Creates a new participation with a new participant for a vacancy
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveinParticipationExisting(utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminMoveinParticipationExisting(utilityUnitId, participationId, moveinParticipationExistingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create the participation for a utilityUnit with new participant
         * @summary Create the participation for a utilityUnit with new participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinWithNewParticipantAdminRequest} moveinWithNewParticipantAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantAdminRequest: MoveinWithNewParticipantAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminMoveinWithNewParticipant(utilityUnitId, participationId, moveinWithNewParticipantAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a vacancy after the moveout
         * @summary Creates a vacancy after the moveout
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveoutParticipationVacancy(utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminMoveoutParticipationVacancy(utilityUnitId, participationId, moveoutParticipationVacancyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Change the movein date of a participation
         * @summary Change the movein date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationChangeMoveinDate(utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminParticipationChangeMoveinDate(utilityUnitId, participationId, participationUpdateMoveinRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Change the moveout date of a participation
         * @summary Change the moveout date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationChangeMoveoutDate(utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminParticipationChangeMoveoutDate(utilityUnitId, participationId, participationUpdateMoveoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Stops the movein of a participant
         * @summary Stops the movein of a participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationStopMovein(utilityUnitId: string, participationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminParticipationStopMovein(utilityUnitId, participationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Stops the moveout of the participant
         * @summary Stops the moveout of the participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationStopMoveout(utilityUnitId: string, participationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminParticipationStopMoveout(utilityUnitId, participationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Replace Participant for a Participation
         * @summary Replace Participant for a Participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ReplaceParticipantRequest} replaceParticipantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminReplaceParticipant(utilityUnitId, participationId, replaceParticipantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a participation by id
         * @summary Get a participation by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminParticipationById(utilityUnitId: string, participationId: string, options?: any): AxiosPromise<ParticipationResponse> {
            return localVarFp.getAdminParticipationById(utilityUnitId, participationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminParticipationApi - interface
 * @export
 * @interface AdminParticipationApi
 */
export interface AdminParticipationApiInterface {
    /**
     * Get all participation of a utility  unit
     * @summary Get all participation of a utility unit
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApiInterface
     */
    adminGetParticipationByUtilityUnitId(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedParticipationResponse>;

    /**
     * Creates a new participation with a new participant for a vacancy
     * @summary Creates a new participation with a new participant for a vacancy
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApiInterface
     */
    adminMoveinParticipationExisting(utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Create the participation for a utilityUnit with new participant
     * @summary Create the participation for a utilityUnit with new participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinWithNewParticipantAdminRequest} moveinWithNewParticipantAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApiInterface
     */
    adminMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantAdminRequest: MoveinWithNewParticipantAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Creates a vacancy after the moveout
     * @summary Creates a vacancy after the moveout
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApiInterface
     */
    adminMoveoutParticipationVacancy(utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Change the movein date of a participation
     * @summary Change the movein date of a participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApiInterface
     */
    adminParticipationChangeMoveinDate(utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Change the moveout date of a participation
     * @summary Change the moveout date of a participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApiInterface
     */
    adminParticipationChangeMoveoutDate(utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Stops the movein of a participant
     * @summary Stops the movein of a participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApiInterface
     */
    adminParticipationStopMovein(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Stops the moveout of the participant
     * @summary Stops the moveout of the participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApiInterface
     */
    adminParticipationStopMoveout(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Replace Participant for a Participation
     * @summary Replace Participant for a Participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ReplaceParticipantRequest} replaceParticipantRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApiInterface
     */
    adminReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Get a participation by id
     * @summary Get a participation by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApiInterface
     */
    getAdminParticipationById(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): AxiosPromise<ParticipationResponse>;

}

/**
 * AdminParticipationApi - object-oriented interface
 * @export
 * @class AdminParticipationApi
 * @extends {BaseAPI}
 */
export class AdminParticipationApi extends BaseAPI implements AdminParticipationApiInterface {
    /**
     * Get all participation of a utility  unit
     * @summary Get all participation of a utility unit
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApi
     */
    public adminGetParticipationByUtilityUnitId(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminParticipationApiFp(this.configuration).adminGetParticipationByUtilityUnitId(utilityUnitId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new participation with a new participant for a vacancy
     * @summary Creates a new participation with a new participant for a vacancy
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApi
     */
    public adminMoveinParticipationExisting(utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options?: AxiosRequestConfig) {
        return AdminParticipationApiFp(this.configuration).adminMoveinParticipationExisting(utilityUnitId, participationId, moveinParticipationExistingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create the participation for a utilityUnit with new participant
     * @summary Create the participation for a utilityUnit with new participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinWithNewParticipantAdminRequest} moveinWithNewParticipantAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApi
     */
    public adminMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantAdminRequest: MoveinWithNewParticipantAdminRequest, options?: AxiosRequestConfig) {
        return AdminParticipationApiFp(this.configuration).adminMoveinWithNewParticipant(utilityUnitId, participationId, moveinWithNewParticipantAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a vacancy after the moveout
     * @summary Creates a vacancy after the moveout
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApi
     */
    public adminMoveoutParticipationVacancy(utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options?: AxiosRequestConfig) {
        return AdminParticipationApiFp(this.configuration).adminMoveoutParticipationVacancy(utilityUnitId, participationId, moveoutParticipationVacancyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change the movein date of a participation
     * @summary Change the movein date of a participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApi
     */
    public adminParticipationChangeMoveinDate(utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options?: AxiosRequestConfig) {
        return AdminParticipationApiFp(this.configuration).adminParticipationChangeMoveinDate(utilityUnitId, participationId, participationUpdateMoveinRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change the moveout date of a participation
     * @summary Change the moveout date of a participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApi
     */
    public adminParticipationChangeMoveoutDate(utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options?: AxiosRequestConfig) {
        return AdminParticipationApiFp(this.configuration).adminParticipationChangeMoveoutDate(utilityUnitId, participationId, participationUpdateMoveoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stops the movein of a participant
     * @summary Stops the movein of a participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApi
     */
    public adminParticipationStopMovein(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig) {
        return AdminParticipationApiFp(this.configuration).adminParticipationStopMovein(utilityUnitId, participationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stops the moveout of the participant
     * @summary Stops the moveout of the participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApi
     */
    public adminParticipationStopMoveout(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig) {
        return AdminParticipationApiFp(this.configuration).adminParticipationStopMoveout(utilityUnitId, participationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replace Participant for a Participation
     * @summary Replace Participant for a Participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ReplaceParticipantRequest} replaceParticipantRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApi
     */
    public adminReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: AxiosRequestConfig) {
        return AdminParticipationApiFp(this.configuration).adminReplaceParticipant(utilityUnitId, participationId, replaceParticipantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a participation by id
     * @summary Get a participation by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminParticipationApi
     */
    public getAdminParticipationById(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig) {
        return AdminParticipationApiFp(this.configuration).getAdminParticipationById(utilityUnitId, participationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductsAndServicesApi - axios parameter creator
 * @export
 */
export const AdminProductsAndServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a Pricing Component and link it to the referenced product
         * @summary Create and link a Pricing Component to a Product
         * @param {string} productId uuid of the Product
         * @param {PriceComponentCreateAdminRequest} priceComponentCreateAdminRequest Pricing components, services and other info related to creation of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAndLinkPricingComponent: async (productId: string, priceComponentCreateAdminRequest: PriceComponentCreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminCreateAndLinkPricingComponent', 'productId', productId)
            // verify required parameter 'priceComponentCreateAdminRequest' is not null or undefined
            assertParamExists('adminCreateAndLinkPricingComponent', 'priceComponentCreateAdminRequest', priceComponentCreateAdminRequest)
            const localVarPath = `/api/admin/v1/products/{productId}/price`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(priceComponentCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Product to offer to Contract customers
         * @summary Create a Product
         * @param {ProductCreateAdminRequest} productCreateAdminRequest Pricing components, services and other info related to creation of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateProduct: async (productCreateAdminRequest: ProductCreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCreateAdminRequest' is not null or undefined
            assertParamExists('adminCreateProduct', 'productCreateAdminRequest', productCreateAdminRequest)
            const localVarPath = `/api/admin/v1/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Pricing Component
         * @summary Delete a Pricing Component linked to a product
         * @param {string} productId uuid of the Product
         * @param {string} priceId uuid of the Pricing Component to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeletePricingComponent: async (productId: string, priceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminDeletePricingComponent', 'productId', productId)
            // verify required parameter 'priceId' is not null or undefined
            assertParamExists('adminDeletePricingComponent', 'priceId', priceId)
            const localVarPath = `/api/admin/v1/products/{productId}/price/{priceId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"priceId"}}`, encodeURIComponent(String(priceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Product if it is not in use on a Contract
         * @summary Delete a Product
         * @param {string} productId uuid of the Product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteProduct: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminDeleteProduct', 'productId', productId)
            const localVarPath = `/api/admin/v1/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Product by its productId
         * @param {string} productId uuid of the Product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProductById: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminGetProductById', 'productId', productId)
            const localVarPath = `/api/admin/v1/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all preloaded Products for detailing in Products
         * @summary Get all Products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Service by its serviceId
         * @param {string} serviceId uuid of the Service Component
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetServiceComponentById: async (serviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('adminGetServiceComponentById', 'serviceId', serviceId)
            const localVarPath = `/api/admin/v1/servicecomponents/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all preloaded Service Components for detailing in Products
         * @summary Get all Service Components
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetServiceComponents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/servicecomponents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Service by its serviceId
         * @param {string} serviceId uuid of the Service Component
         * @param {ServiceComponentUpdateAdminRequest} serviceComponentUpdateAdminRequest ServiceComponent update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPutServiceComponentById: async (serviceId: string, serviceComponentUpdateAdminRequest: ServiceComponentUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('adminPutServiceComponentById', 'serviceId', serviceId)
            // verify required parameter 'serviceComponentUpdateAdminRequest' is not null or undefined
            assertParamExists('adminPutServiceComponentById', 'serviceComponentUpdateAdminRequest', serviceComponentUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/servicecomponents/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceComponentUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update details of a Pricing Component, such as terminating it.  Currently active Pricing Components cannot be updated
         * @summary Update details of a Pricing Component linked to a product
         * @param {string} productId uuid of the Product
         * @param {string} priceId uuid of the Pricing Component to be updated
         * @param {PriceComponentUpdateAdminRequest} priceComponentUpdateAdminRequest Pricing components, services and other info related to creation of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdatePricingComponent: async (productId: string, priceId: string, priceComponentUpdateAdminRequest: PriceComponentUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminUpdatePricingComponent', 'productId', productId)
            // verify required parameter 'priceId' is not null or undefined
            assertParamExists('adminUpdatePricingComponent', 'priceId', priceId)
            // verify required parameter 'priceComponentUpdateAdminRequest' is not null or undefined
            assertParamExists('adminUpdatePricingComponent', 'priceComponentUpdateAdminRequest', priceComponentUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/products/{productId}/price/{priceId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"priceId"}}`, encodeURIComponent(String(priceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(priceComponentUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Product currently being offered to Contract customers
         * @summary Update a Product
         * @param {string} productId uuid of the Product
         * @param {ProductUpdateAdminRequest} productUpdateAdminRequest Pricing components, services and other info related to creation of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateProduct: async (productId: string, productUpdateAdminRequest: ProductUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminUpdateProduct', 'productId', productId)
            // verify required parameter 'productUpdateAdminRequest' is not null or undefined
            assertParamExists('adminUpdateProduct', 'productUpdateAdminRequest', productUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductsAndServicesApi - functional programming interface
 * @export
 */
export const AdminProductsAndServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductsAndServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a Pricing Component and link it to the referenced product
         * @summary Create and link a Pricing Component to a Product
         * @param {string} productId uuid of the Product
         * @param {PriceComponentCreateAdminRequest} priceComponentCreateAdminRequest Pricing components, services and other info related to creation of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateAndLinkPricingComponent(productId: string, priceComponentCreateAdminRequest: PriceComponentCreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateAndLinkPricingComponent(productId, priceComponentCreateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Product to offer to Contract customers
         * @summary Create a Product
         * @param {ProductCreateAdminRequest} productCreateAdminRequest Pricing components, services and other info related to creation of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateProduct(productCreateAdminRequest: ProductCreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateProduct(productCreateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a Pricing Component
         * @summary Delete a Pricing Component linked to a product
         * @param {string} productId uuid of the Product
         * @param {string} priceId uuid of the Pricing Component to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeletePricingComponent(productId: string, priceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeletePricingComponent(productId, priceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a Product if it is not in use on a Contract
         * @summary Delete a Product
         * @param {string} productId uuid of the Product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteProduct(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteProduct(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a Product by its productId
         * @param {string} productId uuid of the Product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetProductById(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetProductById(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all preloaded Products for detailing in Products
         * @summary Get all Products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiProductAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a Service by its serviceId
         * @param {string} serviceId uuid of the Service Component
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetServiceComponentById(serviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceComponentAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetServiceComponentById(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all preloaded Service Components for detailing in Products
         * @summary Get all Service Components
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetServiceComponents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiServiceComponentAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetServiceComponents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Service by its serviceId
         * @param {string} serviceId uuid of the Service Component
         * @param {ServiceComponentUpdateAdminRequest} serviceComponentUpdateAdminRequest ServiceComponent update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPutServiceComponentById(serviceId: string, serviceComponentUpdateAdminRequest: ServiceComponentUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPutServiceComponentById(serviceId, serviceComponentUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update details of a Pricing Component, such as terminating it.  Currently active Pricing Components cannot be updated
         * @summary Update details of a Pricing Component linked to a product
         * @param {string} productId uuid of the Product
         * @param {string} priceId uuid of the Pricing Component to be updated
         * @param {PriceComponentUpdateAdminRequest} priceComponentUpdateAdminRequest Pricing components, services and other info related to creation of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdatePricingComponent(productId: string, priceId: string, priceComponentUpdateAdminRequest: PriceComponentUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdatePricingComponent(productId, priceId, priceComponentUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a Product currently being offered to Contract customers
         * @summary Update a Product
         * @param {string} productId uuid of the Product
         * @param {ProductUpdateAdminRequest} productUpdateAdminRequest Pricing components, services and other info related to creation of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateProduct(productId: string, productUpdateAdminRequest: ProductUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateProduct(productId, productUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductsAndServicesApi - factory interface
 * @export
 */
export const AdminProductsAndServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductsAndServicesApiFp(configuration)
    return {
        /**
         * Create a Pricing Component and link it to the referenced product
         * @summary Create and link a Pricing Component to a Product
         * @param {string} productId uuid of the Product
         * @param {PriceComponentCreateAdminRequest} priceComponentCreateAdminRequest Pricing components, services and other info related to creation of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAndLinkPricingComponent(productId: string, priceComponentCreateAdminRequest: PriceComponentCreateAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateAndLinkPricingComponent(productId, priceComponentCreateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Product to offer to Contract customers
         * @summary Create a Product
         * @param {ProductCreateAdminRequest} productCreateAdminRequest Pricing components, services and other info related to creation of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateProduct(productCreateAdminRequest: ProductCreateAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateProduct(productCreateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Pricing Component
         * @summary Delete a Pricing Component linked to a product
         * @param {string} productId uuid of the Product
         * @param {string} priceId uuid of the Pricing Component to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeletePricingComponent(productId: string, priceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeletePricingComponent(productId, priceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Product if it is not in use on a Contract
         * @summary Delete a Product
         * @param {string} productId uuid of the Product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteProduct(productId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteProduct(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Product by its productId
         * @param {string} productId uuid of the Product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProductById(productId: string, options?: any): AxiosPromise<ProductAdminResponse> {
            return localVarFp.adminGetProductById(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all preloaded Products for detailing in Products
         * @summary Get all Products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProducts(options?: any): AxiosPromise<MultiProductAdminResponse> {
            return localVarFp.adminGetProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Service by its serviceId
         * @param {string} serviceId uuid of the Service Component
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetServiceComponentById(serviceId: string, options?: any): AxiosPromise<ServiceComponentAdminResponse> {
            return localVarFp.adminGetServiceComponentById(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all preloaded Service Components for detailing in Products
         * @summary Get all Service Components
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetServiceComponents(options?: any): AxiosPromise<MultiServiceComponentAdminResponse> {
            return localVarFp.adminGetServiceComponents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Service by its serviceId
         * @param {string} serviceId uuid of the Service Component
         * @param {ServiceComponentUpdateAdminRequest} serviceComponentUpdateAdminRequest ServiceComponent update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPutServiceComponentById(serviceId: string, serviceComponentUpdateAdminRequest: ServiceComponentUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminPutServiceComponentById(serviceId, serviceComponentUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update details of a Pricing Component, such as terminating it.  Currently active Pricing Components cannot be updated
         * @summary Update details of a Pricing Component linked to a product
         * @param {string} productId uuid of the Product
         * @param {string} priceId uuid of the Pricing Component to be updated
         * @param {PriceComponentUpdateAdminRequest} priceComponentUpdateAdminRequest Pricing components, services and other info related to creation of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdatePricingComponent(productId: string, priceId: string, priceComponentUpdateAdminRequest: PriceComponentUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdatePricingComponent(productId, priceId, priceComponentUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Product currently being offered to Contract customers
         * @summary Update a Product
         * @param {string} productId uuid of the Product
         * @param {ProductUpdateAdminRequest} productUpdateAdminRequest Pricing components, services and other info related to creation of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateProduct(productId: string, productUpdateAdminRequest: ProductUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateProduct(productId, productUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProductsAndServicesApi - interface
 * @export
 * @interface AdminProductsAndServicesApi
 */
export interface AdminProductsAndServicesApiInterface {
    /**
     * Create a Pricing Component and link it to the referenced product
     * @summary Create and link a Pricing Component to a Product
     * @param {string} productId uuid of the Product
     * @param {PriceComponentCreateAdminRequest} priceComponentCreateAdminRequest Pricing components, services and other info related to creation of a product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApiInterface
     */
    adminCreateAndLinkPricingComponent(productId: string, priceComponentCreateAdminRequest: PriceComponentCreateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Create a Product to offer to Contract customers
     * @summary Create a Product
     * @param {ProductCreateAdminRequest} productCreateAdminRequest Pricing components, services and other info related to creation of a product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApiInterface
     */
    adminCreateProduct(productCreateAdminRequest: ProductCreateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Delete a Pricing Component
     * @summary Delete a Pricing Component linked to a product
     * @param {string} productId uuid of the Product
     * @param {string} priceId uuid of the Pricing Component to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApiInterface
     */
    adminDeletePricingComponent(productId: string, priceId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete a Product if it is not in use on a Contract
     * @summary Delete a Product
     * @param {string} productId uuid of the Product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApiInterface
     */
    adminDeleteProduct(productId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get a Product by its productId
     * @param {string} productId uuid of the Product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApiInterface
     */
    adminGetProductById(productId: string, options?: AxiosRequestConfig): AxiosPromise<ProductAdminResponse>;

    /**
     * Get all preloaded Products for detailing in Products
     * @summary Get all Products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApiInterface
     */
    adminGetProducts(options?: AxiosRequestConfig): AxiosPromise<MultiProductAdminResponse>;

    /**
     * 
     * @summary Get a Service by its serviceId
     * @param {string} serviceId uuid of the Service Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApiInterface
     */
    adminGetServiceComponentById(serviceId: string, options?: AxiosRequestConfig): AxiosPromise<ServiceComponentAdminResponse>;

    /**
     * Get all preloaded Service Components for detailing in Products
     * @summary Get all Service Components
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApiInterface
     */
    adminGetServiceComponents(options?: AxiosRequestConfig): AxiosPromise<MultiServiceComponentAdminResponse>;

    /**
     * 
     * @summary Update a Service by its serviceId
     * @param {string} serviceId uuid of the Service Component
     * @param {ServiceComponentUpdateAdminRequest} serviceComponentUpdateAdminRequest ServiceComponent update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApiInterface
     */
    adminPutServiceComponentById(serviceId: string, serviceComponentUpdateAdminRequest: ServiceComponentUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update details of a Pricing Component, such as terminating it.  Currently active Pricing Components cannot be updated
     * @summary Update details of a Pricing Component linked to a product
     * @param {string} productId uuid of the Product
     * @param {string} priceId uuid of the Pricing Component to be updated
     * @param {PriceComponentUpdateAdminRequest} priceComponentUpdateAdminRequest Pricing components, services and other info related to creation of a product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApiInterface
     */
    adminUpdatePricingComponent(productId: string, priceId: string, priceComponentUpdateAdminRequest: PriceComponentUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update a Product currently being offered to Contract customers
     * @summary Update a Product
     * @param {string} productId uuid of the Product
     * @param {ProductUpdateAdminRequest} productUpdateAdminRequest Pricing components, services and other info related to creation of a product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApiInterface
     */
    adminUpdateProduct(productId: string, productUpdateAdminRequest: ProductUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminProductsAndServicesApi - object-oriented interface
 * @export
 * @class AdminProductsAndServicesApi
 * @extends {BaseAPI}
 */
export class AdminProductsAndServicesApi extends BaseAPI implements AdminProductsAndServicesApiInterface {
    /**
     * Create a Pricing Component and link it to the referenced product
     * @summary Create and link a Pricing Component to a Product
     * @param {string} productId uuid of the Product
     * @param {PriceComponentCreateAdminRequest} priceComponentCreateAdminRequest Pricing components, services and other info related to creation of a product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApi
     */
    public adminCreateAndLinkPricingComponent(productId: string, priceComponentCreateAdminRequest: PriceComponentCreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminProductsAndServicesApiFp(this.configuration).adminCreateAndLinkPricingComponent(productId, priceComponentCreateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Product to offer to Contract customers
     * @summary Create a Product
     * @param {ProductCreateAdminRequest} productCreateAdminRequest Pricing components, services and other info related to creation of a product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApi
     */
    public adminCreateProduct(productCreateAdminRequest: ProductCreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminProductsAndServicesApiFp(this.configuration).adminCreateProduct(productCreateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Pricing Component
     * @summary Delete a Pricing Component linked to a product
     * @param {string} productId uuid of the Product
     * @param {string} priceId uuid of the Pricing Component to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApi
     */
    public adminDeletePricingComponent(productId: string, priceId: string, options?: AxiosRequestConfig) {
        return AdminProductsAndServicesApiFp(this.configuration).adminDeletePricingComponent(productId, priceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Product if it is not in use on a Contract
     * @summary Delete a Product
     * @param {string} productId uuid of the Product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApi
     */
    public adminDeleteProduct(productId: string, options?: AxiosRequestConfig) {
        return AdminProductsAndServicesApiFp(this.configuration).adminDeleteProduct(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Product by its productId
     * @param {string} productId uuid of the Product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApi
     */
    public adminGetProductById(productId: string, options?: AxiosRequestConfig) {
        return AdminProductsAndServicesApiFp(this.configuration).adminGetProductById(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all preloaded Products for detailing in Products
     * @summary Get all Products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApi
     */
    public adminGetProducts(options?: AxiosRequestConfig) {
        return AdminProductsAndServicesApiFp(this.configuration).adminGetProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Service by its serviceId
     * @param {string} serviceId uuid of the Service Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApi
     */
    public adminGetServiceComponentById(serviceId: string, options?: AxiosRequestConfig) {
        return AdminProductsAndServicesApiFp(this.configuration).adminGetServiceComponentById(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all preloaded Service Components for detailing in Products
     * @summary Get all Service Components
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApi
     */
    public adminGetServiceComponents(options?: AxiosRequestConfig) {
        return AdminProductsAndServicesApiFp(this.configuration).adminGetServiceComponents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Service by its serviceId
     * @param {string} serviceId uuid of the Service Component
     * @param {ServiceComponentUpdateAdminRequest} serviceComponentUpdateAdminRequest ServiceComponent update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApi
     */
    public adminPutServiceComponentById(serviceId: string, serviceComponentUpdateAdminRequest: ServiceComponentUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminProductsAndServicesApiFp(this.configuration).adminPutServiceComponentById(serviceId, serviceComponentUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update details of a Pricing Component, such as terminating it.  Currently active Pricing Components cannot be updated
     * @summary Update details of a Pricing Component linked to a product
     * @param {string} productId uuid of the Product
     * @param {string} priceId uuid of the Pricing Component to be updated
     * @param {PriceComponentUpdateAdminRequest} priceComponentUpdateAdminRequest Pricing components, services and other info related to creation of a product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApi
     */
    public adminUpdatePricingComponent(productId: string, priceId: string, priceComponentUpdateAdminRequest: PriceComponentUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminProductsAndServicesApiFp(this.configuration).adminUpdatePricingComponent(productId, priceId, priceComponentUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Product currently being offered to Contract customers
     * @summary Update a Product
     * @param {string} productId uuid of the Product
     * @param {ProductUpdateAdminRequest} productUpdateAdminRequest Pricing components, services and other info related to creation of a product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsAndServicesApi
     */
    public adminUpdateProduct(productId: string, productUpdateAdminRequest: ProductUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminProductsAndServicesApiFp(this.configuration).adminUpdateProduct(productId, productUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProfileApi - axios parameter creator
 * @export
 */
export const AdminProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch all contracts linked to a Profile
         * @summary Get the contracts a Profile manages
         * @param {string} profileId uuid of the Profile
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPagedContractsByProfileId: async (profileId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('adminGetPagedContractsByProfileId', 'profileId', profileId)
            const localVarPath = `/api/admin/v1/profiles/{profileId}/contracts`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A admin can trigger the password reset process for a Profile.
         * @summary Trigger a password reset process flow for a Profile by ID
         * @param {string} profileId uuid of the Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProfileResetPassword: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('adminProfileResetPassword', 'profileId', profileId)
            const localVarPath = `/api/admin/v1/profiles/{profileId}/reset-password`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The Profile can be blocked/unblocked from the system
         * @summary Block/Unblock Profile by ID
         * @param {string} profileId uuid of the Profile
         * @param {'block' | 'unblock'} blockaction the block or unblock action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockunblockProfileById: async (profileId: string, blockaction: 'block' | 'unblock', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('blockunblockProfileById', 'profileId', profileId)
            // verify required parameter 'blockaction' is not null or undefined
            assertParamExists('blockunblockProfileById', 'blockaction', blockaction)
            const localVarPath = `/api/admin/v1/profiles/{profileId}/{blockaction}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)))
                .replace(`{${"blockaction"}}`, encodeURIComponent(String(blockaction)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A Profile can have its login created later
         * @summary Create a login for a profile
         * @param {string} profileId uuid of the Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminProfileLoginById: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('createAdminProfileLoginById', 'profileId', profileId)
            const localVarPath = `/api/admin/v1/profiles/{profileId}/login`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A Profile can be created with or without a login
         * @summary Create a Profile
         * @param {ProfileCreateAdminRequest} profileCreateAdminRequest A Profile admin create request
         * @param {boolean} [withLogin] Whether to create a login at the creation time of this profile
         * @param {boolean} [skipEmailVerification] Whether to skip the email verification process for setting up a profile when withLogin&#x3D;true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfile: async (profileCreateAdminRequest: ProfileCreateAdminRequest, withLogin?: boolean, skipEmailVerification?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileCreateAdminRequest' is not null or undefined
            assertParamExists('createProfile', 'profileCreateAdminRequest', profileCreateAdminRequest)
            const localVarPath = `/api/admin/v1/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (withLogin !== undefined) {
                localVarQueryParameter['withLogin'] = withLogin;
            }

            if (skipEmailVerification !== undefined) {
                localVarQueryParameter['skipEmailVerification'] = skipEmailVerification;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a draft profile (person)
         * @param {string} profileId uuid of the Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfileById: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('deleteProfileById', 'profileId', profileId)
            const localVarPath = `/api/admin/v1/profiles/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A logged-in admin can fetch its Profile
         * @summary Get the logged-in admin\'s profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/profiles/logged-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A Profile can be fetched by its known uuid and returned
         * @summary Get a Profile by its ID
         * @param {string} profileId uuid of the Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminProfileById: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('getAdminProfileById', 'profileId', profileId)
            const localVarPath = `/api/admin/v1/profiles/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Numerous parameters can be passed to paginate through Profiles
         * @summary Get paginated results of Profiles
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProfiles: async (page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logged-in Admin can update its password
         * @summary Update the logged-in admin\'s profile password
         * @param {ProfilePasswordRequest} profilePasswordRequest An admin profile password request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminPassword: async (profilePasswordRequest: ProfilePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profilePasswordRequest' is not null or undefined
            assertParamExists('updateAdminPassword', 'profilePasswordRequest', profilePasswordRequest)
            const localVarPath = `/api/admin/v1/profiles/logged-in/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profilePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A logged-in admin can update its profile
         * @summary Update the logged-in admin\'s profile
         * @param {ProfileUpdateAdminRequest} profileUpdateAdminRequest An admin profile update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminProfile: async (profileUpdateAdminRequest: ProfileUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileUpdateAdminRequest' is not null or undefined
            assertParamExists('updateAdminProfile', 'profileUpdateAdminRequest', profileUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/profiles/logged-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A Profile can have some of its properties updated post-creation
         * @summary Update a Profile by its ID
         * @param {string} profileId uuid of the Profile
         * @param {ProfileUpdateAdminRequest} profileUpdateAdminRequest A Profile admin update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminProfileById: async (profileId: string, profileUpdateAdminRequest: ProfileUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('updateAdminProfileById', 'profileId', profileId)
            // verify required parameter 'profileUpdateAdminRequest' is not null or undefined
            assertParamExists('updateAdminProfileById', 'profileUpdateAdminRequest', profileUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/profiles/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the contracts a Profile manages by sending a list of Contract IDs that should be linked to a Profile
         * @summary Update the contracts a Profile manages
         * @param {string} profileId uuid of the Profile
         * @param {ProfileContractsAdminRequest} profileContractsAdminRequest Profile\&#39;s contracts update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContractsByProfileId: async (profileId: string, profileContractsAdminRequest: ProfileContractsAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('updateContractsByProfileId', 'profileId', profileId)
            // verify required parameter 'profileContractsAdminRequest' is not null or undefined
            assertParamExists('updateContractsByProfileId', 'profileContractsAdminRequest', profileContractsAdminRequest)
            const localVarPath = `/api/admin/v1/profiles/{profileId}/contracts`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileContractsAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProfileApi - functional programming interface
 * @export
 */
export const AdminProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch all contracts linked to a Profile
         * @summary Get the contracts a Profile manages
         * @param {string} profileId uuid of the Profile
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPagedContractsByProfileId(profileId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedContractAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPagedContractsByProfileId(profileId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A admin can trigger the password reset process for a Profile.
         * @summary Trigger a password reset process flow for a Profile by ID
         * @param {string} profileId uuid of the Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProfileResetPassword(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProfileResetPassword(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The Profile can be blocked/unblocked from the system
         * @summary Block/Unblock Profile by ID
         * @param {string} profileId uuid of the Profile
         * @param {'block' | 'unblock'} blockaction the block or unblock action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockunblockProfileById(profileId: string, blockaction: 'block' | 'unblock', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockunblockProfileById(profileId, blockaction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A Profile can have its login created later
         * @summary Create a login for a profile
         * @param {string} profileId uuid of the Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdminProfileLoginById(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdminProfileLoginById(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A Profile can be created with or without a login
         * @summary Create a Profile
         * @param {ProfileCreateAdminRequest} profileCreateAdminRequest A Profile admin create request
         * @param {boolean} [withLogin] Whether to create a login at the creation time of this profile
         * @param {boolean} [skipEmailVerification] Whether to skip the email verification process for setting up a profile when withLogin&#x3D;true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProfile(profileCreateAdminRequest: ProfileCreateAdminRequest, withLogin?: boolean, skipEmailVerification?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProfile(profileCreateAdminRequest, withLogin, skipEmailVerification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a draft profile (person)
         * @param {string} profileId uuid of the Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfileById(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProfileById(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A logged-in admin can fetch its Profile
         * @summary Get the logged-in admin\'s profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A Profile can be fetched by its known uuid and returned
         * @summary Get a Profile by its ID
         * @param {string} profileId uuid of the Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminProfileById(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminProfileById(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Numerous parameters can be passed to paginate through Profiles
         * @summary Get paginated results of Profiles
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProfiles(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedProfileAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProfiles(page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logged-in Admin can update its password
         * @summary Update the logged-in admin\'s profile password
         * @param {ProfilePasswordRequest} profilePasswordRequest An admin profile password request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminPassword(profilePasswordRequest: ProfilePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminPassword(profilePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A logged-in admin can update its profile
         * @summary Update the logged-in admin\'s profile
         * @param {ProfileUpdateAdminRequest} profileUpdateAdminRequest An admin profile update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminProfile(profileUpdateAdminRequest: ProfileUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminProfile(profileUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A Profile can have some of its properties updated post-creation
         * @summary Update a Profile by its ID
         * @param {string} profileId uuid of the Profile
         * @param {ProfileUpdateAdminRequest} profileUpdateAdminRequest A Profile admin update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminProfileById(profileId: string, profileUpdateAdminRequest: ProfileUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminProfileById(profileId, profileUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the contracts a Profile manages by sending a list of Contract IDs that should be linked to a Profile
         * @summary Update the contracts a Profile manages
         * @param {string} profileId uuid of the Profile
         * @param {ProfileContractsAdminRequest} profileContractsAdminRequest Profile\&#39;s contracts update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContractsByProfileId(profileId: string, profileContractsAdminRequest: ProfileContractsAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContractsByProfileId(profileId, profileContractsAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProfileApi - factory interface
 * @export
 */
export const AdminProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProfileApiFp(configuration)
    return {
        /**
         * Fetch all contracts linked to a Profile
         * @summary Get the contracts a Profile manages
         * @param {string} profileId uuid of the Profile
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPagedContractsByProfileId(profileId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedContractAdminResponse> {
            return localVarFp.adminGetPagedContractsByProfileId(profileId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * A admin can trigger the password reset process for a Profile.
         * @summary Trigger a password reset process flow for a Profile by ID
         * @param {string} profileId uuid of the Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProfileResetPassword(profileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminProfileResetPassword(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * The Profile can be blocked/unblocked from the system
         * @summary Block/Unblock Profile by ID
         * @param {string} profileId uuid of the Profile
         * @param {'block' | 'unblock'} blockaction the block or unblock action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockunblockProfileById(profileId: string, blockaction: 'block' | 'unblock', options?: any): AxiosPromise<void> {
            return localVarFp.blockunblockProfileById(profileId, blockaction, options).then((request) => request(axios, basePath));
        },
        /**
         * A Profile can have its login created later
         * @summary Create a login for a profile
         * @param {string} profileId uuid of the Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminProfileLoginById(profileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.createAdminProfileLoginById(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * A Profile can be created with or without a login
         * @summary Create a Profile
         * @param {ProfileCreateAdminRequest} profileCreateAdminRequest A Profile admin create request
         * @param {boolean} [withLogin] Whether to create a login at the creation time of this profile
         * @param {boolean} [skipEmailVerification] Whether to skip the email verification process for setting up a profile when withLogin&#x3D;true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfile(profileCreateAdminRequest: ProfileCreateAdminRequest, withLogin?: boolean, skipEmailVerification?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.createProfile(profileCreateAdminRequest, withLogin, skipEmailVerification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a draft profile (person)
         * @param {string} profileId uuid of the Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfileById(profileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProfileById(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * A logged-in admin can fetch its Profile
         * @summary Get the logged-in admin\'s profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminProfile(options?: any): AxiosPromise<ProfileAdminResponse> {
            return localVarFp.getAdminProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * A Profile can be fetched by its known uuid and returned
         * @summary Get a Profile by its ID
         * @param {string} profileId uuid of the Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminProfileById(profileId: string, options?: any): AxiosPromise<ProfileAdminResponse> {
            return localVarFp.getAdminProfileById(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Numerous parameters can be passed to paginate through Profiles
         * @summary Get paginated results of Profiles
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProfiles(page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedProfileAdminResponse> {
            return localVarFp.getAllProfiles(page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Logged-in Admin can update its password
         * @summary Update the logged-in admin\'s profile password
         * @param {ProfilePasswordRequest} profilePasswordRequest An admin profile password request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminPassword(profilePasswordRequest: ProfilePasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAdminPassword(profilePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * A logged-in admin can update its profile
         * @summary Update the logged-in admin\'s profile
         * @param {ProfileUpdateAdminRequest} profileUpdateAdminRequest An admin profile update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminProfile(profileUpdateAdminRequest: ProfileUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAdminProfile(profileUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * A Profile can have some of its properties updated post-creation
         * @summary Update a Profile by its ID
         * @param {string} profileId uuid of the Profile
         * @param {ProfileUpdateAdminRequest} profileUpdateAdminRequest A Profile admin update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminProfileById(profileId: string, profileUpdateAdminRequest: ProfileUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAdminProfileById(profileId, profileUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the contracts a Profile manages by sending a list of Contract IDs that should be linked to a Profile
         * @summary Update the contracts a Profile manages
         * @param {string} profileId uuid of the Profile
         * @param {ProfileContractsAdminRequest} profileContractsAdminRequest Profile\&#39;s contracts update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContractsByProfileId(profileId: string, profileContractsAdminRequest: ProfileContractsAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateContractsByProfileId(profileId, profileContractsAdminRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProfileApi - interface
 * @export
 * @interface AdminProfileApi
 */
export interface AdminProfileApiInterface {
    /**
     * Fetch all contracts linked to a Profile
     * @summary Get the contracts a Profile manages
     * @param {string} profileId uuid of the Profile
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    adminGetPagedContractsByProfileId(profileId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedContractAdminResponse>;

    /**
     * A admin can trigger the password reset process for a Profile.
     * @summary Trigger a password reset process flow for a Profile by ID
     * @param {string} profileId uuid of the Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    adminProfileResetPassword(profileId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * The Profile can be blocked/unblocked from the system
     * @summary Block/Unblock Profile by ID
     * @param {string} profileId uuid of the Profile
     * @param {'block' | 'unblock'} blockaction the block or unblock action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    blockunblockProfileById(profileId: string, blockaction: 'block' | 'unblock', options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * A Profile can have its login created later
     * @summary Create a login for a profile
     * @param {string} profileId uuid of the Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    createAdminProfileLoginById(profileId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * A Profile can be created with or without a login
     * @summary Create a Profile
     * @param {ProfileCreateAdminRequest} profileCreateAdminRequest A Profile admin create request
     * @param {boolean} [withLogin] Whether to create a login at the creation time of this profile
     * @param {boolean} [skipEmailVerification] Whether to skip the email verification process for setting up a profile when withLogin&#x3D;true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    createProfile(profileCreateAdminRequest: ProfileCreateAdminRequest, withLogin?: boolean, skipEmailVerification?: boolean, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary delete a draft profile (person)
     * @param {string} profileId uuid of the Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    deleteProfileById(profileId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * A logged-in admin can fetch its Profile
     * @summary Get the logged-in admin\'s profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    getAdminProfile(options?: AxiosRequestConfig): AxiosPromise<ProfileAdminResponse>;

    /**
     * A Profile can be fetched by its known uuid and returned
     * @summary Get a Profile by its ID
     * @param {string} profileId uuid of the Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    getAdminProfileById(profileId: string, options?: AxiosRequestConfig): AxiosPromise<ProfileAdminResponse>;

    /**
     * Numerous parameters can be passed to paginate through Profiles
     * @summary Get paginated results of Profiles
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    getAllProfiles(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedProfileAdminResponse>;

    /**
     * Logged-in Admin can update its password
     * @summary Update the logged-in admin\'s profile password
     * @param {ProfilePasswordRequest} profilePasswordRequest An admin profile password request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    updateAdminPassword(profilePasswordRequest: ProfilePasswordRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * A logged-in admin can update its profile
     * @summary Update the logged-in admin\'s profile
     * @param {ProfileUpdateAdminRequest} profileUpdateAdminRequest An admin profile update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    updateAdminProfile(profileUpdateAdminRequest: ProfileUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * A Profile can have some of its properties updated post-creation
     * @summary Update a Profile by its ID
     * @param {string} profileId uuid of the Profile
     * @param {ProfileUpdateAdminRequest} profileUpdateAdminRequest A Profile admin update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    updateAdminProfileById(profileId: string, profileUpdateAdminRequest: ProfileUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update the contracts a Profile manages by sending a list of Contract IDs that should be linked to a Profile
     * @summary Update the contracts a Profile manages
     * @param {string} profileId uuid of the Profile
     * @param {ProfileContractsAdminRequest} profileContractsAdminRequest Profile\&#39;s contracts update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApiInterface
     */
    updateContractsByProfileId(profileId: string, profileContractsAdminRequest: ProfileContractsAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminProfileApi - object-oriented interface
 * @export
 * @class AdminProfileApi
 * @extends {BaseAPI}
 */
export class AdminProfileApi extends BaseAPI implements AdminProfileApiInterface {
    /**
     * Fetch all contracts linked to a Profile
     * @summary Get the contracts a Profile manages
     * @param {string} profileId uuid of the Profile
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public adminGetPagedContractsByProfileId(profileId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).adminGetPagedContractsByProfileId(profileId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A admin can trigger the password reset process for a Profile.
     * @summary Trigger a password reset process flow for a Profile by ID
     * @param {string} profileId uuid of the Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public adminProfileResetPassword(profileId: string, options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).adminProfileResetPassword(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The Profile can be blocked/unblocked from the system
     * @summary Block/Unblock Profile by ID
     * @param {string} profileId uuid of the Profile
     * @param {'block' | 'unblock'} blockaction the block or unblock action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public blockunblockProfileById(profileId: string, blockaction: 'block' | 'unblock', options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).blockunblockProfileById(profileId, blockaction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A Profile can have its login created later
     * @summary Create a login for a profile
     * @param {string} profileId uuid of the Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public createAdminProfileLoginById(profileId: string, options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).createAdminProfileLoginById(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A Profile can be created with or without a login
     * @summary Create a Profile
     * @param {ProfileCreateAdminRequest} profileCreateAdminRequest A Profile admin create request
     * @param {boolean} [withLogin] Whether to create a login at the creation time of this profile
     * @param {boolean} [skipEmailVerification] Whether to skip the email verification process for setting up a profile when withLogin&#x3D;true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public createProfile(profileCreateAdminRequest: ProfileCreateAdminRequest, withLogin?: boolean, skipEmailVerification?: boolean, options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).createProfile(profileCreateAdminRequest, withLogin, skipEmailVerification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a draft profile (person)
     * @param {string} profileId uuid of the Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public deleteProfileById(profileId: string, options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).deleteProfileById(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A logged-in admin can fetch its Profile
     * @summary Get the logged-in admin\'s profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public getAdminProfile(options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).getAdminProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A Profile can be fetched by its known uuid and returned
     * @summary Get a Profile by its ID
     * @param {string} profileId uuid of the Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public getAdminProfileById(profileId: string, options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).getAdminProfileById(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Numerous parameters can be passed to paginate through Profiles
     * @summary Get paginated results of Profiles
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public getAllProfiles(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).getAllProfiles(page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logged-in Admin can update its password
     * @summary Update the logged-in admin\'s profile password
     * @param {ProfilePasswordRequest} profilePasswordRequest An admin profile password request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public updateAdminPassword(profilePasswordRequest: ProfilePasswordRequest, options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).updateAdminPassword(profilePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A logged-in admin can update its profile
     * @summary Update the logged-in admin\'s profile
     * @param {ProfileUpdateAdminRequest} profileUpdateAdminRequest An admin profile update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public updateAdminProfile(profileUpdateAdminRequest: ProfileUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).updateAdminProfile(profileUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A Profile can have some of its properties updated post-creation
     * @summary Update a Profile by its ID
     * @param {string} profileId uuid of the Profile
     * @param {ProfileUpdateAdminRequest} profileUpdateAdminRequest A Profile admin update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public updateAdminProfileById(profileId: string, profileUpdateAdminRequest: ProfileUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).updateAdminProfileById(profileId, profileUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the contracts a Profile manages by sending a list of Contract IDs that should be linked to a Profile
     * @summary Update the contracts a Profile manages
     * @param {string} profileId uuid of the Profile
     * @param {ProfileContractsAdminRequest} profileContractsAdminRequest Profile\&#39;s contracts update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProfileApi
     */
    public updateContractsByProfileId(profileId: string, profileContractsAdminRequest: ProfileContractsAdminRequest, options?: AxiosRequestConfig) {
        return AdminProfileApiFp(this.configuration).updateContractsByProfileId(profileId, profileContractsAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminServiceBillingsApi - axios parameter creator
 * @export
 */
export const AdminServiceBillingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Approve a service-billing run by id
         * @summary Approve a service-billing run by id
         * @param {string} id uuid of the service-billing run object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAdminServiceBillingRunById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveAdminServiceBillingRunById', 'id', id)
            const localVarPath = `/api/admin/v1/service-billings/runs/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a service-billing run
         * @summary Create a service-billing run
         * @param {ServiceBillingRunCreateAdminRequest} serviceBillingRunCreateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminServiceBillingRun: async (serviceBillingRunCreateAdminRequest: ServiceBillingRunCreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceBillingRunCreateAdminRequest' is not null or undefined
            assertParamExists('createAdminServiceBillingRun', 'serviceBillingRunCreateAdminRequest', serviceBillingRunCreateAdminRequest)
            const localVarPath = `/api/admin/v1/service-billings/runs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceBillingRunCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a service-billing entity by id
         * @summary Delete a service-billing entity by id
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminServiceBillingById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminServiceBillingById', 'id', id)
            const localVarPath = `/api/admin/v1/service-billings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a service-billing run by id
         * @summary Delete a service-billing run by id
         * @param {string} id uuid of the service-billing run object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminServiceBillingRunById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminServiceBillingRunById', 'id', id)
            const localVarPath = `/api/admin/v1/service-billings/runs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a service-billing entity by id
         * @summary Get a service-billing entity by id
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminServiceBillingById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminServiceBillingById', 'id', id)
            const localVarPath = `/api/admin/v1/service-billings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a service-billing run by id
         * @summary Get a service-billing run by id
         * @param {string} id uuid of the service-billing run object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminServiceBillingRunById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminServiceBillingRunById', 'id', id)
            const localVarPath = `/api/admin/v1/service-billings/runs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all service billings
         * @summary Get all service billings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminServiceBillings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/service-billings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all initial and recurring service billings
         * @summary Get all initial and recurring service billings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUnifiedServiceBillings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/service-billings/unified`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all service-billing runs
         * @summary Get all service-billing runs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminServiceBillingRuns: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/service-billings/runs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lifecycle state change for a service-billing entity
         * @summary Lifecycle state change for a service-billing entity
         * @param {string} id uuid of the Billing object
         * @param {'approve' | 'cancel'} approveOrCancelAction the lifecycle action to perform on this service-billing entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminServiceBillingStateChangeById: async (id: string, approveOrCancelAction: 'approve' | 'cancel', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminServiceBillingStateChangeById', 'id', id)
            // verify required parameter 'approveOrCancelAction' is not null or undefined
            assertParamExists('putAdminServiceBillingStateChangeById', 'approveOrCancelAction', approveOrCancelAction)
            const localVarPath = `/api/admin/v1/service-billings/{id}/{approveOrCancelAction}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"approveOrCancelAction"}}`, encodeURIComponent(String(approveOrCancelAction)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Recalculate a service-billing by id
         * @summary Recalculate a service-billing by id
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateAdminServiceBillingById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('recalculateAdminServiceBillingById', 'id', id)
            const localVarPath = `/api/admin/v1/service-billings/{id}/recalculate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove a contract from an existing a service-billing run by id
         * @summary Remove a contract from an existing a service-billing run by id
         * @param {string} id uuid of the service-billing run object
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAdminServiceBillingRunContractById: async (id: string, contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeAdminServiceBillingRunContractById', 'id', id)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('removeAdminServiceBillingRunContractById', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/service-billings/runs/{id}/remove/{contractId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send a service-billing details by SAP
         * @summary Send a service-billing details by SAP
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sapSendAdminServiceBillingById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sapSendAdminServiceBillingById', 'id', id)
            const localVarPath = `/api/admin/v1/service-billings/{id}/sap-send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send a service-billing run details to SAP
         * @summary Send a service-billing run details to SAP
         * @param {string} id uuid of the service-billing run object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sapSendAdminServiceBillingRunById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sapSendAdminServiceBillingRunById', 'id', id)
            const localVarPath = `/api/admin/v1/service-billings/runs/{id}/sap-send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Flag an initial service-billing as manually created
         * @summary Flag an initial service-billing as manually created
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAdminServiceBillingAsManuallyCreated: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setAdminServiceBillingAsManuallyCreated', 'id', id)
            const localVarPath = `/api/admin/v1/service-billings/{id}/setManuallyCreated`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a service-billing entity by id
         * @summary Update a service-billing entity by id
         * @param {string} id uuid of the Billing object
         * @param {ServiceBillingUpdateAdminRequest} serviceBillingUpdateAdminRequest A service-billing update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminServiceBillingById: async (id: string, serviceBillingUpdateAdminRequest: ServiceBillingUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdminServiceBillingById', 'id', id)
            // verify required parameter 'serviceBillingUpdateAdminRequest' is not null or undefined
            assertParamExists('updateAdminServiceBillingById', 'serviceBillingUpdateAdminRequest', serviceBillingUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/service-billings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceBillingUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminServiceBillingsApi - functional programming interface
 * @export
 */
export const AdminServiceBillingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminServiceBillingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Approve a service-billing run by id
         * @summary Approve a service-billing run by id
         * @param {string} id uuid of the service-billing run object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveAdminServiceBillingRunById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveAdminServiceBillingRunById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a service-billing run
         * @summary Create a service-billing run
         * @param {ServiceBillingRunCreateAdminRequest} serviceBillingRunCreateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdminServiceBillingRun(serviceBillingRunCreateAdminRequest: ServiceBillingRunCreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdminServiceBillingRun(serviceBillingRunCreateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a service-billing entity by id
         * @summary Delete a service-billing entity by id
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminServiceBillingById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminServiceBillingById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a service-billing run by id
         * @summary Delete a service-billing run by id
         * @param {string} id uuid of the service-billing run object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminServiceBillingRunById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminServiceBillingRunById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a service-billing entity by id
         * @summary Get a service-billing entity by id
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminServiceBillingById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceBillingAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminServiceBillingById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a service-billing run by id
         * @summary Get a service-billing run by id
         * @param {string} id uuid of the service-billing run object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminServiceBillingRunById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceBillingRunAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminServiceBillingRunById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all service billings
         * @summary Get all service billings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminServiceBillings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiServiceBillingAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminServiceBillings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all initial and recurring service billings
         * @summary Get all initial and recurring service billings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUnifiedServiceBillings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiUnifiedServiceBillingAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminUnifiedServiceBillings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all service-billing runs
         * @summary Get all service-billing runs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAdminServiceBillingRuns(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceBillingRunMultiAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAdminServiceBillingRuns(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lifecycle state change for a service-billing entity
         * @summary Lifecycle state change for a service-billing entity
         * @param {string} id uuid of the Billing object
         * @param {'approve' | 'cancel'} approveOrCancelAction the lifecycle action to perform on this service-billing entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminServiceBillingStateChangeById(id: string, approveOrCancelAction: 'approve' | 'cancel', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminServiceBillingStateChangeById(id, approveOrCancelAction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Recalculate a service-billing by id
         * @summary Recalculate a service-billing by id
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recalculateAdminServiceBillingById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recalculateAdminServiceBillingById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove a contract from an existing a service-billing run by id
         * @summary Remove a contract from an existing a service-billing run by id
         * @param {string} id uuid of the service-billing run object
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAdminServiceBillingRunContractById(id: string, contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAdminServiceBillingRunContractById(id, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send a service-billing details by SAP
         * @summary Send a service-billing details by SAP
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sapSendAdminServiceBillingById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sapSendAdminServiceBillingById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send a service-billing run details to SAP
         * @summary Send a service-billing run details to SAP
         * @param {string} id uuid of the service-billing run object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sapSendAdminServiceBillingRunById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sapSendAdminServiceBillingRunById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Flag an initial service-billing as manually created
         * @summary Flag an initial service-billing as manually created
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAdminServiceBillingAsManuallyCreated(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAdminServiceBillingAsManuallyCreated(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a service-billing entity by id
         * @summary Update a service-billing entity by id
         * @param {string} id uuid of the Billing object
         * @param {ServiceBillingUpdateAdminRequest} serviceBillingUpdateAdminRequest A service-billing update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminServiceBillingById(id: string, serviceBillingUpdateAdminRequest: ServiceBillingUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminServiceBillingById(id, serviceBillingUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminServiceBillingsApi - factory interface
 * @export
 */
export const AdminServiceBillingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminServiceBillingsApiFp(configuration)
    return {
        /**
         * Approve a service-billing run by id
         * @summary Approve a service-billing run by id
         * @param {string} id uuid of the service-billing run object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAdminServiceBillingRunById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.approveAdminServiceBillingRunById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a service-billing run
         * @summary Create a service-billing run
         * @param {ServiceBillingRunCreateAdminRequest} serviceBillingRunCreateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminServiceBillingRun(serviceBillingRunCreateAdminRequest: ServiceBillingRunCreateAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.createAdminServiceBillingRun(serviceBillingRunCreateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a service-billing entity by id
         * @summary Delete a service-billing entity by id
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminServiceBillingById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminServiceBillingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a service-billing run by id
         * @summary Delete a service-billing run by id
         * @param {string} id uuid of the service-billing run object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminServiceBillingRunById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminServiceBillingRunById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a service-billing entity by id
         * @summary Get a service-billing entity by id
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminServiceBillingById(id: string, options?: any): AxiosPromise<ServiceBillingAdminResponse> {
            return localVarFp.getAdminServiceBillingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a service-billing run by id
         * @summary Get a service-billing run by id
         * @param {string} id uuid of the service-billing run object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminServiceBillingRunById(id: string, options?: any): AxiosPromise<ServiceBillingRunAdminResponse> {
            return localVarFp.getAdminServiceBillingRunById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all service billings
         * @summary Get all service billings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminServiceBillings(options?: any): AxiosPromise<MultiServiceBillingAdminResponse> {
            return localVarFp.getAdminServiceBillings(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all initial and recurring service billings
         * @summary Get all initial and recurring service billings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUnifiedServiceBillings(options?: any): AxiosPromise<MultiUnifiedServiceBillingAdminResponse> {
            return localVarFp.getAdminUnifiedServiceBillings(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all service-billing runs
         * @summary Get all service-billing runs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminServiceBillingRuns(options?: any): AxiosPromise<ServiceBillingRunMultiAdminResponse> {
            return localVarFp.getAllAdminServiceBillingRuns(options).then((request) => request(axios, basePath));
        },
        /**
         * Lifecycle state change for a service-billing entity
         * @summary Lifecycle state change for a service-billing entity
         * @param {string} id uuid of the Billing object
         * @param {'approve' | 'cancel'} approveOrCancelAction the lifecycle action to perform on this service-billing entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminServiceBillingStateChangeById(id: string, approveOrCancelAction: 'approve' | 'cancel', options?: any): AxiosPromise<void> {
            return localVarFp.putAdminServiceBillingStateChangeById(id, approveOrCancelAction, options).then((request) => request(axios, basePath));
        },
        /**
         * Recalculate a service-billing by id
         * @summary Recalculate a service-billing by id
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateAdminServiceBillingById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.recalculateAdminServiceBillingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a contract from an existing a service-billing run by id
         * @summary Remove a contract from an existing a service-billing run by id
         * @param {string} id uuid of the service-billing run object
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAdminServiceBillingRunContractById(id: string, contractId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeAdminServiceBillingRunContractById(id, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Send a service-billing details by SAP
         * @summary Send a service-billing details by SAP
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sapSendAdminServiceBillingById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.sapSendAdminServiceBillingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Send a service-billing run details to SAP
         * @summary Send a service-billing run details to SAP
         * @param {string} id uuid of the service-billing run object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sapSendAdminServiceBillingRunById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.sapSendAdminServiceBillingRunById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Flag an initial service-billing as manually created
         * @summary Flag an initial service-billing as manually created
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAdminServiceBillingAsManuallyCreated(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.setAdminServiceBillingAsManuallyCreated(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a service-billing entity by id
         * @summary Update a service-billing entity by id
         * @param {string} id uuid of the Billing object
         * @param {ServiceBillingUpdateAdminRequest} serviceBillingUpdateAdminRequest A service-billing update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminServiceBillingById(id: string, serviceBillingUpdateAdminRequest: ServiceBillingUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAdminServiceBillingById(id, serviceBillingUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminServiceBillingsApi - interface
 * @export
 * @interface AdminServiceBillingsApi
 */
export interface AdminServiceBillingsApiInterface {
    /**
     * Approve a service-billing run by id
     * @summary Approve a service-billing run by id
     * @param {string} id uuid of the service-billing run object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    approveAdminServiceBillingRunById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Create a service-billing run
     * @summary Create a service-billing run
     * @param {ServiceBillingRunCreateAdminRequest} serviceBillingRunCreateAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    createAdminServiceBillingRun(serviceBillingRunCreateAdminRequest: ServiceBillingRunCreateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Delete a service-billing entity by id
     * @summary Delete a service-billing entity by id
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    deleteAdminServiceBillingById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete a service-billing run by id
     * @summary Delete a service-billing run by id
     * @param {string} id uuid of the service-billing run object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    deleteAdminServiceBillingRunById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get a service-billing entity by id
     * @summary Get a service-billing entity by id
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    getAdminServiceBillingById(id: string, options?: AxiosRequestConfig): AxiosPromise<ServiceBillingAdminResponse>;

    /**
     * Get a service-billing run by id
     * @summary Get a service-billing run by id
     * @param {string} id uuid of the service-billing run object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    getAdminServiceBillingRunById(id: string, options?: AxiosRequestConfig): AxiosPromise<ServiceBillingRunAdminResponse>;

    /**
     * Get all service billings
     * @summary Get all service billings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    getAdminServiceBillings(options?: AxiosRequestConfig): AxiosPromise<MultiServiceBillingAdminResponse>;

    /**
     * Get all initial and recurring service billings
     * @summary Get all initial and recurring service billings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    getAdminUnifiedServiceBillings(options?: AxiosRequestConfig): AxiosPromise<MultiUnifiedServiceBillingAdminResponse>;

    /**
     * Get all service-billing runs
     * @summary Get all service-billing runs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    getAllAdminServiceBillingRuns(options?: AxiosRequestConfig): AxiosPromise<ServiceBillingRunMultiAdminResponse>;

    /**
     * Lifecycle state change for a service-billing entity
     * @summary Lifecycle state change for a service-billing entity
     * @param {string} id uuid of the Billing object
     * @param {'approve' | 'cancel'} approveOrCancelAction the lifecycle action to perform on this service-billing entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    putAdminServiceBillingStateChangeById(id: string, approveOrCancelAction: 'approve' | 'cancel', options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Recalculate a service-billing by id
     * @summary Recalculate a service-billing by id
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    recalculateAdminServiceBillingById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Remove a contract from an existing a service-billing run by id
     * @summary Remove a contract from an existing a service-billing run by id
     * @param {string} id uuid of the service-billing run object
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    removeAdminServiceBillingRunContractById(id: string, contractId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Send a service-billing details by SAP
     * @summary Send a service-billing details by SAP
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    sapSendAdminServiceBillingById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Send a service-billing run details to SAP
     * @summary Send a service-billing run details to SAP
     * @param {string} id uuid of the service-billing run object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    sapSendAdminServiceBillingRunById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Flag an initial service-billing as manually created
     * @summary Flag an initial service-billing as manually created
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    setAdminServiceBillingAsManuallyCreated(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update a service-billing entity by id
     * @summary Update a service-billing entity by id
     * @param {string} id uuid of the Billing object
     * @param {ServiceBillingUpdateAdminRequest} serviceBillingUpdateAdminRequest A service-billing update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApiInterface
     */
    updateAdminServiceBillingById(id: string, serviceBillingUpdateAdminRequest: ServiceBillingUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminServiceBillingsApi - object-oriented interface
 * @export
 * @class AdminServiceBillingsApi
 * @extends {BaseAPI}
 */
export class AdminServiceBillingsApi extends BaseAPI implements AdminServiceBillingsApiInterface {
    /**
     * Approve a service-billing run by id
     * @summary Approve a service-billing run by id
     * @param {string} id uuid of the service-billing run object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public approveAdminServiceBillingRunById(id: string, options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).approveAdminServiceBillingRunById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a service-billing run
     * @summary Create a service-billing run
     * @param {ServiceBillingRunCreateAdminRequest} serviceBillingRunCreateAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public createAdminServiceBillingRun(serviceBillingRunCreateAdminRequest: ServiceBillingRunCreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).createAdminServiceBillingRun(serviceBillingRunCreateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a service-billing entity by id
     * @summary Delete a service-billing entity by id
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public deleteAdminServiceBillingById(id: string, options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).deleteAdminServiceBillingById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a service-billing run by id
     * @summary Delete a service-billing run by id
     * @param {string} id uuid of the service-billing run object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public deleteAdminServiceBillingRunById(id: string, options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).deleteAdminServiceBillingRunById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a service-billing entity by id
     * @summary Get a service-billing entity by id
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public getAdminServiceBillingById(id: string, options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).getAdminServiceBillingById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a service-billing run by id
     * @summary Get a service-billing run by id
     * @param {string} id uuid of the service-billing run object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public getAdminServiceBillingRunById(id: string, options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).getAdminServiceBillingRunById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all service billings
     * @summary Get all service billings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public getAdminServiceBillings(options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).getAdminServiceBillings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all initial and recurring service billings
     * @summary Get all initial and recurring service billings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public getAdminUnifiedServiceBillings(options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).getAdminUnifiedServiceBillings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all service-billing runs
     * @summary Get all service-billing runs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public getAllAdminServiceBillingRuns(options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).getAllAdminServiceBillingRuns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lifecycle state change for a service-billing entity
     * @summary Lifecycle state change for a service-billing entity
     * @param {string} id uuid of the Billing object
     * @param {'approve' | 'cancel'} approveOrCancelAction the lifecycle action to perform on this service-billing entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public putAdminServiceBillingStateChangeById(id: string, approveOrCancelAction: 'approve' | 'cancel', options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).putAdminServiceBillingStateChangeById(id, approveOrCancelAction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Recalculate a service-billing by id
     * @summary Recalculate a service-billing by id
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public recalculateAdminServiceBillingById(id: string, options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).recalculateAdminServiceBillingById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove a contract from an existing a service-billing run by id
     * @summary Remove a contract from an existing a service-billing run by id
     * @param {string} id uuid of the service-billing run object
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public removeAdminServiceBillingRunContractById(id: string, contractId: string, options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).removeAdminServiceBillingRunContractById(id, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send a service-billing details by SAP
     * @summary Send a service-billing details by SAP
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public sapSendAdminServiceBillingById(id: string, options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).sapSendAdminServiceBillingById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send a service-billing run details to SAP
     * @summary Send a service-billing run details to SAP
     * @param {string} id uuid of the service-billing run object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public sapSendAdminServiceBillingRunById(id: string, options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).sapSendAdminServiceBillingRunById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Flag an initial service-billing as manually created
     * @summary Flag an initial service-billing as manually created
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public setAdminServiceBillingAsManuallyCreated(id: string, options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).setAdminServiceBillingAsManuallyCreated(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a service-billing entity by id
     * @summary Update a service-billing entity by id
     * @param {string} id uuid of the Billing object
     * @param {ServiceBillingUpdateAdminRequest} serviceBillingUpdateAdminRequest A service-billing update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceBillingsApi
     */
    public updateAdminServiceBillingById(id: string, serviceBillingUpdateAdminRequest: ServiceBillingUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminServiceBillingsApiFp(this.configuration).updateAdminServiceBillingById(id, serviceBillingUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminSessionApi - axios parameter creator
 * @export
 */
export const AdminSessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Success when the passed token for password refresh is valid
         * @summary Checks if the token is valid
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('adminCheckToken', 'token', token)
            const localVarPath = `/api/admin/v1/session/help/check-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirm an e-mail for the change to be persisted
         * @summary Confirm an e-mail change as part of the verification flow
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminConfirmEmail: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('adminConfirmEmail', 'token', token)
            const localVarPath = `/api/admin/v1/session/help/confirm-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Anyone can forget his password
         * @summary Trigger a reset-password process flow
         * @param {string} email email to initiate a reset-password flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminForgotPassword: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('adminForgotPassword', 'email', email)
            const localVarPath = `/api/admin/v1/session/help/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * When a new login is created for a Profile, the user must complete the flow
         * @summary End a new account password flow
         * @param {string} token Json-web-token authorizing the password change
         * @param {NewAccountPasswordData} newAccountPasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNewAccountPassword: async (token: string, newAccountPasswordData: NewAccountPasswordData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('adminNewAccountPassword', 'token', token)
            // verify required parameter 'newAccountPasswordData' is not null or undefined
            assertParamExists('adminNewAccountPassword', 'newAccountPasswordData', newAccountPasswordData)
            const localVarPath = `/api/admin/v1/session/help/new-account-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAccountPasswordData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If the token from refresh password/activate email has expired it requests new email with token
         * @summary Request new activation email
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNewActivationEmail: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('adminNewActivationEmail', 'token', token)
            const localVarPath = `/api/admin/v1/session/help/request-email-with-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The person who started the reset-password flow can complete it within allotted time
         * @summary End a reset-password flow
         * @param {string} token Json-web-token authorizing the password change
         * @param {ResetPasswordData} resetPasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResetPassword: async (token: string, resetPasswordData: ResetPasswordData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('adminResetPassword', 'token', token)
            // verify required parameter 'resetPasswordData' is not null or undefined
            assertParamExists('adminResetPassword', 'resetPasswordData', resetPasswordData)
            const localVarPath = `/api/admin/v1/session/help/confirm-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An admin session is required to access the admin APIs
         * @summary Create an admin session
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminSession: async (authenticationRequest: AuthenticationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticationRequest' is not null or undefined
            assertParamExists('createAdminSession', 'authenticationRequest', authenticationRequest)
            const localVarPath = `/api/admin/v1/session/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An admin session is required to be refreshed periodically
         * @summary Refresh an existing admin session
         * @param {AuthenticationRefreshRequest} authenticationRefreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAdminSession: async (authenticationRefreshRequest: AuthenticationRefreshRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticationRefreshRequest' is not null or undefined
            assertParamExists('refreshAdminSession', 'authenticationRefreshRequest', authenticationRefreshRequest)
            const localVarPath = `/api/admin/v1/session/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticationRefreshRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSessionApi - functional programming interface
 * @export
 */
export const AdminSessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSessionApiAxiosParamCreator(configuration)
    return {
        /**
         * Success when the passed token for password refresh is valid
         * @summary Checks if the token is valid
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Confirm an e-mail for the change to be persisted
         * @summary Confirm an e-mail change as part of the verification flow
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminConfirmEmail(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminConfirmEmail(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Anyone can forget his password
         * @summary Trigger a reset-password process flow
         * @param {string} email email to initiate a reset-password flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminForgotPassword(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminForgotPassword(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * When a new login is created for a Profile, the user must complete the flow
         * @summary End a new account password flow
         * @param {string} token Json-web-token authorizing the password change
         * @param {NewAccountPasswordData} newAccountPasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNewAccountPassword(token: string, newAccountPasswordData: NewAccountPasswordData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNewAccountPassword(token, newAccountPasswordData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * If the token from refresh password/activate email has expired it requests new email with token
         * @summary Request new activation email
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNewActivationEmail(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNewActivationEmail(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The person who started the reset-password flow can complete it within allotted time
         * @summary End a reset-password flow
         * @param {string} token Json-web-token authorizing the password change
         * @param {ResetPasswordData} resetPasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminResetPassword(token: string, resetPasswordData: ResetPasswordData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminResetPassword(token, resetPasswordData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An admin session is required to access the admin APIs
         * @summary Create an admin session
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdminSession(authenticationRequest: AuthenticationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdminSession(authenticationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An admin session is required to be refreshed periodically
         * @summary Refresh an existing admin session
         * @param {AuthenticationRefreshRequest} authenticationRefreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshAdminSession(authenticationRefreshRequest: AuthenticationRefreshRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshAdminSession(authenticationRefreshRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSessionApi - factory interface
 * @export
 */
export const AdminSessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSessionApiFp(configuration)
    return {
        /**
         * Success when the passed token for password refresh is valid
         * @summary Checks if the token is valid
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckToken(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminCheckToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirm an e-mail for the change to be persisted
         * @summary Confirm an e-mail change as part of the verification flow
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminConfirmEmail(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminConfirmEmail(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Anyone can forget his password
         * @summary Trigger a reset-password process flow
         * @param {string} email email to initiate a reset-password flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminForgotPassword(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminForgotPassword(email, options).then((request) => request(axios, basePath));
        },
        /**
         * When a new login is created for a Profile, the user must complete the flow
         * @summary End a new account password flow
         * @param {string} token Json-web-token authorizing the password change
         * @param {NewAccountPasswordData} newAccountPasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNewAccountPassword(token: string, newAccountPasswordData: NewAccountPasswordData, options?: any): AxiosPromise<void> {
            return localVarFp.adminNewAccountPassword(token, newAccountPasswordData, options).then((request) => request(axios, basePath));
        },
        /**
         * If the token from refresh password/activate email has expired it requests new email with token
         * @summary Request new activation email
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNewActivationEmail(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminNewActivationEmail(token, options).then((request) => request(axios, basePath));
        },
        /**
         * The person who started the reset-password flow can complete it within allotted time
         * @summary End a reset-password flow
         * @param {string} token Json-web-token authorizing the password change
         * @param {ResetPasswordData} resetPasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResetPassword(token: string, resetPasswordData: ResetPasswordData, options?: any): AxiosPromise<void> {
            return localVarFp.adminResetPassword(token, resetPasswordData, options).then((request) => request(axios, basePath));
        },
        /**
         * An admin session is required to access the admin APIs
         * @summary Create an admin session
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminSession(authenticationRequest: AuthenticationRequest, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.createAdminSession(authenticationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * An admin session is required to be refreshed periodically
         * @summary Refresh an existing admin session
         * @param {AuthenticationRefreshRequest} authenticationRefreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAdminSession(authenticationRefreshRequest: AuthenticationRefreshRequest, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.refreshAdminSession(authenticationRefreshRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminSessionApi - interface
 * @export
 * @interface AdminSessionApi
 */
export interface AdminSessionApiInterface {
    /**
     * Success when the passed token for password refresh is valid
     * @summary Checks if the token is valid
     * @param {string} token JWT action token as part of the link sent by e-mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApiInterface
     */
    adminCheckToken(token: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Confirm an e-mail for the change to be persisted
     * @summary Confirm an e-mail change as part of the verification flow
     * @param {string} token JWT action token as part of the link sent by e-mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApiInterface
     */
    adminConfirmEmail(token: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Anyone can forget his password
     * @summary Trigger a reset-password process flow
     * @param {string} email email to initiate a reset-password flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApiInterface
     */
    adminForgotPassword(email: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * When a new login is created for a Profile, the user must complete the flow
     * @summary End a new account password flow
     * @param {string} token Json-web-token authorizing the password change
     * @param {NewAccountPasswordData} newAccountPasswordData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApiInterface
     */
    adminNewAccountPassword(token: string, newAccountPasswordData: NewAccountPasswordData, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * If the token from refresh password/activate email has expired it requests new email with token
     * @summary Request new activation email
     * @param {string} token JWT action token as part of the link sent by e-mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApiInterface
     */
    adminNewActivationEmail(token: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * The person who started the reset-password flow can complete it within allotted time
     * @summary End a reset-password flow
     * @param {string} token Json-web-token authorizing the password change
     * @param {ResetPasswordData} resetPasswordData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApiInterface
     */
    adminResetPassword(token: string, resetPasswordData: ResetPasswordData, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * An admin session is required to access the admin APIs
     * @summary Create an admin session
     * @param {AuthenticationRequest} authenticationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApiInterface
     */
    createAdminSession(authenticationRequest: AuthenticationRequest, options?: AxiosRequestConfig): AxiosPromise<AuthenticationResponse>;

    /**
     * An admin session is required to be refreshed periodically
     * @summary Refresh an existing admin session
     * @param {AuthenticationRefreshRequest} authenticationRefreshRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApiInterface
     */
    refreshAdminSession(authenticationRefreshRequest: AuthenticationRefreshRequest, options?: AxiosRequestConfig): AxiosPromise<AuthenticationResponse>;

}

/**
 * AdminSessionApi - object-oriented interface
 * @export
 * @class AdminSessionApi
 * @extends {BaseAPI}
 */
export class AdminSessionApi extends BaseAPI implements AdminSessionApiInterface {
    /**
     * Success when the passed token for password refresh is valid
     * @summary Checks if the token is valid
     * @param {string} token JWT action token as part of the link sent by e-mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApi
     */
    public adminCheckToken(token: string, options?: AxiosRequestConfig) {
        return AdminSessionApiFp(this.configuration).adminCheckToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Confirm an e-mail for the change to be persisted
     * @summary Confirm an e-mail change as part of the verification flow
     * @param {string} token JWT action token as part of the link sent by e-mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApi
     */
    public adminConfirmEmail(token: string, options?: AxiosRequestConfig) {
        return AdminSessionApiFp(this.configuration).adminConfirmEmail(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Anyone can forget his password
     * @summary Trigger a reset-password process flow
     * @param {string} email email to initiate a reset-password flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApi
     */
    public adminForgotPassword(email: string, options?: AxiosRequestConfig) {
        return AdminSessionApiFp(this.configuration).adminForgotPassword(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * When a new login is created for a Profile, the user must complete the flow
     * @summary End a new account password flow
     * @param {string} token Json-web-token authorizing the password change
     * @param {NewAccountPasswordData} newAccountPasswordData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApi
     */
    public adminNewAccountPassword(token: string, newAccountPasswordData: NewAccountPasswordData, options?: AxiosRequestConfig) {
        return AdminSessionApiFp(this.configuration).adminNewAccountPassword(token, newAccountPasswordData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * If the token from refresh password/activate email has expired it requests new email with token
     * @summary Request new activation email
     * @param {string} token JWT action token as part of the link sent by e-mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApi
     */
    public adminNewActivationEmail(token: string, options?: AxiosRequestConfig) {
        return AdminSessionApiFp(this.configuration).adminNewActivationEmail(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The person who started the reset-password flow can complete it within allotted time
     * @summary End a reset-password flow
     * @param {string} token Json-web-token authorizing the password change
     * @param {ResetPasswordData} resetPasswordData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApi
     */
    public adminResetPassword(token: string, resetPasswordData: ResetPasswordData, options?: AxiosRequestConfig) {
        return AdminSessionApiFp(this.configuration).adminResetPassword(token, resetPasswordData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An admin session is required to access the admin APIs
     * @summary Create an admin session
     * @param {AuthenticationRequest} authenticationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApi
     */
    public createAdminSession(authenticationRequest: AuthenticationRequest, options?: AxiosRequestConfig) {
        return AdminSessionApiFp(this.configuration).createAdminSession(authenticationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An admin session is required to be refreshed periodically
     * @summary Refresh an existing admin session
     * @param {AuthenticationRefreshRequest} authenticationRefreshRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSessionApi
     */
    public refreshAdminSession(authenticationRefreshRequest: AuthenticationRefreshRequest, options?: AxiosRequestConfig) {
        return AdminSessionApiFp(this.configuration).refreshAdminSession(authenticationRefreshRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminTariffSchedulesApi - axios parameter creator
 * @export
 */
export const AdminTariffSchedulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add tariff schedule to contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {AddTariffToContractRequest} addTariffToContractRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddTariffScheduleToContract: async (contractId: string, addTariffToContractRequest: AddTariffToContractRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminAddTariffScheduleToContract', 'contractId', contractId)
            // verify required parameter 'addTariffToContractRequest' is not null or undefined
            assertParamExists('adminAddTariffScheduleToContract', 'addTariffToContractRequest', addTariffToContractRequest)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/tariffSchedule`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTariffToContractRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create tariff schedule for admin
         * @param {TariffScheduleRequest} tariffScheduleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateTariffSchedule: async (tariffScheduleRequest: TariffScheduleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tariffScheduleRequest' is not null or undefined
            assertParamExists('adminCreateTariffSchedule', 'tariffScheduleRequest', tariffScheduleRequest)
            const localVarPath = `/api/admin/v1/tariffSchedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tariffScheduleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete tariff schedule by admin
         * @param {string} id uuid of the tariff schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteTariffScheduleById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDeleteTariffScheduleById', 'id', id)
            const localVarPath = `/api/admin/v1/tariffSchedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all tariff schedules for admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllTariffSchedules: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/tariffSchedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve tariff schedule for admin
         * @param {string} id uuid of the tariff schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTariffScheduleById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGetTariffScheduleById', 'id', id)
            const localVarPath = `/api/admin/v1/tariffSchedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve tariff schedule for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTariffScheduleForContract: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('adminGetTariffScheduleForContract', 'contractId', contractId)
            const localVarPath = `/api/admin/v1/contracts/{contractId}/tariffSchedule`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update tariff schedule by admin
         * @param {string} id uuid of the tariff schedule
         * @param {TariffScheduleRequest} tariffScheduleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateTariffScheduleById: async (id: string, tariffScheduleRequest: TariffScheduleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUpdateTariffScheduleById', 'id', id)
            // verify required parameter 'tariffScheduleRequest' is not null or undefined
            assertParamExists('adminUpdateTariffScheduleById', 'tariffScheduleRequest', tariffScheduleRequest)
            const localVarPath = `/api/admin/v1/tariffSchedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tariffScheduleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTariffSchedulesApi - functional programming interface
 * @export
 */
export const AdminTariffSchedulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTariffSchedulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add tariff schedule to contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {AddTariffToContractRequest} addTariffToContractRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddTariffScheduleToContract(contractId: string, addTariffToContractRequest: AddTariffToContractRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddTariffScheduleToContract(contractId, addTariffToContractRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create tariff schedule for admin
         * @param {TariffScheduleRequest} tariffScheduleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateTariffSchedule(tariffScheduleRequest: TariffScheduleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateTariffSchedule(tariffScheduleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete tariff schedule by admin
         * @param {string} id uuid of the tariff schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteTariffScheduleById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteTariffScheduleById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve all tariff schedules for admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAllTariffSchedules(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffSchedulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAllTariffSchedules(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve tariff schedule for admin
         * @param {string} id uuid of the tariff schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTariffScheduleById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTariffScheduleById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve tariff schedule for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTariffScheduleForContract(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTariffScheduleForContract(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update tariff schedule by admin
         * @param {string} id uuid of the tariff schedule
         * @param {TariffScheduleRequest} tariffScheduleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateTariffScheduleById(id: string, tariffScheduleRequest: TariffScheduleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateTariffScheduleById(id, tariffScheduleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminTariffSchedulesApi - factory interface
 * @export
 */
export const AdminTariffSchedulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTariffSchedulesApiFp(configuration)
    return {
        /**
         * 
         * @summary Add tariff schedule to contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {AddTariffToContractRequest} addTariffToContractRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddTariffScheduleToContract(contractId: string, addTariffToContractRequest: AddTariffToContractRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAddTariffScheduleToContract(contractId, addTariffToContractRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create tariff schedule for admin
         * @param {TariffScheduleRequest} tariffScheduleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateTariffSchedule(tariffScheduleRequest: TariffScheduleRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateTariffSchedule(tariffScheduleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete tariff schedule by admin
         * @param {string} id uuid of the tariff schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteTariffScheduleById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteTariffScheduleById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve all tariff schedules for admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllTariffSchedules(options?: any): AxiosPromise<TariffSchedulesResponse> {
            return localVarFp.adminGetAllTariffSchedules(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve tariff schedule for admin
         * @param {string} id uuid of the tariff schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTariffScheduleById(id: string, options?: any): AxiosPromise<TariffScheduleResponse> {
            return localVarFp.adminGetTariffScheduleById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve tariff schedule for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTariffScheduleForContract(contractId: string, options?: any): AxiosPromise<TariffScheduleResponse> {
            return localVarFp.adminGetTariffScheduleForContract(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update tariff schedule by admin
         * @param {string} id uuid of the tariff schedule
         * @param {TariffScheduleRequest} tariffScheduleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateTariffScheduleById(id: string, tariffScheduleRequest: TariffScheduleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateTariffScheduleById(id, tariffScheduleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTariffSchedulesApi - interface
 * @export
 * @interface AdminTariffSchedulesApi
 */
export interface AdminTariffSchedulesApiInterface {
    /**
     * 
     * @summary Add tariff schedule to contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {AddTariffToContractRequest} addTariffToContractRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApiInterface
     */
    adminAddTariffScheduleToContract(contractId: string, addTariffToContractRequest: AddTariffToContractRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Create tariff schedule for admin
     * @param {TariffScheduleRequest} tariffScheduleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApiInterface
     */
    adminCreateTariffSchedule(tariffScheduleRequest: TariffScheduleRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Delete tariff schedule by admin
     * @param {string} id uuid of the tariff schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApiInterface
     */
    adminDeleteTariffScheduleById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Retrieve all tariff schedules for admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApiInterface
     */
    adminGetAllTariffSchedules(options?: AxiosRequestConfig): AxiosPromise<TariffSchedulesResponse>;

    /**
     * 
     * @summary Retrieve tariff schedule for admin
     * @param {string} id uuid of the tariff schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApiInterface
     */
    adminGetTariffScheduleById(id: string, options?: AxiosRequestConfig): AxiosPromise<TariffScheduleResponse>;

    /**
     * 
     * @summary Retrieve tariff schedule for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApiInterface
     */
    adminGetTariffScheduleForContract(contractId: string, options?: AxiosRequestConfig): AxiosPromise<TariffScheduleResponse>;

    /**
     * 
     * @summary Update tariff schedule by admin
     * @param {string} id uuid of the tariff schedule
     * @param {TariffScheduleRequest} tariffScheduleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApiInterface
     */
    adminUpdateTariffScheduleById(id: string, tariffScheduleRequest: TariffScheduleRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminTariffSchedulesApi - object-oriented interface
 * @export
 * @class AdminTariffSchedulesApi
 * @extends {BaseAPI}
 */
export class AdminTariffSchedulesApi extends BaseAPI implements AdminTariffSchedulesApiInterface {
    /**
     * 
     * @summary Add tariff schedule to contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {AddTariffToContractRequest} addTariffToContractRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApi
     */
    public adminAddTariffScheduleToContract(contractId: string, addTariffToContractRequest: AddTariffToContractRequest, options?: AxiosRequestConfig) {
        return AdminTariffSchedulesApiFp(this.configuration).adminAddTariffScheduleToContract(contractId, addTariffToContractRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create tariff schedule for admin
     * @param {TariffScheduleRequest} tariffScheduleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApi
     */
    public adminCreateTariffSchedule(tariffScheduleRequest: TariffScheduleRequest, options?: AxiosRequestConfig) {
        return AdminTariffSchedulesApiFp(this.configuration).adminCreateTariffSchedule(tariffScheduleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete tariff schedule by admin
     * @param {string} id uuid of the tariff schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApi
     */
    public adminDeleteTariffScheduleById(id: string, options?: AxiosRequestConfig) {
        return AdminTariffSchedulesApiFp(this.configuration).adminDeleteTariffScheduleById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve all tariff schedules for admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApi
     */
    public adminGetAllTariffSchedules(options?: AxiosRequestConfig) {
        return AdminTariffSchedulesApiFp(this.configuration).adminGetAllTariffSchedules(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve tariff schedule for admin
     * @param {string} id uuid of the tariff schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApi
     */
    public adminGetTariffScheduleById(id: string, options?: AxiosRequestConfig) {
        return AdminTariffSchedulesApiFp(this.configuration).adminGetTariffScheduleById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve tariff schedule for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApi
     */
    public adminGetTariffScheduleForContract(contractId: string, options?: AxiosRequestConfig) {
        return AdminTariffSchedulesApiFp(this.configuration).adminGetTariffScheduleForContract(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update tariff schedule by admin
     * @param {string} id uuid of the tariff schedule
     * @param {TariffScheduleRequest} tariffScheduleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTariffSchedulesApi
     */
    public adminUpdateTariffScheduleById(id: string, tariffScheduleRequest: TariffScheduleRequest, options?: AxiosRequestConfig) {
        return AdminTariffSchedulesApiFp(this.configuration).adminUpdateTariffScheduleById(id, tariffScheduleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminTasksApi - axios parameter creator
 * @export
 */
export const AdminTasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * complete a task
         * @summary complete a task
         * @param {string} taskId uuid of the admin task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeAdminTaskById: async (taskId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('completeAdminTaskById', 'taskId', taskId)
            const localVarPath = `/api/admin/v1/tasks/{taskId}/complete`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get all tasks
         * @summary get all tasks
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {string} [searchQuery] The search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTasks: async (page?: number, limit?: number, orderBy?: string, searchQuery?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update a task\'s comment
         * @summary update a task\'s comment
         * @param {string} taskId uuid of the admin task
         * @param {UpdateCommentRequest} updateCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskCommentById: async (taskId: string, updateCommentRequest: UpdateCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('updateTaskCommentById', 'taskId', taskId)
            // verify required parameter 'updateCommentRequest' is not null or undefined
            assertParamExists('updateTaskCommentById', 'updateCommentRequest', updateCommentRequest)
            const localVarPath = `/api/admin/v1/tasks/{taskId}/comments`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTasksApi - functional programming interface
 * @export
 */
export const AdminTasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTasksApiAxiosParamCreator(configuration)
    return {
        /**
         * complete a task
         * @summary complete a task
         * @param {string} taskId uuid of the admin task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeAdminTaskById(taskId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeAdminTaskById(taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get all tasks
         * @summary get all tasks
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {string} [searchQuery] The search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminTasks(page?: number, limit?: number, orderBy?: string, searchQuery?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedTaskAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTasks(page, limit, orderBy, searchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update a task\'s comment
         * @summary update a task\'s comment
         * @param {string} taskId uuid of the admin task
         * @param {UpdateCommentRequest} updateCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskCommentById(taskId: string, updateCommentRequest: UpdateCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskCommentById(taskId, updateCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminTasksApi - factory interface
 * @export
 */
export const AdminTasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTasksApiFp(configuration)
    return {
        /**
         * complete a task
         * @summary complete a task
         * @param {string} taskId uuid of the admin task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeAdminTaskById(taskId: string, options?: any): AxiosPromise<void> {
            return localVarFp.completeAdminTaskById(taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * get all tasks
         * @summary get all tasks
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {string} [searchQuery] The search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTasks(page?: number, limit?: number, orderBy?: string, searchQuery?: string, options?: any): AxiosPromise<PagedTaskAdminResponse> {
            return localVarFp.getAdminTasks(page, limit, orderBy, searchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * update a task\'s comment
         * @summary update a task\'s comment
         * @param {string} taskId uuid of the admin task
         * @param {UpdateCommentRequest} updateCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskCommentById(taskId: string, updateCommentRequest: UpdateCommentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateTaskCommentById(taskId, updateCommentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTasksApi - interface
 * @export
 * @interface AdminTasksApi
 */
export interface AdminTasksApiInterface {
    /**
     * complete a task
     * @summary complete a task
     * @param {string} taskId uuid of the admin task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTasksApiInterface
     */
    completeAdminTaskById(taskId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * get all tasks
     * @summary get all tasks
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {string} [searchQuery] The search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTasksApiInterface
     */
    getAdminTasks(page?: number, limit?: number, orderBy?: string, searchQuery?: string, options?: AxiosRequestConfig): AxiosPromise<PagedTaskAdminResponse>;

    /**
     * update a task\'s comment
     * @summary update a task\'s comment
     * @param {string} taskId uuid of the admin task
     * @param {UpdateCommentRequest} updateCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTasksApiInterface
     */
    updateTaskCommentById(taskId: string, updateCommentRequest: UpdateCommentRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminTasksApi - object-oriented interface
 * @export
 * @class AdminTasksApi
 * @extends {BaseAPI}
 */
export class AdminTasksApi extends BaseAPI implements AdminTasksApiInterface {
    /**
     * complete a task
     * @summary complete a task
     * @param {string} taskId uuid of the admin task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTasksApi
     */
    public completeAdminTaskById(taskId: string, options?: AxiosRequestConfig) {
        return AdminTasksApiFp(this.configuration).completeAdminTaskById(taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get all tasks
     * @summary get all tasks
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {string} [searchQuery] The search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTasksApi
     */
    public getAdminTasks(page?: number, limit?: number, orderBy?: string, searchQuery?: string, options?: AxiosRequestConfig) {
        return AdminTasksApiFp(this.configuration).getAdminTasks(page, limit, orderBy, searchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update a task\'s comment
     * @summary update a task\'s comment
     * @param {string} taskId uuid of the admin task
     * @param {UpdateCommentRequest} updateCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTasksApi
     */
    public updateTaskCommentById(taskId: string, updateCommentRequest: UpdateCommentRequest, options?: AxiosRequestConfig) {
        return AdminTasksApiFp(this.configuration).updateTaskCommentById(taskId, updateCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminUtilityUnitApi - axios parameter creator
 * @export
 */
export const AdminUtilityUnitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a Meter and link it to the Utility Unit
         * @summary Create a Meter with the provided details
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {MeterCreateAdminRequest} meterCreateAdminRequest A Meter admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateMeterForUtilityUnit: async (utilityUnitId: string, meterCreateAdminRequest: MeterCreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminCreateMeterForUtilityUnit', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'meterCreateAdminRequest' is not null or undefined
            assertParamExists('adminCreateMeterForUtilityUnit', 'meterCreateAdminRequest', meterCreateAdminRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/meters`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meterCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Utility Unit and link it to the provided Contract
         * @summary Create a Utility Unit with the provided details
         * @param {string} buildingId UUID of the Building
         * @param {UtilityUnitCreateAdminRequest} utilityUnitCreateAdminRequest A Utility Unit admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUtilityUnitForBuilding: async (buildingId: string, utilityUnitCreateAdminRequest: UtilityUnitCreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('adminCreateUtilityUnitForBuilding', 'buildingId', buildingId)
            // verify required parameter 'utilityUnitCreateAdminRequest' is not null or undefined
            assertParamExists('adminCreateUtilityUnitForBuilding', 'utilityUnitCreateAdminRequest', utilityUnitCreateAdminRequest)
            const localVarPath = `/api/admin/v1/buildings/{buildingId}/utilityUnits`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(utilityUnitCreateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An active Utility Unit can be deactivated, triggering the deactivation/deletion of its child objects.
         * @summary Deactivate a Utility Unit by its id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} from the date the deactivation begins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeactivateUtilityUnitById: async (utilityUnitId: string, from: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminDeactivateUtilityUnitById', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('adminDeactivateUtilityUnitById', 'from', from)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/deactivate`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a utilityUnit by its utilityUnitId
         * @summary Delete a utilityUnit by its utilityUnitId
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteUtilityUnitById: async (utilityUnitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminDeleteUtilityUnitById', 'utilityUnitId', utilityUnitId)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides calculated meter-readings for a utilityUnitId over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific utilityUnitId by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsByUtilityUnitId: async (utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminGetMeterReadingsByUtilityUnitId', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsByUtilityUnitId', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsByUtilityUnitId', 'endDate', endDate)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('adminGetMeterReadingsByUtilityUnitId', 'resolution', resolution)
            const localVarPath = `/api/admin/v1/meter-readings/utilityUnits/{utilityUnitId}`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }

            if (isFinalOnly !== undefined) {
                localVarQueryParameter['isFinalOnly'] = isFinalOnly;
            }

            if (contractType !== undefined) {
                localVarQueryParameter['contractType'] = contractType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides calculated meter-readings inspection for a meter over a time period divided according to the provided time resolution
         * @summary Get meter-readings inspection for a specific meter by metering code
         * @param {string} meteringCode metering code of Meter
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsInspectionByMeteringCode: async (meteringCode: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meteringCode' is not null or undefined
            assertParamExists('adminGetMeterReadingsInspectionByMeteringCode', 'meteringCode', meteringCode)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsInspectionByMeteringCode', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('adminGetMeterReadingsInspectionByMeteringCode', 'endDate', endDate)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('adminGetMeterReadingsInspectionByMeteringCode', 'resolution', resolution)
            const localVarPath = `/api/admin/v1/meter-readings/meters/{meteringCode}/inspection`
                .replace(`{${"meteringCode"}}`, encodeURIComponent(String(meteringCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }

            if (isFinalOnly !== undefined) {
                localVarQueryParameter['isFinalOnly'] = isFinalOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Meters linked to the Utility Unit
         * @summary Get meters with the provided details
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMetersForUtilityUnit: async (utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminGetMetersForUtilityUnit', 'utilityUnitId', utilityUnitId)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/meters`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all participation of a utility  unit
         * @summary Get all participation of a utility unit
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParticipationByUtilityUnitId: async (utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminGetParticipationByUtilityUnitId', 'utilityUnitId', utilityUnitId)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the billings of the utility unit
         * @summary Get all the billings of the utility unit
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetUtilityUnitBillingsById: async (utilityUnitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminGetUtilityUnitBillingsById', 'utilityUnitId', utilityUnitId)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/billings`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Utility Unit can be fetched by their respective UUID
         * @summary Get a Utility Unit by its ID
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetUtilityUnitById: async (utilityUnitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminGetUtilityUnitById', 'utilityUnitId', utilityUnitId)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Utility units, with some possible slicing and dicing
         * @summary Get all Utility units
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetUtilityUnits: async (page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/utilityUnits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new participation with a new participant for a vacancy
         * @summary Creates a new participation with a new participant for a vacancy
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveinParticipationExisting: async (utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminMoveinParticipationExisting', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminMoveinParticipationExisting', 'participationId', participationId)
            // verify required parameter 'moveinParticipationExistingRequest' is not null or undefined
            assertParamExists('adminMoveinParticipationExisting', 'moveinParticipationExistingRequest', moveinParticipationExistingRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein/existing`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveinParticipationExistingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create the participation for a utilityUnit with new participant
         * @summary Create the participation for a utilityUnit with new participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinWithNewParticipantAdminRequest} moveinWithNewParticipantAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveinWithNewParticipant: async (utilityUnitId: string, participationId: string, moveinWithNewParticipantAdminRequest: MoveinWithNewParticipantAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminMoveinWithNewParticipant', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminMoveinWithNewParticipant', 'participationId', participationId)
            // verify required parameter 'moveinWithNewParticipantAdminRequest' is not null or undefined
            assertParamExists('adminMoveinWithNewParticipant', 'moveinWithNewParticipantAdminRequest', moveinWithNewParticipantAdminRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein/new`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveinWithNewParticipantAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a vacancy after the moveout
         * @summary Creates a vacancy after the moveout
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveoutParticipationVacancy: async (utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminMoveoutParticipationVacancy', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminMoveoutParticipationVacancy', 'participationId', participationId)
            // verify required parameter 'moveoutParticipationVacancyRequest' is not null or undefined
            assertParamExists('adminMoveoutParticipationVacancy', 'moveoutParticipationVacancyRequest', moveoutParticipationVacancyRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/moveout/successor/vacancy`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveoutParticipationVacancyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change the movein date of a participation
         * @summary Change the movein date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationChangeMoveinDate: async (utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminParticipationChangeMoveinDate', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminParticipationChangeMoveinDate', 'participationId', participationId)
            // verify required parameter 'participationUpdateMoveinRequest' is not null or undefined
            assertParamExists('adminParticipationChangeMoveinDate', 'participationUpdateMoveinRequest', participationUpdateMoveinRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participationUpdateMoveinRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change the moveout date of a participation
         * @summary Change the moveout date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationChangeMoveoutDate: async (utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminParticipationChangeMoveoutDate', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminParticipationChangeMoveoutDate', 'participationId', participationId)
            // verify required parameter 'participationUpdateMoveoutRequest' is not null or undefined
            assertParamExists('adminParticipationChangeMoveoutDate', 'participationUpdateMoveoutRequest', participationUpdateMoveoutRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/moveout`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participationUpdateMoveoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stops the movein of a participant
         * @summary Stops the movein of a participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationStopMovein: async (utilityUnitId: string, participationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminParticipationStopMovein', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminParticipationStopMovein', 'participationId', participationId)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stops the moveout of the participant
         * @summary Stops the moveout of the participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationStopMoveout: async (utilityUnitId: string, participationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminParticipationStopMoveout', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminParticipationStopMoveout', 'participationId', participationId)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/moveout`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Utility unit can be updated by its respective UUID
         * @summary Put a Utility unit by its ID
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {UtilityUnitUpdateAdminRequest} utilityUnitUpdateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPutUtilityUnitById: async (utilityUnitId: string, utilityUnitUpdateAdminRequest: UtilityUnitUpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminPutUtilityUnitById', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'utilityUnitUpdateAdminRequest' is not null or undefined
            assertParamExists('adminPutUtilityUnitById', 'utilityUnitUpdateAdminRequest', utilityUnitUpdateAdminRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(utilityUnitUpdateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replace Participant for a Participation
         * @summary Replace Participant for a Participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ReplaceParticipantRequest} replaceParticipantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReplaceParticipant: async (utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('adminReplaceParticipant', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('adminReplaceParticipant', 'participationId', participationId)
            // verify required parameter 'replaceParticipantRequest' is not null or undefined
            assertParamExists('adminReplaceParticipant', 'replaceParticipantRequest', replaceParticipantRequest)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/participant/replace`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceParticipantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a participation by id
         * @summary Get a participation by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminParticipationById: async (utilityUnitId: string, participationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('getAdminParticipationById', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('getAdminParticipationById', 'participationId', participationId)
            const localVarPath = `/api/admin/v1/utilityUnits/{utilityUnitId}/participations/{participationId}`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUtilityUnitApi - functional programming interface
 * @export
 */
export const AdminUtilityUnitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUtilityUnitApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a Meter and link it to the Utility Unit
         * @summary Create a Meter with the provided details
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {MeterCreateAdminRequest} meterCreateAdminRequest A Meter admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateMeterForUtilityUnit(utilityUnitId: string, meterCreateAdminRequest: MeterCreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateMeterForUtilityUnit(utilityUnitId, meterCreateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Utility Unit and link it to the provided Contract
         * @summary Create a Utility Unit with the provided details
         * @param {string} buildingId UUID of the Building
         * @param {UtilityUnitCreateAdminRequest} utilityUnitCreateAdminRequest A Utility Unit admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateUtilityUnitForBuilding(buildingId: string, utilityUnitCreateAdminRequest: UtilityUnitCreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateUtilityUnitForBuilding(buildingId, utilityUnitCreateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An active Utility Unit can be deactivated, triggering the deactivation/deletion of its child objects.
         * @summary Deactivate a Utility Unit by its id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} from the date the deactivation begins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeactivateUtilityUnitById(utilityUnitId: string, from: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeactivateUtilityUnitById(utilityUnitId, from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a utilityUnit by its utilityUnitId
         * @summary Delete a utilityUnit by its utilityUnitId
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteUtilityUnitById(utilityUnitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteUtilityUnitById(utilityUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides calculated meter-readings for a utilityUnitId over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific utilityUnitId by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadingsUtilityUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMeterReadingsByUtilityUnitId(utilityUnitId, startDate, endDate, resolution, isFinalOnly, contractType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides calculated meter-readings inspection for a meter over a time period divided according to the provided time resolution
         * @summary Get meter-readings inspection for a specific meter by metering code
         * @param {string} meteringCode metering code of Meter
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMeterReadingsInspectionByMeteringCode(meteringCode: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterDataCalcCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMeterReadingsInspectionByMeteringCode(meteringCode, startDate, endDate, resolution, isFinalOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Meters linked to the Utility Unit
         * @summary Get meters with the provided details
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMetersForUtilityUnit(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMeterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMetersForUtilityUnit(utilityUnitId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all participation of a utility  unit
         * @summary Get all participation of a utility unit
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParticipationByUtilityUnitId(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedParticipationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParticipationByUtilityUnitId(utilityUnitId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the billings of the utility unit
         * @summary Get all the billings of the utility unit
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetUtilityUnitBillingsById(utilityUnitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiBillingDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetUtilityUnitBillingsById(utilityUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Utility Unit can be fetched by their respective UUID
         * @summary Get a Utility Unit by its ID
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetUtilityUnitById(utilityUnitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilityUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetUtilityUnitById(utilityUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Utility units, with some possible slicing and dicing
         * @summary Get all Utility units
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetUtilityUnits(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedUtilityUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetUtilityUnits(page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new participation with a new participant for a vacancy
         * @summary Creates a new participation with a new participant for a vacancy
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMoveinParticipationExisting(utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMoveinParticipationExisting(utilityUnitId, participationId, moveinParticipationExistingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create the participation for a utilityUnit with new participant
         * @summary Create the participation for a utilityUnit with new participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinWithNewParticipantAdminRequest} moveinWithNewParticipantAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantAdminRequest: MoveinWithNewParticipantAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMoveinWithNewParticipant(utilityUnitId, participationId, moveinWithNewParticipantAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a vacancy after the moveout
         * @summary Creates a vacancy after the moveout
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMoveoutParticipationVacancy(utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMoveoutParticipationVacancy(utilityUnitId, participationId, moveoutParticipationVacancyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Change the movein date of a participation
         * @summary Change the movein date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminParticipationChangeMoveinDate(utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminParticipationChangeMoveinDate(utilityUnitId, participationId, participationUpdateMoveinRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Change the moveout date of a participation
         * @summary Change the moveout date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminParticipationChangeMoveoutDate(utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminParticipationChangeMoveoutDate(utilityUnitId, participationId, participationUpdateMoveoutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stops the movein of a participant
         * @summary Stops the movein of a participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminParticipationStopMovein(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminParticipationStopMovein(utilityUnitId, participationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stops the moveout of the participant
         * @summary Stops the moveout of the participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminParticipationStopMoveout(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminParticipationStopMoveout(utilityUnitId, participationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Utility unit can be updated by its respective UUID
         * @summary Put a Utility unit by its ID
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {UtilityUnitUpdateAdminRequest} utilityUnitUpdateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPutUtilityUnitById(utilityUnitId: string, utilityUnitUpdateAdminRequest: UtilityUnitUpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPutUtilityUnitById(utilityUnitId, utilityUnitUpdateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replace Participant for a Participation
         * @summary Replace Participant for a Participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ReplaceParticipantRequest} replaceParticipantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReplaceParticipant(utilityUnitId, participationId, replaceParticipantRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a participation by id
         * @summary Get a participation by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminParticipationById(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminParticipationById(utilityUnitId, participationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminUtilityUnitApi - factory interface
 * @export
 */
export const AdminUtilityUnitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUtilityUnitApiFp(configuration)
    return {
        /**
         * Create a Meter and link it to the Utility Unit
         * @summary Create a Meter with the provided details
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {MeterCreateAdminRequest} meterCreateAdminRequest A Meter admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateMeterForUtilityUnit(utilityUnitId: string, meterCreateAdminRequest: MeterCreateAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateMeterForUtilityUnit(utilityUnitId, meterCreateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Utility Unit and link it to the provided Contract
         * @summary Create a Utility Unit with the provided details
         * @param {string} buildingId UUID of the Building
         * @param {UtilityUnitCreateAdminRequest} utilityUnitCreateAdminRequest A Utility Unit admin create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUtilityUnitForBuilding(buildingId: string, utilityUnitCreateAdminRequest: UtilityUnitCreateAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateUtilityUnitForBuilding(buildingId, utilityUnitCreateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * An active Utility Unit can be deactivated, triggering the deactivation/deletion of its child objects.
         * @summary Deactivate a Utility Unit by its id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} from the date the deactivation begins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeactivateUtilityUnitById(utilityUnitId: string, from: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeactivateUtilityUnitById(utilityUnitId, from, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a utilityUnit by its utilityUnitId
         * @summary Delete a utilityUnit by its utilityUnitId
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteUtilityUnitById(utilityUnitId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteUtilityUnitById(utilityUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides calculated meter-readings for a utilityUnitId over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific utilityUnitId by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: any): AxiosPromise<MeterReadingsUtilityUnitResponse> {
            return localVarFp.adminGetMeterReadingsByUtilityUnitId(utilityUnitId, startDate, endDate, resolution, isFinalOnly, contractType, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides calculated meter-readings inspection for a meter over a time period divided according to the provided time resolution
         * @summary Get meter-readings inspection for a specific meter by metering code
         * @param {string} meteringCode metering code of Meter
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMeterReadingsInspectionByMeteringCode(meteringCode: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, options?: any): AxiosPromise<MeterDataCalcCountResponse> {
            return localVarFp.adminGetMeterReadingsInspectionByMeteringCode(meteringCode, startDate, endDate, resolution, isFinalOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Meters linked to the Utility Unit
         * @summary Get meters with the provided details
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMetersForUtilityUnit(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedMeterResponse> {
            return localVarFp.adminGetMetersForUtilityUnit(utilityUnitId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all participation of a utility  unit
         * @summary Get all participation of a utility unit
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParticipationByUtilityUnitId(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedParticipationResponse> {
            return localVarFp.adminGetParticipationByUtilityUnitId(utilityUnitId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the billings of the utility unit
         * @summary Get all the billings of the utility unit
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetUtilityUnitBillingsById(utilityUnitId: string, options?: any): AxiosPromise<MultiBillingDetailsResponse> {
            return localVarFp.adminGetUtilityUnitBillingsById(utilityUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Utility Unit can be fetched by their respective UUID
         * @summary Get a Utility Unit by its ID
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetUtilityUnitById(utilityUnitId: string, options?: any): AxiosPromise<UtilityUnitResponse> {
            return localVarFp.adminGetUtilityUnitById(utilityUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Utility units, with some possible slicing and dicing
         * @summary Get all Utility units
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetUtilityUnits(page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedUtilityUnitResponse> {
            return localVarFp.adminGetUtilityUnits(page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new participation with a new participant for a vacancy
         * @summary Creates a new participation with a new participant for a vacancy
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveinParticipationExisting(utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminMoveinParticipationExisting(utilityUnitId, participationId, moveinParticipationExistingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create the participation for a utilityUnit with new participant
         * @summary Create the participation for a utilityUnit with new participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinWithNewParticipantAdminRequest} moveinWithNewParticipantAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantAdminRequest: MoveinWithNewParticipantAdminRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminMoveinWithNewParticipant(utilityUnitId, participationId, moveinWithNewParticipantAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a vacancy after the moveout
         * @summary Creates a vacancy after the moveout
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveoutParticipationVacancy(utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminMoveoutParticipationVacancy(utilityUnitId, participationId, moveoutParticipationVacancyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Change the movein date of a participation
         * @summary Change the movein date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationChangeMoveinDate(utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminParticipationChangeMoveinDate(utilityUnitId, participationId, participationUpdateMoveinRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Change the moveout date of a participation
         * @summary Change the moveout date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationChangeMoveoutDate(utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminParticipationChangeMoveoutDate(utilityUnitId, participationId, participationUpdateMoveoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Stops the movein of a participant
         * @summary Stops the movein of a participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationStopMovein(utilityUnitId: string, participationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminParticipationStopMovein(utilityUnitId, participationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Stops the moveout of the participant
         * @summary Stops the moveout of the participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminParticipationStopMoveout(utilityUnitId: string, participationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminParticipationStopMoveout(utilityUnitId, participationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Utility unit can be updated by its respective UUID
         * @summary Put a Utility unit by its ID
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {UtilityUnitUpdateAdminRequest} utilityUnitUpdateAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPutUtilityUnitById(utilityUnitId: string, utilityUnitUpdateAdminRequest: UtilityUnitUpdateAdminRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminPutUtilityUnitById(utilityUnitId, utilityUnitUpdateAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replace Participant for a Participation
         * @summary Replace Participant for a Participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ReplaceParticipantRequest} replaceParticipantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminReplaceParticipant(utilityUnitId, participationId, replaceParticipantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a participation by id
         * @summary Get a participation by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminParticipationById(utilityUnitId: string, participationId: string, options?: any): AxiosPromise<ParticipationResponse> {
            return localVarFp.getAdminParticipationById(utilityUnitId, participationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUtilityUnitApi - interface
 * @export
 * @interface AdminUtilityUnitApi
 */
export interface AdminUtilityUnitApiInterface {
    /**
     * Create a Meter and link it to the Utility Unit
     * @summary Create a Meter with the provided details
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {MeterCreateAdminRequest} meterCreateAdminRequest A Meter admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminCreateMeterForUtilityUnit(utilityUnitId: string, meterCreateAdminRequest: MeterCreateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Create a Utility Unit and link it to the provided Contract
     * @summary Create a Utility Unit with the provided details
     * @param {string} buildingId UUID of the Building
     * @param {UtilityUnitCreateAdminRequest} utilityUnitCreateAdminRequest A Utility Unit admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminCreateUtilityUnitForBuilding(buildingId: string, utilityUnitCreateAdminRequest: UtilityUnitCreateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * An active Utility Unit can be deactivated, triggering the deactivation/deletion of its child objects.
     * @summary Deactivate a Utility Unit by its id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} from the date the deactivation begins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminDeactivateUtilityUnitById(utilityUnitId: string, from: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete a utilityUnit by its utilityUnitId
     * @summary Delete a utilityUnit by its utilityUnitId
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminDeleteUtilityUnitById(utilityUnitId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Provides calculated meter-readings for a utilityUnitId over a time period divided according to the provided time resolution
     * @summary Get meter-readings for a specific utilityUnitId by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
     * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: AxiosRequestConfig): AxiosPromise<MeterReadingsUtilityUnitResponse>;

    /**
     * Provides calculated meter-readings inspection for a meter over a time period divided according to the provided time resolution
     * @summary Get meter-readings inspection for a specific meter by metering code
     * @param {string} meteringCode metering code of Meter
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminGetMeterReadingsInspectionByMeteringCode(meteringCode: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, options?: AxiosRequestConfig): AxiosPromise<MeterDataCalcCountResponse>;

    /**
     * Get Meters linked to the Utility Unit
     * @summary Get meters with the provided details
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminGetMetersForUtilityUnit(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedMeterResponse>;

    /**
     * Get all participation of a utility  unit
     * @summary Get all participation of a utility unit
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminGetParticipationByUtilityUnitId(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedParticipationResponse>;

    /**
     * Get all the billings of the utility unit
     * @summary Get all the billings of the utility unit
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminGetUtilityUnitBillingsById(utilityUnitId: string, options?: AxiosRequestConfig): AxiosPromise<MultiBillingDetailsResponse>;

    /**
     * Utility Unit can be fetched by their respective UUID
     * @summary Get a Utility Unit by its ID
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminGetUtilityUnitById(utilityUnitId: string, options?: AxiosRequestConfig): AxiosPromise<UtilityUnitResponse>;

    /**
     * Get all Utility units, with some possible slicing and dicing
     * @summary Get all Utility units
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminGetUtilityUnits(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedUtilityUnitResponse>;

    /**
     * Creates a new participation with a new participant for a vacancy
     * @summary Creates a new participation with a new participant for a vacancy
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminMoveinParticipationExisting(utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Create the participation for a utilityUnit with new participant
     * @summary Create the participation for a utilityUnit with new participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinWithNewParticipantAdminRequest} moveinWithNewParticipantAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantAdminRequest: MoveinWithNewParticipantAdminRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Creates a vacancy after the moveout
     * @summary Creates a vacancy after the moveout
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminMoveoutParticipationVacancy(utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Change the movein date of a participation
     * @summary Change the movein date of a participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminParticipationChangeMoveinDate(utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Change the moveout date of a participation
     * @summary Change the moveout date of a participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminParticipationChangeMoveoutDate(utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Stops the movein of a participant
     * @summary Stops the movein of a participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminParticipationStopMovein(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Stops the moveout of the participant
     * @summary Stops the moveout of the participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminParticipationStopMoveout(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Utility unit can be updated by its respective UUID
     * @summary Put a Utility unit by its ID
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {UtilityUnitUpdateAdminRequest} utilityUnitUpdateAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminPutUtilityUnitById(utilityUnitId: string, utilityUnitUpdateAdminRequest: UtilityUnitUpdateAdminRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Replace Participant for a Participation
     * @summary Replace Participant for a Participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ReplaceParticipantRequest} replaceParticipantRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    adminReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Get a participation by id
     * @summary Get a participation by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApiInterface
     */
    getAdminParticipationById(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): AxiosPromise<ParticipationResponse>;

}

/**
 * AdminUtilityUnitApi - object-oriented interface
 * @export
 * @class AdminUtilityUnitApi
 * @extends {BaseAPI}
 */
export class AdminUtilityUnitApi extends BaseAPI implements AdminUtilityUnitApiInterface {
    /**
     * Create a Meter and link it to the Utility Unit
     * @summary Create a Meter with the provided details
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {MeterCreateAdminRequest} meterCreateAdminRequest A Meter admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminCreateMeterForUtilityUnit(utilityUnitId: string, meterCreateAdminRequest: MeterCreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminCreateMeterForUtilityUnit(utilityUnitId, meterCreateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Utility Unit and link it to the provided Contract
     * @summary Create a Utility Unit with the provided details
     * @param {string} buildingId UUID of the Building
     * @param {UtilityUnitCreateAdminRequest} utilityUnitCreateAdminRequest A Utility Unit admin create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminCreateUtilityUnitForBuilding(buildingId: string, utilityUnitCreateAdminRequest: UtilityUnitCreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminCreateUtilityUnitForBuilding(buildingId, utilityUnitCreateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An active Utility Unit can be deactivated, triggering the deactivation/deletion of its child objects.
     * @summary Deactivate a Utility Unit by its id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} from the date the deactivation begins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminDeactivateUtilityUnitById(utilityUnitId: string, from: string, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminDeactivateUtilityUnitById(utilityUnitId, from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a utilityUnit by its utilityUnitId
     * @summary Delete a utilityUnit by its utilityUnitId
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminDeleteUtilityUnitById(utilityUnitId: string, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminDeleteUtilityUnitById(utilityUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides calculated meter-readings for a utilityUnitId over a time period divided according to the provided time resolution
     * @summary Get meter-readings for a specific utilityUnitId by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
     * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminGetMeterReadingsByUtilityUnitId(utilityUnitId, startDate, endDate, resolution, isFinalOnly, contractType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides calculated meter-readings inspection for a meter over a time period divided according to the provided time resolution
     * @summary Get meter-readings inspection for a specific meter by metering code
     * @param {string} meteringCode metering code of Meter
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminGetMeterReadingsInspectionByMeteringCode(meteringCode: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminGetMeterReadingsInspectionByMeteringCode(meteringCode, startDate, endDate, resolution, isFinalOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Meters linked to the Utility Unit
     * @summary Get meters with the provided details
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminGetMetersForUtilityUnit(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminGetMetersForUtilityUnit(utilityUnitId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all participation of a utility  unit
     * @summary Get all participation of a utility unit
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminGetParticipationByUtilityUnitId(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminGetParticipationByUtilityUnitId(utilityUnitId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the billings of the utility unit
     * @summary Get all the billings of the utility unit
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminGetUtilityUnitBillingsById(utilityUnitId: string, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminGetUtilityUnitBillingsById(utilityUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Utility Unit can be fetched by their respective UUID
     * @summary Get a Utility Unit by its ID
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminGetUtilityUnitById(utilityUnitId: string, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminGetUtilityUnitById(utilityUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Utility units, with some possible slicing and dicing
     * @summary Get all Utility units
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminGetUtilityUnits(page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminGetUtilityUnits(page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new participation with a new participant for a vacancy
     * @summary Creates a new participation with a new participant for a vacancy
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminMoveinParticipationExisting(utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminMoveinParticipationExisting(utilityUnitId, participationId, moveinParticipationExistingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create the participation for a utilityUnit with new participant
     * @summary Create the participation for a utilityUnit with new participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinWithNewParticipantAdminRequest} moveinWithNewParticipantAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantAdminRequest: MoveinWithNewParticipantAdminRequest, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminMoveinWithNewParticipant(utilityUnitId, participationId, moveinWithNewParticipantAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a vacancy after the moveout
     * @summary Creates a vacancy after the moveout
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminMoveoutParticipationVacancy(utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminMoveoutParticipationVacancy(utilityUnitId, participationId, moveoutParticipationVacancyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change the movein date of a participation
     * @summary Change the movein date of a participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminParticipationChangeMoveinDate(utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminParticipationChangeMoveinDate(utilityUnitId, participationId, participationUpdateMoveinRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change the moveout date of a participation
     * @summary Change the moveout date of a participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminParticipationChangeMoveoutDate(utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminParticipationChangeMoveoutDate(utilityUnitId, participationId, participationUpdateMoveoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stops the movein of a participant
     * @summary Stops the movein of a participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminParticipationStopMovein(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminParticipationStopMovein(utilityUnitId, participationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stops the moveout of the participant
     * @summary Stops the moveout of the participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminParticipationStopMoveout(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminParticipationStopMoveout(utilityUnitId, participationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Utility unit can be updated by its respective UUID
     * @summary Put a Utility unit by its ID
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {UtilityUnitUpdateAdminRequest} utilityUnitUpdateAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminPutUtilityUnitById(utilityUnitId: string, utilityUnitUpdateAdminRequest: UtilityUnitUpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminPutUtilityUnitById(utilityUnitId, utilityUnitUpdateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replace Participant for a Participation
     * @summary Replace Participant for a Participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ReplaceParticipantRequest} replaceParticipantRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public adminReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).adminReplaceParticipant(utilityUnitId, participationId, replaceParticipantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a participation by id
     * @summary Get a participation by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUtilityUnitApi
     */
    public getAdminParticipationById(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig) {
        return AdminUtilityUnitApiFp(this.configuration).getAdminParticipationById(utilityUnitId, participationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerAllParticipantBillingApi - axios parameter creator
 * @export
 */
export const CustomerAllParticipantBillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes Vewa participant billing data and resets next billing date
         * @summary Customer delete Vewa participant billing data and reset next billing date
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {BillingDateRequest} billingDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerDeleteVewaBillingDataAndResetNextBillingDate: async (vewaAllParticipantBillingId: string, billingDateRequest: BillingDateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('customerDeleteVewaBillingDataAndResetNextBillingDate', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            // verify required parameter 'billingDateRequest' is not null or undefined
            assertParamExists('customerDeleteVewaBillingDataAndResetNextBillingDate', 'billingDateRequest', billingDateRequest)
            const localVarPath = `/api/customer/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/delete`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the billing details of an VewaAllParticipantBilling
         * @summary Get the billing details of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetVewaAllParticipantBillingById: async (vewaAllParticipantBillingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('customerGetVewaAllParticipantBillingById', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/customer/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the participants details of an VewaAllParticipantBilling
         * @summary Get the participants details of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetVewaIndividualParticipationConsumption: async (vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('customerGetVewaIndividualParticipationConsumption', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/customer/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/participationConsumption`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reloads Vewa participant billing data
         * @summary Customer reload Vewa participant billing data
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerReloadVewaParticipantBillingData: async (vewaAllParticipantBillingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('customerReloadVewaParticipantBillingData', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/customer/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/reloadData`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reloads Zev participant billing data
         * @summary Customer reload Zev participant billing data
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerReloadZevParticipantBillingData: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customerReloadZevParticipantBillingData', 'id', id)
            const localVarPath = `/api/customer/v1/allParticipantBilling/{id}/reload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reopens Vewa participant billing in DONE state
         * @summary Customer reopen Vewa participant billing
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerReopenVewaParticipantBilling: async (vewaAllParticipantBillingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('customerReopenVewaParticipantBilling', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/customer/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/reopen`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update cost share settings per participant billing
         * @summary Customer update billing cost shares
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {VewaCostShares} vewaCostShares 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateBillingCostShares: async (vewaAllParticipantBillingId: string, vewaCostShares: VewaCostShares, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('customerUpdateBillingCostShares', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            // verify required parameter 'vewaCostShares' is not null or undefined
            assertParamExists('customerUpdateBillingCostShares', 'vewaCostShares', vewaCostShares)
            const localVarPath = `/api/customer/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/costShares`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vewaCostShares, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the billing details of an AllParticipantBilling
         * @summary Get the billing details of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAllParticipantBillingDetailsById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerAllParticipantBillingDetailsById', 'id', id)
            const localVarPath = `/api/customer/v1/allParticipantBilling/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to approved for AllParticipantBilling
         * @summary State change to approved for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerAllParticipantBillingApproveStateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putCustomerAllParticipantBillingApproveStateById', 'id', id)
            const localVarPath = `/api/customer/v1/allParticipantBilling/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to reopened for AllParticipantBilling
         * @summary State change to reopened for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerAllParticipantBillingReopenStateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putCustomerAllParticipantBillingReopenStateById', 'id', id)
            const localVarPath = `/api/customer/v1/allParticipantBilling/{id}/reopen`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the billing prices of an AllParticipantBilling
         * @summary Update the billing prices of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {AllParticipantBillingPricesUpdateCustomerRequest} allParticipantBillingPricesUpdateCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerAllParticipantBillingPricesById: async (id: string, allParticipantBillingPricesUpdateCustomerRequest: AllParticipantBillingPricesUpdateCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomerAllParticipantBillingPricesById', 'id', id)
            // verify required parameter 'allParticipantBillingPricesUpdateCustomerRequest' is not null or undefined
            assertParamExists('updateCustomerAllParticipantBillingPricesById', 'allParticipantBillingPricesUpdateCustomerRequest', allParticipantBillingPricesUpdateCustomerRequest)
            const localVarPath = `/api/customer/v1/allParticipantBilling/{id}/prices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allParticipantBillingPricesUpdateCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerAllParticipantBillingApi - functional programming interface
 * @export
 */
export const CustomerAllParticipantBillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerAllParticipantBillingApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes Vewa participant billing data and resets next billing date
         * @summary Customer delete Vewa participant billing data and reset next billing date
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {BillingDateRequest} billingDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId: string, billingDateRequest: BillingDateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId, billingDateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the billing details of an VewaAllParticipantBilling
         * @summary Get the billing details of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetVewaAllParticipantBillingById(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VewaAllParticipantsBilling>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetVewaAllParticipantBillingById(vewaAllParticipantBillingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the participants details of an VewaAllParticipantBilling
         * @summary Get the participants details of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedVewaIndividualParticipationConsumptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reloads Vewa participant billing data
         * @summary Customer reload Vewa participant billing data
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerReloadVewaParticipantBillingData(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerReloadVewaParticipantBillingData(vewaAllParticipantBillingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reloads Zev participant billing data
         * @summary Customer reload Zev participant billing data
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerReloadZevParticipantBillingData(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerReloadZevParticipantBillingData(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reopens Vewa participant billing in DONE state
         * @summary Customer reopen Vewa participant billing
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerReopenVewaParticipantBilling(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerReopenVewaParticipantBilling(vewaAllParticipantBillingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update cost share settings per participant billing
         * @summary Customer update billing cost shares
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {VewaCostShares} vewaCostShares 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateBillingCostShares(vewaAllParticipantBillingId: string, vewaCostShares: VewaCostShares, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateBillingCostShares(vewaAllParticipantBillingId, vewaCostShares, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the billing details of an AllParticipantBilling
         * @summary Get the billing details of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerAllParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllParticipantBillingDetailsCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerAllParticipantBillingDetailsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to approved for AllParticipantBilling
         * @summary State change to approved for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomerAllParticipantBillingApproveStateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomerAllParticipantBillingApproveStateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to reopened for AllParticipantBilling
         * @summary State change to reopened for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomerAllParticipantBillingReopenStateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomerAllParticipantBillingReopenStateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the billing prices of an AllParticipantBilling
         * @summary Update the billing prices of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {AllParticipantBillingPricesUpdateCustomerRequest} allParticipantBillingPricesUpdateCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerAllParticipantBillingPricesById(id: string, allParticipantBillingPricesUpdateCustomerRequest: AllParticipantBillingPricesUpdateCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerAllParticipantBillingPricesById(id, allParticipantBillingPricesUpdateCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerAllParticipantBillingApi - factory interface
 * @export
 */
export const CustomerAllParticipantBillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerAllParticipantBillingApiFp(configuration)
    return {
        /**
         * Deletes Vewa participant billing data and resets next billing date
         * @summary Customer delete Vewa participant billing data and reset next billing date
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {BillingDateRequest} billingDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId: string, billingDateRequest: BillingDateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId, billingDateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the billing details of an VewaAllParticipantBilling
         * @summary Get the billing details of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetVewaAllParticipantBillingById(vewaAllParticipantBillingId: string, options?: any): AxiosPromise<VewaAllParticipantsBilling> {
            return localVarFp.customerGetVewaAllParticipantBillingById(vewaAllParticipantBillingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the participants details of an VewaAllParticipantBilling
         * @summary Get the participants details of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedVewaIndividualParticipationConsumptionResponse> {
            return localVarFp.customerGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Reloads Vewa participant billing data
         * @summary Customer reload Vewa participant billing data
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerReloadVewaParticipantBillingData(vewaAllParticipantBillingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerReloadVewaParticipantBillingData(vewaAllParticipantBillingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Reloads Zev participant billing data
         * @summary Customer reload Zev participant billing data
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerReloadZevParticipantBillingData(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerReloadZevParticipantBillingData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Reopens Vewa participant billing in DONE state
         * @summary Customer reopen Vewa participant billing
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerReopenVewaParticipantBilling(vewaAllParticipantBillingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerReopenVewaParticipantBilling(vewaAllParticipantBillingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update cost share settings per participant billing
         * @summary Customer update billing cost shares
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {VewaCostShares} vewaCostShares 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateBillingCostShares(vewaAllParticipantBillingId: string, vewaCostShares: VewaCostShares, options?: any): AxiosPromise<void> {
            return localVarFp.customerUpdateBillingCostShares(vewaAllParticipantBillingId, vewaCostShares, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the billing details of an AllParticipantBilling
         * @summary Get the billing details of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAllParticipantBillingDetailsById(id: string, options?: any): AxiosPromise<AllParticipantBillingDetailsCustomerResponse> {
            return localVarFp.getCustomerAllParticipantBillingDetailsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to approved for AllParticipantBilling
         * @summary State change to approved for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerAllParticipantBillingApproveStateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomerAllParticipantBillingApproveStateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to reopened for AllParticipantBilling
         * @summary State change to reopened for AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerAllParticipantBillingReopenStateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomerAllParticipantBillingReopenStateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the billing prices of an AllParticipantBilling
         * @summary Update the billing prices of an AllParticipantBilling
         * @param {string} id uuid of the all participant billing
         * @param {AllParticipantBillingPricesUpdateCustomerRequest} allParticipantBillingPricesUpdateCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerAllParticipantBillingPricesById(id: string, allParticipantBillingPricesUpdateCustomerRequest: AllParticipantBillingPricesUpdateCustomerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCustomerAllParticipantBillingPricesById(id, allParticipantBillingPricesUpdateCustomerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerAllParticipantBillingApi - interface
 * @export
 * @interface CustomerAllParticipantBillingApi
 */
export interface CustomerAllParticipantBillingApiInterface {
    /**
     * Deletes Vewa participant billing data and resets next billing date
     * @summary Customer delete Vewa participant billing data and reset next billing date
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {BillingDateRequest} billingDateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApiInterface
     */
    customerDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId: string, billingDateRequest: BillingDateRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get the billing details of an VewaAllParticipantBilling
     * @summary Get the billing details of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApiInterface
     */
    customerGetVewaAllParticipantBillingById(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): AxiosPromise<VewaAllParticipantsBilling>;

    /**
     * Get the participants details of an VewaAllParticipantBilling
     * @summary Get the participants details of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApiInterface
     */
    customerGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedVewaIndividualParticipationConsumptionResponse>;

    /**
     * Reloads Vewa participant billing data
     * @summary Customer reload Vewa participant billing data
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApiInterface
     */
    customerReloadVewaParticipantBillingData(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Reloads Zev participant billing data
     * @summary Customer reload Zev participant billing data
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApiInterface
     */
    customerReloadZevParticipantBillingData(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Reopens Vewa participant billing in DONE state
     * @summary Customer reopen Vewa participant billing
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApiInterface
     */
    customerReopenVewaParticipantBilling(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update cost share settings per participant billing
     * @summary Customer update billing cost shares
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {VewaCostShares} vewaCostShares 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApiInterface
     */
    customerUpdateBillingCostShares(vewaAllParticipantBillingId: string, vewaCostShares: VewaCostShares, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get the billing details of an AllParticipantBilling
     * @summary Get the billing details of an AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApiInterface
     */
    getCustomerAllParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig): AxiosPromise<AllParticipantBillingDetailsCustomerResponse>;

    /**
     * State change to approved for AllParticipantBilling
     * @summary State change to approved for AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApiInterface
     */
    putCustomerAllParticipantBillingApproveStateById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * State change to reopened for AllParticipantBilling
     * @summary State change to reopened for AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApiInterface
     */
    putCustomerAllParticipantBillingReopenStateById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update the billing prices of an AllParticipantBilling
     * @summary Update the billing prices of an AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {AllParticipantBillingPricesUpdateCustomerRequest} allParticipantBillingPricesUpdateCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApiInterface
     */
    updateCustomerAllParticipantBillingPricesById(id: string, allParticipantBillingPricesUpdateCustomerRequest: AllParticipantBillingPricesUpdateCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CustomerAllParticipantBillingApi - object-oriented interface
 * @export
 * @class CustomerAllParticipantBillingApi
 * @extends {BaseAPI}
 */
export class CustomerAllParticipantBillingApi extends BaseAPI implements CustomerAllParticipantBillingApiInterface {
    /**
     * Deletes Vewa participant billing data and resets next billing date
     * @summary Customer delete Vewa participant billing data and reset next billing date
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {BillingDateRequest} billingDateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApi
     */
    public customerDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId: string, billingDateRequest: BillingDateRequest, options?: AxiosRequestConfig) {
        return CustomerAllParticipantBillingApiFp(this.configuration).customerDeleteVewaBillingDataAndResetNextBillingDate(vewaAllParticipantBillingId, billingDateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the billing details of an VewaAllParticipantBilling
     * @summary Get the billing details of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApi
     */
    public customerGetVewaAllParticipantBillingById(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig) {
        return CustomerAllParticipantBillingApiFp(this.configuration).customerGetVewaAllParticipantBillingById(vewaAllParticipantBillingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the participants details of an VewaAllParticipantBilling
     * @summary Get the participants details of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApi
     */
    public customerGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return CustomerAllParticipantBillingApiFp(this.configuration).customerGetVewaIndividualParticipationConsumption(vewaAllParticipantBillingId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reloads Vewa participant billing data
     * @summary Customer reload Vewa participant billing data
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApi
     */
    public customerReloadVewaParticipantBillingData(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig) {
        return CustomerAllParticipantBillingApiFp(this.configuration).customerReloadVewaParticipantBillingData(vewaAllParticipantBillingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reloads Zev participant billing data
     * @summary Customer reload Zev participant billing data
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApi
     */
    public customerReloadZevParticipantBillingData(id: string, options?: AxiosRequestConfig) {
        return CustomerAllParticipantBillingApiFp(this.configuration).customerReloadZevParticipantBillingData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reopens Vewa participant billing in DONE state
     * @summary Customer reopen Vewa participant billing
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApi
     */
    public customerReopenVewaParticipantBilling(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig) {
        return CustomerAllParticipantBillingApiFp(this.configuration).customerReopenVewaParticipantBilling(vewaAllParticipantBillingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update cost share settings per participant billing
     * @summary Customer update billing cost shares
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {VewaCostShares} vewaCostShares 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApi
     */
    public customerUpdateBillingCostShares(vewaAllParticipantBillingId: string, vewaCostShares: VewaCostShares, options?: AxiosRequestConfig) {
        return CustomerAllParticipantBillingApiFp(this.configuration).customerUpdateBillingCostShares(vewaAllParticipantBillingId, vewaCostShares, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the billing details of an AllParticipantBilling
     * @summary Get the billing details of an AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApi
     */
    public getCustomerAllParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig) {
        return CustomerAllParticipantBillingApiFp(this.configuration).getCustomerAllParticipantBillingDetailsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to approved for AllParticipantBilling
     * @summary State change to approved for AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApi
     */
    public putCustomerAllParticipantBillingApproveStateById(id: string, options?: AxiosRequestConfig) {
        return CustomerAllParticipantBillingApiFp(this.configuration).putCustomerAllParticipantBillingApproveStateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to reopened for AllParticipantBilling
     * @summary State change to reopened for AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApi
     */
    public putCustomerAllParticipantBillingReopenStateById(id: string, options?: AxiosRequestConfig) {
        return CustomerAllParticipantBillingApiFp(this.configuration).putCustomerAllParticipantBillingReopenStateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the billing prices of an AllParticipantBilling
     * @summary Update the billing prices of an AllParticipantBilling
     * @param {string} id uuid of the all participant billing
     * @param {AllParticipantBillingPricesUpdateCustomerRequest} allParticipantBillingPricesUpdateCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAllParticipantBillingApi
     */
    public updateCustomerAllParticipantBillingPricesById(id: string, allParticipantBillingPricesUpdateCustomerRequest: AllParticipantBillingPricesUpdateCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerAllParticipantBillingApiFp(this.configuration).updateCustomerAllParticipantBillingPricesById(id, allParticipantBillingPricesUpdateCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerAreaApi - axios parameter creator
 * @export
 */
export const CustomerAreaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create a participants in an Area
         * @summary Create an Area participant
         * @param {string} areaId uuid of the Area
         * @param {ParticipantCustomerCreateRequest} participantCustomerCreateRequest A Participant create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCreateAreaParticipant: async (areaId: string, participantCustomerCreateRequest: ParticipantCustomerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('customerCreateAreaParticipant', 'areaId', areaId)
            // verify required parameter 'participantCustomerCreateRequest' is not null or undefined
            assertParamExists('customerCreateAreaParticipant', 'participantCustomerCreateRequest', participantCustomerCreateRequest)
            const localVarPath = `/api/customer/v1/areas/{areaId}/participants`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participantCustomerCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get all Areas for a customer
         * @summary Get all Areas for a customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetAllAreas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/v1/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the customer\'s contracts of an Area
         * @summary Get an Area\'s contracts for the customer
         * @param {string} areaId uuid of the Area
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetAreaManagerContracts: async (areaId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('customerGetAreaManagerContracts', 'areaId', areaId)
            const localVarPath = `/api/customer/v1/areas/{areaId}/contracts`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get all participants of an Area
         * @summary Get an Area\'s participants
         * @param {string} areaId uuid of the Area
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetAreaParticipants: async (areaId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('customerGetAreaParticipants', 'areaId', areaId)
            const localVarPath = `/api/customer/v1/areas/{areaId}/participants`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerAreaApi - functional programming interface
 * @export
 */
export const CustomerAreaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerAreaApiAxiosParamCreator(configuration)
    return {
        /**
         * create a participants in an Area
         * @summary Create an Area participant
         * @param {string} areaId uuid of the Area
         * @param {ParticipantCustomerCreateRequest} participantCustomerCreateRequest A Participant create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerCreateAreaParticipant(areaId: string, participantCustomerCreateRequest: ParticipantCustomerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerCreateAreaParticipant(areaId, participantCustomerCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get all Areas for a customer
         * @summary Get all Areas for a customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetAllAreas(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AreaCustomerResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetAllAreas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get the customer\'s contracts of an Area
         * @summary Get an Area\'s contracts for the customer
         * @param {string} areaId uuid of the Area
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetAreaManagerContracts(areaId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiContractCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetAreaManagerContracts(areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get all participants of an Area
         * @summary Get an Area\'s participants
         * @param {string} areaId uuid of the Area
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetAreaParticipants(areaId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedParticipantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetAreaParticipants(areaId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerAreaApi - factory interface
 * @export
 */
export const CustomerAreaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerAreaApiFp(configuration)
    return {
        /**
         * create a participants in an Area
         * @summary Create an Area participant
         * @param {string} areaId uuid of the Area
         * @param {ParticipantCustomerCreateRequest} participantCustomerCreateRequest A Participant create request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCreateAreaParticipant(areaId: string, participantCustomerCreateRequest: ParticipantCustomerCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.customerCreateAreaParticipant(areaId, participantCustomerCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * get all Areas for a customer
         * @summary Get all Areas for a customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetAllAreas(options?: any): AxiosPromise<Array<AreaCustomerResponse>> {
            return localVarFp.customerGetAllAreas(options).then((request) => request(axios, basePath));
        },
        /**
         * get the customer\'s contracts of an Area
         * @summary Get an Area\'s contracts for the customer
         * @param {string} areaId uuid of the Area
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetAreaManagerContracts(areaId: string, options?: any): AxiosPromise<MultiContractCustomerResponse> {
            return localVarFp.customerGetAreaManagerContracts(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * get all participants of an Area
         * @summary Get an Area\'s participants
         * @param {string} areaId uuid of the Area
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetAreaParticipants(areaId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedParticipantResponse> {
            return localVarFp.customerGetAreaParticipants(areaId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerAreaApi - interface
 * @export
 * @interface CustomerAreaApi
 */
export interface CustomerAreaApiInterface {
    /**
     * create a participants in an Area
     * @summary Create an Area participant
     * @param {string} areaId uuid of the Area
     * @param {ParticipantCustomerCreateRequest} participantCustomerCreateRequest A Participant create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAreaApiInterface
     */
    customerCreateAreaParticipant(areaId: string, participantCustomerCreateRequest: ParticipantCustomerCreateRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * get all Areas for a customer
     * @summary Get all Areas for a customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAreaApiInterface
     */
    customerGetAllAreas(options?: AxiosRequestConfig): AxiosPromise<Array<AreaCustomerResponse>>;

    /**
     * get the customer\'s contracts of an Area
     * @summary Get an Area\'s contracts for the customer
     * @param {string} areaId uuid of the Area
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAreaApiInterface
     */
    customerGetAreaManagerContracts(areaId: string, options?: AxiosRequestConfig): AxiosPromise<MultiContractCustomerResponse>;

    /**
     * get all participants of an Area
     * @summary Get an Area\'s participants
     * @param {string} areaId uuid of the Area
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAreaApiInterface
     */
    customerGetAreaParticipants(areaId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedParticipantResponse>;

}

/**
 * CustomerAreaApi - object-oriented interface
 * @export
 * @class CustomerAreaApi
 * @extends {BaseAPI}
 */
export class CustomerAreaApi extends BaseAPI implements CustomerAreaApiInterface {
    /**
     * create a participants in an Area
     * @summary Create an Area participant
     * @param {string} areaId uuid of the Area
     * @param {ParticipantCustomerCreateRequest} participantCustomerCreateRequest A Participant create request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAreaApi
     */
    public customerCreateAreaParticipant(areaId: string, participantCustomerCreateRequest: ParticipantCustomerCreateRequest, options?: AxiosRequestConfig) {
        return CustomerAreaApiFp(this.configuration).customerCreateAreaParticipant(areaId, participantCustomerCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get all Areas for a customer
     * @summary Get all Areas for a customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAreaApi
     */
    public customerGetAllAreas(options?: AxiosRequestConfig) {
        return CustomerAreaApiFp(this.configuration).customerGetAllAreas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get the customer\'s contracts of an Area
     * @summary Get an Area\'s contracts for the customer
     * @param {string} areaId uuid of the Area
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAreaApi
     */
    public customerGetAreaManagerContracts(areaId: string, options?: AxiosRequestConfig) {
        return CustomerAreaApiFp(this.configuration).customerGetAreaManagerContracts(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get all participants of an Area
     * @summary Get an Area\'s participants
     * @param {string} areaId uuid of the Area
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAreaApi
     */
    public customerGetAreaParticipants(areaId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return CustomerAreaApiFp(this.configuration).customerGetAreaParticipants(areaId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerAreaBuildingApi - axios parameter creator
 * @export
 */
export const CustomerAreaBuildingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all Buildings with some possible slicing and dicing for an areaId
         * @summary Get all Buildings in an Area
         * @param {string} areaId uuid of the Area
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetAreaBuildings: async (areaId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('customerGetAreaBuildings', 'areaId', areaId)
            const localVarPath = `/api/customer/v1/areas/{areaId}/buildings`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerAreaBuildingApi - functional programming interface
 * @export
 */
export const CustomerAreaBuildingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerAreaBuildingApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all Buildings with some possible slicing and dicing for an areaId
         * @summary Get all Buildings in an Area
         * @param {string} areaId uuid of the Area
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetAreaBuildings(areaId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedBuildingAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetAreaBuildings(areaId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerAreaBuildingApi - factory interface
 * @export
 */
export const CustomerAreaBuildingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerAreaBuildingApiFp(configuration)
    return {
        /**
         * Get all Buildings with some possible slicing and dicing for an areaId
         * @summary Get all Buildings in an Area
         * @param {string} areaId uuid of the Area
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetAreaBuildings(areaId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedBuildingAdminResponse> {
            return localVarFp.customerGetAreaBuildings(areaId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerAreaBuildingApi - interface
 * @export
 * @interface CustomerAreaBuildingApi
 */
export interface CustomerAreaBuildingApiInterface {
    /**
     * Get all Buildings with some possible slicing and dicing for an areaId
     * @summary Get all Buildings in an Area
     * @param {string} areaId uuid of the Area
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAreaBuildingApiInterface
     */
    customerGetAreaBuildings(areaId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedBuildingAdminResponse>;

}

/**
 * CustomerAreaBuildingApi - object-oriented interface
 * @export
 * @class CustomerAreaBuildingApi
 * @extends {BaseAPI}
 */
export class CustomerAreaBuildingApi extends BaseAPI implements CustomerAreaBuildingApiInterface {
    /**
     * Get all Buildings with some possible slicing and dicing for an areaId
     * @summary Get all Buildings in an Area
     * @param {string} areaId uuid of the Area
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAreaBuildingApi
     */
    public customerGetAreaBuildings(areaId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return CustomerAreaBuildingApiFp(this.configuration).customerGetAreaBuildings(areaId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerBillsApi - axios parameter creator
 * @export
 */
export const CustomerBillsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create bills of an VewaAllParticipantBilling
         * @summary Create bills of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCreateVewaParticipantBillingBills: async (vewaAllParticipantBillingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('customerCreateVewaParticipantBillingBills', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/customer/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/bills`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get bills of an VewaAllParticipantBilling
         * @summary Get bills of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetVewaParticipantBillingBills: async (vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('customerGetVewaParticipantBillingBills', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/customer/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/bills`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exports the bills to a file in CSV format
         * @summary Exports the bills to a file in CSV format
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAllParticipantBillingCsv: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerAllParticipantBillingCsv', 'id', id)
            const localVarPath = `/api/customer/v1/allParticipantBilling/{id}/csv`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all PDF bills for a billing cycle zipped up
         * @summary Fetch all PDF bills for a billing cycle
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAllParticipantBillingPdfs: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerAllParticipantBillingPdfs', 'id', id)
            const localVarPath = `/api/customer/v1/allParticipantBilling/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all PDF bills for vewa participant billing in zip file
         * @summary Fetch all PDF bills for vewa participant billing in zip file
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAllVewaBillPdfs: async (vewaAllParticipantBillingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('getCustomerAllVewaBillPdfs', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/customer/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/zip`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a PDF billing document for the provided billing ID
         * @summary Fetch the PDF bill
         * @param {string} billId uuid of the Bill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerBillPdf: async (billId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billId' is not null or undefined
            assertParamExists('getCustomerBillPdf', 'billId', billId)
            const localVarPath = `/api/customer/v1/bills/{billId}/pdf`
                .replace(`{${"billId"}}`, encodeURIComponent(String(billId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a PDF billing document for the provided billing ID and participant ID
         * @summary Fetch the PDF bill
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerVewaParticipantBillPdf: async (vewaAllParticipantBillingId: string, participantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('getCustomerVewaParticipantBillPdf', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('getCustomerVewaParticipantBillPdf', 'participantId', participantId)
            const localVarPath = `/api/customer/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/participant/{participantId}/pdf`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exports Bills as a file in CSV format
         * @summary Exports Bills as a file in CSV format
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerVewaParticipantBillingCsv: async (vewaAllParticipantBillingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vewaAllParticipantBillingId' is not null or undefined
            assertParamExists('getCustomerVewaParticipantBillingCsv', 'vewaAllParticipantBillingId', vewaAllParticipantBillingId)
            const localVarPath = `/api/customer/v1/vewaAllParticipantBillings/{vewaAllParticipantBillingId}/csv`
                .replace(`{${"vewaAllParticipantBillingId"}}`, encodeURIComponent(String(vewaAllParticipantBillingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the bill as paid or unpaid
         * @summary Update the bill as paid or unpaid
         * @param {string} id uuid of the Bill
         * @param {'paid' | 'unpaid'} paidOrUnpaid flag the bill as paid or unpaid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerBillPaidOrUnpaid: async (id: string, paidOrUnpaid: 'paid' | 'unpaid', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomerBillPaidOrUnpaid', 'id', id)
            // verify required parameter 'paidOrUnpaid' is not null or undefined
            assertParamExists('updateCustomerBillPaidOrUnpaid', 'paidOrUnpaid', paidOrUnpaid)
            const localVarPath = `/api/customer/v1/bills/{id}/{paidOrUnpaid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"paidOrUnpaid"}}`, encodeURIComponent(String(paidOrUnpaid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerBillsApi - functional programming interface
 * @export
 */
export const CustomerBillsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerBillsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create bills of an VewaAllParticipantBilling
         * @summary Create bills of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerCreateVewaParticipantBillingBills(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerCreateVewaParticipantBillingBills(vewaAllParticipantBillingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get bills of an VewaAllParticipantBilling
         * @summary Get bills of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetVewaParticipantBillingBills(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedVewaBillsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetVewaParticipantBillingBills(vewaAllParticipantBillingId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exports the bills to a file in CSV format
         * @summary Exports the bills to a file in CSV format
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerAllParticipantBillingCsv(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerAllParticipantBillingCsv(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all PDF bills for a billing cycle zipped up
         * @summary Fetch all PDF bills for a billing cycle
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerAllParticipantBillingPdfs(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerAllParticipantBillingPdfs(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all PDF bills for vewa participant billing in zip file
         * @summary Fetch all PDF bills for vewa participant billing in zip file
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerAllVewaBillPdfs(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerAllVewaBillPdfs(vewaAllParticipantBillingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a PDF billing document for the provided billing ID
         * @summary Fetch the PDF bill
         * @param {string} billId uuid of the Bill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerBillPdf(billId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerBillPdf(billId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a PDF billing document for the provided billing ID and participant ID
         * @summary Fetch the PDF bill
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerVewaParticipantBillPdf(vewaAllParticipantBillingId: string, participantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerVewaParticipantBillPdf(vewaAllParticipantBillingId, participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exports Bills as a file in CSV format
         * @summary Exports Bills as a file in CSV format
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerVewaParticipantBillingCsv(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerVewaParticipantBillingCsv(vewaAllParticipantBillingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the bill as paid or unpaid
         * @summary Update the bill as paid or unpaid
         * @param {string} id uuid of the Bill
         * @param {'paid' | 'unpaid'} paidOrUnpaid flag the bill as paid or unpaid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerBillPaidOrUnpaid(id: string, paidOrUnpaid: 'paid' | 'unpaid', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillPaymentStatusUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerBillPaidOrUnpaid(id, paidOrUnpaid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerBillsApi - factory interface
 * @export
 */
export const CustomerBillsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerBillsApiFp(configuration)
    return {
        /**
         * Create bills of an VewaAllParticipantBilling
         * @summary Create bills of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCreateVewaParticipantBillingBills(vewaAllParticipantBillingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerCreateVewaParticipantBillingBills(vewaAllParticipantBillingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get bills of an VewaAllParticipantBilling
         * @summary Get bills of an VewaAllParticipantBilling
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetVewaParticipantBillingBills(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedVewaBillsResponse> {
            return localVarFp.customerGetVewaParticipantBillingBills(vewaAllParticipantBillingId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Exports the bills to a file in CSV format
         * @summary Exports the bills to a file in CSV format
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAllParticipantBillingCsv(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getCustomerAllParticipantBillingCsv(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all PDF bills for a billing cycle zipped up
         * @summary Fetch all PDF bills for a billing cycle
         * @param {string} id uuid of the all participant billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAllParticipantBillingPdfs(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getCustomerAllParticipantBillingPdfs(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all PDF bills for vewa participant billing in zip file
         * @summary Fetch all PDF bills for vewa participant billing in zip file
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAllVewaBillPdfs(vewaAllParticipantBillingId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getCustomerAllVewaBillPdfs(vewaAllParticipantBillingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a PDF billing document for the provided billing ID
         * @summary Fetch the PDF bill
         * @param {string} billId uuid of the Bill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerBillPdf(billId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getCustomerBillPdf(billId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a PDF billing document for the provided billing ID and participant ID
         * @summary Fetch the PDF bill
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerVewaParticipantBillPdf(vewaAllParticipantBillingId: string, participantId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getCustomerVewaParticipantBillPdf(vewaAllParticipantBillingId, participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Exports Bills as a file in CSV format
         * @summary Exports Bills as a file in CSV format
         * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerVewaParticipantBillingCsv(vewaAllParticipantBillingId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getCustomerVewaParticipantBillingCsv(vewaAllParticipantBillingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the bill as paid or unpaid
         * @summary Update the bill as paid or unpaid
         * @param {string} id uuid of the Bill
         * @param {'paid' | 'unpaid'} paidOrUnpaid flag the bill as paid or unpaid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerBillPaidOrUnpaid(id: string, paidOrUnpaid: 'paid' | 'unpaid', options?: any): AxiosPromise<BillPaymentStatusUpdateResponse> {
            return localVarFp.updateCustomerBillPaidOrUnpaid(id, paidOrUnpaid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerBillsApi - interface
 * @export
 * @interface CustomerBillsApi
 */
export interface CustomerBillsApiInterface {
    /**
     * Create bills of an VewaAllParticipantBilling
     * @summary Create bills of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApiInterface
     */
    customerCreateVewaParticipantBillingBills(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get bills of an VewaAllParticipantBilling
     * @summary Get bills of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApiInterface
     */
    customerGetVewaParticipantBillingBills(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedVewaBillsResponse>;

    /**
     * Exports the bills to a file in CSV format
     * @summary Exports the bills to a file in CSV format
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApiInterface
     */
    getCustomerAllParticipantBillingCsv(id: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Fetch all PDF bills for a billing cycle zipped up
     * @summary Fetch all PDF bills for a billing cycle
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApiInterface
     */
    getCustomerAllParticipantBillingPdfs(id: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Fetch all PDF bills for vewa participant billing in zip file
     * @summary Fetch all PDF bills for vewa participant billing in zip file
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApiInterface
     */
    getCustomerAllVewaBillPdfs(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Fetch a PDF billing document for the provided billing ID
     * @summary Fetch the PDF bill
     * @param {string} billId uuid of the Bill
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApiInterface
     */
    getCustomerBillPdf(billId: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Fetch a PDF billing document for the provided billing ID and participant ID
     * @summary Fetch the PDF bill
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApiInterface
     */
    getCustomerVewaParticipantBillPdf(vewaAllParticipantBillingId: string, participantId: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Exports Bills as a file in CSV format
     * @summary Exports Bills as a file in CSV format
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApiInterface
     */
    getCustomerVewaParticipantBillingCsv(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Update the bill as paid or unpaid
     * @summary Update the bill as paid or unpaid
     * @param {string} id uuid of the Bill
     * @param {'paid' | 'unpaid'} paidOrUnpaid flag the bill as paid or unpaid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApiInterface
     */
    updateCustomerBillPaidOrUnpaid(id: string, paidOrUnpaid: 'paid' | 'unpaid', options?: AxiosRequestConfig): AxiosPromise<BillPaymentStatusUpdateResponse>;

}

/**
 * CustomerBillsApi - object-oriented interface
 * @export
 * @class CustomerBillsApi
 * @extends {BaseAPI}
 */
export class CustomerBillsApi extends BaseAPI implements CustomerBillsApiInterface {
    /**
     * Create bills of an VewaAllParticipantBilling
     * @summary Create bills of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApi
     */
    public customerCreateVewaParticipantBillingBills(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig) {
        return CustomerBillsApiFp(this.configuration).customerCreateVewaParticipantBillingBills(vewaAllParticipantBillingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get bills of an VewaAllParticipantBilling
     * @summary Get bills of an VewaAllParticipantBilling
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApi
     */
    public customerGetVewaParticipantBillingBills(vewaAllParticipantBillingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return CustomerBillsApiFp(this.configuration).customerGetVewaParticipantBillingBills(vewaAllParticipantBillingId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exports the bills to a file in CSV format
     * @summary Exports the bills to a file in CSV format
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApi
     */
    public getCustomerAllParticipantBillingCsv(id: string, options?: AxiosRequestConfig) {
        return CustomerBillsApiFp(this.configuration).getCustomerAllParticipantBillingCsv(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all PDF bills for a billing cycle zipped up
     * @summary Fetch all PDF bills for a billing cycle
     * @param {string} id uuid of the all participant billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApi
     */
    public getCustomerAllParticipantBillingPdfs(id: string, options?: AxiosRequestConfig) {
        return CustomerBillsApiFp(this.configuration).getCustomerAllParticipantBillingPdfs(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all PDF bills for vewa participant billing in zip file
     * @summary Fetch all PDF bills for vewa participant billing in zip file
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApi
     */
    public getCustomerAllVewaBillPdfs(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig) {
        return CustomerBillsApiFp(this.configuration).getCustomerAllVewaBillPdfs(vewaAllParticipantBillingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a PDF billing document for the provided billing ID
     * @summary Fetch the PDF bill
     * @param {string} billId uuid of the Bill
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApi
     */
    public getCustomerBillPdf(billId: string, options?: AxiosRequestConfig) {
        return CustomerBillsApiFp(this.configuration).getCustomerBillPdf(billId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a PDF billing document for the provided billing ID and participant ID
     * @summary Fetch the PDF bill
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApi
     */
    public getCustomerVewaParticipantBillPdf(vewaAllParticipantBillingId: string, participantId: string, options?: AxiosRequestConfig) {
        return CustomerBillsApiFp(this.configuration).getCustomerVewaParticipantBillPdf(vewaAllParticipantBillingId, participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exports Bills as a file in CSV format
     * @summary Exports Bills as a file in CSV format
     * @param {string} vewaAllParticipantBillingId UUID of the VEWA All Participant Billing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApi
     */
    public getCustomerVewaParticipantBillingCsv(vewaAllParticipantBillingId: string, options?: AxiosRequestConfig) {
        return CustomerBillsApiFp(this.configuration).getCustomerVewaParticipantBillingCsv(vewaAllParticipantBillingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the bill as paid or unpaid
     * @summary Update the bill as paid or unpaid
     * @param {string} id uuid of the Bill
     * @param {'paid' | 'unpaid'} paidOrUnpaid flag the bill as paid or unpaid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBillsApi
     */
    public updateCustomerBillPaidOrUnpaid(id: string, paidOrUnpaid: 'paid' | 'unpaid', options?: AxiosRequestConfig) {
        return CustomerBillsApiFp(this.configuration).updateCustomerBillPaidOrUnpaid(id, paidOrUnpaid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerBuildingApi - axios parameter creator
 * @export
 */
export const CustomerBuildingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Buildings can be fetched by their respective UUID
         * @summary Get a Building by its ID
         * @param {string} buildingId UUID of the Building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetBuildingById: async (buildingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('customerGetBuildingById', 'buildingId', buildingId)
            const localVarPath = `/api/customer/v1/buildings/{buildingId}`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all utilityUnits linked to the building page
         * @summary Get all Building Utility Units
         * @param {string} buildingId UUID of the Building
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetUtilityUnitsForBuilding: async (buildingId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('customerGetUtilityUnitsForBuilding', 'buildingId', buildingId)
            const localVarPath = `/api/customer/v1/buildings/{buildingId}/utilityUnits`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerBuildingApi - functional programming interface
 * @export
 */
export const CustomerBuildingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerBuildingApiAxiosParamCreator(configuration)
    return {
        /**
         * Buildings can be fetched by their respective UUID
         * @summary Get a Building by its ID
         * @param {string} buildingId UUID of the Building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetBuildingById(buildingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetBuildingById(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all utilityUnits linked to the building page
         * @summary Get all Building Utility Units
         * @param {string} buildingId UUID of the Building
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetUtilityUnitsForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedUtilityUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetUtilityUnitsForBuilding(buildingId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerBuildingApi - factory interface
 * @export
 */
export const CustomerBuildingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerBuildingApiFp(configuration)
    return {
        /**
         * Buildings can be fetched by their respective UUID
         * @summary Get a Building by its ID
         * @param {string} buildingId UUID of the Building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetBuildingById(buildingId: string, options?: any): AxiosPromise<BuildingCustomerResponse> {
            return localVarFp.customerGetBuildingById(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all utilityUnits linked to the building page
         * @summary Get all Building Utility Units
         * @param {string} buildingId UUID of the Building
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetUtilityUnitsForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedUtilityUnitResponse> {
            return localVarFp.customerGetUtilityUnitsForBuilding(buildingId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerBuildingApi - interface
 * @export
 * @interface CustomerBuildingApi
 */
export interface CustomerBuildingApiInterface {
    /**
     * Buildings can be fetched by their respective UUID
     * @summary Get a Building by its ID
     * @param {string} buildingId UUID of the Building
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBuildingApiInterface
     */
    customerGetBuildingById(buildingId: string, options?: AxiosRequestConfig): AxiosPromise<BuildingCustomerResponse>;

    /**
     * Get all utilityUnits linked to the building page
     * @summary Get all Building Utility Units
     * @param {string} buildingId UUID of the Building
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBuildingApiInterface
     */
    customerGetUtilityUnitsForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedUtilityUnitResponse>;

}

/**
 * CustomerBuildingApi - object-oriented interface
 * @export
 * @class CustomerBuildingApi
 * @extends {BaseAPI}
 */
export class CustomerBuildingApi extends BaseAPI implements CustomerBuildingApiInterface {
    /**
     * Buildings can be fetched by their respective UUID
     * @summary Get a Building by its ID
     * @param {string} buildingId UUID of the Building
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBuildingApi
     */
    public customerGetBuildingById(buildingId: string, options?: AxiosRequestConfig) {
        return CustomerBuildingApiFp(this.configuration).customerGetBuildingById(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all utilityUnits linked to the building page
     * @summary Get all Building Utility Units
     * @param {string} buildingId UUID of the Building
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerBuildingApi
     */
    public customerGetUtilityUnitsForBuilding(buildingId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return CustomerBuildingApiFp(this.configuration).customerGetUtilityUnitsForBuilding(buildingId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerContractApi - axios parameter creator
 * @export
 */
export const CustomerContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Contract onboarding can be completed by their respective UUID
         * @summary Completes a Contract\'s onboarding by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCompleteContractOnboardingById: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerCompleteContractOnboardingById', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/completeOnboarding`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new price package for a contract
         * @summary Create a new price package for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ZevPricePackage} zevPricePackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCreatePricePackageByContractId: async (contractId: string, zevPricePackage: ZevPricePackage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerCreatePricePackageByContractId', 'contractId', contractId)
            // verify required parameter 'zevPricePackage' is not null or undefined
            assertParamExists('customerCreatePricePackageByContractId', 'zevPricePackage', zevPricePackage)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/pricePackages`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zevPricePackage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contract prices can be deleted by their respective contractConfig UUID and price package ID
         * @summary Delete a Contracts price package by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} pricePackageId id of the Price Package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerDeleteContractPricePackageById: async (contractId: string, pricePackageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerDeleteContractPricePackageById', 'contractId', contractId)
            // verify required parameter 'pricePackageId' is not null or undefined
            assertParamExists('customerDeleteContractPricePackageById', 'pricePackageId', pricePackageId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/pricePackages/{pricePackageId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"pricePackageId"}}`, encodeURIComponent(String(pricePackageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get billing information for a contract
         * @summary Get billing information for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetBillingSettingsByContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetBillingSettingsByContractId', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/billingSettings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get contact information of a contract
         * @summary Get contact of a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContactByContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetContactByContractId', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/contact`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contracts can be fetched by their respective UUID
         * @summary Get a Contract by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractById: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetContractById', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the monthly price of the Contract\'s product
         * @summary Get a service fees for a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractContractDetailsById: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetContractContractDetailsById', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/serviceFees`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the time of the last EDM data point for a contract
         * @summary Get the time of the last EDM data point for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractLastDataPointById: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetContractLastDataPointById', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/lastDataPoint`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the energy values for the price calculator
         * @summary Get the energy values for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} year the year for which to return the Contract energy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractPriceCalculatorEnergyById: async (contractId: string, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetContractPriceCalculatorEnergyById', 'contractId', contractId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('customerGetContractPriceCalculatorEnergyById', 'year', year)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/priceCalculator/energy`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the prices input for the price calculator
         * @summary Get the prices input for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractPriceCalculatorInputById: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetContractPriceCalculatorInputById', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/priceCalculator/inputPrices`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get new computed contract prices using the price calculator
         * @summary Get new computed contract prices using the price calculator using past year\'s energy calculations
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} year the year for which to return the Contract energy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractPriceCalculatorPricesById: async (contractId: string, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetContractPriceCalculatorPricesById', 'contractId', contractId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('customerGetContractPriceCalculatorPricesById', 'year', year)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/priceCalculator/prices`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get new computed contract prices using the price calculator
         * @summary Get new computed contract prices using the price calculator with supplied energy
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} year the year for which to return the Contract energy
         * @param {ZevPriceCalculatorEnergy} zevPriceCalculatorEnergy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractPriceCalculatorPricesSuppliedEnergyById: async (contractId: string, year: number, zevPriceCalculatorEnergy: ZevPriceCalculatorEnergy, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetContractPriceCalculatorPricesSuppliedEnergyById', 'contractId', contractId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('customerGetContractPriceCalculatorPricesSuppliedEnergyById', 'year', year)
            // verify required parameter 'zevPriceCalculatorEnergy' is not null or undefined
            assertParamExists('customerGetContractPriceCalculatorPricesSuppliedEnergyById', 'zevPriceCalculatorEnergy', zevPriceCalculatorEnergy)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/priceCalculator/prices`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zevPriceCalculatorEnergy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the Contract municipality tariffs for all the known years for the price calculator
         * @summary Get the Contract municipality tariffs for all the known years for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractPriceCalculatorTariffsById: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetContractPriceCalculatorTariffsById', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/priceCalculator/tariffs`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a Contract\'s service-component features
         * @summary Get a contract\'s service-component features
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractServiceComponentFeatures: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetContractServiceComponentFeatures', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/serviceComponentFeatures`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get All IndividualParticipantBillings of a Contract
         * @summary Get All Individual Participant Billings for a given Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetIndividualParticipantsBillingsContractById: async (contractId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetIndividualParticipantsBillingsContractById', 'contractId', contractId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customerGetIndividualParticipantsBillingsContractById', 'id', id)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/individualParticipantBillings/{individualParticipantBillingId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides calculated meter-readings for a contract over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetMeterReadingsByContractId: async (contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetMeterReadingsByContractId', 'contractId', contractId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('customerGetMeterReadingsByContractId', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('customerGetMeterReadingsByContractId', 'endDate', endDate)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('customerGetMeterReadingsByContractId', 'resolution', resolution)
            const localVarPath = `/api/customer/v1/meter-readings/contract/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get page AllParticipantsBilling of a Contract
         * @summary Get page of All Participants Billing for a given Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetPageAllParticipantsBillingsContractById: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetPageAllParticipantsBillingsContractById', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/allParticipantBillings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get page IndividualParticipantBillings of a contract
         * @summary Get page of individual participant billings for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetPageIndividualParticipantsBillingsByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetPageIndividualParticipantsBillingsByContractId', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/individualParticipantBillings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get page of VEWA All Participant Billing for a contract
         * @summary Get page of VEWA participants billing for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetPagedVewaAllParticipantsBillingsByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetPagedVewaAllParticipantsBillingsByContractId', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/vewaAllParticipantBillings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all participants for a contract
         * @summary Get participants for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetParticipantsByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetParticipantsByContractId', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/participants`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get contract\'s price packages
         * @summary Get contract\'s price packages
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetPricePackagesByContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetPricePackagesByContractId', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/pricePackages`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get contract product history
         * @summary Get contract product history
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetProductHistoryContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetProductHistoryContractId', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/productHistory`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a page of Utility Units linked to a Contract
         * @summary Get a page of Utility Units linked to a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetUtilityUnitsByContractId: async (contractId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetUtilityUnitsByContractId', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/utilityUnits`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get VEWA config details for contract
         * @summary Get VEWA config details for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetVewaConfigByContractId: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetVewaConfigByContractId', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/vewaConfig`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update cost share settings for VEWA contract by customer
         * @summary Customer update VEWA cost shares
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {VewaCostShares} vewaCostShares 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerPutVewaCostSharesByContractId: async (contractId: string, vewaCostShares: VewaCostShares, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerPutVewaCostSharesByContractId', 'contractId', contractId)
            // verify required parameter 'vewaCostShares' is not null or undefined
            assertParamExists('customerPutVewaCostSharesByContractId', 'vewaCostShares', vewaCostShares)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/costShares`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vewaCostShares, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update contact address of a contract
         * @summary Update contact address of a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractAddressData} contractAddressData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContactAddressByContractId: async (contractId: string, contractAddressData: ContractAddressData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerUpdateContactAddressByContractId', 'contractId', contractId)
            // verify required parameter 'contractAddressData' is not null or undefined
            assertParamExists('customerUpdateContactAddressByContractId', 'contractAddressData', contractAddressData)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/contact/address`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractAddressData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update contact data of a contract
         * @summary Update contact data of a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContactData} contactData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContactDataByContractId: async (contractId: string, contactData: ContactData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerUpdateContactDataByContractId', 'contractId', contractId)
            // verify required parameter 'contactData' is not null or undefined
            assertParamExists('customerUpdateContactDataByContractId', 'contactData', contactData)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/contact/contactData`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contract main contact person can be updated by their respective UUID
         * @summary Update a Contracts main contact person by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateMainContactPersonCustomerRequest} contractUpdateMainContactPersonCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractMainContactPersonById: async (contractId: string, contractUpdateMainContactPersonCustomerRequest: ContractUpdateMainContactPersonCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerUpdateContractMainContactPersonById', 'contractId', contractId)
            // verify required parameter 'contractUpdateMainContactPersonCustomerRequest' is not null or undefined
            assertParamExists('customerUpdateContractMainContactPersonById', 'contractUpdateMainContactPersonCustomerRequest', contractUpdateMainContactPersonCustomerRequest)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/mainContactPerson`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractUpdateMainContactPersonCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contract payment information can be updated by their respective UUID
         * @summary Update a Contract\'s payment information by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdatePaymentInformationCustomerRequest} contractUpdatePaymentInformationCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractPaymentInformationById: async (contractId: string, contractUpdatePaymentInformationCustomerRequest: ContractUpdatePaymentInformationCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerUpdateContractPaymentInformationById', 'contractId', contractId)
            // verify required parameter 'contractUpdatePaymentInformationCustomerRequest' is not null or undefined
            assertParamExists('customerUpdateContractPaymentInformationById', 'contractUpdatePaymentInformationCustomerRequest', contractUpdatePaymentInformationCustomerRequest)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/paymentInformation`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractUpdatePaymentInformationCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a contract input prices for the price-calculator
         * @summary Update a contract input prices for the price-calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ZevPriceCalculatorInputUpdateRequest} zevPriceCalculatorInputUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractPriceCalculatorInputById: async (contractId: string, zevPriceCalculatorInputUpdateRequest: ZevPriceCalculatorInputUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerUpdateContractPriceCalculatorInputById', 'contractId', contractId)
            // verify required parameter 'zevPriceCalculatorInputUpdateRequest' is not null or undefined
            assertParamExists('customerUpdateContractPriceCalculatorInputById', 'zevPriceCalculatorInputUpdateRequest', zevPriceCalculatorInputUpdateRequest)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/priceCalculator/inputPrices`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zevPriceCalculatorInputUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * contract prices can be updated by their respective contract UUID and price package ID
         * @summary Update a contract\'s price package by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} pricePackageId id of the Price Package
         * @param {ZevPricePackage} zevPricePackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractPricePackageById: async (contractId: string, pricePackageId: number, zevPricePackage: ZevPricePackage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerUpdateContractPricePackageById', 'contractId', contractId)
            // verify required parameter 'pricePackageId' is not null or undefined
            assertParamExists('customerUpdateContractPricePackageById', 'pricePackageId', pricePackageId)
            // verify required parameter 'zevPricePackage' is not null or undefined
            assertParamExists('customerUpdateContractPricePackageById', 'zevPricePackage', zevPricePackage)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/pricePackages/{pricePackageId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"pricePackageId"}}`, encodeURIComponent(String(pricePackageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zevPricePackage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contract start date can be updated by their respective UUID
         * @summary Update a Contract\'s start date by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateStartDateCustomerRequest} contractUpdateStartDateCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractStartDateById: async (contractId: string, contractUpdateStartDateCustomerRequest: ContractUpdateStartDateCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerUpdateContractStartDateById', 'contractId', contractId)
            // verify required parameter 'contractUpdateStartDateCustomerRequest' is not null or undefined
            assertParamExists('customerUpdateContractStartDateById', 'contractUpdateStartDateCustomerRequest', contractUpdateStartDateCustomerRequest)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/start-date`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractUpdateStartDateCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contract payment information can be updated by their respective UUID
         * @summary Update a VEWA Contract billing settings by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateVewaBillingSettingsCustomerRequest} contractUpdateVewaBillingSettingsCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractVewaBillingSettingsById: async (contractId: string, contractUpdateVewaBillingSettingsCustomerRequest: ContractUpdateVewaBillingSettingsCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerUpdateContractVewaBillingSettingsById', 'contractId', contractId)
            // verify required parameter 'contractUpdateVewaBillingSettingsCustomerRequest' is not null or undefined
            assertParamExists('customerUpdateContractVewaBillingSettingsById', 'contractUpdateVewaBillingSettingsCustomerRequest', contractUpdateVewaBillingSettingsCustomerRequest)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/vewaBillingSettings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractUpdateVewaBillingSettingsCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contract payment information can be updated by their respective UUID
         * @summary Update a ZEV Contract billing settings by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateZevBillingSettingsCustomerRequest} contractUpdateZevBillingSettingsCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractZevBillingSettingsById: async (contractId: string, contractUpdateZevBillingSettingsCustomerRequest: ContractUpdateZevBillingSettingsCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerUpdateContractZevBillingSettingsById', 'contractId', contractId)
            // verify required parameter 'contractUpdateZevBillingSettingsCustomerRequest' is not null or undefined
            assertParamExists('customerUpdateContractZevBillingSettingsById', 'contractUpdateZevBillingSettingsCustomerRequest', contractUpdateZevBillingSettingsCustomerRequest)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/zevBillingSettings`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractUpdateZevBillingSettingsCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerContractApi - functional programming interface
 * @export
 */
export const CustomerContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerContractApiAxiosParamCreator(configuration)
    return {
        /**
         * Contract onboarding can be completed by their respective UUID
         * @summary Completes a Contract\'s onboarding by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerCompleteContractOnboardingById(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerCompleteContractOnboardingById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new price package for a contract
         * @summary Create a new price package for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ZevPricePackage} zevPricePackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerCreatePricePackageByContractId(contractId: string, zevPricePackage: ZevPricePackage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerCreatePricePackageByContractId(contractId, zevPricePackage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contract prices can be deleted by their respective contractConfig UUID and price package ID
         * @summary Delete a Contracts price package by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} pricePackageId id of the Price Package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerDeleteContractPricePackageById(contractId: string, pricePackageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerDeleteContractPricePackageById(contractId, pricePackageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get billing information for a contract
         * @summary Get billing information for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetBillingSettingsByContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetBillingSettingsByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get contact information of a contract
         * @summary Get contact of a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetContactByContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractContactCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetContactByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contracts can be fetched by their respective UUID
         * @summary Get a Contract by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetContractById(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetContractById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the monthly price of the Contract\'s product
         * @summary Get a service fees for a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetContractContractDetailsById(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZevContractCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetContractContractDetailsById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the time of the last EDM data point for a contract
         * @summary Get the time of the last EDM data point for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetContractLastDataPointById(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDataAvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetContractLastDataPointById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the energy values for the price calculator
         * @summary Get the energy values for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} year the year for which to return the Contract energy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetContractPriceCalculatorEnergyById(contractId: string, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZevPriceCalculatorEnergy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetContractPriceCalculatorEnergyById(contractId, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the prices input for the price calculator
         * @summary Get the prices input for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetContractPriceCalculatorInputById(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZevPriceCalculatorInputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetContractPriceCalculatorInputById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get new computed contract prices using the price calculator
         * @summary Get new computed contract prices using the price calculator using past year\'s energy calculations
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} year the year for which to return the Contract energy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetContractPriceCalculatorPricesById(contractId: string, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZevPriceCalculatorPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetContractPriceCalculatorPricesById(contractId, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get new computed contract prices using the price calculator
         * @summary Get new computed contract prices using the price calculator with supplied energy
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} year the year for which to return the Contract energy
         * @param {ZevPriceCalculatorEnergy} zevPriceCalculatorEnergy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetContractPriceCalculatorPricesSuppliedEnergyById(contractId: string, year: number, zevPriceCalculatorEnergy: ZevPriceCalculatorEnergy, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZevPriceCalculatorPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetContractPriceCalculatorPricesSuppliedEnergyById(contractId, year, zevPriceCalculatorEnergy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the Contract municipality tariffs for all the known years for the price calculator
         * @summary Get the Contract municipality tariffs for all the known years for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetContractPriceCalculatorTariffsById(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZevPriceCalculatorTariffsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetContractPriceCalculatorTariffsById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a Contract\'s service-component features
         * @summary Get a contract\'s service-component features
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetContractServiceComponentFeatures(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractServiceComponentFeaturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetContractServiceComponentFeatures(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get All IndividualParticipantBillings of a Contract
         * @summary Get All Individual Participant Billings for a given Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetIndividualParticipantsBillingsContractById(contractId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractIndividualParticipantBillingCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetIndividualParticipantsBillingsContractById(contractId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides calculated meter-readings for a contract over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetMeterReadingsByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadingsContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetMeterReadingsByContractId(contractId, startDate, endDate, resolution, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get page AllParticipantsBilling of a Contract
         * @summary Get page of All Participants Billing for a given Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetPageAllParticipantsBillingsContractById(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedAllParticipantsBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetPageAllParticipantsBillingsContractById(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get page IndividualParticipantBillings of a contract
         * @summary Get page of individual participant billings for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetPageIndividualParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedIndividualParticipantsBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetPageIndividualParticipantsBillingsByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get page of VEWA All Participant Billing for a contract
         * @summary Get page of VEWA participants billing for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetPagedVewaAllParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedVewaAllParticipantsBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetPagedVewaAllParticipantsBillingsByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all participants for a contract
         * @summary Get participants for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetParticipantsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedParticipantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetParticipantsByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get contract\'s price packages
         * @summary Get contract\'s price packages
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetPricePackagesByContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZevPricePackage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetPricePackagesByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get contract product history
         * @summary Get contract product history
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetProductHistoryContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetProductHistoryContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a page of Utility Units linked to a Contract
         * @summary Get a page of Utility Units linked to a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetUtilityUnitsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedUtilityUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetUtilityUnitsByContractId(contractId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get VEWA config details for contract
         * @summary Get VEWA config details for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetVewaConfigByContractId(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VewaConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetVewaConfigByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update cost share settings for VEWA contract by customer
         * @summary Customer update VEWA cost shares
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {VewaCostShares} vewaCostShares 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerPutVewaCostSharesByContractId(contractId: string, vewaCostShares: VewaCostShares, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerPutVewaCostSharesByContractId(contractId, vewaCostShares, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update contact address of a contract
         * @summary Update contact address of a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractAddressData} contractAddressData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateContactAddressByContractId(contractId: string, contractAddressData: ContractAddressData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateContactAddressByContractId(contractId, contractAddressData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update contact data of a contract
         * @summary Update contact data of a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContactData} contactData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateContactDataByContractId(contractId: string, contactData: ContactData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateContactDataByContractId(contractId, contactData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contract main contact person can be updated by their respective UUID
         * @summary Update a Contracts main contact person by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateMainContactPersonCustomerRequest} contractUpdateMainContactPersonCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateContractMainContactPersonById(contractId: string, contractUpdateMainContactPersonCustomerRequest: ContractUpdateMainContactPersonCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateContractMainContactPersonById(contractId, contractUpdateMainContactPersonCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contract payment information can be updated by their respective UUID
         * @summary Update a Contract\'s payment information by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdatePaymentInformationCustomerRequest} contractUpdatePaymentInformationCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateContractPaymentInformationById(contractId: string, contractUpdatePaymentInformationCustomerRequest: ContractUpdatePaymentInformationCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateContractPaymentInformationById(contractId, contractUpdatePaymentInformationCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a contract input prices for the price-calculator
         * @summary Update a contract input prices for the price-calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ZevPriceCalculatorInputUpdateRequest} zevPriceCalculatorInputUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateContractPriceCalculatorInputById(contractId: string, zevPriceCalculatorInputUpdateRequest: ZevPriceCalculatorInputUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateContractPriceCalculatorInputById(contractId, zevPriceCalculatorInputUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * contract prices can be updated by their respective contract UUID and price package ID
         * @summary Update a contract\'s price package by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} pricePackageId id of the Price Package
         * @param {ZevPricePackage} zevPricePackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateContractPricePackageById(contractId: string, pricePackageId: number, zevPricePackage: ZevPricePackage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateContractPricePackageById(contractId, pricePackageId, zevPricePackage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contract start date can be updated by their respective UUID
         * @summary Update a Contract\'s start date by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateStartDateCustomerRequest} contractUpdateStartDateCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateContractStartDateById(contractId: string, contractUpdateStartDateCustomerRequest: ContractUpdateStartDateCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateContractStartDateById(contractId, contractUpdateStartDateCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contract payment information can be updated by their respective UUID
         * @summary Update a VEWA Contract billing settings by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateVewaBillingSettingsCustomerRequest} contractUpdateVewaBillingSettingsCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateContractVewaBillingSettingsById(contractId: string, contractUpdateVewaBillingSettingsCustomerRequest: ContractUpdateVewaBillingSettingsCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateContractVewaBillingSettingsById(contractId, contractUpdateVewaBillingSettingsCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contract payment information can be updated by their respective UUID
         * @summary Update a ZEV Contract billing settings by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateZevBillingSettingsCustomerRequest} contractUpdateZevBillingSettingsCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateContractZevBillingSettingsById(contractId: string, contractUpdateZevBillingSettingsCustomerRequest: ContractUpdateZevBillingSettingsCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateContractZevBillingSettingsById(contractId, contractUpdateZevBillingSettingsCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerContractApi - factory interface
 * @export
 */
export const CustomerContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerContractApiFp(configuration)
    return {
        /**
         * Contract onboarding can be completed by their respective UUID
         * @summary Completes a Contract\'s onboarding by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCompleteContractOnboardingById(contractId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerCompleteContractOnboardingById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new price package for a contract
         * @summary Create a new price package for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ZevPricePackage} zevPricePackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCreatePricePackageByContractId(contractId: string, zevPricePackage: ZevPricePackage, options?: any): AxiosPromise<void> {
            return localVarFp.customerCreatePricePackageByContractId(contractId, zevPricePackage, options).then((request) => request(axios, basePath));
        },
        /**
         * Contract prices can be deleted by their respective contractConfig UUID and price package ID
         * @summary Delete a Contracts price package by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} pricePackageId id of the Price Package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerDeleteContractPricePackageById(contractId: string, pricePackageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.customerDeleteContractPricePackageById(contractId, pricePackageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get billing information for a contract
         * @summary Get billing information for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetBillingSettingsByContractId(contractId: string, options?: any): AxiosPromise<BillingSettingsResponse> {
            return localVarFp.customerGetBillingSettingsByContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get contact information of a contract
         * @summary Get contact of a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContactByContractId(contractId: string, options?: any): AxiosPromise<ContractContactCustomerResponse> {
            return localVarFp.customerGetContactByContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Contracts can be fetched by their respective UUID
         * @summary Get a Contract by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractById(contractId: string, options?: any): AxiosPromise<ContractCustomerResponse> {
            return localVarFp.customerGetContractById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the monthly price of the Contract\'s product
         * @summary Get a service fees for a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractContractDetailsById(contractId: string, options?: any): AxiosPromise<ZevContractCustomerResponse> {
            return localVarFp.customerGetContractContractDetailsById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the time of the last EDM data point for a contract
         * @summary Get the time of the last EDM data point for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractLastDataPointById(contractId: string, options?: any): AxiosPromise<ContractDataAvailabilityResponse> {
            return localVarFp.customerGetContractLastDataPointById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the energy values for the price calculator
         * @summary Get the energy values for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} year the year for which to return the Contract energy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractPriceCalculatorEnergyById(contractId: string, year: number, options?: any): AxiosPromise<ZevPriceCalculatorEnergy> {
            return localVarFp.customerGetContractPriceCalculatorEnergyById(contractId, year, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the prices input for the price calculator
         * @summary Get the prices input for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractPriceCalculatorInputById(contractId: string, options?: any): AxiosPromise<ZevPriceCalculatorInputResponse> {
            return localVarFp.customerGetContractPriceCalculatorInputById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get new computed contract prices using the price calculator
         * @summary Get new computed contract prices using the price calculator using past year\'s energy calculations
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} year the year for which to return the Contract energy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractPriceCalculatorPricesById(contractId: string, year: number, options?: any): AxiosPromise<ZevPriceCalculatorPricesResponse> {
            return localVarFp.customerGetContractPriceCalculatorPricesById(contractId, year, options).then((request) => request(axios, basePath));
        },
        /**
         * Get new computed contract prices using the price calculator
         * @summary Get new computed contract prices using the price calculator with supplied energy
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} year the year for which to return the Contract energy
         * @param {ZevPriceCalculatorEnergy} zevPriceCalculatorEnergy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractPriceCalculatorPricesSuppliedEnergyById(contractId: string, year: number, zevPriceCalculatorEnergy: ZevPriceCalculatorEnergy, options?: any): AxiosPromise<ZevPriceCalculatorPricesResponse> {
            return localVarFp.customerGetContractPriceCalculatorPricesSuppliedEnergyById(contractId, year, zevPriceCalculatorEnergy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the Contract municipality tariffs for all the known years for the price calculator
         * @summary Get the Contract municipality tariffs for all the known years for the price calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractPriceCalculatorTariffsById(contractId: string, options?: any): AxiosPromise<ZevPriceCalculatorTariffsResponse> {
            return localVarFp.customerGetContractPriceCalculatorTariffsById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a Contract\'s service-component features
         * @summary Get a contract\'s service-component features
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetContractServiceComponentFeatures(contractId: string, options?: any): AxiosPromise<ContractServiceComponentFeaturesResponse> {
            return localVarFp.customerGetContractServiceComponentFeatures(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get All IndividualParticipantBillings of a Contract
         * @summary Get All Individual Participant Billings for a given Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetIndividualParticipantsBillingsContractById(contractId: string, id: string, options?: any): AxiosPromise<ContractIndividualParticipantBillingCustomerResponse> {
            return localVarFp.customerGetIndividualParticipantsBillingsContractById(contractId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides calculated meter-readings for a contract over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetMeterReadingsByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: any): AxiosPromise<MeterReadingsContractResponse> {
            return localVarFp.customerGetMeterReadingsByContractId(contractId, startDate, endDate, resolution, options).then((request) => request(axios, basePath));
        },
        /**
         * Get page AllParticipantsBilling of a Contract
         * @summary Get page of All Participants Billing for a given Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetPageAllParticipantsBillingsContractById(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedAllParticipantsBillingResponse> {
            return localVarFp.customerGetPageAllParticipantsBillingsContractById(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get page IndividualParticipantBillings of a contract
         * @summary Get page of individual participant billings for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetPageIndividualParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedIndividualParticipantsBillingResponse> {
            return localVarFp.customerGetPageIndividualParticipantsBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get page of VEWA All Participant Billing for a contract
         * @summary Get page of VEWA participants billing for a contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetPagedVewaAllParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedVewaAllParticipantsBillingResponse> {
            return localVarFp.customerGetPagedVewaAllParticipantsBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all participants for a contract
         * @summary Get participants for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetParticipantsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedParticipantResponse> {
            return localVarFp.customerGetParticipantsByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get contract\'s price packages
         * @summary Get contract\'s price packages
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetPricePackagesByContractId(contractId: string, options?: any): AxiosPromise<Array<ZevPricePackage>> {
            return localVarFp.customerGetPricePackagesByContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get contract product history
         * @summary Get contract product history
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetProductHistoryContractId(contractId: string, options?: any): AxiosPromise<ProductHistoryResponse> {
            return localVarFp.customerGetProductHistoryContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a page of Utility Units linked to a Contract
         * @summary Get a page of Utility Units linked to a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetUtilityUnitsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedUtilityUnitResponse> {
            return localVarFp.customerGetUtilityUnitsByContractId(contractId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get VEWA config details for contract
         * @summary Get VEWA config details for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetVewaConfigByContractId(contractId: string, options?: any): AxiosPromise<VewaConfig> {
            return localVarFp.customerGetVewaConfigByContractId(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update cost share settings for VEWA contract by customer
         * @summary Customer update VEWA cost shares
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {VewaCostShares} vewaCostShares 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerPutVewaCostSharesByContractId(contractId: string, vewaCostShares: VewaCostShares, options?: any): AxiosPromise<void> {
            return localVarFp.customerPutVewaCostSharesByContractId(contractId, vewaCostShares, options).then((request) => request(axios, basePath));
        },
        /**
         * Update contact address of a contract
         * @summary Update contact address of a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractAddressData} contractAddressData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContactAddressByContractId(contractId: string, contractAddressData: ContractAddressData, options?: any): AxiosPromise<void> {
            return localVarFp.customerUpdateContactAddressByContractId(contractId, contractAddressData, options).then((request) => request(axios, basePath));
        },
        /**
         * Update contact data of a contract
         * @summary Update contact data of a Contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContactData} contactData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContactDataByContractId(contractId: string, contactData: ContactData, options?: any): AxiosPromise<void> {
            return localVarFp.customerUpdateContactDataByContractId(contractId, contactData, options).then((request) => request(axios, basePath));
        },
        /**
         * Contract main contact person can be updated by their respective UUID
         * @summary Update a Contracts main contact person by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateMainContactPersonCustomerRequest} contractUpdateMainContactPersonCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractMainContactPersonById(contractId: string, contractUpdateMainContactPersonCustomerRequest: ContractUpdateMainContactPersonCustomerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerUpdateContractMainContactPersonById(contractId, contractUpdateMainContactPersonCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Contract payment information can be updated by their respective UUID
         * @summary Update a Contract\'s payment information by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdatePaymentInformationCustomerRequest} contractUpdatePaymentInformationCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractPaymentInformationById(contractId: string, contractUpdatePaymentInformationCustomerRequest: ContractUpdatePaymentInformationCustomerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerUpdateContractPaymentInformationById(contractId, contractUpdatePaymentInformationCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a contract input prices for the price-calculator
         * @summary Update a contract input prices for the price-calculator
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ZevPriceCalculatorInputUpdateRequest} zevPriceCalculatorInputUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractPriceCalculatorInputById(contractId: string, zevPriceCalculatorInputUpdateRequest: ZevPriceCalculatorInputUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerUpdateContractPriceCalculatorInputById(contractId, zevPriceCalculatorInputUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * contract prices can be updated by their respective contract UUID and price package ID
         * @summary Update a contract\'s price package by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {number} pricePackageId id of the Price Package
         * @param {ZevPricePackage} zevPricePackage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractPricePackageById(contractId: string, pricePackageId: number, zevPricePackage: ZevPricePackage, options?: any): AxiosPromise<void> {
            return localVarFp.customerUpdateContractPricePackageById(contractId, pricePackageId, zevPricePackage, options).then((request) => request(axios, basePath));
        },
        /**
         * Contract start date can be updated by their respective UUID
         * @summary Update a Contract\'s start date by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateStartDateCustomerRequest} contractUpdateStartDateCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractStartDateById(contractId: string, contractUpdateStartDateCustomerRequest: ContractUpdateStartDateCustomerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerUpdateContractStartDateById(contractId, contractUpdateStartDateCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Contract payment information can be updated by their respective UUID
         * @summary Update a VEWA Contract billing settings by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateVewaBillingSettingsCustomerRequest} contractUpdateVewaBillingSettingsCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractVewaBillingSettingsById(contractId: string, contractUpdateVewaBillingSettingsCustomerRequest: ContractUpdateVewaBillingSettingsCustomerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerUpdateContractVewaBillingSettingsById(contractId, contractUpdateVewaBillingSettingsCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Contract payment information can be updated by their respective UUID
         * @summary Update a ZEV Contract billing settings by its ID
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {ContractUpdateZevBillingSettingsCustomerRequest} contractUpdateZevBillingSettingsCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateContractZevBillingSettingsById(contractId: string, contractUpdateZevBillingSettingsCustomerRequest: ContractUpdateZevBillingSettingsCustomerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerUpdateContractZevBillingSettingsById(contractId, contractUpdateZevBillingSettingsCustomerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerContractApi - interface
 * @export
 * @interface CustomerContractApi
 */
export interface CustomerContractApiInterface {
    /**
     * Contract onboarding can be completed by their respective UUID
     * @summary Completes a Contract\'s onboarding by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerCompleteContractOnboardingById(contractId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Create a new price package for a contract
     * @summary Create a new price package for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ZevPricePackage} zevPricePackage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerCreatePricePackageByContractId(contractId: string, zevPricePackage: ZevPricePackage, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Contract prices can be deleted by their respective contractConfig UUID and price package ID
     * @summary Delete a Contracts price package by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} pricePackageId id of the Price Package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerDeleteContractPricePackageById(contractId: string, pricePackageId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get billing information for a contract
     * @summary Get billing information for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetBillingSettingsByContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<BillingSettingsResponse>;

    /**
     * Get contact information of a contract
     * @summary Get contact of a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetContactByContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ContractContactCustomerResponse>;

    /**
     * Contracts can be fetched by their respective UUID
     * @summary Get a Contract by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetContractById(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ContractCustomerResponse>;

    /**
     * Get the monthly price of the Contract\'s product
     * @summary Get a service fees for a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetContractContractDetailsById(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ZevContractCustomerResponse>;

    /**
     * Get the time of the last EDM data point for a contract
     * @summary Get the time of the last EDM data point for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetContractLastDataPointById(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ContractDataAvailabilityResponse>;

    /**
     * Get the energy values for the price calculator
     * @summary Get the energy values for the price calculator
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} year the year for which to return the Contract energy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetContractPriceCalculatorEnergyById(contractId: string, year: number, options?: AxiosRequestConfig): AxiosPromise<ZevPriceCalculatorEnergy>;

    /**
     * Get the prices input for the price calculator
     * @summary Get the prices input for the price calculator
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetContractPriceCalculatorInputById(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ZevPriceCalculatorInputResponse>;

    /**
     * Get new computed contract prices using the price calculator
     * @summary Get new computed contract prices using the price calculator using past year\'s energy calculations
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} year the year for which to return the Contract energy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetContractPriceCalculatorPricesById(contractId: string, year: number, options?: AxiosRequestConfig): AxiosPromise<ZevPriceCalculatorPricesResponse>;

    /**
     * Get new computed contract prices using the price calculator
     * @summary Get new computed contract prices using the price calculator with supplied energy
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} year the year for which to return the Contract energy
     * @param {ZevPriceCalculatorEnergy} zevPriceCalculatorEnergy 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetContractPriceCalculatorPricesSuppliedEnergyById(contractId: string, year: number, zevPriceCalculatorEnergy: ZevPriceCalculatorEnergy, options?: AxiosRequestConfig): AxiosPromise<ZevPriceCalculatorPricesResponse>;

    /**
     * Get the Contract municipality tariffs for all the known years for the price calculator
     * @summary Get the Contract municipality tariffs for all the known years for the price calculator
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetContractPriceCalculatorTariffsById(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ZevPriceCalculatorTariffsResponse>;

    /**
     * Get a Contract\'s service-component features
     * @summary Get a contract\'s service-component features
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetContractServiceComponentFeatures(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ContractServiceComponentFeaturesResponse>;

    /**
     * Get All IndividualParticipantBillings of a Contract
     * @summary Get All Individual Participant Billings for a given Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetIndividualParticipantsBillingsContractById(contractId: string, id: string, options?: AxiosRequestConfig): AxiosPromise<ContractIndividualParticipantBillingCustomerResponse>;

    /**
     * Provides calculated meter-readings for a contract over a time period divided according to the provided time resolution
     * @summary Get meter-readings for a specific contract by id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetMeterReadingsByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: AxiosRequestConfig): AxiosPromise<MeterReadingsContractResponse>;

    /**
     * Get page AllParticipantsBilling of a Contract
     * @summary Get page of All Participants Billing for a given Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetPageAllParticipantsBillingsContractById(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedAllParticipantsBillingResponse>;

    /**
     * Get page IndividualParticipantBillings of a contract
     * @summary Get page of individual participant billings for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetPageIndividualParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedIndividualParticipantsBillingResponse>;

    /**
     * Get page of VEWA All Participant Billing for a contract
     * @summary Get page of VEWA participants billing for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetPagedVewaAllParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedVewaAllParticipantsBillingResponse>;

    /**
     * Get all participants for a contract
     * @summary Get participants for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetParticipantsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedParticipantResponse>;

    /**
     * Get contract\'s price packages
     * @summary Get contract\'s price packages
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetPricePackagesByContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ZevPricePackage>>;

    /**
     * Get contract product history
     * @summary Get contract product history
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetProductHistoryContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<ProductHistoryResponse>;

    /**
     * Get a page of Utility Units linked to a Contract
     * @summary Get a page of Utility Units linked to a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetUtilityUnitsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedUtilityUnitResponse>;

    /**
     * Get VEWA config details for contract
     * @summary Get VEWA config details for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerGetVewaConfigByContractId(contractId: string, options?: AxiosRequestConfig): AxiosPromise<VewaConfig>;

    /**
     * Update cost share settings for VEWA contract by customer
     * @summary Customer update VEWA cost shares
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {VewaCostShares} vewaCostShares 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerPutVewaCostSharesByContractId(contractId: string, vewaCostShares: VewaCostShares, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update contact address of a contract
     * @summary Update contact address of a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractAddressData} contractAddressData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerUpdateContactAddressByContractId(contractId: string, contractAddressData: ContractAddressData, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update contact data of a contract
     * @summary Update contact data of a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContactData} contactData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerUpdateContactDataByContractId(contractId: string, contactData: ContactData, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Contract main contact person can be updated by their respective UUID
     * @summary Update a Contracts main contact person by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdateMainContactPersonCustomerRequest} contractUpdateMainContactPersonCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerUpdateContractMainContactPersonById(contractId: string, contractUpdateMainContactPersonCustomerRequest: ContractUpdateMainContactPersonCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Contract payment information can be updated by their respective UUID
     * @summary Update a Contract\'s payment information by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdatePaymentInformationCustomerRequest} contractUpdatePaymentInformationCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerUpdateContractPaymentInformationById(contractId: string, contractUpdatePaymentInformationCustomerRequest: ContractUpdatePaymentInformationCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update a contract input prices for the price-calculator
     * @summary Update a contract input prices for the price-calculator
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ZevPriceCalculatorInputUpdateRequest} zevPriceCalculatorInputUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerUpdateContractPriceCalculatorInputById(contractId: string, zevPriceCalculatorInputUpdateRequest: ZevPriceCalculatorInputUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * contract prices can be updated by their respective contract UUID and price package ID
     * @summary Update a contract\'s price package by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} pricePackageId id of the Price Package
     * @param {ZevPricePackage} zevPricePackage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerUpdateContractPricePackageById(contractId: string, pricePackageId: number, zevPricePackage: ZevPricePackage, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Contract start date can be updated by their respective UUID
     * @summary Update a Contract\'s start date by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdateStartDateCustomerRequest} contractUpdateStartDateCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerUpdateContractStartDateById(contractId: string, contractUpdateStartDateCustomerRequest: ContractUpdateStartDateCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Contract payment information can be updated by their respective UUID
     * @summary Update a VEWA Contract billing settings by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdateVewaBillingSettingsCustomerRequest} contractUpdateVewaBillingSettingsCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerUpdateContractVewaBillingSettingsById(contractId: string, contractUpdateVewaBillingSettingsCustomerRequest: ContractUpdateVewaBillingSettingsCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Contract payment information can be updated by their respective UUID
     * @summary Update a ZEV Contract billing settings by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdateZevBillingSettingsCustomerRequest} contractUpdateZevBillingSettingsCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApiInterface
     */
    customerUpdateContractZevBillingSettingsById(contractId: string, contractUpdateZevBillingSettingsCustomerRequest: ContractUpdateZevBillingSettingsCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CustomerContractApi - object-oriented interface
 * @export
 * @class CustomerContractApi
 * @extends {BaseAPI}
 */
export class CustomerContractApi extends BaseAPI implements CustomerContractApiInterface {
    /**
     * Contract onboarding can be completed by their respective UUID
     * @summary Completes a Contract\'s onboarding by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerCompleteContractOnboardingById(contractId: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerCompleteContractOnboardingById(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new price package for a contract
     * @summary Create a new price package for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ZevPricePackage} zevPricePackage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerCreatePricePackageByContractId(contractId: string, zevPricePackage: ZevPricePackage, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerCreatePricePackageByContractId(contractId, zevPricePackage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contract prices can be deleted by their respective contractConfig UUID and price package ID
     * @summary Delete a Contracts price package by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} pricePackageId id of the Price Package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerDeleteContractPricePackageById(contractId: string, pricePackageId: number, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerDeleteContractPricePackageById(contractId, pricePackageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get billing information for a contract
     * @summary Get billing information for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetBillingSettingsByContractId(contractId: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetBillingSettingsByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get contact information of a contract
     * @summary Get contact of a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetContactByContractId(contractId: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetContactByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contracts can be fetched by their respective UUID
     * @summary Get a Contract by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetContractById(contractId: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetContractById(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the monthly price of the Contract\'s product
     * @summary Get a service fees for a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetContractContractDetailsById(contractId: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetContractContractDetailsById(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the time of the last EDM data point for a contract
     * @summary Get the time of the last EDM data point for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetContractLastDataPointById(contractId: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetContractLastDataPointById(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the energy values for the price calculator
     * @summary Get the energy values for the price calculator
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} year the year for which to return the Contract energy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetContractPriceCalculatorEnergyById(contractId: string, year: number, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetContractPriceCalculatorEnergyById(contractId, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the prices input for the price calculator
     * @summary Get the prices input for the price calculator
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetContractPriceCalculatorInputById(contractId: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetContractPriceCalculatorInputById(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get new computed contract prices using the price calculator
     * @summary Get new computed contract prices using the price calculator using past year\'s energy calculations
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} year the year for which to return the Contract energy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetContractPriceCalculatorPricesById(contractId: string, year: number, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetContractPriceCalculatorPricesById(contractId, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get new computed contract prices using the price calculator
     * @summary Get new computed contract prices using the price calculator with supplied energy
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} year the year for which to return the Contract energy
     * @param {ZevPriceCalculatorEnergy} zevPriceCalculatorEnergy 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetContractPriceCalculatorPricesSuppliedEnergyById(contractId: string, year: number, zevPriceCalculatorEnergy: ZevPriceCalculatorEnergy, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetContractPriceCalculatorPricesSuppliedEnergyById(contractId, year, zevPriceCalculatorEnergy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the Contract municipality tariffs for all the known years for the price calculator
     * @summary Get the Contract municipality tariffs for all the known years for the price calculator
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetContractPriceCalculatorTariffsById(contractId: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetContractPriceCalculatorTariffsById(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a Contract\'s service-component features
     * @summary Get a contract\'s service-component features
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetContractServiceComponentFeatures(contractId: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetContractServiceComponentFeatures(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get All IndividualParticipantBillings of a Contract
     * @summary Get All Individual Participant Billings for a given Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetIndividualParticipantsBillingsContractById(contractId: string, id: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetIndividualParticipantsBillingsContractById(contractId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides calculated meter-readings for a contract over a time period divided according to the provided time resolution
     * @summary Get meter-readings for a specific contract by id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetMeterReadingsByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetMeterReadingsByContractId(contractId, startDate, endDate, resolution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get page AllParticipantsBilling of a Contract
     * @summary Get page of All Participants Billing for a given Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetPageAllParticipantsBillingsContractById(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetPageAllParticipantsBillingsContractById(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get page IndividualParticipantBillings of a contract
     * @summary Get page of individual participant billings for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetPageIndividualParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetPageIndividualParticipantsBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get page of VEWA All Participant Billing for a contract
     * @summary Get page of VEWA participants billing for a contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetPagedVewaAllParticipantsBillingsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetPagedVewaAllParticipantsBillingsByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all participants for a contract
     * @summary Get participants for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetParticipantsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetParticipantsByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get contract\'s price packages
     * @summary Get contract\'s price packages
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetPricePackagesByContractId(contractId: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetPricePackagesByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get contract product history
     * @summary Get contract product history
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetProductHistoryContractId(contractId: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetProductHistoryContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a page of Utility Units linked to a Contract
     * @summary Get a page of Utility Units linked to a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetUtilityUnitsByContractId(contractId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetUtilityUnitsByContractId(contractId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get VEWA config details for contract
     * @summary Get VEWA config details for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerGetVewaConfigByContractId(contractId: string, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerGetVewaConfigByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update cost share settings for VEWA contract by customer
     * @summary Customer update VEWA cost shares
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {VewaCostShares} vewaCostShares 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerPutVewaCostSharesByContractId(contractId: string, vewaCostShares: VewaCostShares, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerPutVewaCostSharesByContractId(contractId, vewaCostShares, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update contact address of a contract
     * @summary Update contact address of a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractAddressData} contractAddressData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerUpdateContactAddressByContractId(contractId: string, contractAddressData: ContractAddressData, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerUpdateContactAddressByContractId(contractId, contractAddressData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update contact data of a contract
     * @summary Update contact data of a Contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContactData} contactData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerUpdateContactDataByContractId(contractId: string, contactData: ContactData, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerUpdateContactDataByContractId(contractId, contactData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contract main contact person can be updated by their respective UUID
     * @summary Update a Contracts main contact person by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdateMainContactPersonCustomerRequest} contractUpdateMainContactPersonCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerUpdateContractMainContactPersonById(contractId: string, contractUpdateMainContactPersonCustomerRequest: ContractUpdateMainContactPersonCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerUpdateContractMainContactPersonById(contractId, contractUpdateMainContactPersonCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contract payment information can be updated by their respective UUID
     * @summary Update a Contract\'s payment information by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdatePaymentInformationCustomerRequest} contractUpdatePaymentInformationCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerUpdateContractPaymentInformationById(contractId: string, contractUpdatePaymentInformationCustomerRequest: ContractUpdatePaymentInformationCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerUpdateContractPaymentInformationById(contractId, contractUpdatePaymentInformationCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a contract input prices for the price-calculator
     * @summary Update a contract input prices for the price-calculator
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ZevPriceCalculatorInputUpdateRequest} zevPriceCalculatorInputUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerUpdateContractPriceCalculatorInputById(contractId: string, zevPriceCalculatorInputUpdateRequest: ZevPriceCalculatorInputUpdateRequest, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerUpdateContractPriceCalculatorInputById(contractId, zevPriceCalculatorInputUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * contract prices can be updated by their respective contract UUID and price package ID
     * @summary Update a contract\'s price package by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {number} pricePackageId id of the Price Package
     * @param {ZevPricePackage} zevPricePackage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerUpdateContractPricePackageById(contractId: string, pricePackageId: number, zevPricePackage: ZevPricePackage, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerUpdateContractPricePackageById(contractId, pricePackageId, zevPricePackage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contract start date can be updated by their respective UUID
     * @summary Update a Contract\'s start date by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdateStartDateCustomerRequest} contractUpdateStartDateCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerUpdateContractStartDateById(contractId: string, contractUpdateStartDateCustomerRequest: ContractUpdateStartDateCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerUpdateContractStartDateById(contractId, contractUpdateStartDateCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contract payment information can be updated by their respective UUID
     * @summary Update a VEWA Contract billing settings by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdateVewaBillingSettingsCustomerRequest} contractUpdateVewaBillingSettingsCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerUpdateContractVewaBillingSettingsById(contractId: string, contractUpdateVewaBillingSettingsCustomerRequest: ContractUpdateVewaBillingSettingsCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerUpdateContractVewaBillingSettingsById(contractId, contractUpdateVewaBillingSettingsCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contract payment information can be updated by their respective UUID
     * @summary Update a ZEV Contract billing settings by its ID
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {ContractUpdateZevBillingSettingsCustomerRequest} contractUpdateZevBillingSettingsCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContractApi
     */
    public customerUpdateContractZevBillingSettingsById(contractId: string, contractUpdateZevBillingSettingsCustomerRequest: ContractUpdateZevBillingSettingsCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerContractApiFp(this.configuration).customerUpdateContractZevBillingSettingsById(contractId, contractUpdateZevBillingSettingsCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerIndividualParticipantBillingApi - axios parameter creator
 * @export
 */
export const CustomerIndividualParticipantBillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the billing details of an IndividualParticipantBilling
         * @summary Get the billing details of an IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerIndividualParticipantBillingDetailsById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerIndividualParticipantBillingDetailsById', 'id', id)
            const localVarPath = `/api/customer/v1/individualParticipantBilling/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to approved for IndividualParticipantBilling
         * @summary State change to approved for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerIndividualParticipantBillingApproveStateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putCustomerIndividualParticipantBillingApproveStateById', 'id', id)
            const localVarPath = `/api/customer/v1/individualParticipantBilling/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * State change to reopened for IndividualParticipantBilling
         * @summary State change to reopened for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerIndividualParticipantBillingReopenStateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putCustomerIndividualParticipantBillingReopenStateById', 'id', id)
            const localVarPath = `/api/customer/v1/individualParticipantBilling/{id}/reopen`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the billing prices of an IndividualParticipantBilling
         * @summary Update the billing prices of an IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {IndividualParticipantBillingPricesUpdateRequest} individualParticipantBillingPricesUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerIndividualParticipantBillingPricesById: async (id: string, individualParticipantBillingPricesUpdateRequest: IndividualParticipantBillingPricesUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomerIndividualParticipantBillingPricesById', 'id', id)
            // verify required parameter 'individualParticipantBillingPricesUpdateRequest' is not null or undefined
            assertParamExists('updateCustomerIndividualParticipantBillingPricesById', 'individualParticipantBillingPricesUpdateRequest', individualParticipantBillingPricesUpdateRequest)
            const localVarPath = `/api/customer/v1/individualParticipantBilling/{id}/prices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(individualParticipantBillingPricesUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerIndividualParticipantBillingApi - functional programming interface
 * @export
 */
export const CustomerIndividualParticipantBillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerIndividualParticipantBillingApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the billing details of an IndividualParticipantBilling
         * @summary Get the billing details of an IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerIndividualParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndividualParticipantBillingDetailsCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerIndividualParticipantBillingDetailsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to approved for IndividualParticipantBilling
         * @summary State change to approved for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomerIndividualParticipantBillingApproveStateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomerIndividualParticipantBillingApproveStateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * State change to reopened for IndividualParticipantBilling
         * @summary State change to reopened for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomerIndividualParticipantBillingReopenStateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomerIndividualParticipantBillingReopenStateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the billing prices of an IndividualParticipantBilling
         * @summary Update the billing prices of an IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {IndividualParticipantBillingPricesUpdateRequest} individualParticipantBillingPricesUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerIndividualParticipantBillingPricesById(id: string, individualParticipantBillingPricesUpdateRequest: IndividualParticipantBillingPricesUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerIndividualParticipantBillingPricesById(id, individualParticipantBillingPricesUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerIndividualParticipantBillingApi - factory interface
 * @export
 */
export const CustomerIndividualParticipantBillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerIndividualParticipantBillingApiFp(configuration)
    return {
        /**
         * Get the billing details of an IndividualParticipantBilling
         * @summary Get the billing details of an IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerIndividualParticipantBillingDetailsById(id: string, options?: any): AxiosPromise<IndividualParticipantBillingDetailsCustomerResponse> {
            return localVarFp.getCustomerIndividualParticipantBillingDetailsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to approved for IndividualParticipantBilling
         * @summary State change to approved for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerIndividualParticipantBillingApproveStateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomerIndividualParticipantBillingApproveStateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * State change to reopened for IndividualParticipantBilling
         * @summary State change to reopened for IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerIndividualParticipantBillingReopenStateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomerIndividualParticipantBillingReopenStateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the billing prices of an IndividualParticipantBilling
         * @summary Update the billing prices of an IndividualParticipantBilling
         * @param {string} id uuid of the Billing object
         * @param {IndividualParticipantBillingPricesUpdateRequest} individualParticipantBillingPricesUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerIndividualParticipantBillingPricesById(id: string, individualParticipantBillingPricesUpdateRequest: IndividualParticipantBillingPricesUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCustomerIndividualParticipantBillingPricesById(id, individualParticipantBillingPricesUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerIndividualParticipantBillingApi - interface
 * @export
 * @interface CustomerIndividualParticipantBillingApi
 */
export interface CustomerIndividualParticipantBillingApiInterface {
    /**
     * Get the billing details of an IndividualParticipantBilling
     * @summary Get the billing details of an IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerIndividualParticipantBillingApiInterface
     */
    getCustomerIndividualParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig): AxiosPromise<IndividualParticipantBillingDetailsCustomerResponse>;

    /**
     * State change to approved for IndividualParticipantBilling
     * @summary State change to approved for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerIndividualParticipantBillingApiInterface
     */
    putCustomerIndividualParticipantBillingApproveStateById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * State change to reopened for IndividualParticipantBilling
     * @summary State change to reopened for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerIndividualParticipantBillingApiInterface
     */
    putCustomerIndividualParticipantBillingReopenStateById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update the billing prices of an IndividualParticipantBilling
     * @summary Update the billing prices of an IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {IndividualParticipantBillingPricesUpdateRequest} individualParticipantBillingPricesUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerIndividualParticipantBillingApiInterface
     */
    updateCustomerIndividualParticipantBillingPricesById(id: string, individualParticipantBillingPricesUpdateRequest: IndividualParticipantBillingPricesUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CustomerIndividualParticipantBillingApi - object-oriented interface
 * @export
 * @class CustomerIndividualParticipantBillingApi
 * @extends {BaseAPI}
 */
export class CustomerIndividualParticipantBillingApi extends BaseAPI implements CustomerIndividualParticipantBillingApiInterface {
    /**
     * Get the billing details of an IndividualParticipantBilling
     * @summary Get the billing details of an IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerIndividualParticipantBillingApi
     */
    public getCustomerIndividualParticipantBillingDetailsById(id: string, options?: AxiosRequestConfig) {
        return CustomerIndividualParticipantBillingApiFp(this.configuration).getCustomerIndividualParticipantBillingDetailsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to approved for IndividualParticipantBilling
     * @summary State change to approved for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerIndividualParticipantBillingApi
     */
    public putCustomerIndividualParticipantBillingApproveStateById(id: string, options?: AxiosRequestConfig) {
        return CustomerIndividualParticipantBillingApiFp(this.configuration).putCustomerIndividualParticipantBillingApproveStateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * State change to reopened for IndividualParticipantBilling
     * @summary State change to reopened for IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerIndividualParticipantBillingApi
     */
    public putCustomerIndividualParticipantBillingReopenStateById(id: string, options?: AxiosRequestConfig) {
        return CustomerIndividualParticipantBillingApiFp(this.configuration).putCustomerIndividualParticipantBillingReopenStateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the billing prices of an IndividualParticipantBilling
     * @summary Update the billing prices of an IndividualParticipantBilling
     * @param {string} id uuid of the Billing object
     * @param {IndividualParticipantBillingPricesUpdateRequest} individualParticipantBillingPricesUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerIndividualParticipantBillingApi
     */
    public updateCustomerIndividualParticipantBillingPricesById(id: string, individualParticipantBillingPricesUpdateRequest: IndividualParticipantBillingPricesUpdateRequest, options?: AxiosRequestConfig) {
        return CustomerIndividualParticipantBillingApiFp(this.configuration).updateCustomerIndividualParticipantBillingPricesById(id, individualParticipantBillingPricesUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerMeterApi - axios parameter creator
 * @export
 */
export const CustomerMeterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Price package on the meter can be updated
         * @summary Put a price package on the meter
         * @param {string} meterId uuid of the Meter
         * @param {MeterUpdatePricePackageCustomerRequest} meterUpdatePricePackageCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerPutPricePackageId: async (meterId: string, meterUpdatePricePackageCustomerRequest: MeterUpdatePricePackageCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meterId' is not null or undefined
            assertParamExists('customerPutPricePackageId', 'meterId', meterId)
            // verify required parameter 'meterUpdatePricePackageCustomerRequest' is not null or undefined
            assertParamExists('customerPutPricePackageId', 'meterUpdatePricePackageCustomerRequest', meterUpdatePricePackageCustomerRequest)
            const localVarPath = `/api/customer/v1/meters/{meterId}/updatePricePackageId`
                .replace(`{${"meterId"}}`, encodeURIComponent(String(meterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meterUpdatePricePackageCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerMeterApi - functional programming interface
 * @export
 */
export const CustomerMeterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerMeterApiAxiosParamCreator(configuration)
    return {
        /**
         * Price package on the meter can be updated
         * @summary Put a price package on the meter
         * @param {string} meterId uuid of the Meter
         * @param {MeterUpdatePricePackageCustomerRequest} meterUpdatePricePackageCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerPutPricePackageId(meterId: string, meterUpdatePricePackageCustomerRequest: MeterUpdatePricePackageCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerPutPricePackageId(meterId, meterUpdatePricePackageCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerMeterApi - factory interface
 * @export
 */
export const CustomerMeterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerMeterApiFp(configuration)
    return {
        /**
         * Price package on the meter can be updated
         * @summary Put a price package on the meter
         * @param {string} meterId uuid of the Meter
         * @param {MeterUpdatePricePackageCustomerRequest} meterUpdatePricePackageCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerPutPricePackageId(meterId: string, meterUpdatePricePackageCustomerRequest: MeterUpdatePricePackageCustomerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerPutPricePackageId(meterId, meterUpdatePricePackageCustomerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerMeterApi - interface
 * @export
 * @interface CustomerMeterApi
 */
export interface CustomerMeterApiInterface {
    /**
     * Price package on the meter can be updated
     * @summary Put a price package on the meter
     * @param {string} meterId uuid of the Meter
     * @param {MeterUpdatePricePackageCustomerRequest} meterUpdatePricePackageCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMeterApiInterface
     */
    customerPutPricePackageId(meterId: string, meterUpdatePricePackageCustomerRequest: MeterUpdatePricePackageCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CustomerMeterApi - object-oriented interface
 * @export
 * @class CustomerMeterApi
 * @extends {BaseAPI}
 */
export class CustomerMeterApi extends BaseAPI implements CustomerMeterApiInterface {
    /**
     * Price package on the meter can be updated
     * @summary Put a price package on the meter
     * @param {string} meterId uuid of the Meter
     * @param {MeterUpdatePricePackageCustomerRequest} meterUpdatePricePackageCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMeterApi
     */
    public customerPutPricePackageId(meterId: string, meterUpdatePricePackageCustomerRequest: MeterUpdatePricePackageCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerMeterApiFp(this.configuration).customerPutPricePackageId(meterId, meterUpdatePricePackageCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerMeterReadingsApi - axios parameter creator
 * @export
 */
export const CustomerMeterReadingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Provides calculated meter-readings for a contract over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetMeterReadingsByContractId: async (contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetMeterReadingsByContractId', 'contractId', contractId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('customerGetMeterReadingsByContractId', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('customerGetMeterReadingsByContractId', 'endDate', endDate)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('customerGetMeterReadingsByContractId', 'resolution', resolution)
            const localVarPath = `/api/customer/v1/meter-readings/contract/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides calculated meter-readings for a utilityUnit over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific utilityUnit by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetMeterReadingsByUtilityUnitId: async (utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerGetMeterReadingsByUtilityUnitId', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('customerGetMeterReadingsByUtilityUnitId', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('customerGetMeterReadingsByUtilityUnitId', 'endDate', endDate)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('customerGetMeterReadingsByUtilityUnitId', 'resolution', resolution)
            const localVarPath = `/api/customer/v1/meter-readings/utilityUnits/{utilityUnitId}`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }

            if (isFinalOnly !== undefined) {
                localVarQueryParameter['isFinalOnly'] = isFinalOnly;
            }

            if (contractType !== undefined) {
                localVarQueryParameter['contractType'] = contractType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerMeterReadingsApi - functional programming interface
 * @export
 */
export const CustomerMeterReadingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerMeterReadingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Provides calculated meter-readings for a contract over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetMeterReadingsByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadingsContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetMeterReadingsByContractId(contractId, startDate, endDate, resolution, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides calculated meter-readings for a utilityUnit over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific utilityUnit by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadingsUtilityUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetMeterReadingsByUtilityUnitId(utilityUnitId, startDate, endDate, resolution, isFinalOnly, contractType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerMeterReadingsApi - factory interface
 * @export
 */
export const CustomerMeterReadingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerMeterReadingsApiFp(configuration)
    return {
        /**
         * Provides calculated meter-readings for a contract over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific contract by id
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetMeterReadingsByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: any): AxiosPromise<MeterReadingsContractResponse> {
            return localVarFp.customerGetMeterReadingsByContractId(contractId, startDate, endDate, resolution, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides calculated meter-readings for a utilityUnit over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific utilityUnit by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: any): AxiosPromise<MeterReadingsUtilityUnitResponse> {
            return localVarFp.customerGetMeterReadingsByUtilityUnitId(utilityUnitId, startDate, endDate, resolution, isFinalOnly, contractType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerMeterReadingsApi - interface
 * @export
 * @interface CustomerMeterReadingsApi
 */
export interface CustomerMeterReadingsApiInterface {
    /**
     * Provides calculated meter-readings for a contract over a time period divided according to the provided time resolution
     * @summary Get meter-readings for a specific contract by id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMeterReadingsApiInterface
     */
    customerGetMeterReadingsByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: AxiosRequestConfig): AxiosPromise<MeterReadingsContractResponse>;

    /**
     * Provides calculated meter-readings for a utilityUnit over a time period divided according to the provided time resolution
     * @summary Get meter-readings for a specific utilityUnit by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
     * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMeterReadingsApiInterface
     */
    customerGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: AxiosRequestConfig): AxiosPromise<MeterReadingsUtilityUnitResponse>;

}

/**
 * CustomerMeterReadingsApi - object-oriented interface
 * @export
 * @class CustomerMeterReadingsApi
 * @extends {BaseAPI}
 */
export class CustomerMeterReadingsApi extends BaseAPI implements CustomerMeterReadingsApiInterface {
    /**
     * Provides calculated meter-readings for a contract over a time period divided according to the provided time resolution
     * @summary Get meter-readings for a specific contract by id
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMeterReadingsApi
     */
    public customerGetMeterReadingsByContractId(contractId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, options?: AxiosRequestConfig) {
        return CustomerMeterReadingsApiFp(this.configuration).customerGetMeterReadingsByContractId(contractId, startDate, endDate, resolution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides calculated meter-readings for a utilityUnit over a time period divided according to the provided time resolution
     * @summary Get meter-readings for a specific utilityUnit by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
     * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMeterReadingsApi
     */
    public customerGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: AxiosRequestConfig) {
        return CustomerMeterReadingsApiFp(this.configuration).customerGetMeterReadingsByUtilityUnitId(utilityUnitId, startDate, endDate, resolution, isFinalOnly, contractType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerParticipantsApi - axios parameter creator
 * @export
 */
export const CustomerParticipantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a Participant by ID
         * @summary Delete a Participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerDeleteParticipantById: async (participantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('customerDeleteParticipantById', 'participantId', participantId)
            const localVarPath = `/api/customer/v1/participants/{participantId}`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all participants in bulk by ids
         * @summary Fetch all participants in bulk by ids
         * @param {ParticipantsBulkRequest} participantsBulkRequest A Contract Participant bulk fetch request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetParticipantBulkByIds: async (participantsBulkRequest: ParticipantsBulkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantsBulkRequest' is not null or undefined
            assertParamExists('customerGetParticipantBulkByIds', 'participantsBulkRequest', participantsBulkRequest)
            const localVarPath = `/api/customer/v1/participants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participantsBulkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a Contract participant by ID
         * @summary Get a Contract participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetParticipantById: async (participantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('customerGetParticipantById', 'participantId', participantId)
            const localVarPath = `/api/customer/v1/participants/{participantId}`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Participant by ID
         * @summary Update a Participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {ParticipantCustomerUpdateRequest} participantCustomerUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateParticipantById: async (participantId: string, participantCustomerUpdateRequest: ParticipantCustomerUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('customerUpdateParticipantById', 'participantId', participantId)
            // verify required parameter 'participantCustomerUpdateRequest' is not null or undefined
            assertParamExists('customerUpdateParticipantById', 'participantCustomerUpdateRequest', participantCustomerUpdateRequest)
            const localVarPath = `/api/customer/v1/participants/{participantId}`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participantCustomerUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerParticipantsApi - functional programming interface
 * @export
 */
export const CustomerParticipantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerParticipantsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a Participant by ID
         * @summary Delete a Participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerDeleteParticipantById(participantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerDeleteParticipantById(participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all participants in bulk by ids
         * @summary Fetch all participants in bulk by ids
         * @param {ParticipantsBulkRequest} participantsBulkRequest A Contract Participant bulk fetch request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetParticipantBulkByIds(participantsBulkRequest: ParticipantsBulkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiParticipantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetParticipantBulkByIds(participantsBulkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a Contract participant by ID
         * @summary Get a Contract participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetParticipantById(participantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetParticipantById(participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a Participant by ID
         * @summary Update a Participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {ParticipantCustomerUpdateRequest} participantCustomerUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateParticipantById(participantId: string, participantCustomerUpdateRequest: ParticipantCustomerUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateParticipantById(participantId, participantCustomerUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerParticipantsApi - factory interface
 * @export
 */
export const CustomerParticipantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerParticipantsApiFp(configuration)
    return {
        /**
         * Delete a Participant by ID
         * @summary Delete a Participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerDeleteParticipantById(participantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerDeleteParticipantById(participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all participants in bulk by ids
         * @summary Fetch all participants in bulk by ids
         * @param {ParticipantsBulkRequest} participantsBulkRequest A Contract Participant bulk fetch request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetParticipantBulkByIds(participantsBulkRequest: ParticipantsBulkRequest, options?: any): AxiosPromise<MultiParticipantResponse> {
            return localVarFp.customerGetParticipantBulkByIds(participantsBulkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a Contract participant by ID
         * @summary Get a Contract participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetParticipantById(participantId: string, options?: any): AxiosPromise<ParticipantResponse> {
            return localVarFp.customerGetParticipantById(participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Participant by ID
         * @summary Update a Participant by ID
         * @param {string} participantId uuid of the Participant
         * @param {ParticipantCustomerUpdateRequest} participantCustomerUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateParticipantById(participantId: string, participantCustomerUpdateRequest: ParticipantCustomerUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerUpdateParticipantById(participantId, participantCustomerUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerParticipantsApi - interface
 * @export
 * @interface CustomerParticipantsApi
 */
export interface CustomerParticipantsApiInterface {
    /**
     * Delete a Participant by ID
     * @summary Delete a Participant by ID
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerParticipantsApiInterface
     */
    customerDeleteParticipantById(participantId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Fetch all participants in bulk by ids
     * @summary Fetch all participants in bulk by ids
     * @param {ParticipantsBulkRequest} participantsBulkRequest A Contract Participant bulk fetch request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerParticipantsApiInterface
     */
    customerGetParticipantBulkByIds(participantsBulkRequest: ParticipantsBulkRequest, options?: AxiosRequestConfig): AxiosPromise<MultiParticipantResponse>;

    /**
     * Get a Contract participant by ID
     * @summary Get a Contract participant by ID
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerParticipantsApiInterface
     */
    customerGetParticipantById(participantId: string, options?: AxiosRequestConfig): AxiosPromise<ParticipantResponse>;

    /**
     * Update a Participant by ID
     * @summary Update a Participant by ID
     * @param {string} participantId uuid of the Participant
     * @param {ParticipantCustomerUpdateRequest} participantCustomerUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerParticipantsApiInterface
     */
    customerUpdateParticipantById(participantId: string, participantCustomerUpdateRequest: ParticipantCustomerUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CustomerParticipantsApi - object-oriented interface
 * @export
 * @class CustomerParticipantsApi
 * @extends {BaseAPI}
 */
export class CustomerParticipantsApi extends BaseAPI implements CustomerParticipantsApiInterface {
    /**
     * Delete a Participant by ID
     * @summary Delete a Participant by ID
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerParticipantsApi
     */
    public customerDeleteParticipantById(participantId: string, options?: AxiosRequestConfig) {
        return CustomerParticipantsApiFp(this.configuration).customerDeleteParticipantById(participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all participants in bulk by ids
     * @summary Fetch all participants in bulk by ids
     * @param {ParticipantsBulkRequest} participantsBulkRequest A Contract Participant bulk fetch request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerParticipantsApi
     */
    public customerGetParticipantBulkByIds(participantsBulkRequest: ParticipantsBulkRequest, options?: AxiosRequestConfig) {
        return CustomerParticipantsApiFp(this.configuration).customerGetParticipantBulkByIds(participantsBulkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a Contract participant by ID
     * @summary Get a Contract participant by ID
     * @param {string} participantId uuid of the Participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerParticipantsApi
     */
    public customerGetParticipantById(participantId: string, options?: AxiosRequestConfig) {
        return CustomerParticipantsApiFp(this.configuration).customerGetParticipantById(participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Participant by ID
     * @summary Update a Participant by ID
     * @param {string} participantId uuid of the Participant
     * @param {ParticipantCustomerUpdateRequest} participantCustomerUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerParticipantsApi
     */
    public customerUpdateParticipantById(participantId: string, participantCustomerUpdateRequest: ParticipantCustomerUpdateRequest, options?: AxiosRequestConfig) {
        return CustomerParticipantsApiFp(this.configuration).customerUpdateParticipantById(participantId, participantCustomerUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerParticipationApi - axios parameter creator
 * @export
 */
export const CustomerParticipationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create the participation for a utilityUnit with new participant
         * @summary Create the participation for a utilityUnit with new participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinWithNewParticipantCustomerRequest} moveinWithNewParticipantCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerMoveinWithNewParticipant: async (utilityUnitId: string, participationId: string, moveinWithNewParticipantCustomerRequest: MoveinWithNewParticipantCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerMoveinWithNewParticipant', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('customerMoveinWithNewParticipant', 'participationId', participationId)
            // verify required parameter 'moveinWithNewParticipantCustomerRequest' is not null or undefined
            assertParamExists('customerMoveinWithNewParticipant', 'moveinWithNewParticipantCustomerRequest', moveinWithNewParticipantCustomerRequest)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein/new`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveinWithNewParticipantCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replace Participant for a Participation
         * @summary Replace Participant for a Participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ReplaceParticipantRequest} replaceParticipantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerReplaceParticipant: async (utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerReplaceParticipant', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('customerReplaceParticipant', 'participationId', participationId)
            // verify required parameter 'replaceParticipantRequest' is not null or undefined
            assertParamExists('customerReplaceParticipant', 'replaceParticipantRequest', replaceParticipantRequest)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/participant/replace`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceParticipantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerParticipationApi - functional programming interface
 * @export
 */
export const CustomerParticipationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerParticipationApiAxiosParamCreator(configuration)
    return {
        /**
         * Create the participation for a utilityUnit with new participant
         * @summary Create the participation for a utilityUnit with new participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinWithNewParticipantCustomerRequest} moveinWithNewParticipantCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantCustomerRequest: MoveinWithNewParticipantCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerMoveinWithNewParticipant(utilityUnitId, participationId, moveinWithNewParticipantCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replace Participant for a Participation
         * @summary Replace Participant for a Participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ReplaceParticipantRequest} replaceParticipantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerReplaceParticipant(utilityUnitId, participationId, replaceParticipantRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerParticipationApi - factory interface
 * @export
 */
export const CustomerParticipationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerParticipationApiFp(configuration)
    return {
        /**
         * Create the participation for a utilityUnit with new participant
         * @summary Create the participation for a utilityUnit with new participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinWithNewParticipantCustomerRequest} moveinWithNewParticipantCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantCustomerRequest: MoveinWithNewParticipantCustomerRequest, options?: any): AxiosPromise<string> {
            return localVarFp.customerMoveinWithNewParticipant(utilityUnitId, participationId, moveinWithNewParticipantCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replace Participant for a Participation
         * @summary Replace Participant for a Participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ReplaceParticipantRequest} replaceParticipantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerReplaceParticipant(utilityUnitId, participationId, replaceParticipantRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerParticipationApi - interface
 * @export
 * @interface CustomerParticipationApi
 */
export interface CustomerParticipationApiInterface {
    /**
     * Create the participation for a utilityUnit with new participant
     * @summary Create the participation for a utilityUnit with new participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinWithNewParticipantCustomerRequest} moveinWithNewParticipantCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerParticipationApiInterface
     */
    customerMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantCustomerRequest: MoveinWithNewParticipantCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Replace Participant for a Participation
     * @summary Replace Participant for a Participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ReplaceParticipantRequest} replaceParticipantRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerParticipationApiInterface
     */
    customerReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CustomerParticipationApi - object-oriented interface
 * @export
 * @class CustomerParticipationApi
 * @extends {BaseAPI}
 */
export class CustomerParticipationApi extends BaseAPI implements CustomerParticipationApiInterface {
    /**
     * Create the participation for a utilityUnit with new participant
     * @summary Create the participation for a utilityUnit with new participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinWithNewParticipantCustomerRequest} moveinWithNewParticipantCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerParticipationApi
     */
    public customerMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantCustomerRequest: MoveinWithNewParticipantCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerParticipationApiFp(this.configuration).customerMoveinWithNewParticipant(utilityUnitId, participationId, moveinWithNewParticipantCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replace Participant for a Participation
     * @summary Replace Participant for a Participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ReplaceParticipantRequest} replaceParticipantRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerParticipationApi
     */
    public customerReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: AxiosRequestConfig) {
        return CustomerParticipationApiFp(this.configuration).customerReplaceParticipant(utilityUnitId, participationId, replaceParticipantRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerProfileApi - axios parameter creator
 * @export
 */
export const CustomerProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * A logged-in customer can fetch its Profile
         * @summary Get the logged-in customer\'s profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/v1/profiles/logged-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A logged-in customer can update its profile address
         * @summary Update the logged-in customer\'s profile address
         * @param {ProfileUpdateAddressCustomerRequest} profileUpdateAddressCustomerRequest A customer profile address update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerProfileAddress: async (profileUpdateAddressCustomerRequest: ProfileUpdateAddressCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileUpdateAddressCustomerRequest' is not null or undefined
            assertParamExists('updateCustomerProfileAddress', 'profileUpdateAddressCustomerRequest', profileUpdateAddressCustomerRequest)
            const localVarPath = `/api/customer/v1/profiles/logged-in/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdateAddressCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A logged-in customer can update its profile contact details
         * @summary Update the logged-in customer\'s profile contact details
         * @param {ProfileUpdateContactCustomerRequest} profileUpdateContactCustomerRequest A customer profile contact details update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerProfileContact: async (profileUpdateContactCustomerRequest: ProfileUpdateContactCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileUpdateContactCustomerRequest' is not null or undefined
            assertParamExists('updateCustomerProfileContact', 'profileUpdateContactCustomerRequest', profileUpdateContactCustomerRequest)
            const localVarPath = `/api/customer/v1/profiles/logged-in/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdateContactCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A logged-in customer can update its profile email
         * @summary Update the logged-in customer\'s profile email
         * @param {ProfileUpdateEmailCustomerRequest} profileUpdateEmailCustomerRequest A customer profile email update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerProfileEmail: async (profileUpdateEmailCustomerRequest: ProfileUpdateEmailCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileUpdateEmailCustomerRequest' is not null or undefined
            assertParamExists('updateCustomerProfileEmail', 'profileUpdateEmailCustomerRequest', profileUpdateEmailCustomerRequest)
            const localVarPath = `/api/customer/v1/profiles/logged-in/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdateEmailCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logged-in customer can update its password
         * @summary Update the logged-in customer\'s profile password
         * @param {ProfilePasswordRequest} profilePasswordRequest A customer profile password request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerProfilePassword: async (profilePasswordRequest: ProfilePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profilePasswordRequest' is not null or undefined
            assertParamExists('updateCustomerProfilePassword', 'profilePasswordRequest', profilePasswordRequest)
            const localVarPath = `/api/customer/v1/profiles/logged-in/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profilePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A logged-in customer can update its profile personal
         * @summary Update the logged-in customer\'s profile personal
         * @param {ProfileUpdatePersonalCustomerRequest} profileUpdatePersonalCustomerRequest A customer profile personal update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerProfilePersonal: async (profileUpdatePersonalCustomerRequest: ProfileUpdatePersonalCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileUpdatePersonalCustomerRequest' is not null or undefined
            assertParamExists('updateCustomerProfilePersonal', 'profileUpdatePersonalCustomerRequest', profileUpdatePersonalCustomerRequest)
            const localVarPath = `/api/customer/v1/profiles/logged-in/personal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdatePersonalCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerProfileApi - functional programming interface
 * @export
 */
export const CustomerProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * A logged-in customer can fetch its Profile
         * @summary Get the logged-in customer\'s profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A logged-in customer can update its profile address
         * @summary Update the logged-in customer\'s profile address
         * @param {ProfileUpdateAddressCustomerRequest} profileUpdateAddressCustomerRequest A customer profile address update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerProfileAddress(profileUpdateAddressCustomerRequest: ProfileUpdateAddressCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerProfileAddress(profileUpdateAddressCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A logged-in customer can update its profile contact details
         * @summary Update the logged-in customer\'s profile contact details
         * @param {ProfileUpdateContactCustomerRequest} profileUpdateContactCustomerRequest A customer profile contact details update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerProfileContact(profileUpdateContactCustomerRequest: ProfileUpdateContactCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerProfileContact(profileUpdateContactCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A logged-in customer can update its profile email
         * @summary Update the logged-in customer\'s profile email
         * @param {ProfileUpdateEmailCustomerRequest} profileUpdateEmailCustomerRequest A customer profile email update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerProfileEmail(profileUpdateEmailCustomerRequest: ProfileUpdateEmailCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerProfileEmail(profileUpdateEmailCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logged-in customer can update its password
         * @summary Update the logged-in customer\'s profile password
         * @param {ProfilePasswordRequest} profilePasswordRequest A customer profile password request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerProfilePassword(profilePasswordRequest: ProfilePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerProfilePassword(profilePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A logged-in customer can update its profile personal
         * @summary Update the logged-in customer\'s profile personal
         * @param {ProfileUpdatePersonalCustomerRequest} profileUpdatePersonalCustomerRequest A customer profile personal update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerProfilePersonal(profileUpdatePersonalCustomerRequest: ProfileUpdatePersonalCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerProfilePersonal(profileUpdatePersonalCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerProfileApi - factory interface
 * @export
 */
export const CustomerProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerProfileApiFp(configuration)
    return {
        /**
         * A logged-in customer can fetch its Profile
         * @summary Get the logged-in customer\'s profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerProfile(options?: any): AxiosPromise<ProfileCustomerResponse> {
            return localVarFp.getCustomerProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * A logged-in customer can update its profile address
         * @summary Update the logged-in customer\'s profile address
         * @param {ProfileUpdateAddressCustomerRequest} profileUpdateAddressCustomerRequest A customer profile address update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerProfileAddress(profileUpdateAddressCustomerRequest: ProfileUpdateAddressCustomerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCustomerProfileAddress(profileUpdateAddressCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * A logged-in customer can update its profile contact details
         * @summary Update the logged-in customer\'s profile contact details
         * @param {ProfileUpdateContactCustomerRequest} profileUpdateContactCustomerRequest A customer profile contact details update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerProfileContact(profileUpdateContactCustomerRequest: ProfileUpdateContactCustomerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCustomerProfileContact(profileUpdateContactCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * A logged-in customer can update its profile email
         * @summary Update the logged-in customer\'s profile email
         * @param {ProfileUpdateEmailCustomerRequest} profileUpdateEmailCustomerRequest A customer profile email update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerProfileEmail(profileUpdateEmailCustomerRequest: ProfileUpdateEmailCustomerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCustomerProfileEmail(profileUpdateEmailCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Logged-in customer can update its password
         * @summary Update the logged-in customer\'s profile password
         * @param {ProfilePasswordRequest} profilePasswordRequest A customer profile password request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerProfilePassword(profilePasswordRequest: ProfilePasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCustomerProfilePassword(profilePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * A logged-in customer can update its profile personal
         * @summary Update the logged-in customer\'s profile personal
         * @param {ProfileUpdatePersonalCustomerRequest} profileUpdatePersonalCustomerRequest A customer profile personal update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerProfilePersonal(profileUpdatePersonalCustomerRequest: ProfileUpdatePersonalCustomerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCustomerProfilePersonal(profileUpdatePersonalCustomerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerProfileApi - interface
 * @export
 * @interface CustomerProfileApi
 */
export interface CustomerProfileApiInterface {
    /**
     * A logged-in customer can fetch its Profile
     * @summary Get the logged-in customer\'s profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApiInterface
     */
    getCustomerProfile(options?: AxiosRequestConfig): AxiosPromise<ProfileCustomerResponse>;

    /**
     * A logged-in customer can update its profile address
     * @summary Update the logged-in customer\'s profile address
     * @param {ProfileUpdateAddressCustomerRequest} profileUpdateAddressCustomerRequest A customer profile address update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApiInterface
     */
    updateCustomerProfileAddress(profileUpdateAddressCustomerRequest: ProfileUpdateAddressCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * A logged-in customer can update its profile contact details
     * @summary Update the logged-in customer\'s profile contact details
     * @param {ProfileUpdateContactCustomerRequest} profileUpdateContactCustomerRequest A customer profile contact details update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApiInterface
     */
    updateCustomerProfileContact(profileUpdateContactCustomerRequest: ProfileUpdateContactCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * A logged-in customer can update its profile email
     * @summary Update the logged-in customer\'s profile email
     * @param {ProfileUpdateEmailCustomerRequest} profileUpdateEmailCustomerRequest A customer profile email update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApiInterface
     */
    updateCustomerProfileEmail(profileUpdateEmailCustomerRequest: ProfileUpdateEmailCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Logged-in customer can update its password
     * @summary Update the logged-in customer\'s profile password
     * @param {ProfilePasswordRequest} profilePasswordRequest A customer profile password request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApiInterface
     */
    updateCustomerProfilePassword(profilePasswordRequest: ProfilePasswordRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * A logged-in customer can update its profile personal
     * @summary Update the logged-in customer\'s profile personal
     * @param {ProfileUpdatePersonalCustomerRequest} profileUpdatePersonalCustomerRequest A customer profile personal update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApiInterface
     */
    updateCustomerProfilePersonal(profileUpdatePersonalCustomerRequest: ProfileUpdatePersonalCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CustomerProfileApi - object-oriented interface
 * @export
 * @class CustomerProfileApi
 * @extends {BaseAPI}
 */
export class CustomerProfileApi extends BaseAPI implements CustomerProfileApiInterface {
    /**
     * A logged-in customer can fetch its Profile
     * @summary Get the logged-in customer\'s profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApi
     */
    public getCustomerProfile(options?: AxiosRequestConfig) {
        return CustomerProfileApiFp(this.configuration).getCustomerProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A logged-in customer can update its profile address
     * @summary Update the logged-in customer\'s profile address
     * @param {ProfileUpdateAddressCustomerRequest} profileUpdateAddressCustomerRequest A customer profile address update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApi
     */
    public updateCustomerProfileAddress(profileUpdateAddressCustomerRequest: ProfileUpdateAddressCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerProfileApiFp(this.configuration).updateCustomerProfileAddress(profileUpdateAddressCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A logged-in customer can update its profile contact details
     * @summary Update the logged-in customer\'s profile contact details
     * @param {ProfileUpdateContactCustomerRequest} profileUpdateContactCustomerRequest A customer profile contact details update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApi
     */
    public updateCustomerProfileContact(profileUpdateContactCustomerRequest: ProfileUpdateContactCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerProfileApiFp(this.configuration).updateCustomerProfileContact(profileUpdateContactCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A logged-in customer can update its profile email
     * @summary Update the logged-in customer\'s profile email
     * @param {ProfileUpdateEmailCustomerRequest} profileUpdateEmailCustomerRequest A customer profile email update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApi
     */
    public updateCustomerProfileEmail(profileUpdateEmailCustomerRequest: ProfileUpdateEmailCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerProfileApiFp(this.configuration).updateCustomerProfileEmail(profileUpdateEmailCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logged-in customer can update its password
     * @summary Update the logged-in customer\'s profile password
     * @param {ProfilePasswordRequest} profilePasswordRequest A customer profile password request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApi
     */
    public updateCustomerProfilePassword(profilePasswordRequest: ProfilePasswordRequest, options?: AxiosRequestConfig) {
        return CustomerProfileApiFp(this.configuration).updateCustomerProfilePassword(profilePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A logged-in customer can update its profile personal
     * @summary Update the logged-in customer\'s profile personal
     * @param {ProfileUpdatePersonalCustomerRequest} profileUpdatePersonalCustomerRequest A customer profile personal update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApi
     */
    public updateCustomerProfilePersonal(profileUpdatePersonalCustomerRequest: ProfileUpdatePersonalCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerProfileApiFp(this.configuration).updateCustomerProfilePersonal(profileUpdatePersonalCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerSessionApi - axios parameter creator
 * @export
 */
export const CustomerSessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * A customer session is required to access the customer APIs
         * @summary Create an customer session
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerSession: async (authenticationRequest: AuthenticationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticationRequest' is not null or undefined
            assertParamExists('createCustomerSession', 'authenticationRequest', authenticationRequest)
            const localVarPath = `/api/customer/v1/session/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirm an e-mail for the change to be persisted
         * @summary Confirm an e-mail change as part of the verification flow
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerConfirmEmail: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('customerConfirmEmail', 'token', token)
            const localVarPath = `/api/customer/v1/session/help/confirm-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirm a password change
         * @summary Confirm a password change either from a reset-password or forgot-password flow
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerConfirmPassword: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('customerConfirmPassword', 'token', token)
            const localVarPath = `/api/customer/v1/session/help/confirm-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Anyone can forget his password
         * @summary Trigger a reset-password process flow
         * @param {string} email email to initiate a reset-password flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerForgotPassword: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('customerForgotPassword', 'email', email)
            const localVarPath = `/api/customer/v1/session/help/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * When a new login is created for a Profile, the user must complete the flow
         * @summary End a new account password flow
         * @param {string} token Json-web-token authorizing the password change
         * @param {NewAccountPasswordData} newAccountPasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerNewAccountPassword: async (token: string, newAccountPasswordData: NewAccountPasswordData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('customerNewAccountPassword', 'token', token)
            // verify required parameter 'newAccountPasswordData' is not null or undefined
            assertParamExists('customerNewAccountPassword', 'newAccountPasswordData', newAccountPasswordData)
            const localVarPath = `/api/customer/v1/session/help/new-account-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAccountPasswordData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A customer session is required to be refreshed periodically
         * @summary Refresh an existing customer session
         * @param {AuthenticationRefreshRequest} authenticationRefreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshCustomerSession: async (authenticationRefreshRequest: AuthenticationRefreshRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticationRefreshRequest' is not null or undefined
            assertParamExists('refreshCustomerSession', 'authenticationRefreshRequest', authenticationRefreshRequest)
            const localVarPath = `/api/customer/v1/session/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticationRefreshRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerSessionApi - functional programming interface
 * @export
 */
export const CustomerSessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerSessionApiAxiosParamCreator(configuration)
    return {
        /**
         * A customer session is required to access the customer APIs
         * @summary Create an customer session
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerSession(authenticationRequest: AuthenticationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomerSession(authenticationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Confirm an e-mail for the change to be persisted
         * @summary Confirm an e-mail change as part of the verification flow
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerConfirmEmail(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerConfirmEmail(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Confirm a password change
         * @summary Confirm a password change either from a reset-password or forgot-password flow
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerConfirmPassword(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerConfirmPassword(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Anyone can forget his password
         * @summary Trigger a reset-password process flow
         * @param {string} email email to initiate a reset-password flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerForgotPassword(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerForgotPassword(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * When a new login is created for a Profile, the user must complete the flow
         * @summary End a new account password flow
         * @param {string} token Json-web-token authorizing the password change
         * @param {NewAccountPasswordData} newAccountPasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerNewAccountPassword(token: string, newAccountPasswordData: NewAccountPasswordData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerNewAccountPassword(token, newAccountPasswordData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A customer session is required to be refreshed periodically
         * @summary Refresh an existing customer session
         * @param {AuthenticationRefreshRequest} authenticationRefreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshCustomerSession(authenticationRefreshRequest: AuthenticationRefreshRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshCustomerSession(authenticationRefreshRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerSessionApi - factory interface
 * @export
 */
export const CustomerSessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerSessionApiFp(configuration)
    return {
        /**
         * A customer session is required to access the customer APIs
         * @summary Create an customer session
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerSession(authenticationRequest: AuthenticationRequest, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.createCustomerSession(authenticationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirm an e-mail for the change to be persisted
         * @summary Confirm an e-mail change as part of the verification flow
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerConfirmEmail(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerConfirmEmail(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirm a password change
         * @summary Confirm a password change either from a reset-password or forgot-password flow
         * @param {string} token JWT action token as part of the link sent by e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerConfirmPassword(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerConfirmPassword(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Anyone can forget his password
         * @summary Trigger a reset-password process flow
         * @param {string} email email to initiate a reset-password flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerForgotPassword(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerForgotPassword(email, options).then((request) => request(axios, basePath));
        },
        /**
         * When a new login is created for a Profile, the user must complete the flow
         * @summary End a new account password flow
         * @param {string} token Json-web-token authorizing the password change
         * @param {NewAccountPasswordData} newAccountPasswordData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerNewAccountPassword(token: string, newAccountPasswordData: NewAccountPasswordData, options?: any): AxiosPromise<void> {
            return localVarFp.customerNewAccountPassword(token, newAccountPasswordData, options).then((request) => request(axios, basePath));
        },
        /**
         * A customer session is required to be refreshed periodically
         * @summary Refresh an existing customer session
         * @param {AuthenticationRefreshRequest} authenticationRefreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshCustomerSession(authenticationRefreshRequest: AuthenticationRefreshRequest, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.refreshCustomerSession(authenticationRefreshRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerSessionApi - interface
 * @export
 * @interface CustomerSessionApi
 */
export interface CustomerSessionApiInterface {
    /**
     * A customer session is required to access the customer APIs
     * @summary Create an customer session
     * @param {AuthenticationRequest} authenticationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSessionApiInterface
     */
    createCustomerSession(authenticationRequest: AuthenticationRequest, options?: AxiosRequestConfig): AxiosPromise<AuthenticationResponse>;

    /**
     * Confirm an e-mail for the change to be persisted
     * @summary Confirm an e-mail change as part of the verification flow
     * @param {string} token JWT action token as part of the link sent by e-mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSessionApiInterface
     */
    customerConfirmEmail(token: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Confirm a password change
     * @summary Confirm a password change either from a reset-password or forgot-password flow
     * @param {string} token JWT action token as part of the link sent by e-mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSessionApiInterface
     */
    customerConfirmPassword(token: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Anyone can forget his password
     * @summary Trigger a reset-password process flow
     * @param {string} email email to initiate a reset-password flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSessionApiInterface
     */
    customerForgotPassword(email: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * When a new login is created for a Profile, the user must complete the flow
     * @summary End a new account password flow
     * @param {string} token Json-web-token authorizing the password change
     * @param {NewAccountPasswordData} newAccountPasswordData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSessionApiInterface
     */
    customerNewAccountPassword(token: string, newAccountPasswordData: NewAccountPasswordData, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * A customer session is required to be refreshed periodically
     * @summary Refresh an existing customer session
     * @param {AuthenticationRefreshRequest} authenticationRefreshRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSessionApiInterface
     */
    refreshCustomerSession(authenticationRefreshRequest: AuthenticationRefreshRequest, options?: AxiosRequestConfig): AxiosPromise<AuthenticationResponse>;

}

/**
 * CustomerSessionApi - object-oriented interface
 * @export
 * @class CustomerSessionApi
 * @extends {BaseAPI}
 */
export class CustomerSessionApi extends BaseAPI implements CustomerSessionApiInterface {
    /**
     * A customer session is required to access the customer APIs
     * @summary Create an customer session
     * @param {AuthenticationRequest} authenticationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSessionApi
     */
    public createCustomerSession(authenticationRequest: AuthenticationRequest, options?: AxiosRequestConfig) {
        return CustomerSessionApiFp(this.configuration).createCustomerSession(authenticationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Confirm an e-mail for the change to be persisted
     * @summary Confirm an e-mail change as part of the verification flow
     * @param {string} token JWT action token as part of the link sent by e-mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSessionApi
     */
    public customerConfirmEmail(token: string, options?: AxiosRequestConfig) {
        return CustomerSessionApiFp(this.configuration).customerConfirmEmail(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Confirm a password change
     * @summary Confirm a password change either from a reset-password or forgot-password flow
     * @param {string} token JWT action token as part of the link sent by e-mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSessionApi
     */
    public customerConfirmPassword(token: string, options?: AxiosRequestConfig) {
        return CustomerSessionApiFp(this.configuration).customerConfirmPassword(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Anyone can forget his password
     * @summary Trigger a reset-password process flow
     * @param {string} email email to initiate a reset-password flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSessionApi
     */
    public customerForgotPassword(email: string, options?: AxiosRequestConfig) {
        return CustomerSessionApiFp(this.configuration).customerForgotPassword(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * When a new login is created for a Profile, the user must complete the flow
     * @summary End a new account password flow
     * @param {string} token Json-web-token authorizing the password change
     * @param {NewAccountPasswordData} newAccountPasswordData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSessionApi
     */
    public customerNewAccountPassword(token: string, newAccountPasswordData: NewAccountPasswordData, options?: AxiosRequestConfig) {
        return CustomerSessionApiFp(this.configuration).customerNewAccountPassword(token, newAccountPasswordData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A customer session is required to be refreshed periodically
     * @summary Refresh an existing customer session
     * @param {AuthenticationRefreshRequest} authenticationRefreshRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSessionApi
     */
    public refreshCustomerSession(authenticationRefreshRequest: AuthenticationRefreshRequest, options?: AxiosRequestConfig) {
        return CustomerSessionApiFp(this.configuration).refreshCustomerSession(authenticationRefreshRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerTariffSchedulesApi - axios parameter creator
 * @export
 */
export const CustomerTariffSchedulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add tariff schedule to contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {AddTariffToContractRequest} addTariffToContractRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAddTariffScheduleToContract: async (contractId: string, addTariffToContractRequest: AddTariffToContractRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerAddTariffScheduleToContract', 'contractId', contractId)
            // verify required parameter 'addTariffToContractRequest' is not null or undefined
            assertParamExists('customerAddTariffScheduleToContract', 'addTariffToContractRequest', addTariffToContractRequest)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/tariffSchedule`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTariffToContractRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all tariff schedules for customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetAllTariffSchedules: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/v1/tariffSchedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve tariff schedule for customer
         * @param {string} id uuid of the tariff schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetTariffScheduleById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customerGetTariffScheduleById', 'id', id)
            const localVarPath = `/api/customer/v1/tariffSchedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve tariff schedule for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetTariffScheduleForContract: async (contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('customerGetTariffScheduleForContract', 'contractId', contractId)
            const localVarPath = `/api/customer/v1/contracts/{contractId}/tariffSchedule`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerTariffSchedulesApi - functional programming interface
 * @export
 */
export const CustomerTariffSchedulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerTariffSchedulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add tariff schedule to contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {AddTariffToContractRequest} addTariffToContractRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAddTariffScheduleToContract(contractId: string, addTariffToContractRequest: AddTariffToContractRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAddTariffScheduleToContract(contractId, addTariffToContractRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve all tariff schedules for customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetAllTariffSchedules(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffSchedulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetAllTariffSchedules(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve tariff schedule for customer
         * @param {string} id uuid of the tariff schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetTariffScheduleById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetTariffScheduleById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve tariff schedule for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetTariffScheduleForContract(contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetTariffScheduleForContract(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerTariffSchedulesApi - factory interface
 * @export
 */
export const CustomerTariffSchedulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerTariffSchedulesApiFp(configuration)
    return {
        /**
         * 
         * @summary Add tariff schedule to contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {AddTariffToContractRequest} addTariffToContractRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAddTariffScheduleToContract(contractId: string, addTariffToContractRequest: AddTariffToContractRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerAddTariffScheduleToContract(contractId, addTariffToContractRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve all tariff schedules for customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetAllTariffSchedules(options?: any): AxiosPromise<TariffSchedulesResponse> {
            return localVarFp.customerGetAllTariffSchedules(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve tariff schedule for customer
         * @param {string} id uuid of the tariff schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetTariffScheduleById(id: string, options?: any): AxiosPromise<TariffScheduleResponse> {
            return localVarFp.customerGetTariffScheduleById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve tariff schedule for contract
         * @param {string} contractId uuid of the Contract to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetTariffScheduleForContract(contractId: string, options?: any): AxiosPromise<TariffScheduleResponse> {
            return localVarFp.customerGetTariffScheduleForContract(contractId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerTariffSchedulesApi - interface
 * @export
 * @interface CustomerTariffSchedulesApi
 */
export interface CustomerTariffSchedulesApiInterface {
    /**
     * 
     * @summary Add tariff schedule to contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {AddTariffToContractRequest} addTariffToContractRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTariffSchedulesApiInterface
     */
    customerAddTariffScheduleToContract(contractId: string, addTariffToContractRequest: AddTariffToContractRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Retrieve all tariff schedules for customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTariffSchedulesApiInterface
     */
    customerGetAllTariffSchedules(options?: AxiosRequestConfig): AxiosPromise<TariffSchedulesResponse>;

    /**
     * 
     * @summary Retrieve tariff schedule for customer
     * @param {string} id uuid of the tariff schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTariffSchedulesApiInterface
     */
    customerGetTariffScheduleById(id: string, options?: AxiosRequestConfig): AxiosPromise<TariffScheduleResponse>;

    /**
     * 
     * @summary Retrieve tariff schedule for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTariffSchedulesApiInterface
     */
    customerGetTariffScheduleForContract(contractId: string, options?: AxiosRequestConfig): AxiosPromise<TariffScheduleResponse>;

}

/**
 * CustomerTariffSchedulesApi - object-oriented interface
 * @export
 * @class CustomerTariffSchedulesApi
 * @extends {BaseAPI}
 */
export class CustomerTariffSchedulesApi extends BaseAPI implements CustomerTariffSchedulesApiInterface {
    /**
     * 
     * @summary Add tariff schedule to contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {AddTariffToContractRequest} addTariffToContractRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTariffSchedulesApi
     */
    public customerAddTariffScheduleToContract(contractId: string, addTariffToContractRequest: AddTariffToContractRequest, options?: AxiosRequestConfig) {
        return CustomerTariffSchedulesApiFp(this.configuration).customerAddTariffScheduleToContract(contractId, addTariffToContractRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve all tariff schedules for customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTariffSchedulesApi
     */
    public customerGetAllTariffSchedules(options?: AxiosRequestConfig) {
        return CustomerTariffSchedulesApiFp(this.configuration).customerGetAllTariffSchedules(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve tariff schedule for customer
     * @param {string} id uuid of the tariff schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTariffSchedulesApi
     */
    public customerGetTariffScheduleById(id: string, options?: AxiosRequestConfig) {
        return CustomerTariffSchedulesApiFp(this.configuration).customerGetTariffScheduleById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve tariff schedule for contract
     * @param {string} contractId uuid of the Contract to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTariffSchedulesApi
     */
    public customerGetTariffScheduleForContract(contractId: string, options?: AxiosRequestConfig) {
        return CustomerTariffSchedulesApiFp(this.configuration).customerGetTariffScheduleForContract(contractId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerUtilityUnitApi - axios parameter creator
 * @export
 */
export const CustomerUtilityUnitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Provides calculated meter-readings for a utilityUnit over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific utilityUnit by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetMeterReadingsByUtilityUnitId: async (utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerGetMeterReadingsByUtilityUnitId', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('customerGetMeterReadingsByUtilityUnitId', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('customerGetMeterReadingsByUtilityUnitId', 'endDate', endDate)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('customerGetMeterReadingsByUtilityUnitId', 'resolution', resolution)
            const localVarPath = `/api/customer/v1/meter-readings/utilityUnits/{utilityUnitId}`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }

            if (isFinalOnly !== undefined) {
                localVarQueryParameter['isFinalOnly'] = isFinalOnly;
            }

            if (contractType !== undefined) {
                localVarQueryParameter['contractType'] = contractType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Meters linked to the Utility Unit
         * @summary Get meters with the provided details
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetMetersForUtilityUnit: async (utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerGetMetersForUtilityUnit', 'utilityUnitId', utilityUnitId)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/meters`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all participations of a utility unit
         * @summary Get all participations of a utility unit
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetParticipationsByUtilityUnitId: async (utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerGetParticipationsByUtilityUnitId', 'utilityUnitId', utilityUnitId)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/participations`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the billings of a consumption point
         * @summary Get all the billings of a consumption point
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetUtilityUnitBillingsById: async (utilityUnitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerGetUtilityUnitBillingsById', 'utilityUnitId', utilityUnitId)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/billings`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Utility Unit can be fetched by their respective UUID
         * @summary Get a Utility Unit by its ID
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetUtilityUnitById: async (utilityUnitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerGetUtilityUnitById', 'utilityUnitId', utilityUnitId)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new participation with a new participant for a vacancy
         * @summary Creates a new participation with a new participant for a vacancy
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerMoveinParticipationExisting: async (utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerMoveinParticipationExisting', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('customerMoveinParticipationExisting', 'participationId', participationId)
            // verify required parameter 'moveinParticipationExistingRequest' is not null or undefined
            assertParamExists('customerMoveinParticipationExisting', 'moveinParticipationExistingRequest', moveinParticipationExistingRequest)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein/existing`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveinParticipationExistingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create the participation for a utilityUnit with new participant
         * @summary Create the participation for a utilityUnit with new participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinWithNewParticipantCustomerRequest} moveinWithNewParticipantCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerMoveinWithNewParticipant: async (utilityUnitId: string, participationId: string, moveinWithNewParticipantCustomerRequest: MoveinWithNewParticipantCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerMoveinWithNewParticipant', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('customerMoveinWithNewParticipant', 'participationId', participationId)
            // verify required parameter 'moveinWithNewParticipantCustomerRequest' is not null or undefined
            assertParamExists('customerMoveinWithNewParticipant', 'moveinWithNewParticipantCustomerRequest', moveinWithNewParticipantCustomerRequest)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein/new`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveinWithNewParticipantCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a vacancy after the moveout
         * @summary Creates a vacancy after the moveout
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerMoveoutParticipationVacancy: async (utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerMoveoutParticipationVacancy', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('customerMoveoutParticipationVacancy', 'participationId', participationId)
            // verify required parameter 'moveoutParticipationVacancyRequest' is not null or undefined
            assertParamExists('customerMoveoutParticipationVacancy', 'moveoutParticipationVacancyRequest', moveoutParticipationVacancyRequest)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/moveout/successor/vacancy`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveoutParticipationVacancyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change the movein date of a participation
         * @summary Change the movein date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerParticipationChangeMoveinDate: async (utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerParticipationChangeMoveinDate', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('customerParticipationChangeMoveinDate', 'participationId', participationId)
            // verify required parameter 'participationUpdateMoveinRequest' is not null or undefined
            assertParamExists('customerParticipationChangeMoveinDate', 'participationUpdateMoveinRequest', participationUpdateMoveinRequest)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participationUpdateMoveinRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change the moveout date of a participation
         * @summary Change the moveout date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerParticipationChangeMoveoutDate: async (utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerParticipationChangeMoveoutDate', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('customerParticipationChangeMoveoutDate', 'participationId', participationId)
            // verify required parameter 'participationUpdateMoveoutRequest' is not null or undefined
            assertParamExists('customerParticipationChangeMoveoutDate', 'participationUpdateMoveoutRequest', participationUpdateMoveoutRequest)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/moveout`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participationUpdateMoveoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stops the movein of a participant
         * @summary Stops the movein of a participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerParticipationStopMovein: async (utilityUnitId: string, participationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerParticipationStopMovein', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('customerParticipationStopMovein', 'participationId', participationId)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/movein`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stops the moveout of the participant
         * @summary Stops the moveout of the participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerParticipationStopMoveout: async (utilityUnitId: string, participationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerParticipationStopMoveout', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('customerParticipationStopMoveout', 'participationId', participationId)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/moveout`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replace Participant for a Participation
         * @summary Replace Participant for a Participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ReplaceParticipantRequest} replaceParticipantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerReplaceParticipant: async (utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('customerReplaceParticipant', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('customerReplaceParticipant', 'participationId', participationId)
            // verify required parameter 'replaceParticipantRequest' is not null or undefined
            assertParamExists('customerReplaceParticipant', 'replaceParticipantRequest', replaceParticipantRequest)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/participations/{participationId}/participant/replace`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceParticipantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a participation by id
         * @summary Get a participation by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerParticipationById: async (utilityUnitId: string, participationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilityUnitId' is not null or undefined
            assertParamExists('getCustomerParticipationById', 'utilityUnitId', utilityUnitId)
            // verify required parameter 'participationId' is not null or undefined
            assertParamExists('getCustomerParticipationById', 'participationId', participationId)
            const localVarPath = `/api/customer/v1/utilityUnits/{utilityUnitId}/participations/{participationId}`
                .replace(`{${"utilityUnitId"}}`, encodeURIComponent(String(utilityUnitId)))
                .replace(`{${"participationId"}}`, encodeURIComponent(String(participationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication customerBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerUtilityUnitApi - functional programming interface
 * @export
 */
export const CustomerUtilityUnitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerUtilityUnitApiAxiosParamCreator(configuration)
    return {
        /**
         * Provides calculated meter-readings for a utilityUnit over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific utilityUnit by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadingsUtilityUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetMeterReadingsByUtilityUnitId(utilityUnitId, startDate, endDate, resolution, isFinalOnly, contractType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Meters linked to the Utility Unit
         * @summary Get meters with the provided details
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetMetersForUtilityUnit(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMeterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetMetersForUtilityUnit(utilityUnitId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all participations of a utility unit
         * @summary Get all participations of a utility unit
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetParticipationsByUtilityUnitId(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedParticipationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetParticipationsByUtilityUnitId(utilityUnitId, page, limit, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the billings of a consumption point
         * @summary Get all the billings of a consumption point
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetUtilityUnitBillingsById(utilityUnitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiBillingDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetUtilityUnitBillingsById(utilityUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Utility Unit can be fetched by their respective UUID
         * @summary Get a Utility Unit by its ID
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetUtilityUnitById(utilityUnitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilityUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetUtilityUnitById(utilityUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new participation with a new participant for a vacancy
         * @summary Creates a new participation with a new participant for a vacancy
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerMoveinParticipationExisting(utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerMoveinParticipationExisting(utilityUnitId, participationId, moveinParticipationExistingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create the participation for a utilityUnit with new participant
         * @summary Create the participation for a utilityUnit with new participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinWithNewParticipantCustomerRequest} moveinWithNewParticipantCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantCustomerRequest: MoveinWithNewParticipantCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerMoveinWithNewParticipant(utilityUnitId, participationId, moveinWithNewParticipantCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a vacancy after the moveout
         * @summary Creates a vacancy after the moveout
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerMoveoutParticipationVacancy(utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerMoveoutParticipationVacancy(utilityUnitId, participationId, moveoutParticipationVacancyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Change the movein date of a participation
         * @summary Change the movein date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerParticipationChangeMoveinDate(utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerParticipationChangeMoveinDate(utilityUnitId, participationId, participationUpdateMoveinRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Change the moveout date of a participation
         * @summary Change the moveout date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerParticipationChangeMoveoutDate(utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerParticipationChangeMoveoutDate(utilityUnitId, participationId, participationUpdateMoveoutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stops the movein of a participant
         * @summary Stops the movein of a participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerParticipationStopMovein(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerParticipationStopMovein(utilityUnitId, participationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stops the moveout of the participant
         * @summary Stops the moveout of the participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerParticipationStopMoveout(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerParticipationStopMoveout(utilityUnitId, participationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replace Participant for a Participation
         * @summary Replace Participant for a Participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ReplaceParticipantRequest} replaceParticipantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerReplaceParticipant(utilityUnitId, participationId, replaceParticipantRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a participation by id
         * @summary Get a participation by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerParticipationById(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerParticipationById(utilityUnitId, participationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerUtilityUnitApi - factory interface
 * @export
 */
export const CustomerUtilityUnitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerUtilityUnitApiFp(configuration)
    return {
        /**
         * Provides calculated meter-readings for a utilityUnit over a time period divided according to the provided time resolution
         * @summary Get meter-readings for a specific utilityUnit by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} startDate startDate of the query
         * @param {string} endDate endDate of the query
         * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
         * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
         * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: any): AxiosPromise<MeterReadingsUtilityUnitResponse> {
            return localVarFp.customerGetMeterReadingsByUtilityUnitId(utilityUnitId, startDate, endDate, resolution, isFinalOnly, contractType, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Meters linked to the Utility Unit
         * @summary Get meters with the provided details
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetMetersForUtilityUnit(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedMeterResponse> {
            return localVarFp.customerGetMetersForUtilityUnit(utilityUnitId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all participations of a utility unit
         * @summary Get all participations of a utility unit
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {number} [page] The page number to start from
         * @param {number} [limit] The limit number of hits per page
         * @param {string} [orderBy] The sorting order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetParticipationsByUtilityUnitId(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: any): AxiosPromise<PagedParticipationResponse> {
            return localVarFp.customerGetParticipationsByUtilityUnitId(utilityUnitId, page, limit, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the billings of a consumption point
         * @summary Get all the billings of a consumption point
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetUtilityUnitBillingsById(utilityUnitId: string, options?: any): AxiosPromise<MultiBillingDetailsResponse> {
            return localVarFp.customerGetUtilityUnitBillingsById(utilityUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Utility Unit can be fetched by their respective UUID
         * @summary Get a Utility Unit by its ID
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetUtilityUnitById(utilityUnitId: string, options?: any): AxiosPromise<UtilityUnitResponse> {
            return localVarFp.customerGetUtilityUnitById(utilityUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new participation with a new participant for a vacancy
         * @summary Creates a new participation with a new participant for a vacancy
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerMoveinParticipationExisting(utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options?: any): AxiosPromise<string> {
            return localVarFp.customerMoveinParticipationExisting(utilityUnitId, participationId, moveinParticipationExistingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create the participation for a utilityUnit with new participant
         * @summary Create the participation for a utilityUnit with new participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveinWithNewParticipantCustomerRequest} moveinWithNewParticipantCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantCustomerRequest: MoveinWithNewParticipantCustomerRequest, options?: any): AxiosPromise<string> {
            return localVarFp.customerMoveinWithNewParticipant(utilityUnitId, participationId, moveinWithNewParticipantCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a vacancy after the moveout
         * @summary Creates a vacancy after the moveout
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerMoveoutParticipationVacancy(utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options?: any): AxiosPromise<string> {
            return localVarFp.customerMoveoutParticipationVacancy(utilityUnitId, participationId, moveoutParticipationVacancyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Change the movein date of a participation
         * @summary Change the movein date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerParticipationChangeMoveinDate(utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerParticipationChangeMoveinDate(utilityUnitId, participationId, participationUpdateMoveinRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Change the moveout date of a participation
         * @summary Change the moveout date of a participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerParticipationChangeMoveoutDate(utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerParticipationChangeMoveoutDate(utilityUnitId, participationId, participationUpdateMoveoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Stops the movein of a participant
         * @summary Stops the movein of a participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerParticipationStopMovein(utilityUnitId: string, participationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerParticipationStopMovein(utilityUnitId, participationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Stops the moveout of the participant
         * @summary Stops the moveout of the participant
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerParticipationStopMoveout(utilityUnitId: string, participationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerParticipationStopMoveout(utilityUnitId, participationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Replace Participant for a Participation
         * @summary Replace Participant for a Participation
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {ReplaceParticipantRequest} replaceParticipantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerReplaceParticipant(utilityUnitId, participationId, replaceParticipantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a participation by id
         * @summary Get a participation by id
         * @param {string} utilityUnitId uuid of the Utility Unit
         * @param {string} participationId uuid of the participation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerParticipationById(utilityUnitId: string, participationId: string, options?: any): AxiosPromise<ParticipationResponse> {
            return localVarFp.getCustomerParticipationById(utilityUnitId, participationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerUtilityUnitApi - interface
 * @export
 * @interface CustomerUtilityUnitApi
 */
export interface CustomerUtilityUnitApiInterface {
    /**
     * Provides calculated meter-readings for a utilityUnit over a time period divided according to the provided time resolution
     * @summary Get meter-readings for a specific utilityUnit by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
     * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: AxiosRequestConfig): AxiosPromise<MeterReadingsUtilityUnitResponse>;

    /**
     * Get Meters linked to the Utility Unit
     * @summary Get meters with the provided details
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerGetMetersForUtilityUnit(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedMeterResponse>;

    /**
     * Get all participations of a utility unit
     * @summary Get all participations of a utility unit
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerGetParticipationsByUtilityUnitId(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig): AxiosPromise<PagedParticipationResponse>;

    /**
     * Get all the billings of a consumption point
     * @summary Get all the billings of a consumption point
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerGetUtilityUnitBillingsById(utilityUnitId: string, options?: AxiosRequestConfig): AxiosPromise<MultiBillingDetailsResponse>;

    /**
     * Utility Unit can be fetched by their respective UUID
     * @summary Get a Utility Unit by its ID
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerGetUtilityUnitById(utilityUnitId: string, options?: AxiosRequestConfig): AxiosPromise<UtilityUnitResponse>;

    /**
     * Creates a new participation with a new participant for a vacancy
     * @summary Creates a new participation with a new participant for a vacancy
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerMoveinParticipationExisting(utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Create the participation for a utilityUnit with new participant
     * @summary Create the participation for a utilityUnit with new participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinWithNewParticipantCustomerRequest} moveinWithNewParticipantCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantCustomerRequest: MoveinWithNewParticipantCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Creates a vacancy after the moveout
     * @summary Creates a vacancy after the moveout
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerMoveoutParticipationVacancy(utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Change the movein date of a participation
     * @summary Change the movein date of a participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerParticipationChangeMoveinDate(utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Change the moveout date of a participation
     * @summary Change the moveout date of a participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerParticipationChangeMoveoutDate(utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Stops the movein of a participant
     * @summary Stops the movein of a participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerParticipationStopMovein(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Stops the moveout of the participant
     * @summary Stops the moveout of the participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerParticipationStopMoveout(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Replace Participant for a Participation
     * @summary Replace Participant for a Participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ReplaceParticipantRequest} replaceParticipantRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    customerReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get a participation by id
     * @summary Get a participation by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApiInterface
     */
    getCustomerParticipationById(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig): AxiosPromise<ParticipationResponse>;

}

/**
 * CustomerUtilityUnitApi - object-oriented interface
 * @export
 * @class CustomerUtilityUnitApi
 * @extends {BaseAPI}
 */
export class CustomerUtilityUnitApi extends BaseAPI implements CustomerUtilityUnitApiInterface {
    /**
     * Provides calculated meter-readings for a utilityUnit over a time period divided according to the provided time resolution
     * @summary Get meter-readings for a specific utilityUnit by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} startDate startDate of the query
     * @param {string} endDate endDate of the query
     * @param {MeterReadingsCalcResolution} resolution resolution window of the returned data
     * @param {boolean} [isFinalOnly] determines whether all or only final consumption data will be used for calculation
     * @param {ContractType} [contractType] contract type to return calculations for (ZEV, VEWA, ALL)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerGetMeterReadingsByUtilityUnitId(utilityUnitId: string, startDate: string, endDate: string, resolution: MeterReadingsCalcResolution, isFinalOnly?: boolean, contractType?: ContractType, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerGetMeterReadingsByUtilityUnitId(utilityUnitId, startDate, endDate, resolution, isFinalOnly, contractType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Meters linked to the Utility Unit
     * @summary Get meters with the provided details
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerGetMetersForUtilityUnit(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerGetMetersForUtilityUnit(utilityUnitId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all participations of a utility unit
     * @summary Get all participations of a utility unit
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {number} [page] The page number to start from
     * @param {number} [limit] The limit number of hits per page
     * @param {string} [orderBy] The sorting order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerGetParticipationsByUtilityUnitId(utilityUnitId: string, page?: number, limit?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerGetParticipationsByUtilityUnitId(utilityUnitId, page, limit, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the billings of a consumption point
     * @summary Get all the billings of a consumption point
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerGetUtilityUnitBillingsById(utilityUnitId: string, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerGetUtilityUnitBillingsById(utilityUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Utility Unit can be fetched by their respective UUID
     * @summary Get a Utility Unit by its ID
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerGetUtilityUnitById(utilityUnitId: string, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerGetUtilityUnitById(utilityUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new participation with a new participant for a vacancy
     * @summary Creates a new participation with a new participant for a vacancy
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinParticipationExistingRequest} moveinParticipationExistingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerMoveinParticipationExisting(utilityUnitId: string, participationId: string, moveinParticipationExistingRequest: MoveinParticipationExistingRequest, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerMoveinParticipationExisting(utilityUnitId, participationId, moveinParticipationExistingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create the participation for a utilityUnit with new participant
     * @summary Create the participation for a utilityUnit with new participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveinWithNewParticipantCustomerRequest} moveinWithNewParticipantCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerMoveinWithNewParticipant(utilityUnitId: string, participationId: string, moveinWithNewParticipantCustomerRequest: MoveinWithNewParticipantCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerMoveinWithNewParticipant(utilityUnitId, participationId, moveinWithNewParticipantCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a vacancy after the moveout
     * @summary Creates a vacancy after the moveout
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {MoveoutParticipationVacancyRequest} moveoutParticipationVacancyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerMoveoutParticipationVacancy(utilityUnitId: string, participationId: string, moveoutParticipationVacancyRequest: MoveoutParticipationVacancyRequest, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerMoveoutParticipationVacancy(utilityUnitId, participationId, moveoutParticipationVacancyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change the movein date of a participation
     * @summary Change the movein date of a participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ParticipationUpdateMoveinRequest} participationUpdateMoveinRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerParticipationChangeMoveinDate(utilityUnitId: string, participationId: string, participationUpdateMoveinRequest: ParticipationUpdateMoveinRequest, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerParticipationChangeMoveinDate(utilityUnitId, participationId, participationUpdateMoveinRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change the moveout date of a participation
     * @summary Change the moveout date of a participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ParticipationUpdateMoveoutRequest} participationUpdateMoveoutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerParticipationChangeMoveoutDate(utilityUnitId: string, participationId: string, participationUpdateMoveoutRequest: ParticipationUpdateMoveoutRequest, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerParticipationChangeMoveoutDate(utilityUnitId, participationId, participationUpdateMoveoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stops the movein of a participant
     * @summary Stops the movein of a participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerParticipationStopMovein(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerParticipationStopMovein(utilityUnitId, participationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stops the moveout of the participant
     * @summary Stops the moveout of the participant
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerParticipationStopMoveout(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerParticipationStopMoveout(utilityUnitId, participationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replace Participant for a Participation
     * @summary Replace Participant for a Participation
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {ReplaceParticipantRequest} replaceParticipantRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public customerReplaceParticipant(utilityUnitId: string, participationId: string, replaceParticipantRequest: ReplaceParticipantRequest, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).customerReplaceParticipant(utilityUnitId, participationId, replaceParticipantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a participation by id
     * @summary Get a participation by id
     * @param {string} utilityUnitId uuid of the Utility Unit
     * @param {string} participationId uuid of the participation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUtilityUnitApi
     */
    public getCustomerParticipationById(utilityUnitId: string, participationId: string, options?: AxiosRequestConfig) {
        return CustomerUtilityUnitApiFp(this.configuration).getCustomerParticipationById(utilityUnitId, participationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns information required to render breadcrumbs on UI
         * @param {BreadcrumbsAdminRequest} breadcrumbsAdminRequest Request should contain at least one of IDs - Building, Utility Unit or Meter. In case more that one are set, the response will contain only one which is higher  in the hierarchy: Building &gt; Utility Unit &gt; Meter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBreadcrumbInfo: async (breadcrumbsAdminRequest: BreadcrumbsAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'breadcrumbsAdminRequest' is not null or undefined
            assertParamExists('adminGetBreadcrumbInfo', 'breadcrumbsAdminRequest', breadcrumbsAdminRequest)
            const localVarPath = `/api/admin/v1/breadcrumbs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(breadcrumbsAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns information required to render breadcrumbs on UI
         * @param {BreadcrumbsAdminRequest} breadcrumbsAdminRequest Request should contain at least one of IDs - Building, Utility Unit or Meter. In case more that one are set, the response will contain only one which is higher  in the hierarchy: Building &gt; Utility Unit &gt; Meter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetBreadcrumbInfo(breadcrumbsAdminRequest: BreadcrumbsAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BreadcrumbsAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetBreadcrumbInfo(breadcrumbsAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns information required to render breadcrumbs on UI
         * @param {BreadcrumbsAdminRequest} breadcrumbsAdminRequest Request should contain at least one of IDs - Building, Utility Unit or Meter. In case more that one are set, the response will contain only one which is higher  in the hierarchy: Building &gt; Utility Unit &gt; Meter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBreadcrumbInfo(breadcrumbsAdminRequest: BreadcrumbsAdminRequest, options?: any): AxiosPromise<BreadcrumbsAdminResponse> {
            return localVarFp.adminGetBreadcrumbInfo(breadcrumbsAdminRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @summary Returns information required to render breadcrumbs on UI
     * @param {BreadcrumbsAdminRequest} breadcrumbsAdminRequest Request should contain at least one of IDs - Building, Utility Unit or Meter. In case more that one are set, the response will contain only one which is higher  in the hierarchy: Building &gt; Utility Unit &gt; Meter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    adminGetBreadcrumbInfo(breadcrumbsAdminRequest: BreadcrumbsAdminRequest, options?: AxiosRequestConfig): AxiosPromise<BreadcrumbsAdminResponse>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @summary Returns information required to render breadcrumbs on UI
     * @param {BreadcrumbsAdminRequest} breadcrumbsAdminRequest Request should contain at least one of IDs - Building, Utility Unit or Meter. In case more that one are set, the response will contain only one which is higher  in the hierarchy: Building &gt; Utility Unit &gt; Meter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminGetBreadcrumbInfo(breadcrumbsAdminRequest: BreadcrumbsAdminRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminGetBreadcrumbInfo(breadcrumbsAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


