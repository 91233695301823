import { BillingsInitialList } from "../../../../../domain/portal/admin/billings/initial/BillingsInitial.Model"
import { getInitialBillings } from "../../../../../domain/portal/admin/billings/initial/BillingsInitial.Repository"
import { DOMAIN_DEPENDENCIES } from "../../../../App.Config"
import { createEpic } from "../../../../Shared.Epic"

export enum BillingsInitialActionType {
  BILLINGS_INITIAL_LIST_GET = "BILLINGS_INITIAL_LIST_GET",
}

export const billingsInitialEpic = [
  createEpic<BillingsInitialList>(BillingsInitialActionType.BILLINGS_INITIAL_LIST_GET, () =>
    getInitialBillings(DOMAIN_DEPENDENCIES),
  ),
]
